export enum Roles {
  Admin = 'admin',
  CallCenterUser = 'callcenter',
  Experementor = 'pilot'
}

export interface ITokenResponse {
  access_token: string;
  expires: string;
  issued?: Date;
  language?: string;
  token_created: string;
  username: string;
  id?: string;
}

export interface IUserInterface extends ITokenResponse {
  role?: Roles;
  allowNotifications?: boolean;
  account_id?: number;
}

export interface IUserMe {
  id: number;
  name: string;
  customerId: string;
  customerName: string;
}

export interface IUserRequest {
  username: string;
  password: string;
  grant_type: string;
}
