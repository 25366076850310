<div *mobxAutorun class="p-d-flex p-flex-column">
  <div class="p-d-flex p-jc-between p-ai-center">
    <app-sub-heading
      [item]="accountsStore.account"
      [accountId]="accountId"
      [path]="'notifications'">
    </app-sub-heading>
    <div class="p-d-flex p-ai-center">
      <app-sub-nav
        *ngIf="accountId!=='' && accountId !== undefined"
        [id]="accountId"
        [path]="'accounts'"
        class="p-as-end">
      </app-sub-nav>
      <app-sub-search
        (filterHandler)="onFilterGlobal($event)"
        [page]="'notifications'">
      </app-sub-search>
    </div>
  </div>
  <div>
    <div class="card">
      <p-table #dt
               stateStorage="{{ configOptions.pages.notifications.storageType }}"
               stateKey="{{ configOptions.pages.notifications.storageKeyTable }}"
               [(selection)]="selectedItems"
               [columns]="selectedColumns"
               [customSort]="true"
               (onSort)="onSort($event)"
               (onStateSave)="onStateSave($event)"
               [globalFilterFields]="this.globalFilterFields"
               [loading]="loading"
               [lazy]="false"
               [resizableColumns]="true"
               [rowHover]="true"
               [rowsPerPageOptions]="[5,10,25,50]"
               [rows]="notificationQuery?.query?.limit || selectedRowsPerPage"
               [reorderableColumns]="true"
               [showCurrentPageReport]="false"
               [totalRecords]="paginationSettings.totalItems"
               [value]="items"
               dataKey="id"
               scrollHeight="100%"
               tableStyleClass="p-datatable p-datatable-sm p-datatable-striped p-datatable-customers p-datatable-gridlines">
        <ng-template pTemplate="caption">
          <div class="p-d-flex p-jc-between p-ai-center p-flex-column p-flex-md-row">
            <p-multiSelect
              styleClass="select-columns p-my-2"
              [options]="cols"
              [showToggleAll]="selectedColumns?.length !== cols?.length"
              [(ngModel)]="selectedColumns"
              optionLabel="name"
              selectedItemsLabel="{0} {{ 'DEFAULTS.columns_selected' | translate }}"
              [style]="{minWidth: '200px'}"
              placeholder="{{ 'DEFAULTS.choose_columns' | translate }}">
              <ng-template let-option pTemplate="item">
                <div class="p-multiselect-representative-option">
                  <span
                    class="p-ml-1">{{'PAGES.notifications.fields.' + option.name | translate}}</span>
                </div>
              </ng-template>
            </p-multiSelect>
            <app-filter-list
              [options]="{showTitle: true}"
              (filterChange)="filterTable($event)">
            </app-filter-list>
          </div>
        </ng-template>
        <ng-template let-columns pTemplate="header">
          <tr>
            <th *ngFor="let col of columns"
                [ngStyle]="col.styles"
                pReorderableColumn
                pResizableColumn
                pSortableColumn="{{col?.sortable ? col.name : '' }}">
              <div class="p-d-flex p-ai-center p-py-1 font-condenced">
                <p-sortIcon *ngIf="col?.sortable" field="{{col.name}}"></p-sortIcon>
                {{ 'PAGES.notifications.fields.' + col.name | translate}}
              </div>
            </th>
            <th [style.width]="'60px !important'">
              <p-button
                (click)="dt.clear(); clearFilterState()"
                icon="pi pi-filter-slash">
              </p-button>
            </th>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-item pTemplate="body">
          <tr class="items-row" [ngStyle]="{'background-color': getStatusBg(item['state'])}">
            <td *ngFor="let col of columns" [ngStyle]="col.styles" [routerLink]="['./' + item.id]"
                class="td-row">
              <ng-container *ngIf="!col['component'] else components">
                {{item[col.name]}}
              </ng-container>
              <ng-template #components>
                <ng-container [ngSwitch]="col['component']">
                  <ng-container *ngSwitchCase="'frequency'">
                    {{ 'PAGES.rules.frequency.' + item[col.name] | translate }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'extra_values'">
                    <ng-container *ngIf="item['extra_values']['base_value']">
                      {{ 'PAGES.notifications.fields.extra_values.base_value' | translate }}
                      :
                      <ng-container *ngIf="item['extra_values']['base_value_unit']!=='time' else regular_base_value">
                        {{ item['extra_values']['base_value'] | number:'1.0-1' }}
                      </ng-container>
                      <ng-template #regular_base_value>{{ item['extra_values']['base_value'] }}</ng-template>
                      {{ 'PAGES.rules.base_units_short.' + item['extra_values']['base_value_unit'] | translate }}
                      <br>
                    </ng-container>
                    <ng-container *ngIf="item['extra_values']['actual_value']">
                      <ng-container *ngIf="item['extra_values']['base_value_unit']!=='time' else regular_actual_value">
                        {{ 'PAGES.notifications.fields.extra_values.actual_value' | translate }}
                        : {{ item['extra_values']['actual_value'] | number:'1.0-1' }}
                      </ng-container>
                      <ng-template #regular_actual_value>
                        {{ 'PAGES.notifications.fields.extra_values.actual_value' | translate }}
                        : {{ item['extra_values']['actual_value'] }}
                      </ng-template>
                      {{ 'PAGES.rules.base_units_short.' + item['extra_values']['base_value_unit'] | translate }}
                      <br>
                    </ng-container>
                    <ng-container>
                      {{ 'PAGES.notifications.fields.extra_values.limits' | translate }}
                      : {{ item['extra_values']['low_value'] | number:'1.0-1'}}
                      ... {{ item['extra_values']['high_value'] | number:'1.0-1' }}
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'created_at'">
                    {{item?.date_time_year}}<br>
                    {{item?.date_time_date}}
                  </ng-container>
                  <ng-container *ngSwitchCase="'state'">
                    <div [ngStyle]="{'font-weight': item[col.name] === 'open'? 800 : 400, 'text-align':'center'}">
                      {{ 'PAGES.notifications.' + col.name + '.' + item[col.name] | translate}}
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'type'">
                    <strong [style.color]="utilityService.notificationType[item[col.name]]">
                      <i class="pi {{alertTypes[item[col.name]].icon}}"></i>
                      {{ 'PAGES.notifications.' + col.name + '.' + item[col.name] | translate}}
                    </strong>
                  </ng-container>
                </ng-container>
              </ng-template>
            </td>
            <td [style.width]="'60px !important'">
              <app-sub-actions-buttons
                [includedRoles]="Roles.CallCenterUser"
                [allowDelete]="false"
                [allowEdit]="true"
                [allowView]="false"
                [item]="item">
              </app-sub-actions-buttons>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="summary">
          <p-paginator [rows]="notificationQuery?.query?.limit || selectedRowsPerPage"
                       [totalRecords]="paginationSettings.totalItems"
                       [rowsPerPageOptions]="[5,10,20,30,50]"
                       (onPageChange)="onPage($event)">
          </p-paginator>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="selectedColumns?.length + 1">
              <div class="p-d-flex p-jc-center">{{ 'DEFAULTS.no_data_found' | translate }}</div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
