/**
 * @Service Persons service for CRUD actions with API server.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PagesSharedModule } from '@pages/pages-shared.module';

import { environment } from '@environments/environment';
import { NAVIGATION } from '@app/const';
import { IAccountPerson } from '@pages/accounts/persons/persons.interface';
import { lastValueFrom, map } from 'rxjs';

@Injectable()
export class PersonsService {
  apiURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  api = (id) => `${this.apiURL}/${NAVIGATION.accounts.api}/${id}/${NAVIGATION.persons.api}`;

  getPersons(accountId: string): Promise<HttpResponse<IAccountPerson[]>> {
    return lastValueFrom(
      this.http
        .get<IAccountPerson[]>(this.api(accountId), {
          observe: 'response'
        })
        .pipe(map((res: HttpResponse<IAccountPerson[]>) => res))
    );
  }

  getPerson(accountId: string, id: string): Promise<IAccountPerson> {
    return lastValueFrom(
      this.http
        .get<IAccountPerson>(`${this.api(accountId)}/${id}`, {
          observe: 'response'
        })
        .pipe(map((res: HttpResponse<IAccountPerson>) => res.body))
    );
  }

  addPerson(accountId: string, body: IAccountPerson): Promise<any> {
    return lastValueFrom(
      this.http.post<IAccountPerson>(this.api(accountId), body, {
        observe: 'response'
      })
    );
  }

  editPerson(accountId: string, body: IAccountPerson): Promise<any> {
    return lastValueFrom(
      this.http.put<IAccountPerson>(`${this.api(accountId)}/${body.id}`, body, {
        observe: 'response'
      })
    );
  }

  deletePerson(accountId: string, id: string): Promise<any> {
    return lastValueFrom(
      this.http.delete<any>(`${this.api(accountId)}/${id}`, {
        observe: 'response'
      })
    );
  }
}
