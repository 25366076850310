import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Roles } from '@app/core/interfaces/user.interface';

@Component({
  selector: 'app-sub-form-actions-buttons',
  templateUrl: './sub-form-actions-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubFormActionsButtonsComponent {
  @Output() saveHandler: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteHandler: EventEmitter<void> = new EventEmitter<void>();
  @Input() item;
  @Input() isSaveDisabled;
  @Input() isDeleteDisabled;
  @Input() isViewDisabled;
  @Input() showOptions;
  @Input() removeButtonText;
  @Input() includedRoles;
  Roles = Roles;

  constructor() {}
}
