/**
 * @Service Service for getting countries, cities data for using them in the dropdown lists mostly
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";

interface ICountries {
  cities: string[];
  country: string;
}

interface ICountriesResponse {
  label: string;
  value: string;
  cities: string[];
}

interface ICitiesResponse {
  label: string;
  value: string;
}

@Injectable()
export class DataService {
  apiURL = `${window.location.protocol}//${window.location.host}/assets/countries/countries.json`;
  countriesData: ICountriesResponse[] = [];

  constructor(private http: HttpClient) {}

  /**
   * @description Retrieving world countries array
   * @return Observable: countries array for populating countries dropdown
   */
  getCountries(): Observable<ICountriesResponse[]> {
    if (this.countriesData.length > 0) {
      return of(this.countriesData);
    }
    return this.http.get<ICountries[]>(this.apiURL).pipe(
      map((countriesArray) => {
        return countriesArray.map((el) => ({ label: el.country, value: el.country, cities: el.cities }));
      }),
      tap(countries => (this.countriesData = countries))
    );
  }

  /**
   * @description Retrieving world cities array depends on the country name
   * @param country: country name as string
   * @return Observable: cities array for populating cities dropdown
   */

  getCities(country: string): Observable<ICitiesResponse[]> {
    const mapFunction = (it: ICountries[]) => {
      const foundCountry = it.find((el) => el.country === country)?.cities;
      return foundCountry ? foundCountry.map((el) => ({ label: el, value: el })) : [];
    }
    if (this.countriesData.length > 0) {
      const foundCountry = this.countriesData.find((el) => el.value === country)?.cities;
      const mappedCity = foundCountry ? foundCountry.map((el) => ({ label: el, value: el })) : [];
      return of(mappedCity);
    }
    return this.http.get<ICountries[]>(this.apiURL).pipe(map(mapFunction));
  }
}
