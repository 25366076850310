<div *mobxAutorun class="p-d-flex p-flex-column">
  <app-sub-nav [id]="accountId" [path]="'accounts'" class="p-mb-2 p-as-end"></app-sub-nav>
  <div class="p-d-flex p-jc-between p-ai-center p-mb-2">
    <app-sub-heading
      [item]="accountsStore.account"
      [accountId]="accountId"
      [path]="'contacts'">
    </app-sub-heading>
    <div class="page-actions">
      <app-sub-search (filterHandler)="dt.filterGlobal($event, 'contains')" [page]="'contacts'"></app-sub-search>
      <app-sub-new-delete (deleteHandler)="deleteSelectedItems()" [page]="'contacts'"
                          [selectedItems]="selectedItems"></app-sub-new-delete>
    </div>
  </div>
  <div>
    <div class="card">
      <p-table #dt
               [(selection)]="selectedItems"
               [columns]="cols"
               [globalFilterFields]="[
                          'first_name',
                          'last_name',
                          'sex',
                          'home_phone',
                          'cell_phone_primary',
                          'cell_phone_secondary',
                          'family_closeness'
                     ]"
               [loading]="loading"
               [paginator]="true"
               [resizableColumns]="true"
               [rowHover]="true"
               [rowsPerPageOptions]="[10,25,50]"
               [rows]="10"
               [showCurrentPageReport]="false"
               [totalRecords]="totalRecords"
               [value]="items"
               dataKey="id"
               scrollHeight="100%"
               tableStyleClass="p-datatable p-datatable-sm p-datatable-striped p-datatable-customers p-datatable-gridlines">
        <ng-template let-columns pTemplate="header">
          <tr>
            <th style="width: 3rem" *appUserRole="[ Roles.Admin ]">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns" [ngStyle]="col.styles"
                pResizableColumn
                pSortableColumn="{{col.name}}">
              <div class="p-d-flex p-ai-center p-p-1">
                <p-columnFilter
                  *ngIf="col.name === 'family_closeness'"
                  [showAddButton]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  display="menu"
                  field="family_closeness"
                  matchMode="in">
                  <ng-template pTemplate="header">
                    <div class="p-px-3 p-pt-3 p-pb-0">
                      {{ 'PAGES.contacts.fields.' + col.name | translate}}
                    </div>
                  </ng-template>
                  <ng-template let-filter="filterCallback" let-value
                               pTemplate="filter">
                    <p-multiSelect
                      (onChange)="filter($event.value)"
                      [ngModel]="value"
                      [options]="familyClosenessOptions"
                      placeholder="---">
                      <ng-template let-option pTemplate="item">
                        <div class="p-multiselect-representative-option">
                          <span class="p-ml-1">{{option.label}}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
                <p-columnFilter
                  *ngIf="col.name === 'for_monitored_person_id'"
                  [showAddButton]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  display="menu"
                  field="for_monitored_person_id"
                  matchMode="in">
                  <ng-template pTemplate="header">
                    <div class="p-px-3 p-pt-3 p-pb-0">
                      {{ 'PAGES.contacts.fields.' + col.name | translate}}
                    </div>
                  </ng-template>
                  <ng-template let-filter="filterCallback" let-value
                               pTemplate="filter">
                    <p-multiSelect
                      (onChange)="filter($event.value)"
                      [ngModel]="value"
                      [options]="residentsOptions"
                      placeholder="---">
                      <ng-template let-option pTemplate="item">
                        <div class="p-multiselect-representative-option">
                          <span class="p-ml-1">{{option.label}}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
                <p-sortIcon field="{{col.name}}"></p-sortIcon>
                {{ 'PAGES.contacts.fields.' + col.name | translate}}
              </div>
            </th>
            <th style="width: 7.2rem">
              <p-button
                (click)="dt.clear()"
                icon="pi pi-filter-slash">
              </p-button>
            </th>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-item pTemplate="body">
          <tr class="items-row">
            <td style="width: 3rem" *appUserRole="[ Roles.Admin ]">
              <p-tableCheckbox [value]="item"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns" [ngStyle]="col.styles" [routerLink]="['./' + item.id]"
                class="td-row">
              <ng-container *ngIf="!col['component'] else components">
                {{item[col.name]}}
              </ng-container>
              <ng-template #components>
                <div [ngSwitch]="col['component']">
                  <div *ngSwitchCase="'for_monitored_person_id'">
                    {{personsMap[item[col.name]]}}
                  </div>
                </div>
              </ng-template>
            </td>
            <td>
              <app-sub-actions-buttons
                (deleteHandler)="deleteItem(item)"
                [allowView]="false"
                [item]="item">
              </app-sub-actions-buttons>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td *appUserRole="[ Roles.Admin ]"></td>
            <td [attr.colspan]="cols?.length + 1">
              <div class="p-d-flex p-jc-center">{{ 'DEFAULTS.no_data_found' | translate }}</div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
