<div *mobxAutorun class="p-d-flex p-flex-column">
    <div class="p-d-flex p-jc-between p-ai-center">
        <app-sub-heading
            [hideBackButton]="true"
            [item]="accountsStore.account"
            [path]="'accounts'">
        </app-sub-heading>
        <div class="page-actions">
            <app-sub-search
                (filterHandler)="dt.filterGlobal($event, 'contains')"
                [page]="'contacts'">
            </app-sub-search>
            <app-sub-new-delete
                [hideDelete]="!showMultipleOptions"
                (deleteHandler)="deleteSelectedItems()"
                [page]="'accounts'"
                [selectedItems]="selectedItems">
            </app-sub-new-delete>
        </div>
    </div>
    <div>
        <div class="card">
            <p-table #dt
                     [(selection)]="selectedItems"
                     [columns]="cols"
                     [globalFilterFields]="['gateway_id', 'service_provider_id', 'owner_name', 'owner_cell_phone', 'owner_home_phone', 'address']"
                     [loading]="loading"
                     [paginator]="true"
                     [resizableColumns]="true"
                     [autoLayout]="true"
                     [rowHover]="true"
                     [rowsPerPageOptions]="[10,25,50]"
                     [rows]="10"
                     [showCurrentPageReport]="false"
                     [totalRecords]="totalRecords"
                     [value]="items"
                     responsiveLayout="scroll"
                     dataKey="id"
                     scrollHeight="100%"
                     tableStyleClass="p-datatable p-datatable-sm p-datatable-striped p-datatable-customers p-datatable-gridlines">
                <ng-template let-columns pTemplate="header">
                    <tr>
                        <th [hidden]="!showMultipleOptions" style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th *ngFor="let col of columns" [ngStyle]="col.styles" pResizableColumn
                            pSortableColumn="{{col.name}}">
                            <div class="p-d-flex p-ai-center p-p-1">
                                <p-columnFilter
                                    *ngIf="col.name==='is_active'"
                                    [showAddButton]="false"
                                    [showApplyButton]="false"
                                    [showClearButton]="false"
                                    [showMatchModes]="false"
                                    [showOperator]="false"
                                    display="menu"
                                    field="is_active"
                                    matchMode="equals">
                                    <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                                        <div class="p-py-1">{{ 'PAGES.accounts.status' | translate}}</div>
                                        <p-dropdown (onChange)="filter($event.value)"
                                                    [filter]="false"
                                                    [ngModel]="value"
                                                    [options]="statusOptions"
                                                    placeholder="---">
                                            <ng-template let-option pTemplate="selectedItem">
                                                <div>{{ 'PAGES.accounts.status.' + option.label| translate}}</div>
                                            </ng-template>
                                            <ng-template let-option pTemplate="item">
                                                <span>{{ 'PAGES.sensors.status.' + option.label | translate}}</span>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                                <p-columnFilter
                                    *ngIf="col.name==='number_of_residents'"
                                    [showAddButton]="false"
                                    [showApplyButton]="false"
                                    [showClearButton]="false"
                                    [showMatchModes]="false"
                                    [showOperator]="false"
                                    display="menu"
                                    field="monitored_persons.length"
                                    matchMode="equals">
                                    <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                                        {{ 'PAGES.accounts.fields.' + col.name | translate}}
                                        <p-dropdown (onChange)="filter($event.value)"
                                                    [ngModel]="value"
                                                    [options]="numberResidentsOptions"
                                                    placeholder="---">
                                            <ng-template let-option pTemplate="item">
                                                <span>{{option.label}}</span>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                                <p-sortIcon field="{{col.name}}" *ngIf="col?.sorting!==false"></p-sortIcon>
                                {{ 'PAGES.accounts.fields.' + col.name | translate}}
                            </div>
                        </th>
                        <th style="width: 4.2rem">
                            <p-button
                                (click)="dt.clear()"
                                icon="pi pi-filter-slash">
                            </p-button>
                        </th>
                    </tr>
                </ng-template>
                <ng-template let-columns="columns" let-item pTemplate="body">
                    <tr class="items-row">
                        <td [hidden]="!showMultipleOptions" style="width: 3rem">
                            <p-tableCheckbox [value]="item"></p-tableCheckbox>
                        </td>
                        <td *ngFor="let col of columns" [ngStyle]="col.styles" [routerLink]="['./' + item.id]"
                            class="td-row">
                            <ng-container *ngIf="!col['component'] else components">
                                {{item[col.name]}}
                            </ng-container>
                            <ng-template #components>
                                <div [ngSwitch]="col['component']">
                                    <app-is-active *ngSwitchCase="'is_active'"
                                                   [active]="item[col.name]"></app-is-active>
                                    <div *ngSwitchCase="'notes'">
                                        {{item[col.name] || '---'}}
                                    </div>
                                </div>
                            </ng-template>
                        </td>
                        <td>
                            <app-sub-actions-buttons
                                (deleteHandler)="deleteItem(item)"
                                [allowDelete]="false"
                                [allowView]="false"
                                [includedRoles]="[roles.Experementor]"
                                [item]="item">
                            </app-sub-actions-buttons>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td [attr.colspan]="cols?.length + 1">
                            <div class="p-d-flex p-jc-center">{{ 'DEFAULTS.no_data_found' | translate }}</div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
