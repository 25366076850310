/**
 * @Component Reports chart component
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import * as d3 from "d3";
import { TranslateService } from "@ngx-translate/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { formatDate } from "@angular/common";
import { activitySvg, IReport, IReportChartConfig, IReportChartSize } from "@pages/reports/reports.interface";
import { DateUtility } from "@app/core/utils/date.utility";

import { ReportsStore } from "@pages/reports/reports.store";
import { IAccountRoom } from "@pages/accounts/rooms/rooms.interface";
import { ELanguages } from "@app/const/config.interface";
import { SensorsStore } from "@pages/accounts/sensors/sensors.store";
import { IZoomType, ZoomService } from "@services/zoom.service";

@UntilDestroy()
@Component({
  selector: "app-sub-chart-report",
  templateUrl: "./sub-chart-report.component.html",
  styleUrls: ["./sub-chart-report.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubChartReportComponent implements OnInit, OnChanges {
  @Input() data: IReport[];
  @Input() rooms: IAccountRoom[];
  @Input() showGroups: boolean;
  @Input() showColors: boolean;
  @ViewChild("chartContainer", { static: true }) container: ElementRef;
  private currentZoom = 1;
  private margin = { top: 20, right: 130, bottom: 5, left: 20 };
  private size: IReportChartSize = { width: 0, height: 0 };
  private config: IReportChartConfig = {
    defaultColor: "#607D8B",
    barHeight: 20,
    hours: 24,
    delay: 100,
    duration: 100,
    iconHeight: 28,
    outerMargins: 90,
    verticalGridOpacity: 0.2,
    zoomStep: 1,
    scaleMin: 1,
    scaleMax: 40,
    modalWidth: 300,
    tickPadding: -10,
    xAxisTextFormat: "%H:%M",
    yAxisTickSizeInner: -6,
    xAxisTextHeight: 20,
    scrollWidth: 15,
    modalShiftPosition: 5,
    minBarWidth: 5,
    barStrokeWidth: 0.2,
    barStrokeColor: "white",
    xAxisFontSize: "10px",
    yAxisFontSize: "14px",
    barCombinedOpacity: 0.4,
    clipRightPadding: 2,
    clipLeftPadding: 1
  };
  private svg;
  private options = {};
  private xScale;
  private yScale;
  private xAxis;
  private xAxisGroup;
  private yAxis;
  private start = new Date();
  private end = new Date();
  private toolTip;
  private zoomD3;

  private colors = [
    "blue",
    "purple",
    "olive",
    "orange",
    "grey",
    "green",
    "teal",
    "red",
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6)
  ];
  private showGroupedBars = true;

  constructor(
    private translateService: TranslateService,
    public reportsStore: ReportsStore,
    private sensorsStore: SensorsStore,
    public zoomService: ZoomService
  ) {
  }

  @HostListener("window:resize", ["$event"])
  onResize(event): void {
    this.size.width = event.target.innerWidth - this.margin.left - this.margin.right - this.config.outerMargins;
    this.updateMergedBars();
    this.updateActivityBars();
    this.drawChart();
  }

  zoomSubscribe(): void {
    const zoomMap = {
      [IZoomType.IN]: () => this.zoomIn(),
      [IZoomType.OUT]: () => this.zoomOut(),
      [IZoomType.RESET]: () => this.zoomReset()
    };
    this.zoomService.currentZoom.pipe(untilDestroyed(this)).subscribe((el) => zoomMap[el]());
  }

  langChangeSubscribe(): void {
    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe((_) => {
      this.drawChart();
    });
  }

  ngOnInit(): void {
    this.updateCurrentDate();
    this.drawTooltip();
    this.updateSize();
    this.drawChart();
    this.langChangeSubscribe();
    this.zoomSubscribe();
  }

  zoomIn(): void {
    if (this.currentZoom < this.config.scaleMax) {
      this.currentZoom = this.currentZoom + this.config.zoomStep;
    }
    d3.select("#roomsSvg").transition().call(this.zoomD3.scaleTo, this.currentZoom);
  }

  zoomOut(): void {
    if (this.currentZoom > this.config.scaleMin) {
      this.currentZoom = this.currentZoom - this.config.zoomStep;
    }
    d3.select("#roomsSvg").transition().call(this.zoomD3.scaleTo, this.currentZoom);
  }

  zoomReset(): void {
    this.currentZoom = 1;
    d3.select("#roomsSvg").transition().call(this.zoomD3.scaleTo, this.currentZoom);
    this.drawChart();
  }

  zoomed(event): void {
    this.xScale.range([this.size.width, 0]);
    const newXscale = event.transform.rescaleX(this.xScale);

    this.svg
      .selectAll("#bars .bar-group rect")
      .attr("x", (d) => newXscale(new Date(d.end).getTime()))
      .attr("width", (d) => {
        const xPos = newXscale(new Date(d.start).getTime());
        return xPos <= 0 ? this.widthBar(d, newXscale, true) : this.widthBar(d, newXscale);
      });

    // ToDo avoid start end dependecy for derivate width
    this.svg
      .selectAll("#bars-combined .bar-group-combined rect")
      .attr("x", (d) => newXscale(new Date(d.end).getTime()))
      .attr("width", (d) => {
        const xPos = newXscale(new Date(d.start).getTime());
        if (xPos <= 0) {
          return this.widthBar(d, newXscale, true);
        }
        return this.widthBar(d, newXscale);
      });

    this.xAxisGroup.call(this.xAxis.scale(newXscale));

    this.svg
      .select("#x-axis")
      .selectAll(".tick line")
      .style("opacity", this.config.verticalGridOpacity)
      .style("stroke-width", "1");
  }

  widthBar(d, scale = this.xScale, noMin = false): number {
    const start = d.start;
    const end = d.end;
    const diff = Math.abs(scale(start) - scale(end));
    const minWidth = noMin ? diff : this.config.minBarWidth;
    return diff < minWidth ? minWidth : diff;
  }

  zoomInit(): void {
    const extent: [[number, number], [number, number]] = [
      [0, 0],
      [this.size.width, this.size.height]
    ];
    this.zoomD3 = d3
      .zoom()
      .scaleExtent([this.config.scaleMin, this.config.scaleMax])
      .translateExtent(extent)
      .extent(extent)
      .on("zoom", (e) => this.zoomed(e));
  }

  zoom(svg): void {
    this.zoomInit();
    svg.call(this.zoomD3);
  }

  mapOptions(rooms): void {
    rooms.forEach((el, i) => {
      const color = JSON.parse(el.extra)?.color;
      this.options[el.id] = {
        color: this.showColors ? (color ?? this.colors[i]) : this.config.defaultColor,
        name: el.room_tag,
        icon: "twitter-square"
      };
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.rooms) {
      this.mapOptions(changes.rooms?.currentValue || this.rooms);
    }
    if (changes?.data) {
      this.data = changes.data?.currentValue || this.data;
      this.updateCurrentDate();
      this.drawChart();
    }
    if (changes?.showGroups) {
      this.showGroupedBars = changes.showGroups?.currentValue
      this.drawChart();
    }
    if (changes?.showColors) {
      this.mapOptions(changes?.rooms?.currentValue ?? this.rooms);
      this.drawChart();
    }
  }

  drawChart(): void {
    this.container.nativeElement.innerHTML = "";
    const width = () => Math.abs(window.innerWidth - this.config.outerMargins);
    this.svg = d3
      .select(this.container.nativeElement)
      .append("svg")
      .attr("id", "roomsSvg")
      .style("pointer-events", "all")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .call((svg) => this.zoom(svg))
      .attr("viewBox", `0 0 ${width() + this.margin.left} ${this.container.nativeElement.clientHeight}`)
      .attr("width", width())
      .attr("height", this.container.nativeElement.clientHeight)
      .append("g")
      .attr("transform", "translate(" + this.margin.left + ", " + this.margin.top + ")");
    this.buildChart();
  }

  buildChart(): void {
    this.buildXAxis();
    this.buildYAxis();
    this.drawGridAxis();
    this.drawAxis();
    this.updateMergedBars();
    this.updateActivityBars();
  }

  drawTooltip(): void {
    this.toolTip = d3.select("body").append("div").attr("class", "tooltip").style("display", "none");
  }

  updateSize(): void {
    this.size.width = this.container.nativeElement.clientWidth - this.margin.left - this.margin.right;
    this.size.height = this.container.nativeElement.clientHeight - this.margin.top - this.margin.bottom;
  }

  buildXAxis(): void {
    this.xScale = d3.scaleTime().domain([this.start, this.end]).range([this.size.width, 0]).nice().clamp(true);

    this.xAxis = d3
      .axisBottom(this.xScale)
      .tickSizeInner(this.size.height)
      .tickSizeOuter(this.size.height)
      .tickPadding(this.config.tickPadding - this.size.height)
      .ticks(this.config.hours)
      .tickFormat((d) => d3.timeFormat(this.config.xAxisTextFormat)(new Date(d.toString())));
  }

  buildYAxis(): void {
    this.yScale = d3
      .scaleBand()
      .domain(this.data.map((el: IReport) => el.room_id.toString()))
      .range([this.size.height, 0]);
    this.yAxis = d3
      .axisRight(this.yScale)
      .tickSizeInner(this.config.yAxisTickSizeInner)
      .tickSizeOuter(-this.size.width)
      .tickFormat(() => "");
  }

  drawAxis(): void {
    this.svg
      .append("g")
      .append("defs")
      .append("clipPath")
      .attr("id", "clipX")
      .append("rect")
      .attr("width", this.size.width + this.margin.left + this.margin.right)
      .attr("height", this.size.height + this.margin.top + this.margin.bottom)
      .attr("transform", `translate(${-this.margin.left}, ${-this.margin.top})`);

    this.xAxisGroup = this.svg
      .append("g")
      .attr("id", "x-axis")
      .call(this.xAxis)
      .attr("clip-path", "url(#clipX)")
      .attr("transform", "translate(0,0)");

    this.svg
      .select("#x-axis")
      .selectAll(".tick line")
      .style("opacity", this.config.verticalGridOpacity)
      .style("stroke-width", "1");

    this.svg.select("#x-axis").selectAll(".tick text").style("font-size", this.config.xAxisFontSize); // for further text styling

    this.svg
      .append("g")
      .attr("id", "y-axis")
      .attr("transform", `translate(${this.size.width},0)`)
      .style("font-size", this.config.yAxisFontSize)
      .call(this.yAxis);

    this.svg
      .select("#y-axis")
      .selectAll(".tick")
      .append("g")
      .html(activitySvg)
      .attr("transform", "translate(5, -" + this.config.iconHeight / 2 + ")")
      .attr("fill", (d) => this.options[d]?.color)
      .append("text")
      .text((d) => this.options[d]?.name)
      .attr("transform", "translate(25, " + (3 + this.config.iconHeight / 2) + ")")
      .style("color", (d) => this.options[d]?.color)
      .attr("text-anchor", this.translateService.currentLang === ELanguages.he ? "end" : "start");
  }

  drawGridAxis(): void {
    this.svg
      .append("g")
      .attr("class", "grid grid-horizontal")
      .attr("stroke-width", this.config.barHeight)
      .call(d3.axisLeft(this.yScale).tickSize(-this.size.width).ticks(this.data.length))
      .selectAll("text")
      .remove();
  }

  updateMergedBars(): void {
    if (!this.showGroupedBars) return;
    if (this.data?.length < 1) return;

    this.svg.append("g").attr("id", "bars-combined");

    const barsCombined = this.svg.select("#bars-combined").selectAll(".bar-group-combined").data(this.data);

    barsCombined.exit().remove();

    const roomsLength = this.reportsStore.currentSelectedRoomModel?.rooms?.length;

    const barGroupsCombined = barsCombined
      .enter()
      .append("g")
      .merge(barsCombined)
      .attr("class", "bar-group-combined")
      .attr("transform", () => {
        const roomsLengthValue = roomsLength > 0 ? 2 * roomsLength : 1;
        const marginTop = this.size.height / roomsLengthValue - this.config.barHeight / 2;
        return `translate(0, ${marginTop})`;
      });

    barGroupsCombined
      .selectAll("rect")
      .data((d: IReport) =>
        d?.groups?.map((el) => {
          const isEndMoreStart = new Date(el.end).getTime() > new Date(el.start).getTime();
          return {
            ...el,
            start: isEndMoreStart ? new Date(el.start).getTime() : new Date(el.end).getTime(),
            end: isEndMoreStart ? new Date(el.end).getTime() : new Date(el.start).getTime(),
            color: this.options[`${d.room_id}`]?.color,
            room_id: d.room_id
          };
        })
      )
      .join("rect")
      .attr("class", "bar-item")
      .attr("y", (d) => this.yScale(d.room_id))
      .style("fill", (d) => d.color)
      .style("fill-opacity", this.config.barCombinedOpacity)
      .attr("stroke-width", this.config.barStrokeWidth)
      .attr("stroke", this.config.barStrokeColor)
      .attr("height", this.config.barHeight)
      .transition()
      .delay(this.config.delay)
      .attr("x", (d) => this.xScale(d.end))
      .attr("width", (d) => this.widthBar(d))
      .attr("clip-path", "url(#clip)");
  }

  updateActivityBars(): void {
    if (this.data?.length === 0) {
      this.svg
        .append("g")
        .append("text")
        .attr("x", this.size.width / 2 - 45)
        .attr("y", this.size.height / 2 - 5)
        .style("font-size", "14px")
        .style("font-weight", "600")
        .style("padding", "1.2rem")
        .text(this.translateService.instant("DEFAULTS.no_data_found"))
        .attr("text-anchor", this.translateService.currentLang === ELanguages.he ? "end" : "start");
    }

    this.svg
      .append("g")
      .attr("id", "bars")
      .append("defs")
      .append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("width", Math.abs(this.size.width - this.config.clipRightPadding))
      .attr("height", this.size.height + this.margin.top + this.margin.bottom + this.config.xAxisTextHeight)
      .attr(
        "transform",
        `translate(${this.config.clipLeftPadding}, ${-this.margin.top - this.config.xAxisTextHeight})`
      );

    const roomsLength = this.reportsStore.currentSelectedRoomModel?.rooms?.length;

    const bars = this.svg
      .select("#bars")
      .attr("transform", () => {
        const roomsLengthValue = roomsLength > 0 ? 2 * roomsLength : 1;
        const marginTop = this.size.height / roomsLengthValue - this.config.barHeight / 2;
        return `translate(0, ${marginTop})`;
      })
      .selectAll(".bar-group")
      .data(this.data);

    bars.exit().remove();

    const barGroups = bars.enter().append("g").merge(bars).attr("class", "bar-group");

    barGroups
      .selectAll("rect")
      .data((d: IReport) =>
        d?.times?.map((el) => ({
          ...el,
          start: new Date(el.start).getTime(),
          end: new Date(el.end).getTime(),
          room_id: d.room_id,
          person_id: d.person_id,
          sensor_id: el.sensor_id,
          direction: el.direction_pxls,
          sensor_name: this.sensorsStore.sensorsIdMap[el.sensor_id] ?? ""
        }))
      )
      .join("rect")
      .attr("class", "bar-item")
      .attr("y", (d) => this.yScale(d.room_id))
      .style("fill", (d) => this.options[`${d.room_id}`]?.color)
      .attr("stroke-width", this.config.barStrokeWidth)
      .attr("stroke", this.config.barStrokeColor)
      .attr("height", this.config.barHeight)
      .on("mouseover", (event, d) => {
        const windowSize = window.innerWidth - this.config.scrollWidth; // 15 - scroll width
        d3.select(event.target).style("cursor", "pointer");
        this.toolTip.transition().duration(this.config.duration).style("display", "block");
        this.toolTip
          .html(this.displayTooltip(d))
          .style("width", `${this.config.modalWidth}px`)
          .style(
            "left",
            event.pageX <= windowSize - this.config.modalWidth
              ? `${event.pageX}px`
              : `${event.pageX - this.config.modalWidth}px`
          )
          .style("top", event.pageY + this.config.modalShiftPosition + "px");
      })
      .on("mouseout", (_) => {
        this.toolTip.transition().duration(this.config.duration).style("display", "none");
      })
      .transition()
      .delay(this.config.delay)
      .attr("x", (d) => this.xScale(d.end))
      .attr("width", (d) => this.widthBar(d))
      .attr("clip-path", "url(#clip)");
  }

  displayTooltip(d): string {
    return `<div class="p-d-flex p-flex-column p-jc-start">
                    <div class="p-d-flex p-px-2">
                        <span class="p-col">${this.translateService.instant("PAGES.reports.room")}:</span>
                        <span class="p-col">${this.options[d.room_id]?.name}</span>
                    </div>
                    <div class="p-d-flex p-px-2">
                        <span class="p-col">${this.translateService.instant("PAGES.reports.sensor")}:</span>
                        <span class="p-col">${d?.sensor_name} (${d?.sensor_id})</span>
                    </div>
                    <div class="p-d-flex p-px-2">
                        <span class="p-col">${this.translateService.instant("PAGES.reports.confidence")}:</span>
                        <span class="p-col">${d?.confidence.toFixed(2) ?? "---"}</span>
                    </div>
                    <div class="p-d-flex p-px-2">
                        <span class="p-col">${this.translateService.instant("PAGES.reports.annotation")}:</span>
                        <span class="p-col">${d?.annotation}</span>
                    </div>
                    <div class="p-d-flex p-px-2">
                        <span class="p-col">${this.translateService.instant("PAGES.reports.direction")}:</span>
                        <span class="p-col">${d?.direction}</span>
                    </div>
                    <div class="p-d-flex p-px-2">
                        <span class="p-col">${this.translateService.instant("PAGES.reports.person")}:</span>
                        <span class="p-col">${
      this.reportsStore.person.first_name + " " + this.reportsStore.person.last_name
    } (${d.person_id})</span>
                    </div>
                    <div class="p-d-flex p-px-2">
                        <span class="p-col">${this.translateService.instant("PAGES.reports.start")}:</span>
                        <span class="p-col">${formatDate(new Date(d.start), "YYYY-MM-dd HH:mm:ss", "en_US")}</span>
                    </div>
                    <div class="p-d-flex p-px-2">
                        <span class="p-col">${this.translateService.instant("PAGES.reports.duration")}:</span>
                        <span class="p-col">${DateUtility.durationToHms(d.start, d.end)}</span>
                    </div>
                </div>
                `;
  }

  updateCurrentDate(): void {
    this.start = new Date(this.reportsStore.currentSelectedModel.date);
    this.end = new Date(this.reportsStore.currentSelectedModel.date);
    this.start.setHours(0, 0, 0, 1);
    this.end.setHours(23, 59, 59, 999);
  }
}
