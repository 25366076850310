/**
 * @Component Contacts component for displaying list of contacts
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

// Stores
import { GuiStore } from '@stores/gui.store';
import { AccountsStore } from '@pages/accounts/accounts/accounts.store';
import { PersonsStore } from '@pages/accounts/persons/persons.store';
import { ContactsStore } from '@pages/accounts/contacts/contacts.store';

// Services
import { ModalService } from '@services/modal.service';
import { NotificationService } from '@services/notification.service';
import { ConfirmationService } from 'primeng/api';

// Helpers and interfaces
import { delay } from '@app/core/utils/async.utility';
import { IAccountContact } from '@pages/accounts/contacts/contacts.interface';
import { IAccountItemColumns } from '@pages/accounts/accounts/accounts.interface';
import { Roles } from '@app/core/interfaces/user.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html'
})
export class ContactsComponent implements OnInit {
  accountId: string;
  items: IAccountContact[];
  item: IAccountContact;
  selectedItems: IAccountContact[];
  cols: IAccountItemColumns[];
  loading: boolean;
  totalRecords: number;
  personsMap = {};
  residentsOptions;
  familyClosenessOptions;
  Roles = Roles;

  constructor(
    public title: Title,
    public guiStore: GuiStore,
    public translationService: TranslateService,
    public accountsStore: AccountsStore,
    private personsStore: PersonsStore,
    private contactsStore: ContactsStore,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {}

  mapFields(data): void {
    this.items = data.sort((a, b) => (a.id < b.id ? 1 : -1));
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.paramMap.get('id');
    this.loading = true;
    this.contactsStore
      .fetchContacts(this.accountId)
      .then((data) => {
        this.totalRecords = data.length;
        this.mapFields(data);
        this.personsStore.fetchPersons(this.accountId).then((res) => {
          res.forEach((el) => {
            this.personsMap[+el.id] = el.first_name + ' ' + el.last_name;
          });
          this.residentsOptions = data.map((el) => ({
            label: this.personsMap[el.for_monitored_person_id],
            value: el.for_monitored_person_id
          }));
        });
        this.familyClosenessOptions = data.map((el) => ({
          label: el.family_closeness,
          value: el.family_closeness
        }));
        this.loading = false;
      })
      .catch((_) => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });

    this.cols = [
      { name: 'id', styles: { width: '5rem', 'text-align': 'center' } },
      { name: 'first_name' },
      { name: 'last_name' },
      { name: 'home_phone' },
      { name: 'cell_phone_primary' },
      { name: 'cell_phone_secondary' },
      {
        name: 'for_monitored_person_id',
        component: 'for_monitored_person_id',
        filter: true,
        custom: true
      },
      { name: 'family_closeness', filter: true, custom: true }
    ];

    delay(100).then(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.contacts'));
    });

    this.translationService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.contacts'));
    });
  }

  deleteSelectedItems(): void {
    const modal = {
      type: 'delete_item',
      header: this.translationService.instant(`MODAL.delete_item.header`),
      message: this.translationService.instant(`MODAL.delete_item.message`, {
        item: this.translationService.instant(`MODAL.selected_items`)
      }),
      accept: {
        callback: async () => {
          this.items = this.items.filter((val) => !this.selectedItems.includes(val));
          await this.contactsStore.deleteContacts(
            this.accountId,
            this.selectedItems.map((el) => el.id)
          );
          this.totalRecords = this.contactsStore.contactsLength;
          this.selectedItems = null;
        }
      }
    };
    this.modalService.showModal(modal);
  }

  async deleteItem(item: IAccountContact): Promise<void> {
    const modal = {
      type: 'delete_item',
      header: this.translationService.instant(`MODAL.delete_item.header`),
      message: this.translationService.instant(`MODAL.delete_item.message`, {
        item: item.first_name + ' ' + item.last_name
      }),
      accept: {
        callback: async () => {
          await this.contactsStore.deleteContact(this.accountId, item.id);
          this.totalRecords = this.contactsStore.contactsLength;
          this.items = this.items.filter((val) => val.id !== item.id);
          this.item = null;
        }
      }
    };
    this.modalService.showModal(modal);
  }
}
