/**
 * @Component Login page component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { NotificationService } from '@services/notification.service';
import { delay } from '@app/core/utils/async.utility';
import { TranslateService } from '@ngx-translate/core';
import { NAVIGATION } from '@app/const';
import { GuiStore } from '@stores/gui.store';
import { ELanguages } from '@app/const/config.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const defaultCredentials = {
  username: '',
  password: ''
};

@UntilDestroy()
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  public signInForm: UntypedFormGroup;
  public loading = false;
  public submitted = false;
  public ELanguages = ELanguages;
  public error = '';

  constructor(
    public fb: UntypedFormBuilder,
    public authService: AuthService,
    public router: Router,
    public guiStore: GuiStore,
    private title: Title,
    private notificationService: NotificationService,
    private translationService: TranslateService
  ) {}

  ngOnInit(): void {
    this.signInForm = this.fb.group({
      username: [defaultCredentials.username, Validators.required],
      password: [defaultCredentials.password, Validators.required]
    });
    if (this.authService.isLoggedIn) {
      this.authService.doLogout();
    }

    delay(100).then(() => {
      this.title.setTitle(this.translationService.instant('MENU.loginTitle'));
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.signInForm.controls;
  }

  get isUsernameError(): boolean {
    return this.f.username.invalid && (this.f.username.dirty || this.f.username.touched);
  }

  get isPasswordError(): boolean {
    return this.f.password.invalid && (this.f.password.dirty || this.f.password.touched);
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.signInForm.invalid) {
      return;
    }
    this.loading = true;
    this.authService
      .signIn(this.signInForm.value.username, this.signInForm.value.password)
      .pipe(untilDestroyed(this), first())
      .subscribe(async (auth) => {
        if (auth) {
          this.router.navigate([NAVIGATION.pages.path]).then(() => {
            delay(100).then(() =>
              this.notificationService.addSingle({
                severity: 'success',
                summary: this.translationService.instant('AUTH.signInHeader'),
                detail: this.translationService.instant('AUTH.loginSuccess')
              })
            );
          });
        } else {
          await this.router.navigate([NAVIGATION.login.path]);
        }
      });
  }
}
