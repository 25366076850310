/**
 * @Store Rules mobx store service for state management.
 * @Project: TrendLines
 * @Version Mobx: 6.1.X
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { makeAutoObservable, toJS } from 'mobx';
import { keyBy } from 'lodash-es';

import { AccountsStore } from '@pages/accounts/accounts/accounts.store';

import { TranslateService } from '@ngx-translate/core';
import { RulesService } from '@pages/accounts/rules/rules.service';

import { IAccountRule, IRuleMapping, IRulesTypesRes, IRuleType } from '@pages/accounts/rules/rules.interface';

import { NotificationsService } from '@pages/notifications/notifications.service';
import { IOptions } from '@pages/accounts/accounts/accounts.interface';
import { INotificationType } from '@pages/notifications/notifications.interface';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class RulesStore {
  // ********** Observables ************* //
  $rules: IAccountRule[] = [];
  $ruleTypes: IRuleType[] = [];
  $algorithmTypes: IRuleType[] = [];
  $mapping: IRuleMapping[] = [];
  $notificationTypes: IRuleType[] = [];
  $rulesLength: number;

  constructor(
    public translate: TranslateService,
    private accountsStore: AccountsStore,
    private rulesService: RulesService,
    private notificationsService: NotificationsService
  ) {
    makeAutoObservable(this);
  }

  // ********** Setters ************* //

  setRules(res: IAccountRule[]): void {
    this.$rules = res;
  }

  setMapping(mapping: IRuleMapping[]): void {
    this.$mapping = mapping;
  }

  setRuleTypes(ruleTypes: IRuleType[]): void {
    this.$ruleTypes = ruleTypes;
  }

  setAlgoTypes(algoTypes: IRuleType[]): void {
    this.$algorithmTypes = algoTypes;
  }

  setNotificationTypes(notificationTypes: IRuleType[]): void {
    this.$notificationTypes = notificationTypes;
  }

  // *********** Rules / Algorithm  Types ******** //

  async fetchRuleTypesAll(): Promise<IRulesTypesRes> {
    const ruleTypes = await this.rulesService.getRuleTypes();
    if (ruleTypes) {
      this.setRuleTypes(ruleTypes.rule_types);
      this.setAlgoTypes(ruleTypes.algo_types);
      this.setMapping(ruleTypes.mapping);
    }
    return ruleTypes;
  }

  async fetchNotificationTypes(): Promise<INotificationType[]> {
    const notificationTypes = await this.notificationsService.getNotificationTypes();
    if (notificationTypes) {
      this.setNotificationTypes(notificationTypes.notification_types);
    }
    return notificationTypes.notification_types;
  }

  // ********** Actions ************* //

  async fetchRules(accountId: string): Promise<IAccountRule[]> {
    try {
      const res = await this.rulesService.getRules(accountId);
      if (res) {
        this.setRules(res.body);
        if (!this.accountsStore.account || '' + this.accountsStore.account?.id !== accountId) {
          await this.accountsStore.fetchAccount(accountId);
        }
        return res.body;
      }
    } catch (e) {
      console.log('Error fetching sensors');
    }
  }

  async fetchRule(accountId: string, id: string): Promise<IAccountRule> {
    try {
      const res = await this.rulesService.getRule(accountId, id);
      if (res) {
        if (!this.accountsStore.account || '' + this.accountsStore.account?.id !== accountId) {
          await this.accountsStore.fetchAccount(accountId);
        }
        return res;
      }
    } catch (e) {
      console.log('Error fetching sensors');
    }
  }

  async addRule(accountId: string, body: IAccountRule): Promise<HttpResponse<any>> {
    try {
      return await this.rulesService.addRule(accountId, body);
    } catch (e) {
      console.log('Error adding sensors');
    }
  }

  async editRule(accountId: string, body: IAccountRule): Promise<HttpResponse<any>> {
    try {
      const res = await this.rulesService.editRule(accountId, body);
      if (res) {
        await this.fetchRules(accountId);
        return res;
      }
    } catch (e) {
      console.log('Error updating sensor');
    }
  }

  async deleteRule(accountId: string, id: string): Promise<any> {
    try {
      const res = await this.rulesService.deleteRule(accountId, id);
      if (res) {
        await this.fetchRules(accountId);
      }
    } catch (e) {
      console.log('Error delete sensor');
    }
  }

  async deleteRules(accountId: string, ids: string[]): Promise<void> {
    try {
      for (const id of ids) {
        await this.rulesService.deleteRule(accountId, id);
      }
      await this.fetchRules(accountId);
    } catch (e) {
      console.log('Error delete sensors');
    }
  }

  get currentRules(): IAccountRule[] {
    return toJS(this.$rules);
  }

  get rulesLength(): number {
    return toJS(this.$rulesLength);
  }

  get ruleTypes(): IRuleType[] {
    return toJS(this.$ruleTypes);
  }

  get algoTypes(): IRuleType[] {
    return toJS(this.$algorithmTypes);
  }

  get mapping(): IRuleMapping[] {
    return toJS(this.$mapping);
  }

  get notificationTypes(): IRuleType[] {
    return toJS(this.$notificationTypes);
  }

  get notificationTypesOptions(): IOptions[] {
    const mapped = this.$notificationTypes.map((el) => ({
      label: el.type_name,
      value: el.id
    }));
    return toJS(mapped);
  }

  get ruleIdByKey(): any {
    return keyBy(this.ruleTypes, 'type_key');
  }

  get algoIdByKey(): any {
    return keyBy(this.algoTypes, 'type_key');
  }
}
