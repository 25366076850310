<div>
    <button (click)="menu.toggle($event)"
            icon="pi pi-user"
            title="{{ 'MENU.profile' | translate }}"
            pButton pRipple
            class="p-button-rounded p-button-info p-button-outlined"
            type="button"></button>
    <p-menu #menu [model]="items" [popup]="true">
    </p-menu>
</div>
