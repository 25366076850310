/**
 * @const Object for Encapsulation settings for custom formly types
 * form's management library, used in the components
 * @provides: settings for formly.module.ts
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { FormlyFieldTabsComponent } from '@modules/Formly/types/components/tabs.type.component';
import { FormlyFieldSwitchComponent } from '@modules/Formly/types/components/switch.type.component';
import { FormlyFieldButtonComponent } from '@modules/Formly/types/components/button.type.component';
import { FormlyFieldRepeatingComponent } from '@modules/Formly/types/components/repeating.type.component';
import { FormlyFieldDropdownComponent } from '@modules/Formly/types/components/dropdown.type.component';
import { FormlyFieldMultiSelectComponent } from '@modules/Formly/types/components/multiselect.type.component';
import { FormlyFieldMessageComponent } from '@modules/Formly/types/components/message.type.component';
import { FormlyFieldInputComponent } from '@modules/Formly/types/components/input.type.component';
import { FormlyTimePickerComponent } from '@modules/Formly/types/components/timepicker.type.component';
import { FormlyFieldArrayComponent } from '@modules/Formly/types/components/array.type.component';
import { FormlyFieldLabelComponent } from '@modules/Formly/types/components/label.type.component';
import { FormlyDatePickerComponent } from '@modules/Formly/types/components/datepicker.type.component';
import { FormlyRadioGroupComponent } from '@modules/Formly/types/components/radio.type.component';
import { FormlyFieldInputSetComponent } from '@modules/Formly/types/components/inputset.type.component';
import { FormlyFieldTypesMultipleComponent } from '@modules/Formly/types/components/types.type.component';
import { FormlyFieldTooltipComponent } from '@modules/Formly/types/components/rule-tooltip.type.component';
import { FormlyFieldDoorComponent } from '@modules/Formly/types/components/door.component';
import { FormlyFieldColorComponent } from '@modules/Formly/types/components/color.type.component';
import { FormlyFieldFileComponent } from '@modules/Formly/types/components/file.component';
import { FormlyFieldMultiSelectTooltipComponent } from '@modules/Formly/types/components/multiselect-tooltip.type.component';
import { FormlyFieldInputPasswordComponent } from '@modules/Formly/types/components/password.type.component';

export const formlyTypes = [
  {
    name: 'tabs',
    component: FormlyFieldTabsComponent
  },
  {
    name: 'switch',
    component: FormlyFieldSwitchComponent,
    wrappers: ['form-field']
  },
  {
    name: 'button',
    component: FormlyFieldButtonComponent,
    wrappers: ['form-field'],
    defaultOptions: {
      templateOptions: {
        btnType: 'default',
        type: 'button'
      }
    }
  },
  {
    name: 'repeat',
    component: FormlyFieldRepeatingComponent
  },
  {
    name: 'dropdown',
    component: FormlyFieldDropdownComponent,
    wrappers: ['form-field']
  },
  {
    name: 'datetimepicker',
    component: FormlyTimePickerComponent,
    wrappers: ['form-field']
  },
  {
    name: 'datepicker',
    component: FormlyDatePickerComponent,
    wrappers: ['form-field']
  },
  {
    name: 'multiselect',
    component: FormlyFieldMultiSelectComponent,
    wrappers: ['form-field']
  },
  {
    name: 'multiselect-tooltip',
    component: FormlyFieldMultiSelectTooltipComponent,
    wrappers: ['form-field']
  },
  {
    name: 'message',
    component: FormlyFieldMessageComponent
  },
  {
    name: 'mask',
    extends: 'input',
    component: FormlyFieldInputComponent
  },
  {
    name: 'password',
    extends: 'input',
    component: FormlyFieldInputPasswordComponent
  },
  {
    name: 'array',
    component: FormlyFieldArrayComponent
  },
  {
    name: 'label',
    component: FormlyFieldLabelComponent
  },
  {
    name: 'radioGroup',
    component: FormlyRadioGroupComponent,
    wrappers: ['form-field']
  },
  {
    name: 'inputset',
    component: FormlyFieldInputSetComponent,
    wrappers: ['form-field']
  },
  {
    name: 'multiple',
    component: FormlyFieldTypesMultipleComponent
  },
  {
    name: 'tooltip',
    component: FormlyFieldTooltipComponent,
    wrappers: ['form-field']
  },
  {
    name: 'door',
    component: FormlyFieldDoorComponent
  },
  {
    name: 'color',
    component: FormlyFieldColorComponent,
    wrappers: ['form-field']
  },
  {
    name: 'file',
    component: FormlyFieldFileComponent,
    wrappers: ['form-field']
  }
];
