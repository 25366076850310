<div class="p-d-flex p-flex-column">
  <app-sub-nav [id]="accountId" [path]="'accounts'" class="p-mb-2 p-as-end"></app-sub-nav>
  <div class="p-d-flex p-jc-between p-ai-center p-mb-2">
    <app-sub-heading
      [accountId]="accountId"
      [item]="accountsStore.account"
      [path]="'sensors'">
    </app-sub-heading>
    <div class="page-actions">
      <app-sub-search (filterHandler)="dt.filterGlobal($event, 'contains')" [page]="'sensors'"></app-sub-search>
      <app-sub-new-delete (deleteHandler)="deleteSelectedItems()" [page]="'sensors'"
                          [selectedItems]="selectedItems"></app-sub-new-delete>
    </div>
  </div>
  <div>
    <div class="card">
      <p-table #dt
               [(selection)]="selectedItems"
               [columns]="cols"
               [globalFilterFields]="['id', 'tag', 'type']"
               [loading]="loading"
               [paginator]="true"
               [resizableColumns]="true"
               [rowHover]="true"
               [rowsPerPageOptions]="[10,25,50]"
               [rows]="10"
               [showCurrentPageReport]="false"
               [totalRecords]="totalRecords"
               [value]="items"
               dataKey="id"
               scrollHeight="100%"
               tableStyleClass="p-datatable p-datatable-sm p-datatable-striped p-datatable-customers p-datatable-gridlines">
        <ng-template let-columns pTemplate="header">
          <tr>
            <th style="width: 3rem" *appUserRole="[ Roles.Admin ]">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns" [ngStyle]="col.styles" pResizableColumn
                pSortableColumn="{{col.name}}">
              <div class="p-d-flex p-ai-center p-p-1">
                <p-columnFilter
                  *ngIf="col.name==='enabled'"
                  [showAddButton]="false"
                  [showApplyButton]="false"
                  [showClearButton]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  display="menu"
                  field="enabled"
                  matchMode="equals">
                  <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                    <div class="p-py-1">{{ 'PAGES.sensors.status' | translate}}</div>
                    <p-dropdown (onChange)="filter($event.value)"
                                [filter]="false"
                                [ngModel]="value"
                                [options]="statusOptions"
                                placeholder="---">
                      <ng-template let-option pTemplate="selectedItem">
                        <div>{{ 'PAGES.sensors.status.' + option.label | translate}}</div>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span>{{ 'PAGES.sensors.status.' + option.label | translate}}</span>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
                <p-columnFilter
                  *ngIf="col.name === 'type'"
                  [showAddButton]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  display="menu"
                  field="type"
                  matchMode="in">
                  <ng-template pTemplate="header">
                    <div class="p-px-3 p-pt-3 p-pb-0">
                      {{ 'PAGES.sensors.fields.' + col.name | translate}}
                    </div>
                  </ng-template>
                  <ng-template let-filter="filterCallback" let-value
                               pTemplate="filter">
                    <p-multiSelect
                      (onChange)="filter($event.value)"
                      [ngModel]="value"
                      [options]="deviceTypes"
                      placeholder="---">
                      <ng-template let-option pTemplate="item">
                        <div class="p-multiselect-representative-option">
                                                        <span
                                                          class="p-ml-1">{{ 'PAGES.sensors.types.' + option.value | translate }}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
                <p-columnFilter
                  *ngIf="col.name === 'room'"
                  [showAddButton]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  display="menu"
                  field="room.room_tag"
                  matchMode="in">
                  <ng-template pTemplate="header">
                    <div class="p-px-3 p-pt-3 p-pb-0">
                      {{ 'PAGES.sensors.fields.' + col.name | translate}}
                    </div>
                  </ng-template>
                  <ng-template let-filter="filterCallback" let-value
                               pTemplate="filter">
                    <p-multiSelect
                      (onChange)="filter($event.value)"
                      [ngModel]="value"
                      [options]="roomTypes"
                      placeholder="---">
                      <ng-template let-option pTemplate="item">
                        <div class="p-multiselect-representative-option">
                          <span class="p-ml-1">{{option.label}}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
                <p-sortIcon field="{{col.name}}"></p-sortIcon>
                {{ 'PAGES.sensors.fields.' + col.name | translate}}
              </div>
            </th>
            <th style="width: 7.2rem">
              <p-button
                (click)="dt.clear()"
                icon="pi pi-filter-slash">
              </p-button>
            </th>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-item pTemplate="body">
          <tr class="items-row">
            <td style="width: 3rem" *appUserRole="[ Roles.Admin ]">
              <p-tableCheckbox [value]="item"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns" [ngStyle]="col.styles" [routerLink]="['./' + item.id]"
                class="td-row">
              <ng-container *ngIf="!col['component'] else components">
                {{item[col.name]}}
              </ng-container>
              <ng-template #components>
                <ng-container [ngSwitch]="col['component']">
                  <app-is-active *ngSwitchCase="'enabled'"
                                 [active]="item[col.name]">
                  </app-is-active>
                  <div *ngSwitchCase="'type'">
                    {{ 'PAGES.sensors.types.' + item[col.name] | translate}}
                  </div>
                  <div *ngSwitchCase="'last_seen_at'">
                    {{ item[col.name] || '---' }}
                  </div>
                  <div *ngSwitchCase="'room_id'">
                    {{item[col.name] || '---'}}
                  </div>
                </ng-container>
              </ng-template>
            </td>
            <td>
              <app-sub-actions-buttons
                (deleteHandler)="deleteItem(item)"
                [allowView]="false"
                [item]="item">
              </app-sub-actions-buttons>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td *appUserRole="[ Roles.Admin ]"></td>
            <td [attr.colspan]="cols?.length + 1">
              <div class="p-d-flex p-jc-center">{{ 'DEFAULTS.no_data_found' | translate }}</div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
