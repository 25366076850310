/**
 * @Component Custom multiselect component using prime-ng multiselect
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "app-formly-field-multiselect-tooltip",
  template: `
    <p-multiSelect
      [ngClass]="{'disabled': to.readonly}"
      [tooltip]="tooltip"
      [id]="field.key.toString()"
      [options]="to.options ? (to.options | formlySelectOptions: field | async) : null"
      [placeholder]="to.placeholder"
      [formControl]="$any(form).controls[field.key.toString()]"
      [formlyAttributes]="field"
      [readonly]="to?.readonly"
      [disabled]="to?.disabled"
      selectedItemsLabel="{0} {{ to?.itemsSelected }}"
      (onChange)="to.change && to.change(field, $event)"
    >
    </p-multiSelect>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FormlyFieldMultiSelectTooltipComponent extends FieldType implements OnInit {
  tooltip = "";
  defaultOptions = {
    templateOptions: { options: [] }
  };

  ngOnInit(): void {
    if (this.to?.disabled && this.to?.readonly) {
      this.field.templateOptions.disabled = false;
      let selected = [...(this.field.templateOptions.options as any[])];
      selected = selected.filter((el) => this.field.formControl.value.includes(el.value));
      selected.forEach((el, i) => {
        this.tooltip = this.tooltip + el["label"] + (selected.length - 1 !== i ? "\n\r" : "");
      });
    } else {
      this.tooltip = null;
    }
  }
}
