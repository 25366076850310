/**
 * @directive Users role directive
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { Directive, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Roles } from '@app/core/interfaces/user.interface';

@Directive({ selector: '[appUserRole]' })
export class UserRoleDirective implements OnInit {
  userRoles: Roles[];

  constructor(
    private templateRef: TemplateRef<any>,
    private authService: AuthService,
    private viewContainer: ViewContainerRef
  ) {}

  @Input()
  set appUserRole(roles: Roles[]) {
    const rolesFlatten = roles.filter(Boolean).reduce((acc, val) => acc.concat(val), []);
    if (!rolesFlatten || !rolesFlatten.length) {
      console.log('Roles value is empty or missed');
    }
    this.userRoles = rolesFlatten;
  }

  ngOnInit(): void {
    let hasAccess = false;

    if (this.authService.isLoggedIn && this.userRoles) {
      hasAccess = this.userRoles.some((r) => this.authService.hasRole(r));
    }

    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
