import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: 'app-formly-field-primeng-radio-group',
  template: `
    <div class="p-field-radiobutton" *ngFor="let option of to.options | formlySelectOptions: field | async">
      <p-radioButton
        (onClick)="setType()"
        [name]="field.name || id"
        [label]="option.label.toString()"
        [formControl]="fControl"
        [formlyAttributes]="field"
        [value]="option.value"
        [disabled]="option.disabled || to?.disabled"
      >
      </p-radioButton>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyRadioGroupComponent extends FieldType {
  setType(): void {
    this.field.formControl.setValue(this.field.formControl.value);
  }
  public get fControl() {
    return this.formControl as UntypedFormControl;
  }
}
