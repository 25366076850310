/**
 * @Module Reports's module.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@modules/shared.module';
import { PagesSharedModule } from '@pages/pages-shared.module';
import { ReportsRoutingModule } from '@pages/reports/reports-routing.module';

import { ReportsStore } from '@pages/reports/reports.store';

import { MessageService } from 'primeng/api';
import { ReportsService } from '@pages/reports/reports.service';

import { ReportsComponent } from './reports.component';
import { SubHeadingComponent } from './sub-heading/sub-heading.component';
import { SubChartReportComponent } from './sub-chart-report/sub-chart-report.component';
import { SubDetailsComponent } from '@pages/reports/sub-details/sub-details.component';
import { SubNotificationsReportComponent } from '@pages/reports/sub-notifications-report/sub-notifications-report.component';

@NgModule({
    declarations: [
        ReportsComponent,
        SubHeadingComponent,
        SubChartReportComponent,
        SubNotificationsReportComponent,
        SubDetailsComponent
    ],
    imports: [CommonModule, ReportsRoutingModule, SharedModule, PagesSharedModule],
    providers: [ReportsService, ReportsStore, MessageService]
})
export class ReportsModule {}
