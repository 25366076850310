<div *mobxAutorun
     [ngClass]="{'layoutRtl': guiStore.currentLanguage === ELanguages.he }"
     class="trend-lines layout-wrapper">
  <app-header></app-header>
  <main class="layout-main">
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </main>
  <app-footer></app-footer>
  <ngx-ui-loader [fgsType]="'three-strings'"></ngx-ui-loader>
  <p-confirmDialog
    [baseZIndex]="10000"
    [ngClass]="'layout-modal ' + guiStore.getModalOptions?.styleClass"
    [position]=""
    [rtl]="guiStore.currentLanguage === ELanguages.he"
    key="positionDialog"
    rejectButtonStyleClass="p-button-outlined"
    translate>
  </p-confirmDialog>
  <p-toast position="bottom-center"></p-toast>
</div>
<app-alert></app-alert>
