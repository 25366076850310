/**
 * @Store Gateway mobx store service for state management.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';

import { AccountsStore } from '@pages/accounts/accounts/accounts.store';

import { IAccountGatewayResponse } from '@pages/accounts/gateway/gateway.interface';
import { GatewayService } from '@pages/accounts/gateway/gateway.service';

@Injectable()
export class GatewayStore {
  constructor(private gatewayService: GatewayService, private accountsStore: AccountsStore) {}

  // ********** Actions ************* //
  async fetchGateway(accountId: string): Promise<IAccountGatewayResponse> {
    let gid;
    try {
      gid = this.accountsStore?.account?.gateway_id;
      if (!gid || this.accountsStore?.account?.id !== accountId) {
        const account = await this.accountsStore.fetchAccount(accountId);
        gid = account?.gateway_id;
      }
    } catch (e) {
      console.log('Error fetching account');
    }
    if (gid) {
      try {
        return await this.gatewayService.getGateway(accountId, gid);
      } catch (e) {
        console.log('Error fetching gateway');
        throw new Error(e);
      }
    }
  }
}
