/**
 * @Service Rooms service for CRUD actions with API server.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { PagesSharedModule } from '@pages/pages-shared.module';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { NAVIGATION } from '@app/const';
import { environment } from '@environments/environment';
import { IAccountRoom } from '@pages/accounts/rooms/rooms.interface';
import { lastValueFrom, map } from 'rxjs';

@Injectable()
export class RoomsService {
  apiURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  api = (id) => `${this.apiURL}/${NAVIGATION.accounts.api}/${id}/${NAVIGATION.rooms.api}`;

  getRooms(accountId: string): Promise<HttpResponse<IAccountRoom[]>> {
    return lastValueFrom(
      this.http
        .get<IAccountRoom[]>(this.api(accountId), {
          observe: 'response'
        })
        .pipe(map((res: HttpResponse<IAccountRoom[]>) => res))
    );
  }

  getRoom(accountId: string, id: string): Promise<IAccountRoom> {
    return lastValueFrom(
      this.http
        .get<IAccountRoom>(`${this.api(accountId)}/${id}`, {
          observe: 'response'
        })
        .pipe(map((res: HttpResponse<IAccountRoom>) => res.body))
    );
  }

  addRoom(accountId: string, body: IAccountRoom): Promise<any> {
    return lastValueFrom(
      this.http.post<IAccountRoom>(this.api(accountId), body, {
        observe: 'response'
      })
    );
  }

  editRoom(accountId: string, body: IAccountRoom): Promise<any> {
    return lastValueFrom(
      this.http.put<IAccountRoom>(`${this.api(accountId)}/${body.id}`, body, {
        observe: 'response'
      })
    );
  }

  deleteRoom(accountId: string, id: string): Promise<any> {
    return lastValueFrom(
      this.http.delete<any>(`${this.api(accountId)}/${id}`, {
        observe: 'response'
      })
    );
  }
}
