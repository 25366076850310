/**
 * @Module Logs routing module for authenticated users
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/core/guards/auth.guard';
import { RoleGuard } from '@app/core/guards/role.guard';

import { NAVIGATION } from '@app/const';
import { Roles } from '@app/core/interfaces/user.interface';

import { LogsComponent } from '@pages/logs/logs.component';

const routes: Routes = [
    { path: '', redirectTo: NAVIGATION.log.path, pathMatch: 'full' },
    {
        path: NAVIGATION.log.url,
        canActivate: [AuthGuard],
        canActivateChild: [RoleGuard],
        data: {
            role: [Roles.Admin]
        },
        children: [{ path: '', component: LogsComponent }]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LogsRoutingModule {}
