<p-dropdown
    (onChange)="guiStore.setLanguage($event.value)"
    *mobxAutorun
    [ngModel]="guiStore.currentLanguage"
    [options]="guiStore.languages"
    class="p-ml-3 p-mr-3"
    optionLabel="name"
    optionValue="value"
>
    <ng-template pTemplate="selectedItem">
        <div *ngIf="guiStore.currentLanguage" class="p-d-flex">
            <img [class]="'flag flag-' + guiStore.currentLanguage" alt="" src="/assets/images/flags/flag_placeholder.png"/>
            <div class="p-mx-2">{{ 'DEFAULTS.lang.' + guiStore.currentLanguage | translate}}</div>
        </div>
    </ng-template>
    <ng-template let-lang pTemplate="item">
        <div class="p-d-flex">
            <img [class]="'flag flag-' + lang.value" alt="" src="/assets/images/flags/flag_placeholder.png"/>
            <div class="p-mx-2">{{ 'DEFAULTS.lang.' + lang.name | translate}}</div>
        </div>
    </ng-template>
</p-dropdown>
