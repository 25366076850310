import { Injectable } from '@angular/core';
import { INotificationItem } from '@pages/notifications/notifications.interface';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private notificationTypeOptions = {
    activity: 'blue',
    technical: 'black',
    emergency: 'red'
  };

  get notificationType(): any {
    return this.notificationTypeOptions;
  }

  alertType(type: string): string {
    switch (type) {
      case 'gateway':
        return 'pi-wifi';
      case 'emergency':
        return 'pi-exclamation-triangle';
      case 'security':
        return 'pi-key';
    }
  }

  notificationActualValueParsed(item: INotificationItem): string {
    const parsed = JSON.parse(item.data).base_value;
    if (item.rule?.fix_threshold?.base_value_unit !== 'time') {
      return item?.data ? parsed.toFixed(1) : '';
    }
    return parsed;
  }

  notificationTriggeredSensor(item: INotificationItem): string[] {
    let parsed = JSON.parse(item.data).sensor_id.toString();
    parsed = parsed.replace(/[\[\]\'\"]/gi, '');
    return [parsed];
  }
}
