/**
 * @Service Auth Guard service for authorized routes
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core/services/auth.service';
import { NAVIGATION } from '@app/const';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(public router: Router, private authService: AuthService) {}

    /**
     * @description canActivate -> true if route authorized, false if no
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isLoggedIn) {
            return true;
        }
        this.router.navigate([NAVIGATION.login.path]).then();
        return false;
    }

    /**
     * @description canLoad
     * @param route
     * @param segments
     */
    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isLoggedIn) {
            return true;
        }
        this.router.navigate([NAVIGATION.login.path]).then();
        return false;
    }
}
