/**
 * @Component Custom tabs component using prime-ng tabs
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-tabs',
  template: `
    <div class="card">
      <p-tabView>
        <ng-container *ngFor="let tab of tabs; let i = index; let last = last">
          <ng-container *ngIf="!tab.templateOptions.hidden">
            <p-tabPanel [header]="tab.templateOptions.label" [selected]="i === 0">
              <formly-field [field]="tab"></formly-field>
            </p-tabPanel>
          </ng-container>
        </ng-container>
      </p-tabView>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFieldTabsComponent extends FieldType implements OnInit {
  tabs: FormlyFieldConfig[];

  isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      return field.formControl.valid;
    }
    return field.fieldGroup.every((f) => this.isValid(f));
  }

  ngOnInit(): void {
    this.tabs = this.field.fieldGroup.filter((el) => !el.templateOptions?.hidden);
  }
}
