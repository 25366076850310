/**
 * @Store Store service for basic manipulation with layout settings, language settings.
 * All subscribed components will be dynamically re-rendered
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { toJS, makeAutoObservable } from 'mobx';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { config } from '@app/const';

export interface IModalGuiOptions {
  styleClass: string;
}

@Injectable({ providedIn: 'root' })
export class GuiStore {
  // ******* Observables ******* //
  layoutType = 'normal';
  language = config.languages.defaultLanguage;
  modalOptions: IModalGuiOptions = null;

  constructor(public translate: TranslateService, private localStorageService: LocalStorageService) {
    translate.addLangs(config.languages.list);
    makeAutoObservable(this);
  }

  // ******* Setters ******* //
  setLanguage(lang): void {
    this.language = lang;
    this.setTranslation(lang);
  }

  setModalOptions(options): void {
    this.modalOptions = options;
  }

  setTranslation(lang): void {
    this.localStorageService.insertItem({ language: lang }, config.localStorageObject);
    this.translate.use(lang);
  }

  setLayout(layout: string): void {
    this.layoutType = layout;
  }

  // ******* Computed ******* //
  get layout(): string {
    return toJS(this.layoutType);
  }

  get currentLanguage(): string {
    return toJS(this.language);
  }

  get getModalOptions(): IModalGuiOptions {
    return toJS(this.modalOptions);
  }

  get languages(): { name: string; value: string }[] {
    return toJS(this.translate.getLangs().map((el) => ({ name: el, value: el })));
  }
}
