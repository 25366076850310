/**
 * @Service Configuration service for getting global project's parameters
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Injectable } from "@angular/core";
import { config } from "@app/const";
import { IConfig } from "@app/const/config.interface";

@Injectable({ providedIn: "root" })
export class ConfigurationService {
  private configLocal: IConfig = config;
  /**
   * @description Get global config object
   * @return object from config congiguration object
   */
  get config(): IConfig {
    return this.configLocal;
  }
}
