import { Component, Input } from '@angular/core';
import { AccountsStore } from '@pages/accounts/accounts/accounts.store';
import { Location } from '@angular/common';
import { IExtraHeadingParams } from '@app/shared/components/sub-actions/sub-heading/interface';

@Component({
  selector: 'app-sub-heading',
  templateUrl: './sub-heading.component.html'
})
export class SubHeadingComponent {
  @Input() id: number;
  @Input() page: string;
  @Input() path: string;
  @Input() isEdit: boolean;
  @Input() accountId: string | number;
  @Input() hideBackButton: boolean;
  @Input() item: any;
  @Input() extra: IExtraHeadingParams[];

  constructor(public readonly accountsStore: AccountsStore, public location: Location) {}
}
