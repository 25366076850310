import { Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function applyEmailValidation(field: FormlyFieldConfig): any {
    if (
        !field.templateOptions ||
        field.templateOptions.type !== 'email' ||
        (field.validators && field.validators.email)
    ) {
        return;
    }
    if (field.validators && field.validators.email) {
        return;
    }

    field.validators = field.validators || {};
    field.validators.email = {
        expression: (control) => (!control.value ? true : !Validators.email(control)),
        message: 'FORM.validation.email'
    };
}
