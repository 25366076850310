/**
 * @Module Shared module for declaring components, modules, pipes, directives
 * across entire project that are used in many places. Container for reusable modules.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MobxAngularModule } from 'mobx-angular';
import { configure } from 'mobx';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { PrimeNgModule } from '@modules/prime-ng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { NgxFormlyModule } from '@modules/Formly/formly.module';
import { UserDirective } from '@app/core/directives/user.directive';
import { UserRoleDirective } from '@app/core/directives/user-role.directive';
import { config } from '@app/const';

/**
 * @description Ngx loader module configuration
 */
const ngxUiLoaderConfig: NgxUiLoaderConfig = config.uiLoader;

// Mobx configuration
configure(config.storeSettings);

@NgModule({
  declarations: [UserDirective, UserRoleDirective],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFormlyModule,
    TranslateModule.forChild({
      defaultLanguage: config.languages.defaultLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PrimeNgModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    MobxAngularModule
  ],
  exports: [
    TranslateModule,
    NgxFormlyModule,
    NgxUiLoaderModule,
    MobxAngularModule,
    PrimeNgModule,
    FormsModule,
    FormlyModule,
    ReactiveFormsModule,
    UserDirective,
    UserRoleDirective
  ]
})
export class SharedModule {}

export function HttpLoaderFactory(httpClient: HttpClient): any {
  return new TranslateHttpLoader(httpClient, config.languages.path, '.json');
}
