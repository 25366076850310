/**
 * @Component Notification reports component
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import * as d3 from "d3";
import { TranslateService } from "@ngx-translate/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { formatDate } from "@angular/common";
import {
  activitySvg,
  INotificationsReport, INotificationTimesData,
  IReportChartConfig,
  IReportChartMargins,
  IReportChartSize
} from "@pages/reports/reports.interface";
import { ReportsStore } from "@pages/reports/reports.store";
import { ELanguages } from "@app/const/config.interface";
import { IZoomType, ZoomService } from "@services/zoom.service";
import { SensorsStore } from "@pages/accounts/sensors/sensors.store";

@UntilDestroy()
@Component({
  selector: "app-sub-notifications-chart-report",
  templateUrl: "./sub-notifications-report.component.html",
  styleUrls: ["./sub-notifications-report.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubNotificationsReportComponent implements OnInit, OnChanges {
  @Input() data: INotificationsReport[];
  @ViewChild("chartNotificationsContainer", { static: true }) container: ElementRef;
  currentZoom = 1;
  private margin: IReportChartMargins = { top: 20, right: 130, bottom: 5, left: 20 };
  private size: IReportChartSize = { width: 0, height: 0 };
  private config: IReportChartConfig = {
    barHeight: 20,
    hours: 24,
    delay: 100,
    duration: 100,
    iconHeight: 28,
    barWidth: 7,
    outerMargins: 90,
    verticalGridOpacity: 0.2,
    zoomStep: 1,
    scaleMin: 1,
    scaleMax: 40,
    modalWidth: 300,
    tickPadding: -10,
    xAxisTextFormat: "%H:%M",
    yAxisTickSizeInner: -6,
    xAxisTextHeight: 20,
    scrollWidth: 15,
    modalShiftPosition: 5,
    minBarWidth: 5,
    barStrokeWidth: 0.2,
    barStrokeColor: "white",
    xAxisFontSize: "10px",
    yAxisFontSize: "14px",
    barCombinedOpacity: 0.4,
    clipRightPadding: 2,
    clipLeftPadding: 1
  };
  private svg;
  private xScale;
  private xAxis;
  private xAxisGroup;
  private yScale;
  private yAxis;
  private start = new Date();
  private end = new Date();
  private toolTip;

  private colorsAlgo = [
    "blue",
    "purple",
    "olive",
    "orange",
    "grey",
    "green",
    "teal",
    "red",
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6),
    "#" + Math.random().toString(16).substr(2, 6)
  ];
  private zoomD3;

  constructor(
    private translateService: TranslateService,
    private reportsStore: ReportsStore,
    private sensorsStore: SensorsStore,
    private zoomService: ZoomService
  ) {
  }

  @HostListener("window:resize", ["$event"])
  onResize(event): void {
    this.size.width = event.target.innerWidth - this.margin.left - this.margin.right - this.config.outerMargins;
    this.updateActivityBars();
    this.drawChart();
  }

  zoomIn(): void {
    if (this.currentZoom < this.config.scaleMax) {
      this.currentZoom = this.currentZoom + this.config.zoomStep;
    }
    d3.select("#notificationsSvg").transition().call(this.zoomD3.scaleTo, this.currentZoom);
  }

  zoomOut(): void {
    if (this.currentZoom > this.config.scaleMin) {
      this.currentZoom = this.currentZoom - this.config.zoomStep;
    }
    d3.select("#notificationsSvg").transition().call(this.zoomD3.scaleTo, this.currentZoom);
  }

  zoomReset(): void {
    this.currentZoom = 1;
    d3.select("#notificationsSvg").transition().call(this.zoomD3.scaleTo, this.currentZoom);
    this.drawChart();
  }

  zoomed(event): void {
    this.xScale.range([this.size.width, 0]);
    const newXscale = event.transform.rescaleX(this.xScale);
    this.svg
      .selectAll("#bars-notifications .bar-group rect")
      .attr("x", (d) => newXscale(new Date(d.date_time).getTime()))
      .attr("width", (d) => {
        const xPos = newXscale(new Date(d.date_time).getTime());
        return xPos <= 0
          ? newXscale(new Date(d.date_time).getTime() + this.config.barWidth * 1000)
          : this.config.barWidth;
      });

    this.xAxisGroup.call(this.xAxis.scale(newXscale));

    this.svg
      .select("#x-axis")
      .selectAll(".tick line")
      .style("opacity", this.config.verticalGridOpacity)
      .style("stroke-width", "1");
  }

  zoomInit(): void {
    const extent: [[number, number], [number, number]] = [
      [0, 0],
      [this.size.width, this.size.height]
    ];
    this.zoomD3 = d3
      .zoom()
      .scaleExtent([this.config.scaleMin, this.config.scaleMax])
      .translateExtent(extent)
      .extent(extent)
      .on("zoom", (e) => this.zoomed(e));
  }

  zoom(svg): void {
    this.zoomInit();
    svg.call(this.zoomD3);
  }

  zoomSubscribe(): void {
    const zoomMap = {
      [IZoomType.IN]: () => this.zoomIn(),
      [IZoomType.OUT]: () => this.zoomOut(),
      [IZoomType.RESET]: () => this.zoomReset()
    };
    this.zoomService.currentZoom.pipe(untilDestroyed(this)).subscribe((el) => zoomMap[el]());
  }

  langChangeSubscribe(): void {
    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe((_) => {
      this.drawChart();
    });
  }

  ngOnInit(): void {
    this.updateCurrentDate();
    this.drawTooltip();
    this.updateSize();
    this.drawChart();
    this.langChangeSubscribe();
    this.zoomSubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data) {
      this.data = changes.data.currentValue;
      this.updateCurrentDate();
      this.drawChart();
    }
  }

  drawChart(): void {
    this.container.nativeElement.innerHTML = "";
    const width = () => Math.abs(window.innerWidth - this.config.outerMargins);
    this.svg = d3
      .select(this.container.nativeElement)
      .append("svg")
      .attr("id", "notificationsSvg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .call((svg) => this.zoom(svg))
      .attr("viewBox", `0 0 ${width() + this.margin.left} ${this.container.nativeElement.clientHeight}`)
      .attr("width", width())
      .attr("height", this.container.nativeElement.clientHeight)
      .append("g")
      .attr("transform", "translate(" + this.margin.left + ", " + this.margin.top + ")");
    this.buildChart();
  }

  buildChart(): void {
    this.buildXAxis();
    this.buildYAxis();
    this.drawGridAxis();
    this.drawAxis();
    this.updateActivityBars();
  }

  drawTooltip(): void {
    this.toolTip = d3.select("body").append("div").attr("class", "tooltip").style("display", "none");
  }

  updateSize(): void {
    this.size.width = this.container.nativeElement.clientWidth - this.margin.left - this.margin.right;
    this.size.height = this.container.nativeElement.clientHeight - this.margin.top - this.margin.bottom;
  }

  buildXAxis(): void {
    this.xScale = d3.scaleTime().domain([this.start, this.end]).range([this.size.width, 0]).nice().clamp(true);

    this.xAxis = d3
      .axisBottom(this.xScale)
      .tickSizeInner(this.size.height)
      .tickSizeOuter(this.size.height)
      .tickPadding(this.config.tickPadding - this.size.height)
      .ticks(this.config.hours)
      .tickFormat((d) => d3.timeFormat(this.config.xAxisTextFormat)(new Date(d.toString())));
  }

  buildYAxis(): void {
    this.yScale = d3
      .scaleBand()
      .domain(this.data.map((el) => el.notification_type_key))
      .range([this.size.height, 0]);
    this.yAxis = d3
      .axisRight(this.yScale)
      .tickSizeInner(this.config.yAxisTickSizeInner)
      .tickSizeOuter(-this.size.width)
      .tickFormat(() => "");
  }

  drawAxis(): void {
    this.svg
      .append("g")
      .append("defs")
      .append("clipPath")
      .attr("id", "clipX")
      .append("rect")
      .attr("width", this.size.width + this.margin.left + this.margin.right)
      .attr("height", this.size.height + this.margin.top + this.margin.bottom)
      .attr("transform", `translate(${-this.margin.left}, ${-this.margin.top})`);

    this.xAxisGroup = this.svg
      .append("g")
      .attr("id", "x-axis")
      .call(this.xAxis)
      .attr("clip-path", "url(#clipX)")
      .attr("transform", "translate(0,0)");

    this.svg.select("#x-axis").selectAll(".tick text").style("font-size", this.config.xAxisFontSize); // for further text styling

    this.svg.select("#x-axis").selectAll(".tick line").style("opacity", this.config.verticalGridOpacity);

    this.svg
      .append("g")
      .attr("id", "y-axis")
      .attr("transform", `translate(${this.size.width},0)`)
      .style("font-size", this.config.yAxisFontSize)
      .call(this.yAxis);

    this.svg
      .select("#y-axis")
      .selectAll(".tick")
      .append("g")
      .html(activitySvg)
      .attr("transform", "translate(5, -" + this.config.iconHeight / 2 + ")")
      .attr("fill", (_, i) => this.colorsAlgo[i])
      .append("text")
      .text((d) => this.translateService.instant(`PAGES.rules.algo_keys.${d}`))
      .attr("transform", "translate(25, " + (3 + this.config.iconHeight / 2) + ")")
      .style("color", (_, i) => this.colorsAlgo[i])
      .attr("text-anchor", this.translateService.currentLang === ELanguages.he ? "end" : "start");
  }

  drawGridAxis(): void {
    this.svg
      .append("g")
      .attr("class", "grid grid-horizontal")
      .attr("stroke-width", this.config.barHeight)
      .call(d3.axisLeft(this.yScale).tickSize(-this.size.width).ticks(this.data.length))
      .selectAll("text")
      .remove();
  }

  updateActivityBars(): void {
    if (this.data.length === 0) {
      this.svg
        .append("g")
        .append("text")
        .attr("x", this.size.width / 2 - 45)
        .attr("y", this.size.height / 2 - 5)
        .attr("fill", "red")
        .style("font-size", "14px")
        .style("font-weight", "600")
        .style("padding", "1.2rem")
        .text(this.translateService.instant("DEFAULTS.no_data_found"))
        .attr("text-anchor", this.translateService.currentLang === ELanguages.he ? "end" : "start");
    }

    this.svg
      .append("g")
      .attr("id", "bars-notifications")
      .append("defs")
      .append("clipPath")
      .attr("id", "clipNotification")
      .append("rect")
      .attr("width", Math.abs(this.size.width - this.config.minBarWidth - 4))
      .attr("height", this.size.height + this.margin.top + this.margin.bottom + this.config.xAxisTextHeight)
      .attr(
        "transform",
        `translate(${this.config.minBarWidth + 2}, ${-this.margin.top - this.config.xAxisTextHeight})`
      );

    const bars = this.svg
      .select("#bars-notifications")
      .attr("transform", () => {
        const rLength = this.data?.length > 0 ? 2 * this.data.length : 2;
        const marginTop = this.size.height / rLength - this.config.barHeight / 2;
        return `translate(0, ${marginTop})`;
      })
      .selectAll(".bar-group")
      .data(this.data);

    bars.exit().remove();

    const barGroups = bars.enter().append("g").merge(bars).attr("class", "bar-group");

    barGroups
      .selectAll("rect")
      .data((d: INotificationsReport, i): INotificationTimesData[] => {
        return d.timesData.map((el) => ({ ...el, typeIndex: i }));
      })
      .join("rect")
      .attr("class", "bar-item")
      .attr("y", (d) => this.yScale(d.notification_type_key))
      .style("fill", (d) => this.colorsAlgo[d.typeIndex])
      .attr("stroke-width", this.config.barStrokeWidth)
      .attr("stroke", this.config.barStrokeColor)
      .attr("height", this.config.barHeight)
      .on("mouseover", (event, d) => {
        const windowSize = window.innerWidth - this.config.scrollWidth; // 15 - scroll width
        d3.select(event.target).style("cursor", "pointer");
        this.toolTip.transition().duration(this.config.duration).style("display", "block");
        this.toolTip
          .html(this.displayTooltip(d))
          .style("width", `${this.config.modalWidth}px`)
          .style(
            "left",
            event.pageX <= windowSize - this.config.modalWidth
              ? `${event.pageX}px`
              : `${event.pageX - this.config.modalWidth}px`
          )
          .style("top", event.pageY + this.config.modalShiftPosition + "px");
      })
      .on("mouseout", (_) => {
        this.toolTip.transition().duration(this.config.duration).style("display", "none");
      })
      .transition()
      .delay(this.config.delay)
      .attr("x", (d) => this.xScale(new Date(d.date_time).getTime()))
      .attr("width", this.config.barWidth)
      .attr("clip-path", "url(#clip)");
  }

  displayTooltip(d): string {
    return `<div class="p-d-flex p-flex-column p-jc-start">
                    <div class="p-d-flex p-px-2">
                        <span class="p-col">${this.translateService.instant("PAGES.reports.type")}:</span>
                        <span class="p-col">${this.translateService.instant(
      "PAGES.notifications.type." + d.type
    )}</span>
                    </div>
                    <div class="p-d-flex p-px-2">
                        <span class="p-col">${this.translateService.instant("PAGES.reports.date_time")}:</span>
                        <span class="p-col">${formatDate(new Date(d.date_time), "YYYY-MM-dd HH:mm:ss", "en_US")}</span>
                    </div>
                     <div class="p-d-flex p-px-2">
                        <span class="p-col">${this.translateService.instant("PAGES.reports.sensor")}:</span>
                        <span class="p-col">${d.sensor_id
      .split(",")
      .map((el) => this.translateService.instant("PAGES.sensors.types." + el))
      .join(", ")}</span>
                    </div>
                    <div class="p-d-flex p-px-2">
                        <span class="p-col">${this.translateService.instant("PAGES.reports.actual_value")}:</span>
                        <span class="p-col">${d.actual_value}
                        ${d?.actual_value_unit
                          ? this.translateService.instant("PAGES.rules.base_units." + d.actual_value_unit)
                          : ""
                          }
                        </span>
                    </div>
                </div>
                `;
  }

  updateCurrentDate(): void {
    this.start = new Date(this.reportsStore.currentSelectedModel.date);
    this.end = new Date(this.reportsStore.currentSelectedModel.date);
    this.start.setHours(0, 0, 0, 1);
    this.end.setHours(23, 59, 59, 999);
  }
}
