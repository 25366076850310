/**
 * @Module Account's rooting module for authenticated users
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/core/guards/auth.guard';
import { RoleGuard } from '@app/core/guards/role.guard';
import { ExitGuard } from '@app/core/guards/exit.guard';

// Accounts
import { AccountsComponent } from '@pages/accounts/accounts/accounts.component';
import { UpdateAccountComponent } from '@pages/accounts/accounts/update-account/update-account.component';

// Rooms
import { RoomsComponent } from '@pages/accounts/rooms/rooms.component';
import { UpdateRoomComponent } from '@pages/accounts/rooms/update-room/update-room.component';

// Persons
import { PersonsComponent } from '@pages/accounts/persons/persons.component';
import { UpdatePersonComponent } from '@pages/accounts/persons/update-person/update-person.component';

// Contacts
import { ContactsComponent } from '@pages/accounts/contacts/contacts.component';
import { UpdateContactComponent } from '@pages/accounts/contacts/update-contact/update-contact.component';

// Sensors
import { SensorsComponent } from '@pages/accounts/sensors/sensors.component';
import { UpdateSensorComponent } from '@pages/accounts/sensors/update-sensor/update-sensor.component';

// Rules
import { RulesComponent } from '@pages/accounts/rules/rules.component';
import { UpdateRuleComponent } from '@pages/accounts/rules/update-rule/update-rule.component';

// Gateway
import { GatewayComponent } from '@pages/accounts/gateway/gateway.component';

import { NAVIGATION } from '@app/const';
import { Roles } from '@app/core/interfaces/user.interface';

const routes: Routes = [
  { path: '', redirectTo: NAVIGATION.accounts.url, pathMatch: 'full' },
  {
    path: NAVIGATION.accounts.url,
    canActivate: [AuthGuard],
    canActivateChild: [RoleGuard],
    data: {
      role: [Roles.Admin, Roles.CallCenterUser, Roles.Experementor]
    },
    children: [
      { path: '', component: AccountsComponent },
      {
        path: 'add',
        component: UpdateAccountComponent,
        canDeactivate: [ExitGuard],
        data: { isEdit: false, role: [Roles.Admin] }
      },
      {
        path: 'edit/:id',
        canDeactivate: [ExitGuard],
        component: UpdateAccountComponent,
        data: { isEdit: true, role: [Roles.Admin, Roles.Experementor] }
      },
      {
        path: ':id',
        children: [
          { path: '', component: UpdateAccountComponent, data: { isView: true } },
          {
            path: NAVIGATION.rooms.url,
            children: [
              { path: '', component: RoomsComponent },
              {
                path: 'add',
                canDeactivate: [ExitGuard],
                component: UpdateRoomComponent,
                data: { isEdit: false, role: [Roles.Admin] }
              },
              {
                path: 'edit/:pid',
                canDeactivate: [ExitGuard],
                component: UpdateRoomComponent,
                data: { isEdit: true, role: [Roles.Admin] }
              },
              { path: ':pid', component: UpdateRoomComponent, data: { isView: true } }
            ]
          },
          {
            path: NAVIGATION.persons.url,
            children: [
              { path: '', component: PersonsComponent },
              {
                path: 'add',
                canDeactivate: [ExitGuard],
                component: UpdatePersonComponent,
                data: { isEdit: false, role: [Roles.Admin] }
              },
              {
                path: 'edit/:pid',
                canDeactivate: [ExitGuard],
                component: UpdatePersonComponent,
                data: { isEdit: true, role: [Roles.Admin] }
              },
              { path: ':pid', component: UpdatePersonComponent, data: { isView: true } }
            ]
          },
          {
            path: NAVIGATION.contacts.url,
            children: [
              { path: '', component: ContactsComponent },
              {
                path: 'add',
                canDeactivate: [ExitGuard],
                component: UpdateContactComponent,
                data: { isEdit: false, role: [Roles.Admin] }
              },
              {
                path: 'edit/:pid',
                canDeactivate: [ExitGuard],
                component: UpdateContactComponent,
                data: { isEdit: true, role: [Roles.Admin] }
              },
              { path: ':pid', component: UpdateContactComponent, data: { isView: true } }
            ]
          },
          {
            path: NAVIGATION.sensors.url,
            children: [
              { path: '', component: SensorsComponent },
              {
                path: 'add',
                canDeactivate: [ExitGuard],
                component: UpdateSensorComponent,
                data: { isEdit: false, role: [Roles.Admin] }
              },
              {
                path: 'edit/:pid',
                canDeactivate: [ExitGuard],
                component: UpdateSensorComponent,
                data: { isEdit: true, role: [Roles.Admin] }
              },
              { path: ':pid', component: UpdateSensorComponent, data: { isView: true } }
            ]
          },
          {
            path: NAVIGATION.rules.url,
            children: [
              { path: '', component: RulesComponent },
              {
                path: 'add',
                canDeactivate: [ExitGuard],
                component: UpdateRuleComponent,
                data: { isEdit: false, role: [Roles.Admin] }
              },
              {
                path: 'edit/:pid',
                canDeactivate: [ExitGuard],
                component: UpdateRuleComponent,
                data: { isEdit: true, role: [Roles.Admin] }
              },
              { path: ':pid', component: UpdateRuleComponent, data: { isView: true } }
            ]
          },
          {
            path: NAVIGATION.gateway.url,
            children: [
              { path: '', component: GatewayComponent }
            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountsRoutingModule {}
