/**
 * @Component Rules component for displaying list of rules
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Title } from '@angular/platform-browser';

// Stores
import { GuiStore } from '@stores/gui.store';
import { AccountsStore } from '@pages/accounts/accounts/accounts.store';
import { SensorsStore } from '@pages/accounts/sensors/sensors.store';
import { RulesStore } from '@pages/accounts/rules/rules.store';

// Services
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@services/modal.service';
import { NotificationService } from '@services/notification.service';
import { ConfirmationService } from 'primeng/api';

// Helpers and interfaces
import { delay } from '@app/core/utils/async.utility';
import { IAccountItemColumns } from '@pages/accounts/accounts/accounts.interface';
import { IAccountRule } from '@pages/accounts/rules/rules.interface';
import { Roles } from '@app/core/interfaces/user.interface';

@UntilDestroy()
@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html'
})
export class RulesComponent implements OnInit {
  accountId: string;
  items: IAccountRule[];
  item: IAccountRule;
  selectedItems: IAccountRule[];
  cols: IAccountItemColumns[];
  loading: boolean;
  totalRecords: number;
  ruleTypes;
  Roles = Roles;
  public statusOptions = [
    { label: 'active', value: true },
    { label: 'inactive', value: false }
  ];

  constructor(
    public title: Title,
    public guiStore: GuiStore,
    public translationService: TranslateService,
    public accountsStore: AccountsStore,
    private rulesStore: RulesStore,
    private sensorsStore: SensorsStore,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {}

  async getRuleTypes(): Promise<any> {
    return await this.rulesStore.fetchRuleTypesAll();
  }

  getPersonName(el: IAccountRule): string {
    const firstName = this.accountsStore.accountPersons.find((it) => +it.id === el.monitored_persons_ids[0])
      ?.first_name;
    const lastName = this.accountsStore.accountPersons.find((it) => +it.id === el.monitored_persons_ids[0])?.last_name;
    if (!firstName && lastName) {
      return lastName;
    } else if (firstName && !lastName) {
      return firstName;
    } else if (firstName && lastName) {
      return firstName + ' ' + lastName;
    } else {
      return '---';
    }
  }

  mapFields(data): void {
    this.items = data
      ?.map((el) => {
        // change later when it will be array with more than 1 value
        el.monitored_persons_ids = this.getPersonName(el);
        el.sensor_ids = this.sensorsStore.sensorsForList
          .filter((elem) => el.sensor_ids.includes(elem.value))
          .map((it) => it.label)
          .join(', ');
        el.time_start = el.time_start.substr(0, 5);
        el.rule_type = el.rule_type.type_name;
        el.algorithm_type = el.algorithm_type.type_name;
        el.notification_type = this.translationService.instant('PAGES.notifications.type.' + el.notification_type.type_key);
        el.time_end = el.time_end.substr(0, 5);
        el.time = el.time_start + ' ... ' + el.time_end;
        return el;
      })
      .sort((a, b) => (a.id < b.id ? 1 : -1));
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.accountId = this.route.snapshot.paramMap.get('id');
    try {
      this.ruleTypes = await this.getRuleTypes();
      await this.sensorsStore.fetchSensors(this.accountId);
      const rules = await this.rulesStore.fetchRules(this.accountId);
      this.totalRecords = rules.length;
      this.mapFields(rules);
      this.loading = false;
    } catch (e) {
      console.log('ERROR', e);
    }

    this.cols = [
      { name: 'id', styles: { width: '5rem', 'text-align': 'center' } },
      { name: 'rule_type' },
      { name: 'algorithm_type' },
      { name: 'notification_type' },
      { name: 'notify_policy', component: 'notification_policy' },
      { name: 'apply_on_schedule', component: 'apply_on_schedule' },
      { name: 'sensor_ids', component: 'sensors' },
      { name: 'monitored_persons_ids' },
      { name: 'fix_threshold', component: 'fix_threshold', sorting: false },
      { name: 'time', component: 'time' },
      {
        name: 'enabled',
        styles: { 'text-align': 'center' },
        component: 'enabled',
        filter: true,
        type: 'boolean'
      }
    ];
    delay(100).then(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.rules'));
    });

    this.translationService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.rules'));
    });
  }

  deleteSelectedItems(): void {
    const modal = {
      type: 'delete_item',
      header: this.translationService.instant(`MODAL.delete_item.header`),
      message: this.translationService.instant(`MODAL.delete_item.message`, {
        item: this.translationService.instant(`MODAL.selected_items`)
      }),
      accept: {
        callback: async () => {
          this.items = this.items.filter((val) => !this.selectedItems.includes(val));
          await this.rulesStore.deleteRules(
            this.accountId,
            this.selectedItems?.map((el) => el.id)
          );
          this.totalRecords = this.rulesStore.rulesLength;
          this.selectedItems = null;
        }
      }
    };
    this.modalService.showModal(modal);
  }

  async deleteItem(item: IAccountRule): Promise<void> {
    const modal = {
      type: 'delete_item',
      header: this.translationService.instant(`MODAL.delete_item.header`),
      message: this.translationService.instant(`MODAL.delete_item.message`, {
        item: this.translationService.instant('MODAL.this_rule')
      }),
      accept: {
        callback: async () => {
          this.items = this.items.filter((val) => val.id !== item.id);
          await this.rulesStore.deleteRule(this.accountId, item.id);
          this.totalRecords = this.rulesStore.rulesLength;
          this.item = null;
        }
      }
    };
    this.modalService.showModal(modal);
  }
}
