/**
 * @Store Rooms mobx store service for state management.
 * @Project: TrendLines
 * @Version Mobx: 6.1.X
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { toJS, makeAutoObservable } from 'mobx';

import { AccountsStore } from '@pages/accounts/accounts/accounts.store';

import { TranslateService } from '@ngx-translate/core';
import { RoomsService } from '@pages/accounts/rooms/rooms.service';

import { IAccountRoom } from '@pages/accounts/rooms/rooms.interface';
import { IOptions } from '@pages/accounts/accounts/accounts.interface';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class RoomsStore {
  // ********** Observables ************* //

  $rooms: IAccountRoom[] = [];
  $roomsLength: number;

  constructor(
    public translate: TranslateService,
    private roomsService: RoomsService,
    private accountsStore: AccountsStore
  ) {
    makeAutoObservable(this);
  }

  // ********** Setters ************* //

  setRooms(res: IAccountRoom[]): void {
    this.$rooms = res;
  }

  // ********** Actions ************* //

  async fetchRooms(accountId: string): Promise<IAccountRoom[]> {
    try {
      const res = await this.roomsService.getRooms(accountId);
      if (res) {
        this.setRooms(res.body);
        if (!this.accountsStore.account || '' + this.accountsStore.account?.id !== accountId) {
          await this.accountsStore.fetchAccount(accountId);
        }
        return res.body;
      }
    } catch (e) {
      console.log('Error fetching rooms');
    }
  }

  async fetchRoom(accountId: string, id: string): Promise<IAccountRoom> {
    try {
      const res = await this.roomsService.getRoom(accountId, id);
      if (res) {
        if (!this.accountsStore.account || '' + this.accountsStore.account?.id !== accountId) {
          await this.accountsStore.fetchAccount(accountId);
        }
        return res;
      }
    } catch (e) {
      console.log('Error fetching room');
    }
  }

  async addRoom(accountId: string, body: IAccountRoom): Promise<HttpResponse<any>> {
    try {
      return await this.roomsService.addRoom(accountId, body);
    } catch (e) {
      console.log('Error adding room');
    }
  }

  async editRoom(accountId: string, body: IAccountRoom): Promise<HttpResponse<any>> {
    try {
      const res = await this.roomsService.editRoom(accountId, body);
      if (res) {
        await this.fetchRooms(accountId);
        return res;
      }
    } catch (e) {
      console.log('Error updating room');
    }
  }

  async deleteRoom(accountId: string, id: string): Promise<any> {
    try {
      const res = await this.roomsService.deleteRoom(accountId, id);
      if (res) {
        await this.fetchRooms(accountId);
      }
    } catch (e) {
      console.log('Error delete room');
    }
  }

  async deleteRooms(accountId: string, ids: string[]): Promise<void> {
    try {
      for (const id of ids) {
        await this.roomsService.deleteRoom(accountId, id);
      }
      await this.fetchRooms(accountId);
    } catch (e) {
      console.log('Error delete rooms');
    }
  }

  get rooms(): IAccountRoom[] {
    return toJS(this.$rooms);
  }

  get roomsOptions(): IOptions[] {
    return toJS(this.$rooms).map((el) => ({
      label: `${el.room_tag}`,
      value: el.id.toString(),
      id: el.id
    }));
  }

  get roomsLength(): number {
    return toJS(this.$roomsLength);
  }
}
