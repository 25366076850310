/**
 * @Store Contacts mobx store service for state management.
 * @Project: TrendLines
 * @Version Mobx: 6.1.X
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { toJS, makeAutoObservable } from 'mobx';

import { AccountsStore } from '@pages/accounts/accounts/accounts.store';

import { TranslateService } from '@ngx-translate/core';
import { ContactsService } from '@pages/accounts/contacts/contacts.service';

import { IAccountContact } from '@pages/accounts/contacts/contacts.interface';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class ContactsStore {
  // ********** Observables ************* //
  $contacts: IAccountContact[] = [];
  $contactsLength: number;

  constructor(
    public translate: TranslateService,
    private contactsService: ContactsService,
    private accountsStore: AccountsStore
  ) {
    makeAutoObservable(this);
  }

  // ********** Setters ************* //

  setContacts(res: IAccountContact[]): void {
    this.$contacts = res;
  }

  // ********** Actions ************* //

  async fetchContacts(accountId: string, isListRefresh = true): Promise<IAccountContact[]> {
    try {
      const res = await this.contactsService.getContacts(accountId);
      if (res && isListRefresh) {
        this.setContacts(res.body);
        if (!this.accountsStore.account || '' + this.accountsStore.account?.id !== accountId) {
          await this.accountsStore.fetchAccount(accountId);
        }
        return res.body;
      }
    } catch (e) {
      console.log('Error fetching contacts');
    }
  }

  async fetchContact(accountId: string, id: string): Promise<IAccountContact> {
    try {
      const res = await this.contactsService.getContact(accountId, id);
      if (res) {
        if (!this.accountsStore.account || '' + this.accountsStore.account?.id !== accountId) {
          await this.accountsStore.fetchAccount(accountId);
        }
        return res;
      }
    } catch (e) {
      console.log('Error fetching contact');
    }
  }

  async addContact(accountId: string, body: IAccountContact): Promise<HttpResponse<any>> {
    try {
      return await this.contactsService.addContact(accountId, body);
    } catch (e) {
      console.log('Error adding contact');
    }
  }

  async editContact(accountId: string, body: IAccountContact): Promise<HttpResponse<any>> {
    try {
      const res = await this.contactsService.editContact(accountId, body);
      if (res) {
        await this.fetchContacts(accountId);
        return res;
      }
    } catch (e) {
      console.log('Error updating contact');
    }
  }

  async deleteContact(accountId: string, id: string): Promise<any> {
    try {
      const res = await this.contactsService.deleteContact(accountId, id);
      if (res) {
        await this.fetchContacts(accountId);
      }
    } catch (e) {
      console.log('Error delete contact');
    }
  }

  async deleteContacts(accountId: string, ids: string[]): Promise<void> {
    try {
      for (const id of ids) {
        await this.contactsService.deleteContact(accountId, id);
      }
      await this.fetchContacts(accountId);
    } catch (e) {
      console.log('Error delete contacts');
    }
  }

  get contacts(): IAccountContact[] {
    return toJS(this.$contacts);
  }

  get contactsLength(): number {
    return toJS(this.$contactsLength);
  }
}
