/**
 * @Service Auth service for user's authentication
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from "@angular/common/http";
import { AuthService } from "@app/core/services/auth.service";
import { Observable } from "rxjs";
import { v4 as uuidv4 } from "uuid";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  excludedApis = [];

  constructor(private authService: AuthService) {
  }

  /**
   * @description Intercepting request and checking if user is authenticated
   * If yes, append token in authorization headers
   * @param request
   * @param next
   * @return req next to the middleware que
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getUser?.access_token;
    const isExcludedApis = this.excludedApis.includes(request.url) || false;
    if (token && !isExcludedApis) {
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'X-Request-Id': uuidv4()
      })
      request = request.clone({headers});
    }
    return next.handle(request);
  }
}
