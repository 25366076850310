/**
 * @Component Authorized menu layout component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GuiStore } from '@stores/gui.store';

import { AuthService } from '@app/core/services/auth.service';
import { LoadingIndicatorService } from '@services/loading-indicator.service';
import { ModalService } from '@services/modal.service';
import { TranslateService } from '@ngx-translate/core';

import { NAVIGATION } from '@app/const';

@UntilDestroy()
@Component({
  selector: 'app-auth-menu',
  templateUrl: './auth-menu.component.html',
  styleUrls: ['./auth-menu.component.scss']
})
export class AuthMenuComponent implements OnInit {
  items: any[] = [];

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private loadingIndicator: LoadingIndicatorService,
    private router: Router,
    public guiStore: GuiStore,
    private readonly translationService: TranslateService
  ) {}

  ngOnInit(): void {
    this.items = this.buildMenu();
    this.translationService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.items = this.buildMenu();
    });
  }

  buildMenu(): any {
    const items: any[] = [];
    if (this.authService.isLoggedIn) {
      items.push({
        label: this.translationService.instant('MENU.logout'),
        command: () => this.logoutConfirm()
      });
    } else {
      items.push({
        label: this.translationService.instant('MENU.login'),
        command: async () => await this.router.navigate([NAVIGATION.login.path])
      });
    }
    return items;
  }

  logoutConfirm(): void {
    this.loadingIndicator.stopAll();
    const modal = {
      type: 'logout',
      header: this.translationService.instant('MODAL.logout.header'),
      message: this.translationService.instant('MODAL.logout.message'),
      accept: {
        callback: () => this.logout()
      }
    };
    this.modalService.showModal(modal);
  }

  logout(): void {
    this.authService.doLogout();
  }
}
