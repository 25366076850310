/**
 * @module Persons' module for encapsulating persons components
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@modules/shared.module';
import { PagesSharedModule } from '@pages/pages-shared.module';

import { PersonsComponent } from '@pages/accounts/persons/persons.component';
import { ViewPersonComponent } from '@pages/accounts/persons/view-person/view-person.component';
import { UpdatePersonComponent } from '@pages/accounts/persons/update-person/update-person.component';

@NgModule({
    declarations: [
        PersonsComponent,
        ViewPersonComponent,
        UpdatePersonComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PagesSharedModule
    ]
})

export class PersonsModule {
}
