import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { environment } from '@environments/environment';
import { AppModule } from '@app/app.module';

if (environment.production) {
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [
      new BrowserTracing({
        tracingOrigins: environment.sentryTracingOrigins,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
