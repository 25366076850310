import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import type { IAccount } from '@pages/accounts/accounts/accounts.interface';
import type { IAccountPerson } from '@pages/accounts/persons/persons.interface';

@Component({
    selector: 'app-sub-details-reports',
    templateUrl: './sub-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubDetailsComponent {
    @Input() account: IAccount;
    @Input() person: IAccountPerson;

}
