<div class="p-d-flex p-jc-between">
    <ng-container *appUserRole="[ Roles.Admin, includedRoles ]">
        <button
            (click)="saveHandler.emit()"
            [disabled]="isSaveDisabled"
            class="p-button-success p-button-lg p-mt-1"
            icon="pi pi-check"
            label="{{ 'DEFAULTS.save' | translate }}"
            pButton
            pRipple>
        </button>
    </ng-container>
    <div class="p-d-flex" *ngIf="showOptions">
        <ng-container *appUserRole="['']">
            <button
                [routerLink]="['../../', item.id]"
                [disabled]="isDeleteDisabled"
                class="p-button-warning p-button-lg p-mt-1 p-mx-1"
                icon="pi pi-search"
                label="{{ 'DEFAULTS.view' | translate }}"
                pButton
                pRipple>
            </button>
        </ng-container>
        <ng-container *appUserRole="[ Roles.Admin ]">
            <button
                type="button"
                (click)="deleteHandler.emit()"
                [disabled]="isDeleteDisabled"
                class="p-button-danger p-button-lg p-mt-1 p-mx-1"
                icon="pi pi-times-circle"
                label="{{ (removeButtonText ? removeButtonText : 'DEFAULTS.remove') | translate }}"
                pButton
                pRipple>
            </button>
        </ng-container>
    </div>
</div>
