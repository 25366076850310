<div *ngIf="id" class="p-d-flex p-ai-center sub-nav p-flex-wrap p-jc-end">
  <div class="p-d-flex p-my-1 p-flex-wrap p-jc-end">
    <button
      *ngFor="let navItem of navStructure"
      [ngClass]="{'p-button-help': this.urlSegment === navItem.segment}"
      [routerLinkActiveOptions]="{exact: navItem?.exact ?? false}"
      [routerLinkActive]="navItem.icon_active"
      [routerLink]="navItem.routerLink"
      class="{{ navItem.class ?? 'p-mx-1 p-button-secondary' }}"
      icon="{{ navItem.icon }}"
      label="{{ navItem.label | translate }}"
      pButton
    >
    </button>
  </div>
</div>
