/**
 * @Service Update Rule Hook Service
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs/operators';

import { SensorsStore } from '@pages/accounts/sensors/sensors.store';
import { RulesStore } from '@pages/accounts/rules/rules.store';

import { DtoUpdateService } from '@pages/accounts/rules/update-rule/update-rule.service';

import { UpdateRuleComponent } from '@pages/accounts/rules/update-rule/update-rule.component';
import { DateUtility } from '@app/core/utils/date.utility';
import { IOptions } from '@pages/accounts/accounts/accounts.interface';

@UntilDestroy()
@Injectable()
export class UpdateHooksService {
  private isEdit = false;

  private readonly defaultOptionsFront = {
    algo_t_toilet_use_count: {
      time_start: { value: '00:00', required: true },
      time_end: { value: '23:59', required: true },
      'fix_threshold.base_value': { value: 8, required: true, min: 1 },
      'fix_threshold.low_value': { value: 4, required: true, min: 0 },
      'fix_threshold.high_value': { value: 12, required: true, min: 0 }
    },

    algo_t_toilet_urination_count: {
      time_start: { value: '00:00', required: true },
      time_end: { value: '23:59', required: true },
      'fix_threshold.base_value': { value: 8, required: true, min: 1 },
      'fix_threshold.low_value': { value: 4, required: true, min: 0 },
      'fix_threshold.high_value': { value: 12, required: true, min: 0 }
    },

    algo_t_get_ups_count: {
      time_start: { value: '22:00', required: true },
      time_end: { value: '08:00', required: true },
      'fix_threshold.base_value': { value: 2, required: true, min: 1 },
      'fix_threshold.low_value': { value: 0, required: true, min: 0 },
      'fix_threshold.high_value': { value: 5, required: true, min: 0 }
    },
    algo_t_refrigerator_use_count: {
      time_start: { value: '00:00', required: true },
      time_end: { value: '23:59', required: true },
      'fix_threshold.base_value': { value: 15, required: true, min: 1 },
      'fix_threshold.low_value': { value: 4, required: true, min: 0 },
      'fix_threshold.high_value': { value: 30, required: true, min: 0 }
    },

    algo_t_entrance_door_exits_count: {
      time_start: { value: '00:00', required: true },
      time_end: { value: '23:59', required: true },
      'fix_threshold.base_value': { value: 5, required: true, min: 1 },
      'fix_threshold.low_value': { value: 1, required: true, min: 0 },
      'fix_threshold.high_value': { value: 15, required: true, min: 0 }
    },

    algo_t_sit_duration: {
      time_start: { value: '00:00', required: true },
      time_end: { value: '23:59', required: true },
      'fix_threshold.base_value': { value: 1, required: true, min: 1 },
      'fix_threshold.low_value': { value: 0, required: true, min: 0 },
      'fix_threshold.high_value': { value: 3, required: true, min: 0 }
    },

    algo_t_sensor_battery_status: {
      time_start: { value: '00:00', required: true },
      time_end: { value: '23:59', required: true },
      'fix_threshold.base_value': { value: 30, required: true, min: 1 },
      'fix_threshold.low_value': { value: null, required: false, min: 0 },
      'fix_threshold.high_value': { value: null, required: false, min: 0 }
    },

    algo_t_weight_value: {
      time_start: { value: '08:00', required: true },
      time_end: { value: '08:00', required: true },
      'fix_threshold.base_value': { value: 0, required: false },
      'fix_threshold.low_value': { value: -1, required: false, min: 0 },
      'fix_threshold.high_value': { value: 1, required: false, min: 0 }
    },

    algo_t_heart_rate_trend: {
      time_start: { value: '00:00', required: true },
      time_end: { value: '23:59', required: true },
      'fix_threshold.low_value': { value: null, required: false, min: 0 },
      'fix_threshold.high_value': { value: null, required: false, min: 0 }
    },

    algo_t_sleep_duration: {
      time_start: { value: '22:00', required: true },
      time_end: { value: '08:00', required: true },
      'fix_threshold.base_value': { value: 7, required: true },
      'fix_threshold.low_value': { value: 5, required: true, min: 0 },
      'fix_threshold.high_value': { value: 12, required: true, min: 0 }
    },

    algo_t_defecation_count: {
      time_start: { value: '00:00', required: true },
      time_end: { value: '23:59', required: true },
      'fix_threshold.base_value': { value: 8, required: true, min: 1 },
      'fix_threshold.low_value': { value: 4, required: true, min: 0 },
      'fix_threshold.high_value': { value: 12, required: true, min: 0 }
    },

    algo_t_weight_trend: {
      time_start: { value: '08:00', required: true },
      time_end: { value: '08:00', required: true },
      'fix_threshold.base_value': { value: 0, required: true, min: 0 },
      'fix_threshold.low_value': { value: -5, required: true },
      'fix_threshold.high_value': { value: 5, required: true }
    },

    algo_t_respiratory_rate_trend: {
      time_start: { value: '00:00', required: true },
      time_end: { value: '23:59', required: true },
      'fix_threshold.low_value': { value: null, required: false, min: 0 },
      'fix_threshold.high_value': { value: null, required: false, min: 0 }
    },

    algo_t_panic: {
      time_start: { value: '00:00', required: true },
      time_end: { value: '00:01', required: true },
      'fix_threshold.base_value': { value: null, required: false },
      'fix_threshold.base_value_unit': { value: null, required: false },
      'fix_threshold.low_value': { value: null, required: false, min: 0 },
      'fix_threshold.low_value_unit': { value: null, required: false },
      'fix_threshold.high_value': { value: null, required: false, min: 0 },
      'fix_threshold.high_value_unit': { value: null, required: false }
    },

    algo_t_temperature: {
      time_start: { value: '00:00', required: true },
      time_end: { value: '23:59', required: true },
      'fix_threshold.base_value': { value: 25, required: true, min: 1 },
      'fix_threshold.low_value': { value: 8, required: true, min: 0 },
      'fix_threshold.high_value': { value: 40, required: true, min: 0 }
    },

    algo_t_out_of_bed_time: {
      time_start: { value: '22:00', required: true },
      time_end: { value: '10:00', required: true },
      'fix_threshold.base_value': { value: '10:00', required: true }
    },

    '0': {
      time_start: { value: '22:00', required: true },
      time_end: { value: '10:00', required: true }
    },

    undefined: {
      time_start: { value: '22:00', required: true },
      time_end: { value: '10:00', required: true },
      'fix_threshold.base_value': { value: null, required: true, min: 0 },
      'fix_threshold.low_value': { value: null, required: true, min: null },
      'fix_threshold.high_value': { value: null, required: true, min: null }
    }
  };

  constructor(private dto: DtoUpdateService, private rulesStore: RulesStore, private sensorsStore: SensorsStore) {}

  setMode(isEdit: boolean): void {
    this.isEdit = isEdit;
  }

  getDefaultValues(aid: string, field: FormlyFieldConfig): any {
    const aidValue = this.defaultOptionsFront.hasOwnProperty(aid) ? aid : 'undefined';

    let value = this.defaultOptionsFront[aidValue][field.key.toString()]?.value;
    if (value === 0) value = value.toString();
    return value;
  }

  setDefaultValues(aid: string, field: FormlyFieldConfig): void {
    const aidValue = this.defaultOptionsFront.hasOwnProperty(aid) ? aid : 'undefined';

    let value = this.defaultOptionsFront[aidValue][field.key.toString()]?.value;
    if (value === 0) value = value.toString();
    value ? this.defaultOptionsFront[aidValue][field.key.toString()]?.value.toString() : null;
    Boolean(value) && !this.isEdit && field.formControl.setValue(value);
  }

  setValidatorType(type: string, aid: string, field: FormlyFieldConfig): void {
    const aidValue = this.defaultOptionsFront.hasOwnProperty(aid) ? aid : 'undefined';
    field.templateOptions[type] =
      this.defaultOptionsFront[aidValue][field.key.toString()]?.[type.toString() ?? 'min'] ?? null;
  }

  setValidationStatus(field: FormlyFieldConfig, el: string): void {
    let isRequired = true;
    let isValue = null;
    if (this.defaultOptionsFront[el] && this.defaultOptionsFront[el][field.key.toString()]) {
      isRequired = this.defaultOptionsFront[el][field.key.toString()].required;
      isValue = this.defaultOptionsFront[el][field.key.toString()].value;
    }

    field.templateOptions.required = isRequired;
    if (!isValue) {
      field.formControl.setErrors(null);
      field.formControl.markAsTouched({ onlySelf: isRequired });
      field.formControl.updateValueAndValidity();
    }
  }

  timeHook(field: FormlyFieldConfig, ctx: UpdateRuleComponent, type: 'time_start' | 'time_end'): void {
    if (type === 'time_end') {
      const timeStart = DateUtility.HHMMtoSeconds(ctx.item.time_start);
      const timeEnd = DateUtility.HHMMtoSeconds(ctx.item.time_end);
      const isEscludeCloseHous = ctx.item.time_start === '00:00' && ctx.item.time_end === '00:01';
      if (timeEnd <= timeStart || isEscludeCloseHous) {
        field.templateOptions.label = field.templateOptions.label + '(+1)';
      }
    }
    const algoTypeControl = ctx.form.get('algorithm_type_id');
    algoTypeControl.valueChanges.pipe(untilDestroyed(this)).subscribe((el) => {
      !ctx.isEdit && this.setDefaultValues(el, field);
    });
  }

  sensorIdsHook(field: FormlyFieldConfig, ctx: UpdateRuleComponent): void {
    {
      const roomControl = ctx.form.get('room_id');
      const algoTypeControl = ctx.form.get('algorithm_type_id');
      const sensorIdsOptions = this.sensorsStore.sensorsOptions;
      field.templateOptions.options = this.sensorsStore.sensorsOptions;

      roomControl.valueChanges.pipe(untilDestroyed(this)).subscribe((it) => {
        const filteredByRoomId = sensorIdsOptions
          .filter(
            (el) =>
              (it?.length > 0 ? it.includes(el.room_id) : true) &&
              this.rulesStore.algoTypes
                .find((a) => a.type_key === algoTypeControl?.value)
                ?.type_metadata.sensor_type_filter.includes(el.type)
          )
          .map((el) => el.value);
        field.formControl.setValue(filteredByRoomId);

        const filteredOptions = field.formControl?.value?.filter((el) =>
          (field.templateOptions.options as IOptions[]).find((elem) => elem.value === el)
        );
        if (!ctx.item[field.key as string]) {
          !Boolean(field.formControl.value) && field.formControl.setValue(filteredOptions);
        }
      });

      algoTypeControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((algoKey) => {
        if (algoKey) {
          if (!ctx.item[field.key as string]) {
            !Boolean(field.formControl.value) && field.formControl.setValue(null);
          }
          if (roomControl?.value?.length > 0) {
            field.templateOptions.options = sensorIdsOptions.filter(
              (el) =>
                roomControl.value.includes(el.room_id) &&
                this.rulesStore.algoTypes
                  .find((a) => a.type_key === algoKey)
                  ?.type_metadata.sensor_type_filter.includes(el.type)
            );
          } else {
            !Boolean(field.formControl.value) &&
              field.formControl.setValue(
                ctx.sensorsStore.sensorsOptions
                  .filter((el) =>
                    sensorIdsOptions.find((it) => {
                      return it.value === el.value;
                    })
                  )
                  .map((el) => el.value)
              );
          }
        }
      });
    }
  }

  fixBaseValueHook(field: FormlyFieldConfig, ctx: UpdateRuleComponent): void {
    ctx.form
      .get('fix_threshold.base_value_unit')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        if (el === 'sd') {
          field.formControl.setValue(-field.formControl.value);
        } else if (el === 'percent') {
          field.templateOptions.min = 0;
          field.templateOptions.max = 100;
        } else {
          const aid = ctx.form.get('algorithm_type_id').value;
          this.setValidatorType('min', aid, field);
          field.templateOptions.max = null;
        }
      });

    const personWeight =
      ctx.accountsStore.account.monitored_persons.find(
        (el) => el.id === ctx.item.monitored_persons_ids && el.is_active === true
      )?.weight_kg ?? 0;
    ctx.form
      .get('algorithm_type_id')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        !ctx.isEdit && field.formControl.setValue(null);
        !ctx.isEdit && this.setDefaultValues(el, field);
        const metaData = this.dto.algoTypeMetaData(el);
        this.setValidationStatus(field, el);
        if (metaData?.base_thr_default) {
          field.formControl.setValue(metaData?.base_thr_default);
        }
        if (el === 'algo_t_weight_value') {
          !ctx.isEdit && field.formControl.setValue(personWeight);
        }
      });

    ctx.form
      .get('monitored_persons_ids')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((person) => {
        if (ctx.form.get('algorithm_type_id').value === 'algo_t_weight_value') {
          const personWeight =
            ctx.accountsStore.account.monitored_persons.find((el) => el.id === person && el.is_active === true)
              ?.weight_kg ?? 0;
          !ctx.isEdit && field.formControl.setValue(personWeight);
        }
      });
  }

  fixBaseValueHookTime(field: FormlyFieldConfig, ctx: UpdateRuleComponent): void {
    if (!field.formControl.value) {
      field.formControl.setValue('00:00');
    }
    ctx.form
      .get('algorithm_type_id')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        !ctx.isEdit && field.formControl.setValue(null);
        !ctx.isEdit && this.setDefaultValues(el, field);
        const metaData = this.dto.algoTypeMetaData(el);
        this.setValidationStatus(field, el);
        if (metaData?.base_thr_default) {
          field.formControl.setValue(metaData?.base_thr_default);
        }
      });
  }

  fixBaseValueUnitHook(field: FormlyFieldConfig, ctx: UpdateRuleComponent): void {
    const algoType = ctx.item.algorithm_type;
    field.templateOptions.required = ctx.excludedAlgoTypesFromFixLimits.includes(algoType);
    ctx.form
      .get('algorithm_type_id')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        !ctx.isEdit && field.formControl.setValue(null);
        this.setValidationStatus(field, el);
      });
  }

  fixLowValueHook(field: FormlyFieldConfig, ctx: UpdateRuleComponent): void {
    ctx.form
      .get('fix_threshold.low_value_unit')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        if (el === 'sd') {
          if (field.formControl.value > 0) {
            field.formControl.setValue(-field.formControl.value);
          }
          field.templateOptions.min = null;
        } else if (el === 'percent') {
          field.templateOptions.min = 0;
        } else {
          const aid = ctx.form.get('algorithm_type_id').value;
          this.setValidatorType('min', aid, field);
          field.templateOptions.max = null;
        }
      });

    const baseControl = ctx.form.get('fix_threshold.base_value');
    ctx.form
      .get('algorithm_type_id')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        !ctx.isEdit && field.formControl.setValue(null);
        !ctx.isEdit && this.setDefaultValues(el, field);
        const metaData = this.dto.algoTypeMetaData(el);
        this.setValidationStatus(field, el);
        if (metaData?.lower_thr_default) {
          !field.formControl.value && field.formControl.setValue(metaData?.lower_thr_default);
        }
        if (el === 'algo_t_weight_value') {
          !ctx.isEdit && field.formControl.setValue(field.formControl.value + baseControl.value);
        }
      });

    ctx.form
      .get('fix_threshold.base_value')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        if (ctx.form.get('algorithm_type_id').value === 'algo_t_weight_value') {
          const value = this.getDefaultValues('algo_t_weight_value', field);
          !ctx.isEdit && field.formControl.setValue(+el + value);
        }
      });
  }

  fixLowValueTimeHook(field: FormlyFieldConfig, ctx: UpdateRuleComponent): void {
    ctx.form
      .get('algorithm_type_id')
      .valueChanges.pipe(untilDestroyed(this), startWith(ctx.form.get('algorithm_type_id')?.value as number | string))
      .subscribe((el) => {
        !ctx.isEdit && field.formControl.setValue(null);
        field.defaultValue = '00:00';
        const metaData = this.dto.algoTypeMetaData(el);
        this.setValidationStatus(field, el);
        if (metaData?.lower_thr_default) {
          !field.formControl.value && field.formControl.setValue(metaData?.lower_thr_default);
        }
      });
  }

  fixLowValueUnitHook(field: FormlyFieldConfig, ctx: UpdateRuleComponent): void {
    this.dto.dependantOptions(field, ctx.item, ctx.form);

    ctx.form
      .get('algorithm_type_id')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        this.setValidationStatus(field, el);
      });
  }

  fixHighValueHook(field: FormlyFieldConfig, ctx: UpdateRuleComponent): void {
    ctx.form
      .get('fix_threshold.high_value_unit')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        if (el === 'sd') {
          field.templateOptions.min = null;
        } else if (el === 'percent') {
          field.templateOptions.min = 0;
        } else {
          const aid = ctx.form.get('algorithm_type_id').value;
          this.setValidatorType('min', aid, field);
          field.templateOptions.max = null;
        }
      });
    const baseControl = ctx.form.get('fix_threshold.base_value');
    ctx.form
      .get('algorithm_type_id')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        !ctx.isEdit && field.formControl.setValue(null);
        !ctx.isEdit && this.setDefaultValues(el, field);
        const metaData = this.dto.algoTypeMetaData(el);
        this.setValidationStatus(field, el);
        if (metaData?.upper_thr_default) {
          !field.formControl.value && field.formControl.setValue(metaData?.upper_thr_default);
        }
        if (el === 'algo_t_weight_value') {
          !ctx.isEdit && field.formControl.setValue(field.formControl.value + baseControl.value);
        }
      });

    ctx.form
      .get('fix_threshold.base_value')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        if (ctx.form.get('algorithm_type_id').value === 'algo_t_weight_value') {
          const value = this.getDefaultValues('algo_t_weight_value', field);
          !ctx.isEdit && field.formControl.setValue(+el + value);
        }
      });
  }

  fixHighValueTimeHook(field: FormlyFieldConfig, ctx: UpdateRuleComponent): void {
    ctx.form
      .get('algorithm_type_id')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        !ctx.isEdit && field.formControl.setValue(null);
        !ctx.isEdit && this.setDefaultValues(el, field);
        field.defaultValue = '00:00';
        const metaData = this.dto.algoTypeMetaData(el);
        this.setValidationStatus(field, el);
        if (metaData?.upper_thr_default) {
          !field.formControl.value && field.formControl.setValue(metaData?.upper_thr_default);
        }
      });
  }

  fixHighValueUnitHook(field: FormlyFieldConfig, ctx: UpdateRuleComponent): void {
    this.dto.dependantOptions(field, ctx.item, ctx.form);
    ctx.form
      .get('algorithm_type_id')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((el) => {
        this.setValidationStatus(field, el);
      });
  }
}
