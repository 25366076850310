/**
 * @Component Rule tooltip component using prime-ng tooltip
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-tooltip',
  template: `
    <div class="icon-wrapper">
      <i id="icon" class="pi pi-question-circle" (mouseenter)="op.show($event)" (mouseleave)="op.hide()"></i>
    </div>
    <p-overlayPanel
      #op
      [styleClass]="'panel'"
      [showCloseIcon]="false"
      [style]="{ width: '450px', zIndex: 999 }"
    >
      <ng-template pTemplate>
        <div [innerHTML]="to.content | safeHtml"></div>
      </ng-template>
    </p-overlayPanel>
  `,
  styles: [
    `
      .icon-wrapper {
        height: 100%;
        min-height: 4rem;
        display: flex;
        align-items: center;
        width: 4rem;
      }

      .pi.pi-question-circle {
        margin-top: 0.6rem;
        font-size: 2rem;
        cursor: pointer;
      }
    `
  ]
})
export class FormlyFieldTooltipComponent extends FieldType {}
