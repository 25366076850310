/**
 * @Service Non-Auth Guard service for unauthorized routes
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core/services/auth.service';
import { NAVIGATION } from '@app/const';

@Injectable({
    providedIn: 'root'
})
export class NonAuthGuard implements CanActivate {
    constructor(public router: Router, private authService: AuthService) {}

    /**
     * @description canActivate -> true if route unauthorized, false if authorized
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isLoggedIn) {
            return true;
        }
        this.router.navigate([NAVIGATION.pages.path]).then();
        return false;
    }
}
