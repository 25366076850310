<div *ngIf="page !== undefined else mainPage" class="p-d-flex p-ai-center p-jc-between">
  <button *ngIf="!hideBackButton"
          (click)="location.back()"
          class="p-button-rounded"
          icon="pi pi-arrow-left"
          pButton
          pRipple
          style="min-width:30px"
          type="button">
  </button>
  <h1 *ngIf="(isEdit !== false || isEdit === undefined) else newItem" class="p-mx-2">
    {{ 'PAGES.' + page + '.details' | translate }} ID: {{ id }}
    <ng-container *ngIf="accountId">
      / {{ 'PAGES.accounts.account' | translate }} ID: {{ accountId }},<br>
      {{ 'PAGES.accounts.fields.gateway_id' | translate }}: {{ accountsStore.account?.gateway_id }},
      {{ 'PAGES.accounts.fields.owner_name' | translate }}:
      {{ accountsStore.account?.owner_first_name }}
      {{ accountsStore.account?.owner_last_name }}
      <ng-container *ngIf="extra">
        <br>
        <ng-container *ngFor="let item of extra; let first = first; let last = last;">
          {{ item.title }}: {{ item.value }}
        </ng-container>
      </ng-container>
    </ng-container>
  </h1>
  <ng-template #newItem>
    <h1 class="p-mx-2">
      {{ 'PAGES.' + page + '.new' | translate }},
      {{ 'PAGES.accounts.account' | translate }} ID: {{ accountId }},<br>
      {{ 'PAGES.accounts.fields.gateway_id' | translate }}: {{ accountsStore.account?.gateway_id }},
      {{ 'PAGES.accounts.fields.owner_name' | translate }}:
      {{ accountsStore.account?.owner_first_name }}
      {{ accountsStore.account?.owner_last_name }}
    </h1>
  </ng-template>
</div>
<ng-template #mainPage>
  <div class="p-d-flex p-ai-center p-jc-between">
    <button
      *ngIf="!hideBackButton"
      (click)="location.back()"
      class="p-button-rounded"
      icon="pi pi-arrow-left"
      pButton
      pRipple
      style="min-width:30px"
      type="button"
    >
    </button>
    <h1 *ngIf="(isEdit !== false || isEdit === undefined) else newAccount" class="p-mx-2">
      <ng-container *ngIf="id else list">
        {{ 'PAGES.accounts.details' | translate }} ID: {{ item.id }},
      </ng-container>
      <ng-template #list>
        {{ 'PAGES.' + path + '.label' | translate }}
      </ng-template>
      <ng-container *ngIf="accountId">
        / {{ 'PAGES.accounts.account' | translate }} ID: {{ accountId }},<br>
        {{ 'PAGES.accounts.fields.gateway_id' | translate }}: {{ item?.gateway_id }},
        {{ 'PAGES.accounts.fields.owner_name' | translate }}:
        {{ item?.owner_first_name }}
        {{ item?.owner_last_name }}
      </ng-container>
    </h1>
    <ng-template #newAccount>
      <h1 class="p-mx-2">{{ 'PAGES.' + path + '.new' | translate }}</h1>
    </ng-template>
  </div>
</ng-template>
