import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-custom-types',
  template: `
    <ng-container *ngFor="let f of field.fieldGroup">
      <formly-field [field]="f" *ngIf="to?.type === f.type"></formly-field>
    </ng-container>
  `
})
export class FormlyFieldTypesMultipleComponent extends FieldType {
}
