import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { formatDate, Location } from "@angular/common";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { HttpResponse } from "@angular/common/http";
import * as fileSaver from "file-saver";
import { Title } from "@angular/platform-browser";

import { GuiStore } from "@stores/gui.store";
import { LogsService } from "@pages/logs/logs.service";
import { NotificationService } from "@services/notification.service";

import { TranslateService } from "@ngx-translate/core";
import { delay } from "@app/core/utils/async.utility";

@UntilDestroy()
@Component({
  selector: "app-logs",
  templateUrl: "./logs.component.html",
  styleUrls: ["./logs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogsComponent implements OnInit {
  fields: FormlyFieldConfig[];
  form: UntypedFormGroup;
  options: FormlyFormOptions;
  model: { date?: Date[] | Date; lang?: any } = {};
  error: boolean;
  maxDate = new Date();

  constructor(
    public title: Title,
    public guiStore: GuiStore,
    public translationService: TranslateService,
    public notificationService: NotificationService,
    public logsService: LogsService,
    public location: Location
  ) {
    this.model.lang = guiStore.currentLanguage;
  }

  ngOnInit(): void {
    this.formFields();
    delay(100).then(() => {
      this.title.setTitle(this.translationService.instant("MENU.log.log"));
    });

    this.translationService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.title.setTitle(this.translationService.instant("MENU.log.log"));
      this.formFields();
    });
  }

  isDisabled(): boolean {
    let disabled = true;
    const start = this.model?.date[0];
    const end = this.model?.date[1];

    const startDay = start?.getDate();
    const endDay = end?.getDate();
    if (startDay && endDay) {
      if (endDay - startDay < 7) {
        disabled = false;
      } else {
        this.error = true;
        if (this.error) {
          this.errorNotification();
          const weekLater = new Date(this.model?.date[0]);
          weekLater.setDate(weekLater?.getDate() + 6);
          this.model = { date: [this.model.date[0], weekLater] };
          this.error = false;
        }
      }
    }
    return disabled;
  }

  getLogs(): void {
    const start = this.model?.date[0];
    const end = this.model?.date[1];
    start?.setDate(start?.getDate() + 1);
    end?.setDate(end?.getDate() + 1);
    start?.setUTCHours(0);
    end?.setUTCHours(23);
    end?.setUTCMinutes(59);
    end?.setUTCSeconds(59);
    this.model.date[0] = start;
    this.model.date[1] = end;
    if (this.model.lang) {
      delete this.model.lang;
    }
    this.logsService
      .getLogs({
        start: this.model?.date[0],
        end: this.model?.date[1]
      })
      .pipe(untilDestroyed(this))
      .subscribe({
          next: (response: HttpResponse<BlobPart>) => {
            const blob: any = new Blob([response.body], { type: `${response.type}; charset=utf-8` });
            // const url = window.URL.createObjectURL(blob);
            fileSaver.saveAs(blob, `${formatDate(new Date(), "YYYY-MM-dd_HH-mm-ss", "en_US")}_log.csv`);
          },
          error: () => console.log("Error downloading the file"),
          complete: () => console.log("File downloaded successfully")
        }
      );
  }

  errorNotification(): void {
    this.notificationService.addSingle({
      severity: "error",
      summary: this.translationService.instant("MODAL.attention"),
      detail: this.translationService.instant("PAGES.logs.error_message")
    });
  }

  formFields(): void {
    this.fields = [
      {
        fieldGroupClassName: "p-d-flex p-jc-end p-reports",
        fieldGroup: [
          {
            className: "p-d-flex p-mx-1 p-flex-column",
            type: "datepicker",
            key: "date",
            defaultValue: new Date(),
            templateOptions: {
              showError: true,
              dataType: "date",
              placeholder: "YYYY/MM/DD",
              description: "Description",
              dateFormat: "yy/mm/dd",
              hourFormat: "24",
              numberOfMonths: 1,
              maxDate: this.maxDate,
              selectionMode: "range",
              required: true,
              locale: this.model.lang,
              readonlyInput: false,
              showTime: false,
              showButtonBar: false,
              showIcon: false,
              showOtherMonths: true,
              selectOtherMonths: false,
              monthNavigator: false,
              yearNavigator: false,
              yearRange: "2020:2030",
              inline: true,
              label: "PAGES.logs.select_date_range"
            }
          }
        ]
      }
    ];
  }
}
