/**
 * @Service Service for getting countries, cities data for using them in the dropdown lists mostly
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventService {
    public detectSource$: Subject<boolean> = new Subject<boolean>();
    public readonly currentDetectionChange: Observable<boolean> = this.detectSource$.asObservable();

    detect(): void {
        this.detectSource$.next(true);
    }
}
