/**
 * @Service Account's service for CRUD actions with API server.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { NAVIGATION } from '@app/const';
import { environment } from '@environments/environment';

import { IAccount } from '@pages/accounts/accounts/accounts.interface';
import { lastValueFrom, map } from 'rxjs';

@Injectable()
export class AccountsService {
  apiURL = environment.apiUrl;
  api = `${this.apiURL}/${NAVIGATION.accounts.api}`;
  apiImageUpload = (id: string) => `${this.apiURL}/${NAVIGATION.accounts.api}/${id}/image`;

  constructor(private http: HttpClient) {}

  getAccounts(offset = null): Promise<HttpResponse<IAccount[]>> {
    return lastValueFrom(
      this.http
        .get<IAccount[]>(this.api, {
          params: offset,
          observe: 'response'
        })
        .pipe(map((res: HttpResponse<IAccount[]>) => res))
    );
  }

  getAccount(id: string): Promise<IAccount> {
    return lastValueFrom(
      this.http
        .get<IAccount>(`${this.api}/${id}`, {
          observe: 'response'
        })
        .pipe(map((res: HttpResponse<IAccount>) => res.body))
    );
  }

  addAccount(body: IAccount): Promise<any> {
    return lastValueFrom(
      this.http.post<IAccount>(this.api, body, {
        observe: 'response'
      })
    );
  }

  addAccountImage(id, formData: FormData): Promise<any> {
    const fileHeaders = new HttpHeaders();
    fileHeaders.set('Content-Type', 'multipart/form-data');
    return lastValueFrom(
      this.http.post<IAccount>(this.apiImageUpload(id), formData, {
        observe: 'response',
        reportProgress: true,
        headers: fileHeaders
      })
    );
  }

  deleteAccountImage(id): Promise<any> {
    return lastValueFrom(
      this.http.delete<IAccount>(this.apiImageUpload(id), {
        observe: 'response'
      })
    );
  }

  editAccount(body: IAccount): Promise<any> {
    return lastValueFrom(
      this.http.put<IAccount>(`${this.api}/${body.id}`, body, {
        observe: 'response'
      })
    );
  }

  deleteAccount(id: string): Promise<any> {
    return lastValueFrom(
      this.http.delete<any>(`${this.api}/${id}`, {
        observe: 'response'
      })
    );
  }
}
