/**
 * @Module Auth modules for login pages etc.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@modules/shared.module';
import { LoginPageComponent } from '@auth/login-page/login-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [LoginPageComponent],
    imports: [CommonModule, SharedModule, ReactiveFormsModule, FormsModule]
})
export class AuthModule {}
