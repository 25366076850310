/**
 * @Component 404 page layout component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { delay } from '@app/core/utils/async.utility';
import { Router } from '@angular/router';

@Component({
    selector: 'app-404',
    template: ` <h1 style="text-align: center">404 | {{ 'DEFAULTS.not_found' | translate }}!</h1> `
})
export class Page404Component implements OnInit {
    constructor(public translate: TranslateService, private router: Router) {}

    ngOnInit(): void {
        delay(2000).then(async () => {
            await this.router.navigate(['..']);
        });
    }
}
