<div class="container"
     [ngStyle]="{'direction': guiStore.currentLanguage === ELanguages.he ? 'rtl':'ltr'}">
  <div class="content-wrapper">
    <div class="icon">
      <i class="{{ modal.icon }} p-mx-2" [style.color]="modal.color"></i>
    </div>
    <div class="text">
      <div *ngIf="modal?.message">{{ 'MODAL.heading.message' | translate}}:
        <div class="message-content">{{ modal.message }}</div>
      </div>
      <div class="p-d-flex">
        <span [translate]="'MODAL.heading.id'"
              [translateParams]="{value: modal.item.id}">
        </span>
        <span class="p-mx-1">
          <a [routerLink]="[navigation.notifications.path, modal.item.id]">
          <i class="pi pi-link"></i>
        </a></span>
      </div>
      <div [translate]="'MODAL.heading.accountID'"
           [translateParams]="{value: modal.item.account.id}">
      </div>
      <div [translate]="'MODAL.heading.accountName'"
           [translateParams]="{first: modal.item.account.owner_first_name,
            second: modal.item.account.owner_last_name}">
      </div>
      <div [translate]="'MODAL.heading.personName'"
           [translateParams]="{first: modal.item.person.first_name,
            second: modal.item.person.last_name}">
      </div>
      <div [translate]="'MODAL.heading.sensorID'"
           [translateParams]="{value:  modal.item?.rule.sensor_ids}">
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="form">
      <formly-form
        [fields]="fields"
        [form]="form"
        [model]="result">
      </formly-form>
    </div>
    <div class="actions">
      <button type="button"
              pButton
              icon="pi pi-check"
              (click)="confirm()"
              label="{{ 'MODAL.heading.confirm' | translate | uppercase }}"
              [disabled]="!isFormValid"
              class="p-button p-button-success"></button>
      <button type="button"
              icon="pi pi-times"
              pButton (click)="cancel()"
              label="{{ 'MODAL.heading.cancel' | translate | uppercase }}"
              class="p-button p-button-secondary"></button>
    </div>
  </div>
</div>
