/**
 * @Component Custom repeating component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import {Component} from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
    selector: 'app-formly-repeat-section',
    template: `
        <div *ngFor="let fieldItem of field.fieldGroup; let i = index" class="p-d-flex">
            <formly-field class="p-col" [field]="fieldItem"></formly-field>
            <div class="p-d-flex p-ai-center" *ngIf="!to.isDisabled">
                <p-button
                    [hidden]="fieldItem.fieldGroup[0]?.templateOptions?.disabled"
                    styleClass="p-button-rounded p-button-danger"
                    icon="pi pi-times"
                    (click)="remove(i)"
                >
                </p-button>
            </div>
        </div>
        <div class="p-mx-3" *ngIf="!to.isDisabled">
            <p-button (click)="add()" label="{{ 'DEFAULTS.add' | translate }}"></p-button>
        </div>
    `
})
export class FormlyFieldRepeatingComponent extends FieldArrayType {
}
