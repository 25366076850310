<header class="layout-topbar p-shadow-3 p-jc-between p-px-2">
    <div class="p-d-flex p-jc-between p-ai-center p-p-2 p-header-ordered">
        <div><app-logo></app-logo></div>
        <div><app-menu *ngIf="authService.isLoggedIn" class="menu-navbar"></app-menu></div>
        <div class="p-d-flex">
            <app-language-selector></app-language-selector>
            <app-auth-menu></app-auth-menu>
        </div>
    </div>
</header>
