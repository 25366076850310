/**
 * @Module Logs module for global pages
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsModule } from '@pages/notifications/notifications.module';
import { SharedModule } from '@modules/shared.module';
import { PagesSharedModule } from '@pages/pages-shared.module';
import { LogsRoutingModule } from '@pages/logs/logs-routing.module';
import { CalendarModule } from 'primeng/calendar';

import { LogsComponent } from '@pages/logs/logs.component';

@NgModule({
    declarations: [LogsComponent],
    imports: [CommonModule, LogsRoutingModule, NotificationsModule, SharedModule, PagesSharedModule, CalendarModule]
})
export class LogsModule {}
