<div *ngIf="item else loading">
    <div class="p-d-flex p-jc-between p-ai-center p-mb-2">
        <app-sub-heading
            [id]="item.id"
            [accountId]="accountId"
            [item]="accountsStore.account"
            [page]="'notifications'"
            >
        </app-sub-heading>
        <app-sub-nav
            *ngIf="accountId !== '' && accountId !== undefined"
            [id]="accountId"
            [path]="'accounts'"
            class="p-mb-2 p-as-end">
        </app-sub-nav>
    </div>
    <form *mobxAutorun [formGroup]="form">
        <formly-form [fields]="fields" [form]="form" [model]="item">
        </formly-form>
        <button
            (click)="saveItem()"
            [disabled]="!form.valid"
            class="p-button-success p-button-lg p-mt-1"
            icon="pi pi-check"
            label="{{ 'MODAL.save' | translate }}"
            pButton
            pRipple>
        </button>
    </form>
</div>
<app-view-notification [editView]="true"></app-view-notification>
<ng-template #loading>
</ng-template>
