/**
 * @Component File Upload Component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { UntypedFormControl } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { NotificationService } from "@services/notification.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-formly-field-file",
  template: `
    <div class="p-d-flex">
      <div class="p-ai-start p-d-inline-flex">
        <div class="p-mx-1"
             [ngClass]="{'upload': url===''}"
             (mouseenter)="!to.disabled ? op.show($event) : false"
             (mouseleave)="!to.disabled ? op.hide(): false"
        >
          <p-confirmPopup></p-confirmPopup>
          <p-overlayPanel
            #op
            [styleClass]="'panel'"
            [showCloseIcon]="false"
            [style]="{ zIndex: 999 }"
          >
            <ng-template pTemplate>
              <div [hidden]="url!==''" [innerHTML]="to.uploadText | safeHtml"></div>
              <div [hidden]="url===''" [innerHTML]="to.reuploadText | safeHtml"></div>
            </ng-template>
          </p-overlayPanel>
          <p-overlayPanel
            #opDelete
            [styleClass]="'panel'"
            [showCloseIcon]="false"
            [style]="{ zIndex: 999 }"
          >
            <ng-template pTemplate>
              <div
                [innerHTML]="'MODAL.delete_image_tooltip' | translate | safeHtml"
                class="delete_tooltip"
              ></div>
            </ng-template>
          </p-overlayPanel>
          <p-fileUpload
            #fileUpload
            ngDefaultControl
            [maxFileSize]="to.maxFileSize ?? 5000000"
            mode="basic"
            [multiple]="false"
            chooseIcon="pi pi-upload"
            uploadIcon="pi pi-pencil"
            accept="image/*"
            [name]="to.name ?? 'file'"
            [auto]="to.auto ?? true"
            [disabled]="to.disabled"
            [customUpload]="to.customUpload ?? true"
            [showCancelButton]="true"
            (uploadHandler)="onUploadHandler($event)"
            [formControl]="fControl"
            [formlyAttributes]="field"
          ></p-fileUpload>
        </div>
        <span [hidden]="url === ''" class="image">
        <p-image
          class="pimage"
          [preview]="true"
          [src]="url"
          alt="Image"
          height="100"
        ></p-image>
      </span>
        <a class="cancel"
           [hidden]="url === '' || to.disabled"
           (click)="confirm($event)"
           (mouseenter)="opDelete.show($event)"
           (mouseleave)="opDelete.hide()"
        >
          <i class="pi pi-trash"></i>
        </a>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .pimage {
        max-width: 130px;
      }

      .delete_tooltip {
        color: red;
      }

      .upload {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #e5e5e5;
        width: 100px;
        height: 100px;
      }

      .image {
        border: 0 solid #e5e5e5;
      }

      .cancel {
        font-size: 1rem;
        color: red;
        cursor: pointer;
        margin: 0 0.3rem;
      }

      .cancel:hover {
        opacity: 0.8;
      }

      .cancel .pi-trash {
        font-size: 2rem;
      }
    `
  ]
})
export class FormlyFieldFileComponent extends FieldType implements OnInit {
  @ViewChild("fileUpload") fileUpload: any;
  url = "";

  constructor(
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    public translationService: TranslateService
  ) {
    super();
  }

  public get fControl() {
    return this.formControl as UntypedFormControl;
  }

  ngOnInit(): void {
    this.url = this.field.formControl.value ?? "";
  }

  confirm(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: this.translationService.instant("MODAL.delete_image.message"),
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        const { deleteHandler } = this.field.templateOptions;
        deleteHandler().then((result) => {
          if (result)
            this.updateValue("");
            this.cdr.detectChanges();
            this.notificationService.addSingle({
              severity: "success",
              summary: this.translationService.instant("MODAL.delete_image.header"),
              detail: this.translationService.instant("MODAL.delete_image.accept.notification.detail")
            });
        }).catch(() => {
          this.notificationService.addSingle({
            severity: "danger",
            summary: this.translationService.instant("MODAL.delete_image.cant_delete_image"),
            detail: this.translationService.instant("MODAL.delete_image.reject.rejecty.notification.detail")
          });
        });
      },
      reject: () => {
      }
    });
  }

  updateValue(value) {
    this.url = value;
    this.field.formControl.setValue(value);
  }

  async onUploadHandler(event) {
    const { uploadHandler } = this.field.templateOptions;
    this.url = await uploadHandler(event) ?? "";
    this.fileUpload.clear();
    this.cdr.detectChanges();
  }

}
