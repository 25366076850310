/**
 * @Component Rooms component for displaying list of rooms
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

// Stores
import { GuiStore } from '@stores/gui.store';
import { AccountsStore } from '@pages/accounts/accounts/accounts.store';
import { RoomsStore } from '@pages/accounts/rooms/rooms.store';
import { SensorsStore } from '@pages/accounts/sensors/sensors.store';

// Services
import { ModalService } from '@services/modal.service';
import { NotificationService } from '@services/notification.service';
import { ConfirmationService } from 'primeng/api';

// Helpers and interfaces
import { delay } from '@app/core/utils/async.utility';
import { IAccountRoom } from '@pages/accounts/rooms/rooms.interface';
import { IAccountItemColumns } from '@pages/accounts/accounts/accounts.interface';
import { Roles } from '@app/core/interfaces/user.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-rooms',
  templateUrl: 'rooms.component.html'
})
export class RoomsComponent implements OnInit {
  accountId: string;
  items: IAccountRoom[];
  item: IAccountRoom;
  selectedItems: IAccountRoom[];
  cols: IAccountItemColumns[];
  loading: boolean;
  totalRecords: number;
  Roles = Roles;

  constructor(
    public title: Title,
    public guiStore: GuiStore,
    public translationService: TranslateService,
    public accountsStore: AccountsStore,
    public roomsStore: RoomsStore,
    private sensorsStore: SensorsStore,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {}

  mapFields(data): void {
    this.items = data
      .map((el) => {
        el.color = JSON.parse(el?.extra)?.color;
        el.connected_to = el.connected_to?.map((item) => data.find((it) => it.id === item)?.room_tag);
        el.is_outdoor = el.room_type === 'room_t_outdoors'
        return el;
      })
      .sort((a, b) => (a.id < b.id ? 1 : -1));
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.paramMap.get('id');
    this.loading = true;
    this.roomsStore
      .fetchRooms(this.accountId)
      .then((data) => {
        this.totalRecords = data.length;
        this.mapFields(data);
        this.loading = false;
      })
      .catch((_) => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });

    this.cols = [
      { name: 'id', styles: { width: '5rem', 'text-align': 'center' } },
      { name: 'room_tag', component: 'room_tag' },
      { name: 'connected_to', component: 'connected_to' },
      { name: 'room_size.length', component: 'room_size_length' },
      { name: 'room_size.width', component: 'room_size_width' }
    ];

    delay(100).then(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.rooms'));
    });

    this.translationService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.rooms'));
    });
  }

  deleteSelectedItems(): void {
    const modal = {
      type: 'delete_item',
      defaultFocus: 'reject',
      acceptButtonStyleClass: 'p-button-danger',
      icon: 'pi pi-info-circle big red',
      header: this.translationService.instant(`MODAL.delete_item.header`),
      message: `<div class="p-error p-my-2">${this.translationService.instant(
        `MODAL.delete_item.account_delete_warning`,
        { item: this.translationService.instant(`MODAL.selected_items`) }
      )}</div>`,
      accept: {
        callback: async () => {
          await this.roomsStore.deleteRooms(
            this.accountId,
            this.selectedItems.map((el) => el.id)
          );
          this.mapFields(this.roomsStore.rooms);
          this.totalRecords = this.roomsStore.roomsLength;
          this.selectedItems = null;
        }
      }
    };
    this.modalService.showModal(modal);
  }

  async deleteItem(item: IAccountRoom): Promise<void> {
    let attachedSensors;
    let notificationProp;
    try {
      const sensors = await this.sensorsStore.fetchSensors(this.accountId);
      attachedSensors = sensors.findIndex((el) => el.room_id === +item.id || el.room_out_id === +item.id);
    } catch (e) {}
    if (attachedSensors > -1) {
      notificationProp = {
        acceptButtonStyleClass: 'p-button-danger',
        icon: 'pi pi-info-circle big red',
        message: `<div class="p-error p-my-2">${this.translationService.instant(
          `MODAL.delete_item.room_delete_warning`,
          { item: item.room_tag }
        )}</div>`
      };
    } else {
      notificationProp = {
        acceptButtonStyleClass: 'p-button-danger',
        icon: 'pi pi-info-circle big red',
        message: `<div class="p-error p-my-2">${this.translationService.instant(`MODAL.delete_item.message`, {
          item: item.room_tag
        })}</div>`
      };
    }
    const modal = {
      type: 'delete_item',
      defaultFocus: 'reject',
      header: this.translationService.instant(`MODAL.delete_item.header`),
      ...notificationProp,
      accept: {
        callback: async () => {
          await this.roomsStore.deleteRoom(this.accountId, item.id);
          this.totalRecords = this.roomsStore.roomsLength;
          this.mapFields(this.roomsStore.rooms);
          this.item = null;
        }
      }
    };
    this.modalService.showModal(modal);
  }
}
