<div>
    <div class="p-d-flex p-ai-center">
        <button
            (click)="location.back()"
            class="p-button-rounded"
            icon="pi pi-arrow-left"
            pButton
            pRipple
            style="min-width:30px"
            type="button">
        </button>
        <h1 class="p-mx-2">{{ 'PAGES.logs.logs' | translate }}</h1>
    </div>
    <div class="p-card p-pt-4 p-pb-2 p-mt-3">
        <div class="p-d-flex p-flex-column p-jc-center p-p-2 p-ai-center">
            <formly-form
                [fields]="fields"
                [form]="form"
                [model]="model"
            ></formly-form>
            <button
                [disabled]="isDisabled()"
                (click)="getLogs()"
                class="p-button p-my-2"
                icon="pi pi-save"
                pButton
                pRipple
                label="{{ 'PAGES.logs.download_button' | translate }}"
                style="min-width:30px"
                type="button">
            </button>
        </div>
    </div>
</div>

