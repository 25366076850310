<div *ngIf="item else loading">
  <div class="p-d-flex p-ai-center p-jc-between p-mb-2">
    <app-sub-heading
      [id]="item.id"
      [accountId]="accountId"
      [isEdit]="route.snapshot?.data?.isEdit"
      [page]="'persons'"
      [path]="'accounts'">
    </app-sub-heading>
    <app-sub-nav [path]="'accounts'" [id]="accountId"></app-sub-nav>
  </div>
  <form [formGroup]="form" [ngClass]="{'form-only-view': isView}">
    <formly-form [fields]="fields" [form]="form" [model]="item">
    </formly-form>
    <app-sub-save-button
      [isView]="isView"
      [icon]="!personsStore.state.loading && 'pi pi-check'"
      label="{{ 'MODAL.save' | translate }}"
      [isDisabled]="!form.valid || personsStore.state.loading"
      [isLoading]="personsStore.state.loading"
      (saveItem)="saveItem()"
    ></app-sub-save-button>
    <app-sub-options-buttons
      *ngIf="isView"
      [item]="item"
      label="{{ 'DEFAULTS.edit' | translate }}"
    ></app-sub-options-buttons>
  </form>
</div>
<ng-template #loading>
</ng-template>
