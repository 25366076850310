<div *mobxAutorun class="p-d-flex p-flex-column">
  <app-sub-nav
    [id]="accountId"
    [path]="'accounts'"
    class="p-mb-2 p-as-end">
  </app-sub-nav>
  <div class="p-d-flex p-jc-between p-ai-center p-mb-2">
    <app-sub-heading
      [item]="accountsStore.account"
      [accountId]="accountId"
      [path]="'rules'">
    </app-sub-heading>
    <div class="page-actions">
      <app-sub-search
        (filterHandler)="dt.filterGlobal($event, 'contains')"
        [page]="'rules'">
      </app-sub-search>
      <app-sub-new-delete
        (deleteHandler)="deleteSelectedItems()"
        [page]="'rules'"
        [selectedItems]="selectedItems">
      </app-sub-new-delete>
    </div>
  </div>
  <div>
    <div class="card">
      <p-table #dt
               [(selection)]="selectedItems"
               [columns]="cols"
               [globalFilterFields]="['monitored_persons_ids','sensor_ids','algorithm_type','rule_type','notification_type','notify_policy','time']"
               [loading]="loading"
               [paginator]="true"
               [resizableColumns]="true"
               [rowHover]="true"
               [rowsPerPageOptions]="[10,25,50]"
               [rows]="10"
               [showCurrentPageReport]="false"
               [totalRecords]="totalRecords"
               [value]="items"
               dataKey="id"
               scrollHeight="100%"
               tableStyleClass="p-datatable p-datatable-sm p-datatable-striped p-datatable-customers p-datatable-gridlines">
        <ng-template let-columns pTemplate="header">
          <tr>
            <th style="width: 3rem" *appUserRole="[ Roles.Admin ]">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns" [ngStyle]="col.styles" pResizableColumn
                pSortableColumn="{{col.name}}">
              <div class="p-d-flex p-ai-center">
                <p-columnFilter
                  *ngIf="col.name==='enabled'"
                  [showAddButton]="false"
                  [showApplyButton]="false"
                  [showClearButton]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  display="menu"
                  field="enabled"
                  matchMode="equals">
                  <ng-template let-filter="filterCallback" let-value pTemplate="filter">
                    <div class="p-py-1">{{ 'PAGES.accounts.status' | translate}}</div>
                    <p-dropdown (onChange)="filter($event.value)"
                                [filter]="false"
                                [ngModel]="value"
                                [options]="statusOptions"
                                placeholder="---">
                      <ng-template let-option pTemplate="selectedItem">
                        <div>{{ 'PAGES.accounts.status.' + option.label| translate}}</div>
                      </ng-template>
                      <ng-template let-option pTemplate="item">
                        <span>{{ 'PAGES.sensors.status.' + option.label | translate}}</span>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
                <p-sortIcon field="{{col.name}}" *ngIf="col?.sorting !== false"></p-sortIcon>
                {{ 'PAGES.rules.fields.' + col.name | translate}}
              </div>
            </th>
            <th style="width: 7.2rem">
              <p-button
                (click)="dt.clear()"
                icon="pi pi-filter-slash">
              </p-button>
            </th>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-item pTemplate="body">
          <tr class="items-row">
            <td style="width: 3rem" *appUserRole="[ Roles.Admin ]">
              <p-tableCheckbox [value]="item"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns" [ngStyle]="col.styles" [routerLink]="['./' + item.id]"
                class="td-row">
              <ng-container *ngIf="!col['component'] else components">
                {{item[col.name]}}
              </ng-container>
              <ng-template #components>
                <div [ngSwitch]="col['component']">
                  <ng-container *ngSwitchCase="'notification_policy'">
                    {{ 'PAGES.rules.notify_policy.' + item[col.name] | translate }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'apply_on_schedule'">
                     <span *ngFor="let itemName of item[col.name]; let first = first">
                       <ng-container *ngIf="!first"> ,</ng-container>
                       {{ 'DEFAULTS.' + itemName | translate }}
                     </span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'sensors'">
                    {{ item[col.name] }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'time'">
                    {{ item['time_end'] <= item['time_start'] || item['time_end'] === '00:01'
                    ? item[col.name] + '(+1)'
                    : item[col.name]
                    }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'fix_threshold'">
                    <div>
                      {{ 'PAGES.rules.fields.fix_threshold.base_value' | translate }}
                      : {{ item['fix_threshold']['base_value'] }}
                      {{ 'PAGES.rules.base_units_short.' +
                    (item['fix_threshold']['base_value_unit']
                      ? item['fix_threshold']['base_value_unit']
                      : 'undefined') | translate }}
                    </div>
                    <div>
                      {{ 'PAGES.rules.fields.fix_threshold.low_value' | translate }}
                      : {{ item['fix_threshold']['low_value'] }}
                      {{ 'PAGES.rules.base_units_short.' +
                    (item['fix_threshold']['low_value_unit']
                      ? item['fix_threshold']['low_value_unit']
                      : 'undefined') | translate }}
                    </div>
                    <div>
                      {{ 'PAGES.rules.fields.fix_threshold.high_value' | translate }}
                      : {{ item['fix_threshold']['high_value'] }}
                      {{ 'PAGES.rules.base_units_short.' +
                    (item['fix_threshold']['high_value_unit']
                      ? item['fix_threshold']['high_value_unit']
                      : 'undefined') | translate }}
                    </div>
                  </ng-container>
                  <app-is-active *ngSwitchCase="'enabled'"
                                 [active]="item[col.name]">
                  </app-is-active>
                </div>
              </ng-template>
            </td>
            <td>
              <app-sub-actions-buttons
                (deleteHandler)="deleteItem(item)"
                [allowView]="false"
                [item]="item">
              </app-sub-actions-buttons>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td *appUserRole="[ Roles.Admin ]"></td>
            <td [attr.colspan]="cols?.length + 1">
              <div class="p-d-flex p-jc-center">
                {{ 'DEFAULTS.no_data_found' | translate }}
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
