/**
 * @module Rooms' module for encapsulating rooms components
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@modules/shared.module';
import { PagesSharedModule } from '@pages/pages-shared.module';

import { RoomsComponent } from '@pages/accounts/rooms/rooms.component';
import { UpdateRoomComponent } from '@pages/accounts/rooms/update-room/update-room.component';
import { ViewRoomComponent } from '@pages/accounts/rooms/view-room/view-room.component';

@NgModule({
    declarations: [RoomsComponent, UpdateRoomComponent, ViewRoomComponent],
    imports: [CommonModule, PagesSharedModule, SharedModule]
})
export class RoomsModule {}
