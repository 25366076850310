/**
 * @Component Menu layout component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NAVIGATION } from '@app/const';
import { Roles } from '@app/core/interfaces/user.interface';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    urlSegment = '';
    routePath = [];
    Roles = Roles;
    nav = NAVIGATION;

    constructor(public translate: TranslateService, private route: Router) {}

    ngOnInit(): void {
        this.routePath = this.route.url.split('/');
        this.urlSegment = this.routePath[this.routePath.length - 1];
    }
}
