<div *ngIf="item else loading">
    <div class="p-d-flex p-ai-center p-jc-between p-mb-2">
        <app-sub-heading
            [id]="item.id"
            [accountId]="accountId"
            [isEdit]="route.snapshot?.data?.isEdit"
            [page]="'contacts'"
            [path]="'accounts'">
        </app-sub-heading>
        <app-sub-nav [path]="'accounts'" [id]="accountId"></app-sub-nav>
    </div>
    <form [formGroup]="form" [ngClass]="{'form-only-view': isView}">
        <formly-form [fields]="fields" [form]="form" [model]="item">
        </formly-form>
        <button
            *ngIf="!isView"
            (click)="saveItem()"
            [disabled]="!form.valid"
            class="p-button-success p-button-lg p-mt-1"
            icon="pi pi-check"
            label="{{ 'MODAL.save' | translate }}"
            pButton
            pRipple>
        </button>
        <app-sub-options-buttons
            *ngIf="isView"
            [item] = "item"
            label="{{ 'DEFAULTS.edit' | translate }}"
        ></app-sub-options-buttons>
    </form>
</div>
<ng-template #loading>
</ng-template>
