import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { IAccount } from '@pages/accounts/accounts/accounts.interface';
import { IAccountPerson } from '@pages/accounts/persons/persons.interface';

@Component({
    selector: 'app-sub-heading-reports',
    templateUrl: './sub-heading.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubHeadingComponent {
    @Input() account: IAccount;
    @Input() person: IAccountPerson;

    constructor(public location: Location) {}
}
