/**
 * @Component Alert wrapped component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { PrimeNGConfig } from 'primeng/api';
import { NotificationService } from '@services/notification.service';
import { DialogService } from 'primeng/dynamicdialog';

import { NotificationsStore } from '@pages/notifications/notifications.store';
import { ModalService } from '@services/modal.service';
import { AuthService } from '@app/core/services/auth.service';
import { EventService } from '@services/event.service';
import { TranslateService } from '@ngx-translate/core';
import { GuiStore } from '@stores/gui.store';

import { alertTypes } from '@pages/accounts/sensors/alert-types';
import { config } from '@app/const';
import {
  INotificationItem,
  INotificationQueryRequest,
  INotificationsResponse
} from '@pages/notifications/notifications.interface';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AlertModalComponent } from '@layout/layout-container/alert/alert-modal/alert-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  providers: [DialogService]
})
export class AlertComponent implements OnInit, OnDestroy {
  ref: DynamicDialogRef;

  constructor(
    private notificationService: NotificationService,
    private notificationsStore: NotificationsStore,
    private modalService: ModalService,
    private authService: AuthService,
    private primengConfig: PrimeNGConfig,
    private guiStore: GuiStore,
    private event: EventService,
    private translationService: TranslateService,
    public dialogService: DialogService
  ) {}

  openNotification(currentAlert: INotificationItem): void {
    const notificationType = currentAlert?.notification_type.type_key;
    this.ref = this.dialogService.open(AlertModalComponent, {
      width: '50%',
      header: this.translationService.instant('MODAL.show_notification.header', {
        item: this.translationService.instant('MODAL.show_notification.header.type.' + notificationType)
      }),
      transitionOptions: '400ms cubic-bezier(0.25, 0.8, 0.25, 1)',
      styleClass: 'notification_header ' + alertTypes[notificationType].color,
      contentStyle: { overflow: 'visible' },
      data: {
        color: alertTypes[notificationType].color,
        item: currentAlert,
        icon: 'pi ' + alertTypes[notificationType].icon + ' notification ' + alertTypes[notificationType].color
      },
      baseZIndex: 10000
    });

    this.ref.onClose.pipe(untilDestroyed(this)).subscribe(async (result) => {
      if (result) {
        try {
          await this.notificationsStore.editNotification({
            id: currentAlert?.id,
            state: result.state,
            message: result.message,
            close_reason: result.close_reason ? { id: result.close_reason } : null
          });
          this.event.detect();

          this.notificationService.addSingle({
            severity: 'success',
            summary: this.translationService.instant('MODAL.attention'),
            detail: this.translationService.instant('MODAL.notification.saved')
          });
        } catch (e) {
          console.log('[R][ErrorName]');
        }
      }
    });
  }

  ngOnInit(): void {
    if (config.allowNotifications || this.authService.getUser?.allowNotifications) {
      const request: INotificationQueryRequest = {
        query: {
          page: 1,
          limit: 50,
          order_by: 'created_at',
          sort_dir: 'DESC'
        },
        filter: {
          state: ['open'],
          notifification_type_ids: [1]
        }
      };
      this.notificationsStore
        .fetchNotifications(request)
        .then(async (res: INotificationsResponse) => {
          if (res?.items?.length > 0) {
            const currentAlert: INotificationItem = res?.items[0];
            await this.notificationsStore.fetchNotificationsTypes();
            this.openNotification(currentAlert);
          }
        })
        .catch((e) => console.log(e));

      this.primengConfig.ripple = true;
    }
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.close();
    }
  }
}
