/**
 * @Component Custom message component using prime-ng message component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-formly-field-message',
    template: `
        <div class="{{to.class || ''}}">{{ to?.label }}</div>
  `,
})
export class FormlyFieldMessageComponent extends FieldType {
}
