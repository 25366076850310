<div *mobxAutorun class="layout-login fadeIn p-d-flex p-jc-center p-ai-center">
  <form (ngSubmit)="onSubmit()" [formGroup]="signInForm">
    <p-card header="{{ 'AUTH.signInHeader' | translate }}">
      <div class="p-pb-4 p-pt-4">
                <span class="p-float-label">
                <input
                  formControlName="username"
                  id="username"
                  pInputText
                  required type="text">
                    <label class="color-dark-grey fw-600 fs-s"
                           for="username">{{ 'AUTH.username' | translate }}</label>
                </span>
        <div *ngIf="isUsernameError"
             class="bounce p-error">{{ 'AUTH.required.username' | translate }}</div>
      </div>
      <div class="p-pb-2 p-pt-2 login-password">
                <span class="p-float-label">
                <p-password
                  [feedback]="false"
                  [ngClass]="{'p-password-rtl': guiStore.currentLanguage === ELanguages.he }"
                  [toggleMask]="true"
                  class="p-password"
                  formControlName="password" id="password">
                </p-password>
                <label for="password">{{ 'AUTH.password' | translate }}</label>
                </span>
        <div *ngIf="isPasswordError"
             class="bounce p-error">{{ 'AUTH.required.password' | translate }}</div>

      </div>
      <ng-template pTemplate="footer">
        <button [disabled]="signInForm.invalid"
                [ngClass]="{'disabled':signInForm.invalid}"
                label="{{ 'AUTH.signIn' | translate }}"
                pButton
                pRipple
                type="submit">
        </button>
      </ng-template>
    </p-card>
  </form>
</div>
