import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-is-active',
    templateUrl: './is-active.component.html',
    styleUrls: ['./is-active.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class IsActiveComponent {
    @Input() active = true;
}
