/**
 * @Module Notifications routing module for authenticated users
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/core/guards/auth.guard';
import { NAVIGATION } from '@app/const';
import { ReportsComponent } from '@pages/reports/reports.component';
import { RoleGuard } from '@app/core/guards/role.guard';
import { Roles } from '@app/core/interfaces/user.interface';

const routes: Routes = [
    { path: '', redirectTo: NAVIGATION.reports.path, pathMatch: 'full' },
    {
        path: NAVIGATION.reports.url,
        canActivate: [AuthGuard],
        canActivateChild: [RoleGuard],
        data: {
            role: [Roles.Admin, Roles.CallCenterUser, Roles.Experementor]
        },
        children: [
            { path: '', component: ReportsComponent },
            { path: ':id', component: ReportsComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ReportsRoutingModule {}
