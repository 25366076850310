/**
 * @Service LocalStorage service for making work with it more strait forward
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class LocalStorageService {

  constructor() {
  }

  /**
   * @description Get global object from localStorage
   * @param itemName
   * @return object from localStorage
   */
  getItem<T>(itemName: string): T {
    return JSON.parse(localStorage.getItem(itemName));
  }

  /**
   * @description Get item from localStorage
   * @param globalItemKey
   * @param itemKey
   * @return item value from localStorage object
   */
  getItemValue<T>(globalItemKey: string, itemKey: string): T {
    const item = this.getItem(globalItemKey);
    if (item) {
      return item[itemKey];
    } else {
      return null;
    }
  }

  /**
   * @description Set item object to localStorage
   * @param itemName
   * @param data
   * @return void
   */
  setItem<T>(itemName: string, data: T): void {
    localStorage.setItem(itemName, JSON.stringify(data));
  }

  /**
   * @description Insert item to the localStorage object (nested operation)
   * @param newItem
   * @param currentItemName
   * @return void
   */
  insertItem<N>(newItem: { [key: string]: N }, currentItemName: string): void {
    const item: N = this.getItem<N>(currentItemName);
    const updatedItem = { ...item, ...newItem };
    this.setItem(currentItemName, updatedItem);
  }

  /**
   * @description Remove item to the localStorage object
   * @param item
   * @return void
   */
  removeItem(item: string): void {
    if (this.getItem(item)) {
      localStorage.removeItem(item);
    }
  }
}
