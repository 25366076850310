/**
 * @function Custom ngx-formly validation function for using ngx-translate
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export function formlyValidationConfig(translate: TranslateService): any {
  return {
    validationMessages: [
      {
        name: 'required',
        message(): Observable<any> {
          return translate.stream('FORM.validation.required');
        }
      },
      {
        name: 'min',
        message(error: any): Observable<any> {
          return translate.stream('FORM.validation.min', { value: error.min });
        }
      },
      {
        name: 'max',
        message(error: any): Observable<any> {
          return translate.stream('FORM.validation.max', { value: error.max });
        }
      },
      {
        name: 'minlength',
        message(error: any): Observable<any> {
          return translate.stream('FORM.validation.minLength', {
            required: error.requiredLength,
            actual: error.actualLength
          });
        }
      },
      {
        name: 'maxlength',
        message(error: any): Observable<any> {
          return translate.stream('FORM.validation.maxLength', {
            required: error.requiredLength,
            actual: error.actualLength
          });
        }
      },
      {
        name: 'pattern',
        message(error: any): Observable<any> {
          return translate.stream('FORM.validation.pattern', { value: error.pattern });
        }
      }
    ]
  };
}
