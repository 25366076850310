/**
 * @Module Account's modules with including modules for: accounts, persons, contacts, sensors, rules etc.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountsRoutingModule } from '@pages/accounts/accounts-routing.module';
import { SharedModule } from '@modules/shared.module';
import { PagesSharedModule } from '@pages/pages-shared.module';
import { RoomsModule } from '@pages/accounts/rooms/rooms.module';
import { PersonsModule } from '@pages/accounts/persons/persons.module';
import { ContactsModule } from '@pages/accounts/contacts/contacts.module';
import { SensorsModule } from '@pages/accounts/sensors/sensors.module';
import { RulesModule } from '@pages/accounts/rules/rules.module';

import { MessageService } from 'primeng/api';
import { DataService } from '@services/data.service';

import { AccountsComponent } from '@pages/accounts/accounts/accounts.component';
import { UpdateAccountComponent } from '@pages/accounts/accounts/update-account/update-account.component';
import { ViewAccountComponent } from '@pages/accounts/accounts/view-account/view-account.component';
import { GatewayModule } from '@pages/accounts/gateway/gateway.module';
import { ContactsStore } from '@pages/accounts/contacts/contacts.store';
import { ContactsService } from '@pages/accounts/contacts/contacts.service';
import { GatewayStore } from '@pages/accounts/gateway/gateway.store';
import { GatewayService } from '@pages/accounts/gateway/gateway.service';

@NgModule({
  declarations: [AccountsComponent, UpdateAccountComponent, ViewAccountComponent],
  imports: [
    CommonModule,
    AccountsRoutingModule,
    SharedModule,
    RoomsModule,
    PagesSharedModule,
    PersonsModule,
    ContactsModule,
    SensorsModule,
    RulesModule,
    GatewayModule
  ],
  providers: [MessageService, DataService, ContactsStore, ContactsService, GatewayStore, GatewayService]
})
export class AccountsModule {}
