/**
 * @Service Loading Indicator service for global indicating while http requests does or some loading actions.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({ providedIn: 'root' })
export class LoadingIndicatorService {
    private tasks: string[] = [];
    private stopLoaderTimer;

    constructor(private ngxLoader: NgxUiLoaderService) {}

    /**
     * @description Add loading indicator to the que for displaying.
     * Could be added numbers of loading indicators step by step
     * @param task: task ID
     * @return void: displaying ngx Loader
     */
    public addTask(task: string): void {
        if (!this.tasks.includes(task)) {
            this.tasks.push(task);
        }

        clearTimeout(this.stopLoaderTimer);

        this.ngxLoader.startBackground();
    }

    /**
     * @description Stop all loading indicators.
     * @return void: stop all background / foreground ngx loaders
     */
    public stopAll(): void {
        this.ngxLoader.stopAll();
    }

    /**
     * @description Called upon transport error.
     * @param task: task ID
     * @return void: remove specified by ID ngx loader
     */
    public removeTask(task: string): void {
        if (this.tasks.includes(task)) {
            const idx = this.tasks.indexOf(task);
            this.tasks.splice(idx, 1);
        }

        if (this.tasks.length === 0) {
            this.stopLoaderTimer = setTimeout(() => this.ngxLoader.stopBackground(), 500);
        }
    }
}
