<div *ngIf="item else loading">
  <div class="p-d-flex p-ai-center p-jc-between p-mb-2">
    <app-sub-heading
      [accountId]="item.id"
      [id]="item.id"
      [isEdit]="route.snapshot?.data?.isEdit"
      [item]="item"
      [path]="'accounts'">
    </app-sub-heading>
    <app-sub-nav [id]="item.id" [path]="'accounts'"></app-sub-nav>
  </div>
  <form *mobxAutorun [formGroup]="form" [ngClass]="{'form-only-view': isView}">
    <formly-form [fields]="fields" [form]="form" [model]="item">
    </formly-form>
    <app-sub-form-actions-buttons
      *ngIf="!isView"
      [removeButtonText]="'PAGES.accounts.delete'"
      (saveHandler)="saveItem()"
      (deleteHandler)="deleteItem()"
      [item]="this.item"
      [showOptions]="route.snapshot?.data?.isEdit"
      [isSaveDisabled]="!this.form.valid"
      [includedRoles]="role.Experementor"
    ></app-sub-form-actions-buttons>
    <app-sub-options-buttons
      *ngIf="isView"
      [item]="item"
      [includedRoles]="[role.Experementor]"
      label="{{ 'DEFAULTS.edit' | translate }}"
    ></app-sub-options-buttons>
  </form>
</div>
<ng-template #loading>
</ng-template>
