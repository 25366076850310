/**
 * @module Gateway module for encapsulating gateways' components
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@modules/shared.module';
import { PagesSharedModule } from '@pages/pages-shared.module';

import { GatewayComponent } from './gateway.component';

@NgModule({
  declarations: [
    GatewayComponent
  ],
  imports: [
    CommonModule, SharedModule, PagesSharedModule
  ]
})
export class GatewayModule { }
