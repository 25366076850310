import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-sub-search',
    templateUrl: './sub-search.component.html',
})
export class SubSearchComponent{
    @Input() dt;
    @Input() page;
    @Output() filterHandler: EventEmitter<any> = new EventEmitter<any>();

    filterItems(event): void {
        this.filterHandler.emit(event.target.value);
    }
}
