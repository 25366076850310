/**
 * @Service Update Rule Service with needed methods and functions for data transformations
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { PagesSharedModule } from '@pages/pages-shared.module';

import { RulesStore } from '@pages/accounts/rules/rules.store';

import { DtoUpdateService } from '@pages/accounts/rules/update-rule/update-rule.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdateHooksService } from '@pages/accounts/rules/update-rule/update-rule-hooks.service';
import { UpdateExpressionPropertiesService } from '@pages/accounts/rules/update-rule/update-expression.service';

@UntilDestroy()
@Injectable()
export class UpdateRuleFormService {
  constructor(
    private dto: DtoUpdateService,
    private exp: UpdateExpressionPropertiesService,
    private hooks: UpdateHooksService,
    private rulesStore: RulesStore,
    private translate: TranslateService
  ) {}

  formFields(ctx): void {
    this.hooks.setMode(ctx.isEdit || ctx.isView);
    ctx.fields = [
      {
        hooks: {
          onInit: (_) => {
            if (ctx.isView) {
              ctx.form.disable();
              ctx.cdr.detectChanges();
            }
          }
        },
        type: 'tabs',
        fieldGroup: [
          {
            templateOptions: {
              label: 'PAGES.rules.groups.main'
            },
            fieldGroupClassName: 'p-d-flex p-flex-column',
            fieldGroup: [
              {
                fieldGroupClassName: 'p-d-flex',
                fieldGroup: [
                  {
                    className: 'p-field p-col-3',
                    fieldGroupClassName: 'p-d-flex',
                    fieldGroup: [
                      {
                        className: 'p-field',
                        type: 'tooltip',
                        templateOptions: {
                          label: '',
                          content: ''
                        },
                        hooks: {
                          onInit: (field) => {
                            ctx.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
                              setTimeout(
                                () => (field.templateOptions.content = this.dto.generateTooltip(ctx.item)),
                                10
                              );
                            });
                          }
                        }
                      },
                      {
                        className: 'p-field',
                        type: 'select',
                        key: 'rule_type_id',
                        defaultValue: this.rulesStore?.ruleTypes[0].type_key || null,
                        templateOptions: {
                          disabled: ctx.isEdit,
                          required: true,
                          options: [{ label: 'DEFAULTS.select_option', value: '' }],
                          label: 'PAGES.rules.fields.rule_type_id'
                        },
                        hooks: {
                          onInit: (field: FormlyFieldConfig) => {
                            field.templateOptions.options = this.dto.ruleTypeOptions();
                          }
                        }
                      }
                    ]
                  },
                  {
                    className: 'p-field p-col-3',
                    fieldGroupClassName: 'p-d-flex',
                    fieldGroup: [
                      {
                        className: 'p-field',
                        type: 'select',
                        key: 'algorithm_type_id',
                        defaultValue: '0',
                        templateOptions: {
                          disabled: ctx.isEdit,
                          required: true,
                          options: [{ label: 'DEFAULTS.select_option', value: '' }],
                          label: 'PAGES.rules.fields.algorithm_type_id'
                        },
                        hooks: {
                          onInit: (field: FormlyFieldConfig) => {
                            field.templateOptions.options = this.dto.algorithmTypeOptions(ctx.form, ctx.item);
                          }
                        }
                      }
                    ]
                  },
                  {
                    className: 'p-field p-col-3',
                    fieldGroupClassName: 'p-d-flex',
                    fieldGroup: [
                      {
                        className: 'p-field',
                        type: 'select',
                        key: 'notification_type_id',
                        defaultValue: 1,
                        templateOptions: {
                          required: true,
                          options: this.rulesStore.notificationTypesOptions.map((el) => {
                            return {
                              ...el,
                              label: 'PAGES.notifications.type.' + el.value.toString()
                            };
                          }),
                          label: 'PAGES.rules.fields.notification_type_id'
                        },
                        expressionProperties: this.exp.optionsVisibility(ctx, 'notification_type_id')
                      },
                      {
                        className: 'p-field p-mx-5',
                        type: 'select',
                        key: 'notify_policy',
                        defaultValue: '',
                        templateOptions: {
                          required: true,
                          label: 'PAGES.rules.fields.notify_policy',
                          options: [{ label: 'DEFAULTS.select_option', value: '' }]
                        },
                        hooks: {
                          onInit: (field) => this.dto.dependantOptions(field, ctx.item, ctx.form)
                        },
                        expressionProperties: this.exp.optionsVisibility(ctx, 'notify_policy')
                      }
                    ]
                  },
                  {
                    className: 'p-field p-col-3',
                    fieldGroupClassName: 'p-d-flex',
                    fieldGroup: [
                      {
                        className: 'p-field',
                        type: 'multiselect',
                        key: 'apply_on_schedule',
                        defaultValue: ['weekdays', 'weekends'],
                        templateOptions: {
                          disabled: ctx.isEdit,
                          required: true,
                          placeholder: 'DEFAULTS.select_option',
                          label: 'PAGES.rules.fields.apply_on_schedule',
                          options: [
                            { label: 'DEFAULTS.weekdays', value: 'weekdays' },
                            { label: 'DEFAULTS.weekends', value: 'weekends' }
                          ]
                        }
                      },
                      {
                        className: 'p-field p-mx-4',
                        type: 'switch',
                        key: 'enabled',
                        defaultValue: true,
                        templateOptions: {
                          required: true,
                          label: 'PAGES.rules.fields.enabled'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                fieldGroupClassName: 'p-d-flex',
                fieldGroup: [
                  {
                    className: 'p-field p-col-3',
                    type: 'multiselect',
                    key: 'room_id',
                    defaultValue: '',
                    templateOptions: {
                      itemsSelected: this.translate.instant('FORM.items_selected'),
                      disabled: ctx.isEdit,
                      placeholder: 'DEFAULTS.select_option',
                      label: 'PAGES.sensors.fields.room_id',
                      options: ctx.roomsStore.roomsOptions.map((el) => ({ ...el, label: `${el.label} (${el.id})` }))
                    }
                  },
                  {
                    className: 'p-field p-col-3',
                    type: 'multiselect-tooltip',
                    key: 'sensor_ids',
                    templateOptions: {
                      disabled: ctx.isEdit,
                      readonly: ctx.isEdit || ctx.isView,
                      itemsSelected: this.translate.instant('FORM.items_selected'),
                      placeholder: 'DEFAULTS.select_option',
                      required: true,
                      label: 'PAGES.rules.fields.sensor_ids',
                      options: ctx.sensorsStore.sensorsOptions
                    },
                    hooks: {
                      onInit: (field) => this.hooks.sensorIdsHook(field, ctx)
                    }
                  },
                  {
                    className: 'p-field p-col-3',
                    key: 'monitored_persons_ids',
                    type: 'select',
                    defaultValue:
                      ctx.accountsStore?.accountActivePersonsOptions?.length > 0
                        ? ctx.accountsStore?.accountActivePersonsOptions[0]?.value
                        : '',
                    templateOptions: {
                      disabled: ctx.isEdit,
                      itemsSelected: this.translate.instant('FORM.items_selected'),
                      required: true,
                      placeholder: 'DEFAULTS.select_option',
                      label: 'PAGES.rules.fields.monitored_persons_ids',
                      options: ctx.accountsStore.accountActivePersonsOptions
                    },
                    expressionProperties: this.exp.optionsVisibility(ctx, 'monitored_persons_ids')
                  },
                  {
                    className: 'p-field p-col-1',
                    type: 'datetimepicker',
                    key: 'time_start',
                    defaultValue: '22:00',
                    templateOptions: {
                      disabled: ctx.isEdit,
                      showSeconds: false,
                      dateFormat: 'hh:mm',
                      dataType: 'string',
                      hourFormat: '24',
                      timeOnly: true,
                      required: true,
                      label: 'PAGES.rules.fields.time_start'
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => this.hooks.timeHook(field, ctx, 'time_start')
                    },
                    expressionProperties: this.exp.optionsVisibility(ctx, 'time_start')
                  },
                  {
                    className: 'p-field p-col-2',
                    type: 'datetimepicker',
                    key: 'time_end',
                    defaultValue: '10:00',
                    templateOptions: {
                      disabled: ctx.isEdit,
                      dateFormat: 'hh:mm',
                      showSeconds: false,
                      dataType: 'string',
                      hourFormat: '24',
                      timeOnly: true,
                      required: true,
                      label: 'PAGES.rules.fields.time_end'
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => this.hooks.timeHook(field, ctx, 'time_end')
                    },
                    expressionProperties: this.exp.optionsVisibility(ctx, 'time_end')
                  }
                ]
              },
              {
                fieldGroupClassName: 'p-d-flex',
                fieldGroup: [
                  {
                    className: 'p-col-6',
                    fieldGroupClassName: 'p-d-flex',
                    fieldGroup: [
                      {
                        className: 'p-field p-col p-border-round p-padding-0',
                        fieldGroupClassName: 'p-d-flex p-flex-column',
                        fieldGroup: [
                          {
                            className: 'p-d-flex p-col p-heading',
                            type: 'message',
                            templateOptions: {
                              label: 'PAGES.rules.base_values'
                            }
                          },
                          {
                            className: 'p-field p-col',
                            fieldGroupClassName: 'p-d-flex',
                            fieldGroup: [
                              {
                                className: 'p-field p-col-3',
                                key: 'use_computed_threshold',
                                name: 'base_type',
                                defaultValue: false,
                                type: 'radioGroup',
                                templateOptions: {
                                  required: true,
                                  label: 'PAGES.rules.fields.use_computed_threshold',
                                  options: [
                                    {
                                      label: 'PAGES.rules.base_types.fix',
                                      value: false
                                    },
                                    {
                                      label: 'PAGES.rules.base_types.computed',
                                      value: true
                                    }
                                  ]
                                },
                                expressionProperties: {
                                  'templateOptions.disabled': (model) =>
                                    model.algorithm_type_id === 'algo_t_sensor_battery_status' ||
                                    model.algorithm_type_id === 'algo_t_out_of_bed_time' ||
                                    ctx.isView,
                                  'templateOptions.required':
                                    "model.algorithm_type_id !== 'algo_t_sensor_battery_status' && model.algorithm_type_id !== 'algo_t_out_of_bed_time'"
                                }
                              },
                              {
                                fieldGroupClassName: 'p-d-flex p-flex-column p-p-0 p-m-0',
                                fieldGroup: [
                                  {
                                    className: 'p-field p-col p-px-0 p-p-0 p-m-0',
                                    fieldGroupClassName: 'p-d-flex',
                                    fieldGroup: [
                                      {
                                        type: 'multiple',
                                        expressionProperties: {
                                          'templateOptions.type': (model) =>
                                            model.fix_threshold.base_value_unit !== 'time' ? 'input' : 'datetimepicker'
                                        },
                                        fieldGroup: [
                                          {
                                            className: 'p-field p-col p-pl-0',
                                            key: 'fix_threshold.base_value',
                                            type: 'input',
                                            templateOptions: {
                                              required: true,
                                              disabled: ctx.isView,
                                              label: 'PAGES.rules.fields.fix_threshold.base_value'
                                            },
                                            hooks: {
                                              onInit: (field) => this.hooks.fixBaseValueHook(field, ctx)
                                            },
                                            expressionProperties: this.exp.optionsVisibility(
                                              ctx,
                                              'fix_threshold.base_value'
                                            )
                                          },
                                          {
                                            className: 'p-field p-col p-pl-0',
                                            key: 'fix_threshold.base_value',
                                            type: 'datetimepicker',
                                            templateOptions: {
                                              required: true,
                                              disabled: ctx.isView,
                                              dateFormat: 'hh:mm',
                                              showSeconds: false,
                                              dataType: 'string',
                                              hourFormat: '24',
                                              timeOnly: true,
                                              label: 'PAGES.rules.fields.fix_threshold.base_value'
                                            },
                                            hooks: {
                                              onInit: (field) => this.hooks.fixBaseValueHookTime(field, ctx)
                                            },
                                            expressionProperties: this.exp.optionsVisibility(
                                              ctx,
                                              'fix_threshold.base_value'
                                            )
                                          }
                                        ]
                                      },
                                      {
                                        className: 'p-field p-col base-unit',
                                        key: 'fix_threshold.base_value_unit',
                                        type: 'select',
                                        defaultValue: '',
                                        templateOptions: {
                                          required: true,
                                          options: [
                                            {
                                              label: 'DEFAULTS.select_option',
                                              value: ''
                                            }
                                          ],
                                          label: 'PAGES.rules.fields.fix_threshold.base_value_unit'
                                        },
                                        hooks: {
                                          onInit: (field) => {
                                            this.dto.dependantOptions(field, ctx.item, ctx.form);
                                            this.hooks.fixBaseValueUnitHook(field, ctx);
                                          }
                                        },
                                        expressionProperties: this.exp.optionsVisibility(
                                          ctx,
                                          'fix_threshold.base_value_unit'
                                        )
                                      }
                                    ]
                                  },
                                  {
                                    className: 'p-field p-col p-px-0 p-p-0 p-m-0',
                                    fieldGroupClassName: 'p-d-flex',
                                    fieldGroup: [
                                      {
                                        className: 'p-field p-col-8 p-pl-0',
                                        key: 'computed_threshold.threshold.base_value',
                                        defaultValue: 0,
                                        type: 'input',
                                        templateOptions: {
                                          disabled: true,
                                          label: 'PAGES.rules.fields.computed_threshold.base_value'
                                        }
                                      },
                                      {
                                        className: 'p-field p-col',
                                        key: 'computed_threshold.threshold.base_value_unit',
                                        type: 'select',
                                        templateOptions: {
                                          required: false,
                                          showClear: false,
                                          disabled: true,
                                          label: 'PAGES.rules.fields.fix_threshold.base_value_unit'
                                        },
                                        hooks: {
                                          onInit: (field) => this.dto.dependantOptions(field, ctx.item, ctx.form)
                                        }
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    className: 'p-col-6',
                    fieldGroupClassName: 'p-d-flex',
                    fieldGroup: [
                      {
                        className: 'p-field p-col p-border-round p-padding-0',
                        fieldGroupClassName: 'p-d-flex p-flex-column',
                        fieldGroup: [
                          {
                            className: 'p-d-flex p-col p-heading',
                            type: 'message',
                            templateOptions: {
                              label: 'PAGES.rules.limits'
                            }
                          },
                          {
                            className: 'p-field p-col-8',
                            fieldGroupClassName: 'p-d-flex p-flex-column',
                            fieldGroup: [
                              {
                                className: 'p-field p-col',
                                fieldGroupClassName: 'p-d-flex',
                                fieldGroup: [
                                  {
                                    type: 'multiple',
                                    expressionProperties: {
                                      'templateOptions.type': (model) =>
                                        model.fix_threshold.low_value_unit !== 'time' ? 'input' : 'datetimepicker'
                                    },
                                    fieldGroup: [
                                      {
                                        className: 'p-field p-col p-pl-0',
                                        key: 'fix_threshold.low_value',
                                        type: 'input',
                                        templateOptions: {
                                          required: true,
                                          disabled: ctx.isView,
                                          label: 'PAGES.rules.fields.fix_threshold.low_value'
                                        },
                                        hooks: {
                                          onInit: (field) => this.hooks.fixLowValueHook(field, ctx)
                                        },
                                        validation: {
                                          messages: {
                                            lowMoreHigh: this.translate.instant('PAGES.rules.error.lowMoreHigh'),
                                            lowMoreBase: this.translate.instant('PAGES.rules.error.lowMoreBase')
                                          }
                                        },
                                        expressionProperties: this.exp.optionsVisibility(ctx, 'fix_threshold.low_value')
                                      },
                                      {
                                        className: 'p-field p-col p-pl-0',
                                        key: 'fix_threshold.low_value',
                                        type: 'datetimepicker',
                                        templateOptions: {
                                          required: true,
                                          disabled: ctx.isView,
                                          dateFormat: 'hh:mm',
                                          showSeconds: false,
                                          dataType: 'string',
                                          hourFormat: '24',
                                          timeOnly: true,
                                          label: 'PAGES.rules.fields.fix_threshold.low_value'
                                        },
                                        hooks: {
                                          onInit: (field) => this.hooks.fixLowValueTimeHook(field, ctx)
                                        },
                                        expressionProperties: this.exp.optionsVisibility(ctx, 'fix_threshold.low_value')
                                      }
                                    ]
                                  },
                                  {
                                    className: 'p-field p-col',
                                    key: 'fix_threshold.low_value_unit',
                                    type: 'dropdown',
                                    templateOptions: {
                                      required: true,
                                      showClear: false,
                                      options: [],
                                      label: 'PAGES.rules.fields.fix_threshold.low_value_unit'
                                    },
                                    hooks: {
                                      onInit: (field) => this.hooks.fixLowValueUnitHook(field, ctx)
                                    },
                                    validation: {
                                      messages: {
                                        unitsLowDiffError: this.translate.instant('PAGES.rules.error.unitsDiffError')
                                      }
                                    },
                                    expressionProperties: this.exp.optionsVisibility(
                                      ctx,
                                      'fix_threshold.low_value_unit'
                                    )
                                  },
                                  {
                                    className: 'p-field p-col p-mt-4',
                                    key: 'fix_threshold.low_limit_enabled',
                                    type: 'checkbox',
                                    defaultValue: true
                                  }
                                ]
                              },
                              {
                                className: 'p-field p-col',
                                fieldGroupClassName: 'p-d-flex',
                                fieldGroup: [
                                  {
                                    type: 'multiple',
                                    expressionProperties: {
                                      'templateOptions.type': (model) =>
                                        model.fix_threshold.high_value_unit !== 'time' ? 'input' : 'datetimepicker'
                                    },
                                    fieldGroup: [
                                      {
                                        className: 'p-field p-col p-pl-0',
                                        key: 'fix_threshold.high_value',
                                        type: 'input',
                                        templateOptions: {
                                          required: true,
                                          disabled: ctx.isView,
                                          label: 'PAGES.rules.fields.fix_threshold.high_value'
                                        },
                                        hooks: {
                                          onInit: (field) => this.hooks.fixHighValueHook(field, ctx)
                                        },
                                        validation: {
                                          messages: {
                                            highLessLow: this.translate.instant('PAGES.rules.error.highLessLow'),
                                            highLessBase: this.translate.instant('PAGES.rules.error.highLessBase')
                                          }
                                        },
                                        expressionProperties: this.exp.optionsVisibility(
                                          ctx,
                                          'fix_threshold.high_value'
                                        )
                                      },
                                      {
                                        className: 'p-field p-col p-pl-0',
                                        key: 'fix_threshold.high_value',
                                        type: 'datetimepicker',
                                        templateOptions: {
                                          required: true,
                                          disabled: ctx.isView,
                                          dateFormat: 'hh:mm',
                                          showSeconds: false,
                                          dataType: 'string',
                                          hourFormat: '24',
                                          timeOnly: true,
                                          label: 'PAGES.rules.fields.fix_threshold.high_value'
                                        },
                                        hooks: {
                                          onInit: (field) => this.hooks.fixHighValueTimeHook(field, ctx)
                                        },
                                        expressionProperties: this.exp.optionsVisibility(
                                          ctx,
                                          'fix_threshold.high_value'
                                        )
                                      }
                                    ]
                                  },
                                  {
                                    className: 'p-field p-col',
                                    key: 'fix_threshold.high_value_unit',
                                    type: 'dropdown',
                                    templateOptions: {
                                      required: true,
                                      showClear: false,
                                      options: [],
                                      label: 'PAGES.rules.fields.fix_threshold.high_value_unit'
                                    },
                                    hooks: {
                                      onInit: (field) => this.hooks.fixHighValueUnitHook(field, ctx)
                                    },
                                    validation: {
                                      messages: {
                                        unitsHighDiffError: this.translate.instant('PAGES.rules.error.unitsDiffError')
                                      }
                                    },
                                    expressionProperties: this.exp.optionsVisibility(
                                      ctx,
                                      'fix_threshold.high_value_unit'
                                    )
                                  },
                                  {
                                    className: 'p-field p-col p-mt-4',
                                    key: 'fix_threshold.high_limit_enabled',
                                    type: 'checkbox',
                                    defaultValue: true
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ];
  }
}
