/**
 * @Component Custom radio-button (switch) component using prime-ng switch
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-formly-field-switch',
  template: `
    <p-inputSwitch [formControl]="fControl" [formlyAttributes]="field"></p-inputSwitch> `
})
export class FormlyFieldSwitchComponent extends FieldType {
  public get fControl() {
    return this.formControl as UntypedFormControl;
  }
}
