/**
 * @Service Reports's service for CRUD actions with API server.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";

import { environment } from "@environments/environment";
import { NAVIGATION } from "@app/const";
import { IReport, IReportRequest } from "@pages/reports/reports.interface";
import { IAccount } from "@pages/accounts/accounts/accounts.interface";
import { INotificationQueryRequest, INotificationsResponse } from "@pages/notifications/notifications.interface";
import { lastValueFrom, map } from "rxjs";

@Injectable()
export class ReportsService {
  apiURL = environment.apiUrl;
  api = `${this.apiURL}/${NAVIGATION.reports.api}`;
  apiNotification = `${this.apiURL}/${NAVIGATION.notifications.api}`;
  nav = {
    reports: (id) => `${this.apiURL}/${NAVIGATION.accounts.api}/${id}/${NAVIGATION.reports.api}`,
    accounts: `${this.apiURL}/${NAVIGATION.accounts.api}`,
    persons: (id) => `${this.apiURL}/${NAVIGATION.accounts.api}/${id}/${NAVIGATION.persons.api}`,
    sensors: (id) => `${this.apiURL}/${NAVIGATION.accounts.api}/${id}/${NAVIGATION.sensors.api}`
  };

  constructor(private http: HttpClient) {
  }

  // *********** Reports ************ //

  getReports(payload: IReportRequest): Promise<IReport[]> {
    return lastValueFrom(this.http
      .post<IReport[]>(this.nav.reports(payload.account_ids), payload, {
        observe: "response",
        reportProgress: true
      })
      .pipe(
        map((res: HttpResponse<IReport[]>) => res.body)
      ));
  }

  getNotificationsReports(payload): Promise<INotificationsResponse> {
    const request: INotificationQueryRequest = {
      query: {
        page: 1,
        limit: 50,
        order_by: "created_at",
        sort_dir: "DESC"
      },
      filter: {
        state: [],
        notifification_type_ids: [],
        rule_type_ids: [],
        algo_type_ids: [],
        account_ids: [payload.account],
        start_time: payload.start_time,
        end_time: payload.end_time
      }
    };

    return lastValueFrom(this.http
      .post(`${this.apiNotification}`, request.filter, {
        params: { ...request.query },
        observe: "response"
      })
      .pipe(
        map((res: HttpResponse<INotificationsResponse>) => res.body)));
  }

  getAccounts(): Promise<HttpResponse<IAccount[]>> {
    return lastValueFrom(this.http
      .get<IAccount[]>(this.nav.accounts, {
        observe: "response"
      })
      .pipe(map((res: HttpResponse<IAccount[]>) => res)));
  }
}
