import { NgModule, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";

import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { CoreModule } from "@app/core/core.module";
import { AppRoutingModule } from "./app-routing.module";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyPrimeNGModule } from "@ngx-formly/primeng";

import { PagesModule } from "@pages/pages.module";

import { AppComponent } from "./app.component";
import { Page404Component } from "@layout/404/404-page.component";

import { config } from "@app/const";

@NgModule({
  declarations: [AppComponent, Page404Component],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    PagesModule,
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    FormlyPrimeNGModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    Title,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public translate: TranslateService) {
    translate.setDefaultLang(config.languages.defaultLanguage);
  }
}
