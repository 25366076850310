/**
 * @Service Role Auth Guard service for authorized routes that related to user's role
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core/services/auth.service';
import { NAVIGATION } from '@app/const';
import { NotificationService } from '@services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  constructor(
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService,
    private translationService: TranslateService
  ) {}

  /**
   * @description Controls whether a route can be activated.
   * Interface that a class can implement to be a guard deciding if a route can be activated.
   * If all guards return true, navigation will continue.
   * If any guard returns false, navigation will be canceled.
   * @param next
   * @param state
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    if (!this.checkUserLogin(next, url)) {
      this.notificationService.addSingle({
        severity: 'error',
        summary: this.translationService.instant('AUTH.unauthorized_title'),
        detail: this.translationService.instant('AUTH.unauthorized_message')
      });
    }
    return this.checkUserLogin(next, url);
  }

  /**
   * @description Interface that a class can implement to be a guard deciding if a child route can be activated.
   * If all guards return true, navigation will continue.
   * If any guard returns false, navigation will be canceled.
   * @param next
   * @param state
   */
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  /**
   * @description Interface that a class can implement to be a guard deciding if a route can be deactivated.
   * If all guards return true, navigation will continue.
   * If any guard returns false, navigation will be canceled.
   * @param component
   * @param currentRoute
   * @param currentState
   * @param nextState
   */
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  /**
   * @description Interface that a class can implement to be a guard deciding if children can be loaded.
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  /**
   * @description Check user login and his role
   * @param route
   * @param url
   */
  async checkUserLogin(route: ActivatedRouteSnapshot, url: any): Promise<boolean> {
    if (this.authService.isLoggedIn) {
      const userRole = this.authService.getRole;
      if (route.data?.role?.length > 0 && !route.data.role.includes(userRole)) {
        return !(await this.router.navigate([NAVIGATION.login.path]));
      }
      return true;
    }
    return !(await this.router.navigate([NAVIGATION.login.path]));
  }
}
