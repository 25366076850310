/**
 * @Service Log's service
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { NAVIGATION } from "@app/const";
import { environment } from "@environments/environment";
import { ILog } from "@pages/logs/logs.interface";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class LogsService {
  apiURL = environment.apiUrl;
  api = `${this.apiURL}/${NAVIGATION.log.api}`;

  constructor(private http: HttpClient) {
  }

  // *********** Notifications ************ //

  getLogs(body: ILog): Observable<HttpResponse<any>> {
    return this.http.post(this.api, body, {
      observe: "response",
      responseType: "blob"
    });
  }
}
