<div class="p-d-flex p-ai-center">
    <button (click)="location.back()"
            class="p-button-rounded"
            icon="pi pi-arrow-left"
            pButton
            pRipple
            style="min-width:30px"
            type="button"
    >
    </button>
    <h1 class="p-mx-2">
        {{ 'PAGES.accounts.details' | translate }} ID: {{ account?.id || '\'\''}},
        {{ 'PAGES.accounts.fields.gateway_id' | translate }}: {{ account?.gateway_id || '\'\''}},
        {{ 'PAGES.accounts.fields.owner_name' | translate }}:
        {{ account?.owner_first_name || '\'\''}}
        {{ account?.owner_last_name || '\'\''}}
    </h1>
</div>
