/**
 * @Module Notifications routing module for authenticated users
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@app/core/guards/auth.guard';
import { RoleGuard } from '@app/core/guards/role.guard';

import { NotificationsComponent } from '@pages/notifications/notifications.component';
import { UpdateNotificationComponent } from '@pages/notifications/update-notification/update-notification.component';
import { ViewNotificationComponent } from '@pages/notifications/view-notification/view-notification.component';

import { NAVIGATION } from '@app/const';
import { Roles } from '@app/core/interfaces/user.interface';
import {ExitGuard} from '@app/core/guards/exit.guard';

const routes: Routes = [
    { path: '', redirectTo: NAVIGATION.notifications.path, pathMatch: 'full' },
    {
        path: NAVIGATION.notifications.url,
        canActivate: [AuthGuard],
        canActivateChild: [RoleGuard],
        children: [
            { path: '', component: NotificationsComponent },
            { path: 'account/:id', component: NotificationsComponent },
            {
                path: 'account/:aid/edit/:id',
                canDeactivate: [ExitGuard],
                component: UpdateNotificationComponent,
                data: {
                    role: [Roles.Admin, Roles.CallCenterUser]
                }
            },
            {
                path: 'edit/:id',
                canDeactivate: [ExitGuard],
                component: UpdateNotificationComponent,
                data: {
                    role: [Roles.Admin,  Roles.CallCenterUser]
                }
            },
            { path: 'account/:aid/:id', component: ViewNotificationComponent },
            { path: ':id', component: ViewNotificationComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class NotificationsRoutingModule {}
