/**
 * @Store Reports's mobx store service for state management.
 * @Project: TrendLines
 * @Version Mobx: 6.1.X
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from "@angular/core";
import { toJS, makeAutoObservable, reaction, autorun } from "mobx";
import { groupBy } from "lodash-es";

import { ReportsService } from "@pages/reports/reports.service";
import {
  ILoadingReports,
  INotificationsReport,
  INotificationTimesData,
  IOptionsReports,
  IReport,
  IReportFilter,
  IRoomFilter
} from "@pages/reports/reports.interface";
import { IAccountPerson } from "@pages/accounts/persons/persons.interface";
import { IAccount, IOptions } from "@pages/accounts/accounts/accounts.interface";
import { INotificationItem } from "@pages/notifications/notifications.interface";
import { formatDate } from "@angular/common";
import { UtilityService } from "@services/utility.service";

@Injectable()
export class ReportsStore {
  // ********** Observables ************* //
  $selectedModel: IReportFilter = {
    date: new Date(),
    account: null,
    person: null
  };
  $selectedRoomFilterModel: IRoomFilter = {
    rooms: []
  };
  $roomsOptions: IOptions[] = [];
  $report: IReport[] = null;
  $notificationsReport: INotificationItem[] = null;
  $accounts: IAccount[] = [];
  $loading: ILoadingReports = {
    activity: false,
    notifications: false
  };

  constructor(private reportsService: ReportsService, private utility: UtilityService) {
    makeAutoObservable(this);
    reaction(
      () => this?.currentSelectedModel.account,
      () => {
        this.setSelectedModel({ person: this.account.monitored_persons[0]?.id });
      }
    );
  }

  setLoading(type: Partial<ILoadingReports>): void {
    this.$loading = { ...this.$loading, ...type };
  }

  setReport(res: IReport[]): void {
    this.$report = res;
  }

  setNotificationsReport(res: INotificationItem[]): void {
    this.$notificationsReport = res;
  }

  setSelectedModel(model): void {
    this.$selectedModel = { ...this.$selectedModel, ...model };
  }

  setSelectedRoomFilterModel(model: IRoomFilter): void {
    this.$selectedRoomFilterModel = model;
  }

  setAccounts(res: IAccount[]): void {
    this.$accounts = res;
  }

  setRoomOptions(rooms: IOptions[]): void {
    this.$roomsOptions = rooms;
  }

  // ********** Actions ************* //

  async fetchReports(): Promise<void> {
    try {
      const { date, account, person } = this.currentSelectedModel;
      const timeZoneOffset = new Date().getTimezoneOffset() / 60;

      const time_start = new Date(date);
      time_start.setHours(timeZoneOffset, 0, 0, 0);

      const time_end = new Date(date);
      time_end.setHours(24 + timeZoneOffset, 0, 0, 0);
      let request: any;
      request = {
        fusion_time_start: formatDate(time_start, "YYYY-MM-dd HH:mm:ss", "en_US"),
        fusion_time_end: formatDate(time_end, "YYYY-MM-dd HH:mm:ss", "en_US"),
        account_ids: account?.toString(),
        persons_ids: person?.toString()
      };

      const res = await this.reportsService.getReports(request);
      if (res?.length > 0) {
        this.setReport(res);
      } else {
        this.setReport([]);
      }
    } catch (e) {
      console.log("Error fetching accounts", e);
    }
    this.setLoading({ activity: false });
  }

  async fetchNotificationsReports(): Promise<void> {
    try {
      const { date, person, account } = this.currentSelectedModel;
      const start_time = new Date(new Date(date).setHours(0, 0, 0, 0)).toISOString();
      const end_time = new Date(new Date(date).setHours(23, 59, 59, 999)).toISOString();
      const res = await this.reportsService.getNotificationsReports({ start_time, end_time, account });
      if (res) {
        const skipForFilter = [0, "all"].includes(person);
        const mappedRes: INotificationItem[] = !skipForFilter ? res.items.filter((el) => {
          const personIdEl = Number(el.person.id);
          return personIdEl === person;
        }) : res.items;
        this.setNotificationsReport(mappedRes);
      } else {
        this.setNotificationsReport([]);
      }
    } catch (e) {
      console.log("Error fetching accounts");
    }
    this.setLoading({ notifications: false });
  }

  async fetchAccounts(): Promise<void> {
    try {
      const res = await this.reportsService.getAccounts();
      if (res) {
        this.setAccounts(res.body);
      }
    } catch (e) {
      console.log("Error fetching accounts");
    }
  }

  // ********** Computed ************* //

  get report(): IReport[] {
    return toJS(this.$report);
  }

  get fileredReport(): IReport[] {
    const filteredReport = this.report.filter((el) =>
      this.currentSelectedRoomModel.rooms.includes(el.room_id.toString())
    );
    return toJS(filteredReport);
  }

  get notificationsReport(): INotificationsReport[] {
    const mappedReport = this.$notificationsReport?.map((el: INotificationItem) => ({
      id: el.id,
      type: el.notification_type.type_key,
      rule_type_id: Number(el.rule?.rule_type_id),
      rule_type_key: Number(el.rule?.rule_type_id),
      notification_type: el.algorithm_type.type_name,
      notification_type_key: el.algorithm_type.type_key,
      date_time: el.created_at,
      account_id: Number(el.account.id),
      account_name: el.account.owner_first_name + " " + el.account.owner_last_name,
      resident_id: Number(el.person.id),
      sensor_id: el.algorithm_type.type_metadata?.sensor_type_filter.toString(),
      time_start: el.created_at,
      resident_name: el.person.first_name + " " + el.person.last_name,
      status: el.state,
      description: el.message ?? "---",
      actual_value: this.utility.notificationActualValueParsed(el),
      actual_value_unit: el.rule?.fix_threshold?.base_value_unit
    }));
    const groupedByType = groupBy(mappedReport, "notification_type_key");
    const mappedResult = Object.entries(groupedByType).map(([key, value]) => ({
      notification_type_key: key,
      timesData: (value as INotificationTimesData[]) || []
    }));
    return toJS(mappedResult);
  }

  get accounts(): IAccount[] {
    return toJS(this.$accounts);
  }

  get currentAccountId(): number {
    return toJS(this.$selectedModel?.account || +this.accounts[0]?.id);
  }

  get account(): IAccount {
    return this.accounts?.find((el) => +el.id === this.currentAccountId);
  }

  get accountsOptions(): IOptionsReports[] {
    return this.accounts
      .map((el) => ({
        label: el.owner_first_name + " " + el.owner_last_name,
        value: el.id
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }

  get persons(): IAccountPerson[] {
    return this.account?.monitored_persons || [];
  }

  get personsOptions(): IOptionsReports[] {
    return this.account?.monitored_persons
      .map((el) => ({
        label: el.first_name + " " + el.last_name,
        value: el.id
      }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }

  get person(): IAccountPerson {
    return this.persons.find((el) => +el.id === this.currentPersonId) || this.persons[0];
  }

  get currentPersonId(): number {
    return toJS(this.$selectedModel?.person) || +this.persons[0].id;
  }

  get roomsOptions(): IOptions[] {
    return toJS(this.$roomsOptions);
  }

  get currentSelectedModel(): IReportFilter {
    return toJS(this.$selectedModel);
  }

  get currentSelectedRoomModel(): IRoomFilter {
    return toJS(this.$selectedRoomFilterModel);
  }

  get currentLoading(): ILoadingReports {
    return toJS(this.$loading);
  }
}
