import { formatDate } from '@angular/common';

export class DateUtility {
  static formatDate(date = new Date()): string {
    return formatDate(date, 'yyyy-MM-dd', 'en-US');
  }

  static getDaysFromTo(fromDay: string, toDay: string): string[] {
    const output = [];
    for (const dt = new Date(fromDay); dt <= new Date(toDay); dt.setDate(dt.getDate() + 1)) {
      output.push(formatDate(new Date(dt), 'yyyy-MM-dd', 'en_US'));
    }
    return output;
  }

  static getTimeInMillis(day: string, hours = 0, mins = 0, secs = 0): number {
    const date = new Date(day);
    date.setHours(hours, mins, secs);
    return date.getTime();
  }

  static getDayAndHour = (timestamp: number) => {
    const d = new Date(timestamp);
    const day = d.toDateString().substr(4, 6);
    const time = d.toTimeString().substr(0, 5);
    return `${day},${time}`;
  };

  static secondsToHms = (e) => {
    if (!e) {
      return '00:00:00';
    }
    e = +e;
    const t = Math.floor(e / 3600);
    const a = Math.floor((e % 3600) / 60);
    const o = Math.floor((e % 3600) % 60);
    return ('0' + t).slice(-2) + ':' + ('0' + a).slice(-2) + ':' + ('0' + o).slice(-2);
  };

  static durationToHms = (start, end) => {
    if (!start || !end) {
      return '00:00:00';
    }
    const e = new Date(end).getTime() / 1000 - new Date(start).getTime() / 1000;
    const t = Math.floor(e / 3600);
    const a = Math.floor((e % 3600) / 60);
    const o = Math.floor((e % 3600) % 60);
    return ('0' + t).slice(-2) + ':' + ('0' + a).slice(-2) + ':' + ('0' + o).slice(-2);
  };

  static HHMMtoSeconds = (time) => time.split(':').reduce((acc, t) => 60 * acc + +t);
}
