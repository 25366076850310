<div *mobxAutorun class="p-d-flex p-flex-column">
  <app-sub-nav [id]="accountId" [path]="'accounts'" class="p-mb-2 p-as-end"></app-sub-nav>
  <div class="p-d-flex p-jc-between p-ai-center p-mb-2">
    <app-sub-heading
      [item]="accountsStore.account"
      [accountId]="accountId"
      [path]="'persons'">
    </app-sub-heading>
    <div class="page-actions">
      <app-sub-search (filterHandler)="dt.filterGlobal($event, 'contains')" [page]="'persons'"></app-sub-search>
      <app-sub-new-delete
        (deleteHandler)="deleteSelectedItems()"
        [page]="'persons'"
        [isNewDisabled]="personsStore.canAddNewPerson"
        [selectedItems]="selectedItems">
      </app-sub-new-delete>
    </div>
  </div>
  <div>
    <div class="card">
      <p-table #dt
               [(selection)]="selectedItems"
               [columns]="cols"
               [globalFilterFields]="['name', 'sex', 'age', 'passport_id', 'language', 'resident_relation_type']"
               [loading]="loading"
               [paginator]="true"
               [resizableColumns]="true"
               [rowHover]="true"
               [rowsPerPageOptions]="[10,25,50]"
               [rows]="10"
               [showCurrentPageReport]="false"
               [totalRecords]="totalRecords"
               [value]="items"
               dataKey="id"
               scrollHeight="100%"
               tableStyleClass="p-datatable p-datatable-sm p-datatable-striped p-datatable-customers p-datatable-gridlines">
        <ng-template let-columns pTemplate="header">
          <tr>
            <th style="width: 3rem" *appUserRole="[ Roles.Admin ]">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns" [ngStyle]="col.styles" pResizableColumn
                pSortableColumn="{{col.name}}">
              <div class="p-d-flex p-flex-row p-jc-between p-ai-center">
                <div>
                  <p-sortIcon field="{{col.name}}"></p-sortIcon>
                  {{ 'PAGES.persons.fields.' + col.name | translate}}
                </div>
              </div>
            </th>
            <th style="width: 7.2rem">
            </th>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-item pTemplate="body">
          <tr class="items-row">
            <td style="width: 3rem" *appUserRole="[ Roles.Admin ]">
              <p-tableCheckbox [value]="item"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns" [ngStyle]="col.styles" [routerLink]="['./' + item.id]"
                class="td-row">
              <ng-container *ngIf="!col['component'] else components">
                {{item[col.name]}}
              </ng-container>
              <ng-template #components>
                <div [ngSwitch]="col['component']">
                  <div *ngSwitchCase="'name'">
                    {{item['first_name']}} {{item['last_name']}}
                  </div>
                  <div *ngSwitchCase="'resident_relation_type'">
                    <div *ngIf="item['resident_relation_type']!=='none' else noRelation"
                         class="p-d-flex p-jc-center">
                      {{ 'PAGES.persons.resident_relation_type.' + item['resident_relation_type'] | translate}}
                    </div>
                    <ng-template #noRelation>
                      <div class="p-d-flex p-jc-center">---</div>
                    </ng-template>
                  </div>
                  <div *ngSwitchCase="'sex'">
                    {{ 'PAGES.persons.sex.' + item['sex'] | translate}}
                  </div>
                  <div *ngSwitchCase="'language'">
                    {{ 'DEFAULTS.lang.' + item['language'] | translate}}
                  </div>
                  <app-is-active *ngSwitchCase="'is_active'"
                                 [active]="item[col.name]">
                  </app-is-active>
                </div>
              </ng-template>
            </td>
            <td>
              <app-sub-actions-buttons
                (deleteHandler)="deleteItem(item)"
                [allowView]="false"
                [item]="item">
              </app-sub-actions-buttons>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td *appUserRole="[ Roles.Admin ]"></td>
            <td [attr.colspan]="cols?.length + 1">
              <div class="p-d-flex p-jc-center">{{ 'DEFAULTS.no_data_found' | translate }}</div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
