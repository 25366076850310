/**
 * @Component Custom door component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-door',
  template: `
    <ng-container *ngIf="!to.hidden">
      <div class="container">
        <div class="sensors">
          <button
            (click)="setButtonValue('sensor', 1)"
            [ngClass]="{ selected: sensor === 1 }"
            [disabled]="to?.disabled"
            pButton
            label="1"
            type="button"
            class="p-button-rounded p-button-outlined"
          ></button>
          <div class="arrow left">
            <img src="/assets/images/sensors/arrow-right.png" alt="arrow" />
          </div>
          <div class="arrow right">
            <img src="/assets/images/sensors/arrow-right.png" alt="arrow" />
          </div>
          <button
            (click)="setButtonValue('sensor', 2)"
            [ngClass]="{ selected: sensor === 2 }"
            [disabled]="to?.disabled"
            pButton
            label="2"
            type="button"
            class="p-button-rounded p-button-outlined"
          ></button>
        </div>
        <div class="door door-hidden" *ngIf="to?.doorHidden">
          <div class="hidden_background"></div>
        </div>
        <div class="door" *ngIf="!to?.doorHidden">
          <div class="door-buttons">
            <div>
              <button
                (click)="setButtonValue('door', 1)"
                [ngClass]="{ selected: door === 1 }"
                [disabled]="to?.disabled"
                pButton
                label="A"
                type="button"
                class="p-button-rounded p-button-outlined"
              ></button>
              <button
                (click)="setButtonValue('door', 4)"
                [ngClass]="{ selected: door === 4 }"
                [disabled]="to?.disabled"
                pButton
                label="D"
                type="button"
                class="p-button-rounded p-button-outlined"
              ></button>
            </div>
            <div>
              <button
                (click)="setButtonValue('door', 2)"
                [ngClass]="{ selected: door === 2 }"
                [disabled]="to?.disabled"
                pButton
                label="B"
                type="button"
                class="p-button-rounded p-button-outlined"
              ></button>
              <button
                (click)="setButtonValue('door', 3)"
                [ngClass]="{ selected: door === 3 }"
                [disabled]="to?.disabled"
                pButton
                label="C"
                type="button"
                class="p-button-rounded p-button-outlined"
              ></button>
            </div>
          </div>
        </div>
        <div class="sensors">
          <button
            (click)="setButtonValue('sensor', 4)"
            [ngClass]="{ selected: sensor === 4 }"
            [disabled]="to?.disabled"
            pButton
            label="4"
            type="button"
            class="p-button-rounded p-button-outlined"
          ></button>
          <div class="arrow left">
            <img src="/assets/images/sensors/arrow-right.png" alt="arrow" />
          </div>
          <div class="arrow right">
            <img src="/assets/images/sensors/arrow-right.png" alt="arrow" />
          </div>
          <button
            (click)="setButtonValue('sensor', 3)"
            [ngClass]="{ selected: sensor === 3 }"
            [disabled]="to?.disabled"
            pButton
            label="3"
            type="button"
            class="p-button-rounded p-button-outlined"
          ></button>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['./door.component.scss']
})
export class FormlyFieldDoorComponent extends FieldType implements OnInit {
  door;
  sensor;

  setButtonValue(type: 'sensor' | 'door', key: number): void {
    this[type] = key;
    this.field.formControl.setValue({ doorway_ui_arrows: this.sensor, doorway_ui_door: this.door });
  }

  ngOnInit(): void {
    this.door = this.field.formControl.value?.doorway_ui_door;
    this.sensor = this.field.formControl.value?.doorway_ui_arrows;
  }
}
