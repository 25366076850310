import { NgxUiLoaderConfig } from 'ngx-ui-loader';
import { NAVIGATION } from '@app/const/index';

export enum ELanguages {
  en = 'en',
  he = 'he'
}

type navigationPaths =
  | 'login'
  | 'logout'
  | 'profile'
  | 'pages'
  | 'accounts'
  | 'rooms'
  | 'persons'
  | 'contacts'
  | 'sensors'
  | 'rules'
  | 'notifications'
  | 'reports'
  | 'log'
  | 'gateway';

export type INavigationInterface = Record<
  navigationPaths,
  {
    url?: navigationPaths;
    api: string;
    path?: string;
  }
>;

export interface IConfig {
  localStorageObject: string;
  allowNotifications: boolean;
  allowReports: boolean;
  roleCheckJwt: boolean;
  experimentor_id: number;
  tableDefaults: {
    recordsPerPage: number;
    order_by: string;
  };
  uiLoader: NgxUiLoaderConfig;
  storeSettings: {
    enforceActions?: 'never' | 'always' | 'observed';
    computedRequiresReaction?: boolean;
    reactionRequiresObservable?: boolean;
    observableRequiresReaction?: boolean;
    isolateGlobalState?: boolean;
    disableErrorBoundaries?: boolean;
    safeDescriptors?: boolean;
    reactionScheduler?: (f: () => void) => void;
    useProxies?: 'always' | 'never' | 'ifavailable';
  };
  languages: {
    defaultLanguage: ELanguages;
    list: ELanguages[];
    path: string;
  };
  pages: {
    notifications: {
      storageKeyOptions: string;
      storageKeyTable: string;
      storageType: 'local' | 'session';
    };
    reports: {
      storageKeyOptions: string;
      showGroupedBars: boolean;
      showColors: boolean;
    };
    persons: {
      limitActivePersons: number;
    };
  };
}
