/**
 * @Module Pages module for global pages
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from '@app/core/interceptors/http-error.interceptor';
import { AuthInterceptor } from '@app/core/interceptors/auth.interceptor';

import { AccountsModule } from '@pages/accounts/accounts.module';
import { NotificationsModule } from '@pages/notifications/notifications.module';
import { ReportsModule } from '@pages/reports/reports.module';
import { LogsModule } from '@pages/logs/logs.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, AccountsModule, NotificationsModule, ReportsModule, LogsModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    Title
  ]
})
export class PagesModule {}
