/**
 * @Component Gateway's component
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Title } from '@angular/platform-browser';

import { AccountsStore } from '@pages/accounts/accounts/accounts.store';
import { GuiStore } from '@stores/gui.store';
import { GatewayStore } from '@pages/accounts/gateway/gateway.store';

import { TranslateService } from '@ngx-translate/core';

import { IAccountGatewayResponse } from '@pages/accounts/gateway/gateway.interface';
import { IAccountItemColumns } from '@pages/accounts/accounts/accounts.interface';
import { Roles } from '@root/src/app/core/interfaces/user.interface';
import { delay } from '@app/core/utils/async.utility';

@UntilDestroy()
@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GatewayComponent implements OnInit {
  accountId: string;
  item: IAccountGatewayResponse;
  loaded = false;
  error = false;
  Roles = Roles;
  cols: IAccountItemColumns[];

  constructor(
    public title: Title,
    public guiStore: GuiStore,
    public translationService: TranslateService,
    public accountsStore: AccountsStore,
    private gatewayStore: GatewayStore,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  mapFields(data: IAccountGatewayResponse): void {
    this.item = data;
  }

  async ngOnInit(): Promise<void> {
    this.accountId = this.route.snapshot.paramMap.get('id');
    this.gatewayStore
      .fetchGateway(this.accountId)
      .then((data: IAccountGatewayResponse) => {
        this.error = false;
        this.mapFields(data);
      })
      .catch((e) => {
        console.log(['R']['Error: fetchGateway'], e);
        this.error = true;
        this.loaded = true;
        this.cdr.detectChanges();
      })
      .finally(() => {
        this.loaded = true;
        this.cdr.detectChanges();
      });

    delay(100).then(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.gateway'));
    });

    this.translationService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.gateway'));
    });

    this.cols = [
      { name: 'gateway_id' },
      { name: 'fw_version' },
      { name: 'gateway_created_at', component: 'date' },
      { name: 'aws_received_at', component: 'date' }
    ];
  }
}
