/**
 * @Component Contacts component for creating new contact and update selected one
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";

import { GuiStore } from "@stores/gui.store";
import { AccountsStore } from "@pages/accounts/accounts/accounts.store";
import { ContactsStore } from "@pages/accounts/contacts/contacts.store";
import { PersonsStore } from "@pages/accounts/persons/persons.store";

import { NotificationService } from "@services/notification.service";
import { ModalService } from "@services/modal.service";
import { AccountsSharedService } from "@pages/accounts/accounts-shared.service";

import { delay } from "@app/core/utils/async.utility";
import { IAccountContact } from "@pages/accounts/contacts/contacts.interface";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { NAVIGATION } from "@app/const";

@Component({
  selector: "app-update-contact",
  templateUrl: "./update-contact.component.html"
})
export class UpdateContactComponent implements OnInit {
  isLoading = false;
  item: IAccountContact | any;
  accountId: string;
  personsMap;
  pId: string;

  rooms: FormlyFieldConfig[] = [];
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  isEdit: boolean;
  isView: boolean;

  constructor(
    public guiStore: GuiStore,
    public translate: TranslateService,
    public route: ActivatedRoute,
    private router: Router,
    public accountsStore: AccountsStore,
    private personsStore: PersonsStore,
    private contactsStore: ContactsStore,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private accountsShared: AccountsSharedService,
    private cdr: ChangeDetectorRef
  ) {
    this.model.lang = guiStore.currentLanguage;
  }

  async saveItem(): Promise<void> {
    if (this.route.snapshot?.data?.isEdit) {
      try {
        const result = await this.contactsStore.editContact(this.accountId, this.item);
        if (result?.ok) {
          this.notificationService.addSingle({
            severity: "success",
            summary: this.translate.instant("MODAL.attention"),
            detail: this.translate.instant("MODAL.notification.saved")
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        this.item.phone_code = "+972";
        const result = await this.contactsStore.addContact(this.accountId, this.item);
        if (result?.ok) {
          this.notificationService.addSingle({
            severity: "success",
            summary: this.translate.instant("MODAL.attention"),
            detail: this.translate.instant("MODAL.notification.added")
          });
        }
        delay(2000).then(
          async () =>
            await this.router.navigate([
              NAVIGATION.pages.url,
              NAVIGATION.accounts.url,
              this.accountId,
              NAVIGATION.contacts.url
            ])
        );
      } catch (e) {
        console.log(e);
      }
    }
    this.accountsShared.resetForm(this.form);
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.modalService.exitModal(this.form);
  }

  ngOnInit(): void {
    this.isEdit = this.route.snapshot?.data?.isEdit;
    this.isView = this.route.snapshot?.data?.isView;
    this.accountId = this.route.snapshot.paramMap.get("id");
    this.pId = this.route.snapshot.paramMap.get("pid");
    if (this.isEdit || this.isView) {
      this.isLoading = true;
      this.contactsStore
        .fetchContact(this.accountId, this.pId)
        .then((data) => {
          this.item = data;
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.item = {};
    }
    this.personsStore.fetchPersons(this.accountId).then((res) => {
      this.personsMap = res.map((el) => ({
        label: el.first_name + " " + el.last_name,
        value: el.id
      }));
      delay(100).then(() => this.formFields());
    });
  }

  formFields(): void {
    this.fields = [
      {
        hooks: {
          onInit: (_) => {
            if (this.isView) {
              this.form.disable();
              this.cdr.detectChanges();
            }
          }
        },
        type: "tabs",
        fieldGroup: [
          {
            expressionProperties: {
              "templateOptions.label": this.translate.stream("PAGES.contacts.groups.main")
            },
            fieldGroupClassName: "p-d-flex p-flex-column",
            fieldGroup: [
              {
                className: "p-field p-col",
                type: "input",
                key: "first_name",
                templateOptions: {
                  minLength: 3,
                  required: true,
                  label: "PAGES.contacts.fields.first_name"
                }
              },
              {
                className: "p-field p-col",
                type: "input",
                key: "last_name",
                templateOptions: {
                  minLength: 3,
                  required: true,
                  label: "PAGES.contacts.fields.last_name"
                }
              },
              {
                className: "p-field p-col",
                type: "mask",
                key: "home_phone",
                templateOptions: {
                  placeholder: "+XXX-XX-XXX-XXXX",
                  maskString: "+000-00-000-0099",
                  pattern: "^[+0-9]{10,12}$",
                  required: true,
                  label: "PAGES.contacts.fields.home_phone"
                }
              },
              {
                className: "p-field p-col",
                type: "mask",
                key: "cell_phone_primary",
                templateOptions: {
                  placeholder: "+XXX-XX-XXX-XXXX",
                  maskString: "+000-00-000-0099",
                  pattern: "^[+0-9]{10,12}$",
                  required: true,
                  label: "PAGES.contacts.fields.cell_phone_primary"
                }
              },
              {
                className: "p-field p-col",
                type: "mask",
                key: "cell_phone_secondary",
                templateOptions: {
                  placeholder: "+XXX-XX-XXX-XXXX",
                  maskString: "+000-00-000-0099",
                  pattern: "^[+0-9]{10,12}$",
                  required: true,
                  label: "PAGES.contacts.fields.cell_phone_secondary"
                }
              },
              {
                className: "p-field p-col",
                type: "input",
                key: "family_closeness",
                templateOptions: {
                  minLength: 2,
                  maxLength: 24,
                  required: true,
                  label: "PAGES.contacts.fields.family_closeness"
                }
              },
              {
                className: "p-field p-col-3",
                key: "for_monitored_person_id",
                type: "select",
                defaultValue: this.personsMap[0]?.value,
                templateOptions: {
                  required: true,
                  label: "PAGES.persons.fields.resident_relation_type",
                  options: this.personsMap
                }
              },
              {
                className: "p-field p-col",
                type: "textarea",
                key: "notes",
                templateOptions: {
                  minLength: 5,
                  maxLength: 512,
                  label: "PAGES.contacts.fields.notes"
                }
              }
            ]
          }
        ]
      }
    ];
  }
}
