/**
 * @Store Persons mobx store service for state management.
 * @Project: TrendLines
 * @Version Mobx: 6.1.X
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { makeAutoObservable, toJS } from 'mobx';

import { AccountsStore } from '@pages/accounts/accounts/accounts.store';

import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { PersonsService } from '@pages/accounts/persons/persons.service';

import { IAccountPerson, IHttpState } from '@pages/accounts/persons/persons.interface';
import { HttpResponse } from '@angular/common/http';

import { config } from '@app/const';

const limitSaveActivePersons = config.pages.persons.limitActivePersons;

@Injectable()
export class PersonsStore {
  // ********** Observables ************* //
  $persons: IAccountPerson[] = [];
  $personsLength: number;
  $state: IHttpState = {
    loading: false,
    error: false
  };

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    private personsService: PersonsService,
    private accountsStore: AccountsStore
  ) {
    makeAutoObservable(this);
  }

  // ********** Setters ************* //

  setPersons(res: IAccountPerson[]): void {
    this.$persons = res;
  }

  setState(state: IHttpState): void {
    this.$state = { ...this.$state, ...state };
  }

  // ********** Actions ************* //

  async fetchPersons(accountId: string, isListRefresh = true): Promise<IAccountPerson[]> {
    try {
      const res = await this.personsService.getPersons(accountId);
      if (res && isListRefresh) {
        this.setPersons(res.body);
        if (!this.accountsStore.account || '' + this.accountsStore.account?.id !== accountId) {
          await this.accountsStore.fetchAccount(accountId);
        }
      }
      return res.body;
    } catch (e) {
      console.log('Error fetching persons');
    }
  }

  async fetchPerson(accountId: string, id: string): Promise<IAccountPerson> {
    try {
      this.setState({ loading: true });
      const res = await this.personsService.getPerson(accountId, id);
      if (res) {
        if (!this.accountsStore.account || '' + this.accountsStore.account?.id !== accountId) {
          await this.accountsStore.fetchAccount(accountId);
        }
        this.setState({ loading: false });
        return res;
      }
    } catch (e) {
      this.setState({ loading: false, error: true });
      console.log('Error fetching person');
    }
  }

  async addPerson(accountId: string, body: IAccountPerson): Promise<HttpResponse<any>> {
    try {
      this.setState({ loading: true });
      const res = await this.personsService.addPerson(accountId, body);
      if (res) {
        await this.fetchPersons(accountId);
        setTimeout(() => this.setState({ loading: false }), 1000);
        return res;
      }
    } catch (e) {
      this.setState({ loading: false, error: true });
      console.log('Error adding person');
    }
  }

  async editPerson(accountId: string, body: IAccountPerson): Promise<HttpResponse<any>> {
    try {
      this.setState({ loading: true });
      const res = await this.personsService.editPerson(accountId, body);
      if (res) {
        await this.fetchPersons(accountId);
        setTimeout(() => this.setState({ loading: false }), 1000);
        return res;
      }
    } catch (e) {
      this.setState({ loading: false, error: true });
      console.log('Error updating person');
    }
  }

  async deletePerson(accountId: string, id: string): Promise<any> {
    try {
      const res = await this.personsService.deletePerson(accountId, id);
      if (res) {
        await this.fetchPersons(accountId);
      }
    } catch (e) {
      console.log('Error delete person');
    }
  }

  async deletePersons(accountId: string, ids: string[]): Promise<void> {
    try {
      for (const id of ids) {
        await this.personsService.deletePerson(accountId, id);
      }
      await this.fetchPersons(accountId);
    } catch (e) {
      console.log('Error delete persons');
    }
  }

  get persons(): IAccountPerson[] {
    return toJS(this.$persons);
  }

  get personsLength(): number {
    return toJS(this.$persons.length);
  }

  get activePersons(): IAccountPerson[] {
    return toJS(this.$persons.filter((el) => !!el.is_active));
  }

  get activePersonsLength(): number {
    return this.$persons.filter((el) => !!el.is_active).length;
  }

  get state(): IHttpState {
    return this.$state;
  }

  isPersonInActiveList(person: IAccountPerson): boolean {
    return this.activePersons.findIndex((el) => el.id === person.id && person.is_active) > -1;
  }

  canSavePerson(person: IAccountPerson): boolean {
    return !this.isPersonInActiveList(person) && this.activePersonsLength >= limitSaveActivePersons;
  }

  canSavePassportId(person: IAccountPerson): boolean {
    return this.$persons.findIndex((el) => el.passport_id === person.passport_id) === -1;
  }

  get canAddNewPerson(): boolean {
    return this.activePersonsLength >= limitSaveActivePersons;
  }
}
