/**
 * @Component Custom multiselect component using prime-ng multiselect
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: 'app-formly-field-multiselect',
  template: `
    <p-multiSelect
      [id]="field.key.toString()"
      [options]="to.options ? (to.options | formlySelectOptions: field | async) : null"
      [formControl]="fControl"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder"
      [selectionLimit]="to.selectionLimit"
      selectedItemsLabel="{0} {{to?.itemsSelected}}"
      (onChange)="to.change && to.change(field, $event)"
    >
    </p-multiSelect>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFieldMultiSelectComponent extends FieldType {
  defaultOptions = {
    templateOptions: { options: [] }
  };
  public get fControl() {
    return this.formControl as UntypedFormControl;
  }
}
