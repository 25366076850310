/**
 * @Component Monitored Persons component for displaying list of persons
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

// Stores
import { GuiStore } from '@stores/gui.store';
import { AccountsStore } from '@pages/accounts/accounts/accounts.store';
import { PersonsStore } from '@pages/accounts/persons/persons.store';

// Services
import { ModalService } from '@services/modal.service';
import { NotificationService } from '@services/notification.service';
import { ConfirmationService } from 'primeng/api';

// Helpers and interfaces
import { delay } from '@app/core/utils/async.utility';
import { IAccountItemColumns } from '@pages/accounts/accounts/accounts.interface';
import { IAccountPerson } from '@pages/accounts/persons/persons.interface';
import { Roles } from '@app/core/interfaces/user.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { config } from '@app/const';

@UntilDestroy()
@Component({
  selector: 'app-persons',
  templateUrl: './persons.component.html'
})
export class PersonsComponent implements OnInit {
  accountId: string;
  items: IAccountPerson[];
  activePersons: number;
  limitActivePersons = config.pages.persons.limitActivePersons;
  item: IAccountPerson;
  selectedItems: IAccountPerson[];
  cols: IAccountItemColumns[];
  loading: boolean;
  totalRecords: number;
  Roles = Roles;

  constructor(
    public title: Title,
    public guiStore: GuiStore,
    public translationService: TranslateService,
    public accountsStore: AccountsStore,
    public personsStore: PersonsStore,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {}

  mapFields(data): void {
    this.items = data
      .map((el) => {
        el.name = el.first_name + ' ' + el.last_name;
        return el;
      })
      .sort((a, b) => (a.id < b.id ? 1 : -1));
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.paramMap.get('id');
    this.loading = true;
    this.personsStore
      .fetchPersons(this.accountId)
      .then((data) => {
        this.totalRecords = data.length;
        this.mapFields(data);
        this.loading = false;
      })
      .catch((_) => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });

    this.cols = [
      { name: 'id', styles: { width: '5rem', 'text-align': 'center' } },
      { name: 'name', component: 'name' },
      { name: 'sex', styles: { 'text-align': 'center' }, component: 'sex' },
      { name: 'age', styles: { 'text-align': 'center' } },
      { name: 'passport_id' },
      { name: 'language', component: 'language' },
      { name: 'is_active', component: 'is_active', styles: { 'text-align': 'center' } },
      { name: 'resident_relation_type', component: 'resident_relation_type' }
    ];
    delay(100).then(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.persons'));
    });

    this.translationService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.persons'));
    });
  }

  deleteSelectedItems(): void {
    const modal = {
      type: 'delete_item',
      header: this.translationService.instant(`MODAL.delete_item.header`),
      message: this.translationService.instant(`MODAL.delete_item.message`, {
        item: this.translationService.instant(`MODAL.selected_items`)
      }),
      accept: {
        callback: async () => {
          this.items = this.items.filter((val) => !this.selectedItems.includes(val));
          await this.personsStore.deletePersons(
            this.accountId,
            this.selectedItems.map((el) => el.id)
          );
          this.totalRecords = this.personsStore.personsLength;
          this.selectedItems = null;
        }
      }
    };
    this.modalService.showModal(modal);
  }

  async deleteItem(item: IAccountPerson): Promise<void> {
    const modal = {
      type: 'delete_item',
      header: this.translationService.instant(`MODAL.delete_item.header`),
      message: this.translationService.instant(`MODAL.delete_item.message`, {
        item: item.first_name + ' ' + item.last_name
      }),
      accept: {
        callback: async () => {
          this.items = this.items.filter((val) => val.id !== item.id);
          await this.personsStore.deletePerson(this.accountId, item.id);
          this.totalRecords = this.personsStore.personsLength;
          this.item = null;
        }
      }
    };
    this.modalService.showModal(modal);
  }
}
