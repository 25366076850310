<ng-container *appUserRole="[ Roles.Admin ]">
  <button
    *ngIf="!hideNew"
    [routerLink]="['./add']"
    class="p-button p-button-success p-mx-2"
    icon="pi pi-plus"
    label="{{ 'PAGES.'+ page +'.new' | translate }}"
    [disabled]="isNewDisabled"
    pButton
    pRipple>
  </button>
</ng-container>
<ng-container *appUserRole="[ Roles.Admin ]">
  <button
    *ngIf="!hideDelete"
    (click)="deleteItems()"
    [disabled]="!selectedItems || !selectedItems.length"
    class="p-button p-button-danger"
    icon="pi pi-trash"
    label="{{ 'PAGES.'+page+'.delete' | translate }}"
    pButton
    pRipple>
  </button>
</ng-container>
