import { IAccount } from '@pages/accounts/accounts/accounts.interface';
import { IAccountRule, IRuleType } from '@pages/accounts/rules/rules.interface';
import { IAccountPerson } from '@pages/accounts/persons/persons.interface';

export enum INotificationStates {
  open = 'open',
  closed = 'closed',
  inprogress = 'inprogress'
}

export enum INotificationTypes {
  emergency = 'emergency',
  activity = 'activity',
  technical = 'technical'
}

export enum IOrderByOptions {
  CREATED_AT = 'created_at',
  RULE_TYPE = 'rule_type',
  ALGO_TYPE = 'algo_type',
  NOTIFICATION_TYPE = 'notification_type',
  ACCOUNT_LAST_NAME = 'account_name',
  PERSON_LAST_NAME = 'person_name'
}

export enum IFilterTypes {
  buttons = 'buttons',
  select = 'select'
}

export interface INotificationsResponse {
  items: INotificationItem[];
  meta: INotificationMeta;
}

export interface INotificationMeta {
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}

export interface INotificationQueryRequest {
  query: INotificationQueryQuery;
  filter: INotificationQueryFilter;
}

export interface INotificationQueryQuery {
  page: number;
  limit: number;
  order_by?: string;
  sort_dir?: 'ASC' | 'DESC';
}

export interface INotificationQueryFilter {
  state?: string[];
  notifification_type_ids?: number[];
  rule_type_ids?: number[];
  algo_type_ids?: number[];
  account_ids?: number[];
  start_time?: string;
  end_time?: string;
}

export interface INotificationItem {
  id: string;
  account: IAccount;
  algorithm_type: IRuleType;
  data?: string;
  cloase_reason: INotificationType | null;
  message?: any;
  notification_type: IRuleType;
  person: IAccountPerson;
  rule: IAccountRule;
  state: 'open' | 'closed' | 'inprogress';
  created_at: string;
  updated_at: string;
}

export interface INotificationView {
  id: string;
  type: INotificationTypes;
  rule_type: string;
  algo_type: string;
  created_at: string;
  account_id?: number;
  account_name: string;
  service_provider_id?: number;
  resident_name: string;
  sensor_id?: string;
  frequency?: string;
  date_time_date?: string;
  date_time_year?: string;
  time_start: string;
  time_end: string;
  time_range: string;
  extra_values?: INotificationExtraValue | null;
  description?: string;
  notes?: string;
  state?: INotificationStates;
  close_reason?: INotificationType;
  status_popup?: INotificationStates;
}

export interface INotificationEditRequest {
  id: string;
  state?: INotificationStates;
  status_popup?: INotificationStates;
  message?: string;
  close_reason?: { id: number };
}

export interface INotificationExtraValue {
  base_value: number;
  base_value_unit: string;
  actual_value?: number;
  high_value: number;
  high_value_unit: number;
  low_value: number;
  low_value_unit: number;
}

export interface INotificationType {
  id: number;
  type_key: string;
  type_name: string;
  type_metadata?: {
    [key: string]: string[];
  };
}

export interface INotificationTypesRes {
  notification_types: INotificationType[];
  close_reasons: INotificationType[];
  mapping: any[];
}

export interface INotificationItemColumns {
  name: string;
  field?: string;
  styles?: any;
  component?: string;
  filter?: boolean;
  sortable?: boolean;
  custom?: boolean;
  type?: string;
  icon?: (key: string) => string;
}

export interface INotificationAlertTypes {
  [key: string]: { icon: string; color: string };
}

export interface INotificationNavStructure {
  segment?: string;
  icon?: string;
  icon_active?: string;
  label?: string;
  class?: string;
  exact?: boolean;
  routerLink?: any[];
}

export interface IOptionsFilter {
  value: any;
  label: string;
  color?: string;
  icon?: string;
  background?: string;
}

export interface IFilterItems {
  key: string;
  type: string;
  options?: IOptionsFilter[];
  translate?: boolean;
  translateKey?: string;
  translateKeyPath?: string;
  default?: any;
  multiple?: boolean;
}

export interface IFilterSelected {
  key: string;
  value: any;
  multiple?: boolean;
  type?: IFilterTypes;
}

export interface INotificationStorageSettings {
  filter?: INotificationQueryFilter;
  pagination?: {
    first?: number;
    page?: number;
    pageCount?: number;
    rows?: number;
  };
  sorting?: {
    order_by?: string;
    sort_dir?: 'ASC' | 'DESC';
  };
}
