import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class AccountsSharedService {
    constructor() {}

    resetForm(form: UntypedFormGroup): void {
        form.markAsPristine();
        form.markAsUntouched();
    }
}
