/**
 * @Component Alert notification component with modal
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

import { NotificationsStore } from '@pages/notifications/notifications.store';
import { INotificationItem, INotificationStates } from '@pages/notifications/notifications.interface';
import { GuiStore } from '@stores/gui.store';
import { ELanguages, INavigationInterface } from "@app/const/config.interface";
import { NAVIGATION } from '@app/const';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {
  result = {
    state: 'open',
    message: '',
    close_reason: 10
  };
  form = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  modal: {
    item: INotificationItem;
    icon: string;
    color?: string;
    message: string;
  };
  ELanguages = ELanguages;
  navigation: INavigationInterface = NAVIGATION;

  constructor(
    private notificationsStore: NotificationsStore,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public guiStore: GuiStore
  ) {}

  ngOnInit(): void {
    this.modal = this.config.data;
    this.result = {
      state: this.config.data.item.state,
      message: this.config.data.item?.message,
      close_reason: this.config.data.item?.close_reason?.id
    };
    this.formFields();
  }

  confirm(): void {
    this.ref.close(this.result);
  }

  cancel(): void {
    this.ref.close(null);
  }

  get isFormValid(): boolean {
    return this.form.valid && this.result.state !== INotificationStates.open;
  }

  formFields(): void {
    this.fields = [
      {
        fieldGroupClassName: 'p-d-flex fullwidth wrapped',
        fieldGroup: [
          {
            className: 'p-field',
            type: 'dropdown',
            key: 'state',
            templateOptions: {
              required: true,
              options: [
                { label: 'PAGES.notifications.state.open', value: 'open' },
                { label: 'PAGES.notifications.state.inprogress', value: 'inprogress' },
                { label: 'PAGES.notifications.state.closed', value: 'closed' }
              ],
              label: 'PAGES.notifications.fields.state'
            }
          },
          {
            className: 'p-field p-mx-1',
            type: 'dropdown',
            key: 'close_reason',
            templateOptions: {
              placeholder: 'DEFAULTS.select_option',
              required: true,
              options: [
                { label: 'DEFAULTS.select_option', value: '' },
                ...this.notificationsStore.closeReasonsOptions.map((el) => ({
                  ...el,
                  label: 'PAGES.notifications.fields.reasons.' + el.value
                }))
              ],
              label: 'PAGES.notifications.fields.reason'
            },
            expressionProperties: {
              'templateOptions.disabled': (model) => model.state !== INotificationStates.closed,
              'templateOptions.required': (model) => model.state === INotificationStates.closed
            }
          },
          {
            className: 'p-field p-mx-1',
            type: 'input',
            key: 'message',
            templateOptions: {
              minLength: 5,
              maxLength: 512,
              label: 'PAGES.notifications.fields.notes'
            },
            expressionProperties: {
              'templateOptions.required': 'model.close_reason==="9"'
            }
          }
        ]
      }
    ];
  }
}
