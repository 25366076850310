/**
 * @Component Persons component for creating new person and update selected one
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

// Stores
import { GuiStore } from '@stores/gui.store';
import { AccountsStore } from '@pages/accounts/accounts/accounts.store';
import { PersonsStore } from '@pages/accounts/persons/persons.store';

// Services
import { NotificationService } from '@services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@services/modal.service';
import { AccountsSharedService } from '@pages/accounts/accounts-shared.service';

// Helpers and interfaces
import { delay } from '@app/core/utils/async.utility';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { IAccountPerson } from '@pages/accounts/persons/persons.interface';
import { config, NAVIGATION } from '@app/const';
import { ELanguages } from '@app/const/config.interface';

@UntilDestroy()
@Component({
  selector: 'app-update-person',
  templateUrl: './update-person.component.html'
})
export class UpdatePersonComponent implements OnInit {
  isLoading = false;
  item: IAccountPerson | any;
  accountId: string;
  limitActivePersons = config.pages.persons.limitActivePersons;
  pId: string;
  rooms: FormlyFieldConfig[] = [];
  form = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  isEdit: boolean;
  isView: boolean;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private accountsShared: AccountsSharedService,
    public guiStore: GuiStore,
    public translate: TranslateService,
    public route: ActivatedRoute,
    public accountsStore: AccountsStore,
    public personsStore: PersonsStore
  ) {
    this.model.lang = guiStore.currentLanguage;
  }

  get mapItems(): IAccountPerson | any {
    return {
      ...this.item,
      extra: JSON.stringify(this.item.extra),
      is_caregiver: this.item.resident_relation_type === 'caregiver',
      phone_code: '+972',
      is_active: this.item.is_active ?? true
    };
  }

  async saveItem(): Promise<void> {
    if (this.personsStore.canSavePerson(this.mapItems) && this.mapItems.is_active) {
      return this.notificationService.addSingle({
        severity: 'error',
        summary: this.translate.instant('MODAL.attention'),
        detail: this.translate.instant('MODAL.persons.limit')
      });
    }
    if (this.route.snapshot?.data?.isEdit) {
      try {
        const result = await this.personsStore.editPerson(this.accountId, this.mapItems);
        if (result?.ok) {
          this.notificationService.addSingle({
            severity: 'success',
            summary: this.translate.instant('MODAL.attention'),
            detail: this.translate.instant('MODAL.notification.saved')
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      if (!this.personsStore.canSavePassportId(this.mapItems)) {
        return this.notificationService.addSingle({
          severity: 'error',
          summary: this.translate.instant('MODAL.attention'),
          detail: this.translate.instant('MODAL.persons.passport')
        });
      }
      try {
        const result = await this.personsStore.addPerson(this.accountId, this.mapItems);
        if (result?.ok) {
          this.notificationService.addSingle({
            severity: 'success',
            summary: this.translate.instant('MODAL.attention'),
            detail: this.translate.instant('MODAL.notification.added')
          });
        }
        delay(2000).then(
          async () =>
            await this.router.navigate([
              NAVIGATION.pages.url,
              NAVIGATION.accounts.url,
              this.accountId,
              NAVIGATION.persons.url
            ])
        );
      } catch (e) {
        console.log(e);
      }
    }
    this.accountsShared.resetForm(this.form);
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.modalService.exitModal(this.form);
  }

  ngOnInit(): void {
    this.isView = this.route.snapshot?.data?.isView;
    this.isEdit = this.route.snapshot?.data?.isEdit;
    this.accountId = this.route.snapshot.paramMap.get('id');
    this.pId = this.route.snapshot.paramMap.get('pid');
    if (this.isEdit || this.isView) {
      this.isLoading = true;
      this.personsStore
        .fetchPerson(this.accountId, this.pId)
        .then(async (data) => {
          this.item = data;
          this.item.extra =
            data?.extra === '{}' || !JSON.parse(this.item?.extra).length
              ? [{ key: '', value: '' }]
              : JSON.parse(this.item.extra);
          this.isLoading = false;
          if (this.personsStore.persons?.length === 0) {
            await this.personsStore.fetchPersons(this.accountId);
          }
          this.formFields();
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.item = {};
      this.accountsStore.fetchAccount(this.accountId).then((res) => {
        this.item.first_name = res.owner_first_name;
        this.item.last_name = res.owner_last_name;
        this.item.home_phone = res.owner_home_phone;
        this.item.cell_phone = res.owner_cell_phone;
        this.item.extra = [];
        this.formFields();
        this.cdr.detectChanges();
      });
    }
    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe((_) => {
      this.formFields();
    });
  }

  formFields(): void {
    this.fields = [
      {
        hooks: {
          onInit: (_) => {
            if (this.isView) {
              this.form.disable();
              this.cdr.detectChanges();
            }
          }
        },
        type: 'tabs',
        fieldGroup: [
          {
            templateOptions: {
              label: 'PAGES.persons.groups.main'
            },
            fieldGroupClassName: 'p-d-flex p-flex-column',
            fieldGroup: [
              {
                fieldGroupClassName: 'p-d-flex',
                fieldGroup: [
                  {
                    className: 'p-field p-col',
                    fieldGroupClassName: 'p-d-flex p-flex-column',
                    fieldGroup: [
                      {
                        className: 'p-field p-col',
                        type: 'switch',
                        key: 'is_active',
                        defaultValue: true,
                        templateOptions: {
                          label: 'PAGES.persons.fields.is_active'
                        }
                      },
                      {
                        className: 'p-field p-col',
                        type: 'input',
                        key: 'first_name',
                        templateOptions: {
                          minLength: 2,
                          required: true,
                          label: 'PAGES.persons.fields.first_name'
                        }
                      },
                      {
                        className: 'p-field p-col',
                        type: 'input',
                        key: 'last_name',
                        templateOptions: {
                          minLength: 2,
                          required: true,
                          label: 'PAGES.persons.fields.last_name'
                        }
                      },
                      {
                        className: 'p-field p-col',
                        type: 'mask',
                        key: 'cell_phone',
                        templateOptions: {
                          placeholder: '+XXX-XX-XXX-XXXX',
                          maskString: '+000-00-000-0099',
                          pattern: '^[+0-9]{10,13}$',
                          required: true,
                          label: 'PAGES.persons.fields.cell_phone'
                        }
                      },
                      {
                        className: 'p-field p-col',
                        type: 'mask',
                        key: 'home_phone',
                        templateOptions: {
                          placeholder: '+XXX-XX-XXX-XXXX',
                          maskString: '+000-00-000-0099',
                          pattern: '^[+0-9]{10,13}$',
                          required: true,
                          label: 'PAGES.persons.fields.home_phone'
                        }
                      },
                      {
                        fieldGroupClassName: 'p-d-flex',
                        fieldGroup: [
                          {
                            className: 'p-field p-col-3',
                            key: 'sex',
                            type: 'select',
                            templateOptions: {
                              required: true,
                              label: 'PAGES.persons.fields.sex',
                              options: [
                                { label: 'PAGES.persons.sex.select', value: '' },
                                { label: 'PAGES.persons.sex.male', value: 'male' },
                                { label: 'PAGES.persons.sex.female', value: 'female' }
                              ]
                            }
                          },
                          {
                            className: 'p-field p-col-2',
                            type: 'input',
                            key: 'age',
                            templateOptions: {
                              min: 1,
                              max: 130,
                              type: 'number',
                              required: true,
                              label: 'PAGES.persons.fields.age'
                            }
                          }
                        ]
                      },
                      {
                        fieldGroupClassName: 'p-d-flex',
                        fieldGroup: [
                          {
                            className: 'p-field p-col',
                            type: 'input',
                            key: 'passport_id',
                            templateOptions: {
                              minLength: 5,
                              maxLength: 12,
                              required: true,
                              label: 'PAGES.persons.fields.passport_id'
                            }
                          },
                          {
                            className: 'p-field p-col',
                            type: 'input',
                            key: 'service_provider_id',
                            templateOptions: {
                              minLength: 3,
                              maxLength: 25,
                              required: true,
                              label: 'PAGES.persons.fields.service_provider_id'
                            }
                          }
                        ]
                      },
                      {
                        fieldGroupClassName: 'p-d-flex',
                        fieldGroup: [
                          {
                            className: 'p-field p-col-8',
                            key: 'resident_relation_type',
                            defaultValue: 'none',
                            type: 'dropdown',
                            templateOptions: {
                              required: true,
                              editable: true,
                              label: 'PAGES.persons.fields.resident_relation_type',
                              options: [
                                {
                                  label: 'PAGES.persons.resident_relation_type.none',
                                  value: 'none'
                                },
                                {
                                  label: 'PAGES.persons.resident_relation_type.caretaker',
                                  value: 'caretaker'
                                },
                                {
                                  label: 'PAGES.persons.resident_relation_type.caregiver',
                                  value: 'caregiver'
                                },
                                {
                                  label: 'PAGES.persons.resident_relation_type.spouse',
                                  value: 'spouse'
                                },
                                {
                                  label: 'PAGES.persons.resident_relation_type.brother',
                                  value: 'brother'
                                },
                                {
                                  label: 'PAGES.persons.resident_relation_type.sister',
                                  value: 'sister'
                                }
                              ]
                            }
                          },
                          {
                            className: 'p-field p-col-4',
                            key: 'language',
                            type: 'dropdown',
                            defaultValue: ELanguages.he,
                            templateOptions: {
                              required: true,
                              editable: true,
                              label: 'PAGES.persons.fields.language',
                              options: config.languages.list.map((lang) => ({
                                label: 'DEFAULTS.lang.' + lang,
                                value: lang
                              }))
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    className: 'p-field p-col',
                    fieldGroupClassName: 'p-d-flex p-flex-column',
                    fieldGroup: [
                      {
                        className: 'p-field p-col',
                        type: 'input',
                        key: 'weight_kg',
                        templateOptions: {
                          min: 3,
                          max: 300,
                          type: 'number',
                          required: true,
                          label: 'PAGES.persons.fields.weight_kg'
                        }
                      },
                      {
                        className: 'p-field p-col',
                        type: 'input',
                        key: 'height_cm',
                        templateOptions: {
                          min: 20,
                          max: 300,
                          type: 'number',
                          required: true,
                          label: 'PAGES.persons.fields.height_cm'
                        }
                      },
                      {
                        className: 'p-field p-col',
                        type: 'input',
                        key: 'shoulder_height_cm',
                        templateOptions: {
                          min: 1,
                          max: 200,
                          type: 'number',
                          required: true,
                          label: 'PAGES.persons.fields.shoulder_height_cm'
                        }
                      },
                      {
                        className: 'p-field p-col',
                        type: 'input',
                        key: 'shoulder_width_cm',
                        templateOptions: {
                          min: 1,
                          max: 100,
                          type: 'number',
                          required: true,
                          label: 'PAGES.persons.fields.shoulder_width_cm'
                        }
                      },
                      {
                        className: 'p-field p-col',
                        type: 'input',
                        key: 'sit_weight_kg',
                        templateOptions: {
                          min: 1,
                          max: 500,
                          type: 'number',
                          required: true,
                          label: 'PAGES.persons.fields.sit_weight_kg'
                        }
                      },
                      {
                        className: 'p-field p-col',
                        type: 'input',
                        key: 'resting_rr',
                        templateOptions: {
                          min: 1,
                          type: 'number',
                          required: true,
                          label: 'PAGES.persons.fields.resting_rr'
                        }
                      },
                      {
                        className: 'p-field p-col',
                        type: 'input',
                        key: 'resting_hr',
                        templateOptions: {
                          min: 10,
                          max: 200,
                          type: 'number',
                          required: true,
                          label: 'PAGES.persons.fields.resting_hr'
                        }
                      }
                    ]
                  }
                ]
              },
              {
                className: 'p-d-flex p-col p-heading p-mt-2',
                type: 'message',
                templateOptions: {
                  label: 'PAGES.persons.fields.extra'
                }
              },
              {
                className: 'p-field p-col',
                type: 'repeat',
                key: 'extra',
                templateOptions: {
                  isDisabled: !!this.isView
                },
                fieldArray: {
                  fieldGroup: [
                    {
                      fieldGroupClassName: 'p-d-flex p-card p-p-2',
                      fieldGroup: [
                        {
                          className: 'p-field p-col-6',
                          key: 'key',
                          type: 'mask',
                          templateOptions: {
                            placeholder: 'XXXX-000',
                            maskString: 'AAAA-000',
                            pattern: '^[0-9a-zA-Z]{7}$',
                            required: false,
                            label: 'PAGES.persons.fields.extra.key'
                          }
                        },
                        {
                          className: 'p-field p-col-6',
                          key: 'value',
                          type: 'input',
                          templateOptions: {
                            required: false,
                            label: 'PAGES.persons.fields.extra.value'
                          }
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ];
  }
}
