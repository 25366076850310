/**
 * @Module Core module for major modules needed for project working
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutContainerModule } from '@layout/layout-container/layout-container.module';
import { SharedModule } from '@modules/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@auth/auth.module';

@NgModule({
    declarations: [],
    imports: [CommonModule, AuthModule, LayoutContainerModule, SharedModule, HttpClientModule, ReactiveFormsModule],
    exports: [LayoutContainerModule, AuthModule, SharedModule]
})
export class CoreModule {}
