/**
 * @Service Service for expressions methods in a forms structure array
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { Injectable } from '@angular/core';
import { UpdateRuleComponent } from '@pages/accounts/rules/update-rule/update-rule.component';

const disabled = {
  monitored_persons_ids: ['algo_t_temperature', 'algo_t_panic', 'algo_t_sensor_battery_status'],
  time_start: ['algo_t_panic', 'algo_t_temperature'],
  time_end: ['algo_t_panic', 'algo_t_temperature'],
  notification_type_id: ['algo_t_panic', 'algo_t_temperature'],
  notify_policy: ['algo_t_panic', 'algo_t_temperature'],
  apply_on_schedule: [],
  sensor_ids: [],
  'fix_threshold.base_value': ['algo_t_panic'],
  'fix_threshold.base_value_unit': ['algo_t_panic'],
  'fix_threshold.low_value': ['algo_t_out_of_bed_time', 'algo_t_panic', 'algo_t_sensor_battery_status'],
  'fix_threshold.low_value_unit': ['algo_t_out_of_bed_time', 'algo_t_panic', 'algo_t_sensor_battery_status'],
  'fix_threshold.high_value': ['algo_t_out_of_bed_time', 'algo_t_panic', 'algo_t_sensor_battery_status'],
  'fix_threshold.high_value_unit': ['algo_t_out_of_bed_time', 'algo_t_panic', 'algo_t_sensor_battery_status']
};

@Injectable()
export class UpdateExpressionPropertiesService {
  optionsVisibility(ctx: UpdateRuleComponent, key: string): any {
    return {
      'templateOptions.required': (model) => !model.use_computed_threshold,
      'templateOptions.disabled': (model) => {
        return !ctx.isView ? disabled[key].includes(model.algorithm_type_id) : true;
      }
    };
  }
}
