<ng-container *appUserRole="[ Roles.Admin, Roles.CallCenterUser ]">
  <button
    *ngIf="!isView"
    (click)="saveItem.emit()"
    [disabled]="isDisabled"
    class="p-button-success p-button-lg p-mt-1"
    [icon]="icon"
    [label]="label"
    pButton
    pRipple>
    <ng-container *ngIf="isLoading">
      <p-progressSpinner styleClass="button-spinner"></p-progressSpinner>
    </ng-container>
  </button>
</ng-container>
