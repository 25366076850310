import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Roles } from '@app/core/interfaces/user.interface';

@Component({
  selector: 'app-sub-new-delete',
  templateUrl: './sub-new-delete.component.html'
})
export class SubNewDeleteComponent {
  @Input() page;
  @Input() selectedItems;
  @Input() isNewDisabled;
  @Input() hideNew;
  @Input() hideDelete;
  @Output() deleteHandler: EventEmitter<any> = new EventEmitter<any>();
  Roles = Roles;

  deleteItems(): void {
    this.deleteHandler.emit();
  }
}
