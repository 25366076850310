/**
 * @Component Reports component.
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ActivatedRoute } from "@angular/router";

import { GuiStore } from "@stores/gui.store";
import { ReportsStore } from "@pages/reports/reports.store";
import { RoomsStore } from "@pages/accounts/rooms/rooms.store";

import { IAccountRoom } from "@pages/accounts/accounts/accounts.interface";
import { IReportFilter, IReportStorageOptions } from "@pages/reports/reports.interface";
import { delay } from "@app/core/utils/async.utility";
import { SensorsStore } from "@pages/accounts/sensors/sensors.store";
import { IZoomType, ZoomService } from "@services/zoom.service";
import { LocalStorageService } from "@app/core/services/local-storage.service";
import { ConfigurationService } from "@app/core/services/configuration.service";

@UntilDestroy()
@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsComponent implements OnInit {
  fields: FormlyFieldConfig[];
  roomFields: FormlyFieldConfig[];
  form: UntypedFormGroup;
  roomFilterform: UntypedFormGroup;
  isLoading: boolean;
  model: IReportFilter = {};
  options: FormlyFormOptions;
  optionsRoomFilter: FormlyFormOptions = {};
  rooms: IAccountRoom[] = [];
  accountId: number;
  accountIdRoute;
  showGroups = this.configService.config.pages.reports.showGroupedBars;
  showColors = this.configService.config.pages.reports.showColors;

  constructor(
    public title: Title,
    public guiStore: GuiStore,
    public translateService: TranslateService,
    public reportsStore: ReportsStore,
    public roomsStore: RoomsStore,
    public sensorsStore: SensorsStore,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private zoom: ZoomService,
    private localStorageService: LocalStorageService,
    private configService: ConfigurationService
  ) {
    this.model.lang = guiStore.currentLanguage;
  }

  setRoomOptions(): void {
    const rooms = this.reportsStore.report?.map((el) => el?.room_id.toString());
    this.reportsStore.setSelectedRoomFilterModel({ rooms: [...new Set(rooms)] });
    const roomOptions = this.roomsStore.roomsOptions.filter((el) => rooms?.includes(el.value));
    this.reportsStore.setRoomOptions(roomOptions);
  }

  getOptionsFromStorage(): void {
    const storageOptions = this.localStorageService
      .getItem<IReportStorageOptions>(this.configService.config.pages.reports.storageKeyOptions);
    if (storageOptions) {
      this.showGroups = storageOptions?.showGroups ?? this.configService.config.pages.reports.showGroupedBars;
      this.showColors = storageOptions?.showColors ?? this.configService.config.pages.reports.showColors;
    } else {
      this.localStorageService
        .setItem(this.configService.config.pages.reports.storageKeyOptions,
          { showGroups: this.showGroups, showColors: this.showColors });
    }
  }

  async ngOnInit(): Promise<void> {
    this.reportsStore.setLoading({ activity: true, notifications: true });
    this.getOptionsFromStorage();
    await this.reportsStore.fetchAccounts();
    this.accountId = +this.route.snapshot.paramMap.get("id") || +this.reportsStore.currentAccountId;
    this.accountIdRoute = this.route.snapshot.paramMap.get("id");
    await this.reportsStore.setSelectedModel({
      account: this.accountId,
      person: this.reportsStore?.person?.id
    });
    await this.sensorsStore.fetchSensors(this.accountId.toString());
    await this.roomsStore.fetchRooms(this.accountId.toString());
    await this.reportsStore.fetchReports();
    this.reportsStore.setLoading({ activity: false });
    await this.reportsStore.fetchNotificationsReports();
    this.reportsStore.setLoading({ notifications: false });
    this.setRoomOptions();
    this.formFields();
    this.roomFilterFields();

    delay(100).then(() => {
      this.title.setTitle(this.translateService.instant("MENU.reports.reports"));
    });

    this.translateService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.title.setTitle(this.translateService.instant("MENU.reports.reports"));
      this.roomFilterFields();
    });

    this.cdr.detectChanges();
  }

  toggleGroups(): void {
    this.localStorageService
      .insertItem({ showGroups: !this.showGroups }, this.configService.config.pages.reports.storageKeyOptions);
    this.showGroups = !this.showGroups;
  }

  toggleColors(): void {
    this.localStorageService
      .insertItem({ showColors: !this.showColors }, this.configService.config.pages.reports.storageKeyOptions);
    this.showColors = !this.showColors;

  }

  zoomIn(): void {
    this.zoom.set(IZoomType.IN);
    this.cdr.detectChanges();
  }

  zoomOut(): void {
    this.zoom.set(IZoomType.OUT);
  }

  zoomReset(): void {
    this.zoom.set(IZoomType.RESET);
  }

  async onModelChange(model): Promise<void> {
    await this.reportsStore.setSelectedModel(model);
    await this.sensorsStore.fetchSensors(this.reportsStore.currentSelectedModel.account?.toString());
    await this.roomsStore.fetchRooms(this.reportsStore.currentSelectedModel.account?.toString());
    await this.reportsStore.fetchReports();
    await this.reportsStore.fetchNotificationsReports();
    this.setRoomOptions();
    this.formFields();
    this.roomFilterFields();
  }

  onRoomFilterChange(model): void {
    this.reportsStore.setSelectedRoomFilterModel(model);
  }

  formFields(): void {
    this.fields = [
      {
        fieldGroupClassName: "p-d-flex p-jc-end p-reports",
        fieldGroup: [
          {
            className: "p-d-flex p-field p-mx-1 p-flex-column",
            type: "datepicker",
            key: "date",
            defaultValue: new Date(),
            templateOptions: {
              showError: true,
              placeholder: "YYYY/MM/DD",
              description: "Description",
              dateFormat: "yy/mm/dd",
              hourFormat: "24",
              numberOfMonths: 1,
              selectionMode: "single",
              required: true,
              readonlyInput: false,
              showTime: false,
              showButtonBar: true,
              showIcon: false,
              showOtherMonths: true,
              selectOtherMonths: false,
              monthNavigator: false,
              yearNavigator: false,
              yearRange: "2021:2030",
              inline: false,
              label: "PAGES.reports.fields.day"
            }
          },
          {
            className: "p-d-flex p-field p-mx-1 p-flex-column",
            type: "dropdown",
            key: "account",
            templateOptions: {
              required: true,
              filter: true,
              placeholder: "PAGES.reports.fields.accounts",
              options: this.reportsStore.accountsOptions,
              label: "PAGES.reports.fields.accounts"
            }
          },
          {
            className: "p-d-flex p-field p-mx-1 p-flex-column",
            type: "select",
            key: "person",
            templateOptions: {
              required: true,
              options: [],
              label: "PAGES.reports.fields.persons"
            },
            expressionProperties: {
              "templateOptions.options": (_: any) =>
                !this.reportsStore?.personsOptions?.length
                  ? [
                    {
                      label: this.translateService.instant("PAGES.reports.add_persons"),
                      value: ""
                    },
                    {
                      label: this.translateService.instant("PAGES.reports.unknown_person"),
                      value: 0
                    },
                    {
                      label: this.translateService.instant("PAGES.reports.all_persons"),
                      value: "all"
                    }
                  ]
                  : [
                    ...this.reportsStore.personsOptions,
                    {
                      label: this.translateService.instant("PAGES.reports.unknown_person"),
                      value: 0
                    },
                    {
                      label: this.translateService.instant("PAGES.reports.all_persons"),
                      value: "all"
                    }
                  ]
            }
          }
        ]
      }
    ];
  }

  roomFilterFields(): void {
    this.roomFields = [
      {
        fieldGroupClassName: "p-d-flex p-jc-end p-reports",
        fieldGroup: [
          {
            className: "p-d-flex p-field p-mx-1 p-flex-column",
            type: "multiselect",
            key: "rooms",
            templateOptions: {
              disabled: false,
              readonly: false,
              itemsSelected: this.translateService.instant("FORM.items_selected"),
              filter: true,
              required: true,
              multiple: true,
              placeholder: "PAGES.reports.select_rooms",
              options: [],
              label: "PAGES.reports.rooms_filter"
            },
            expressionProperties: {
              "templateOptions.options": (_: any) => this.reportsStore.roomsOptions
            }
          }
        ]
      }
    ];
  }
}
