/**
 * @Component Sub action button component
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IAccount } from '@pages/accounts/accounts/accounts.interface';
import { IAccountPerson } from '@pages/accounts/persons/persons.interface';
import { IAccountContact } from '@pages/accounts/contacts/contacts.interface';
import { IAccountSensor } from '@pages/accounts/sensors/sensors.interface';
import { Roles } from '@app/core/interfaces/user.interface';

@Component({
  selector: 'app-sub-actions-buttons',
  templateUrl: './sub-actions-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubActionsButtonsComponent {
  @Input() item: IAccount | IAccountPerson | IAccountContact | IAccountSensor;
  @Output() deleteHandler: EventEmitter<any> = new EventEmitter<any>();
  @Input() allowDelete;
  @Input() allowEdit;
  @Input() allowView;
  @Input() includedRoles;
  Roles = Roles;

  constructor() {}

  deleteItem(item): void {
    this.deleteHandler.emit(item);
  }
}
