/**
 * @module Contacts' module for encapsulating contacts components
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@modules/shared.module';
import { PagesSharedModule } from '@pages/pages-shared.module';

import { ContactsComponent } from '@pages/accounts/contacts/contacts.component';
import { UpdateContactComponent } from './update-contact/update-contact.component';
import { ViewContactComponent } from './view-contact/view-contact.component';

@NgModule({
    declarations: [ContactsComponent, UpdateContactComponent, ViewContactComponent],
    imports: [CommonModule, PagesSharedModule, SharedModule]
})
export class ContactsModule {}
