<!--<pre style="font-size: 10px; text-align: left; color: black">{{ selected | json }}</pre>-->
<div class="filter-wrapper" *mobxAutorun>
  <ng-container *ngFor="let item of items">
    <fieldset class="p-mx-1 filter-block">
      <div *ngIf="options?.showTitle">{{ 'PAGES.notifications.fields.' + item.key | translate}}</div>
      <ng-container [ngSwitch]="item.type">
        <ng-container *ngSwitchCase="'buttons'">
          <div class="p-d-flex p-ai-center">
            <button
              pButton
              *ngFor="let option of item.options"
              [style.background] = "option.background"
              [style.padding] = "'0.3rem'"
              [class.option-selected] = "isSelectedContains({ key: item.key, value: option.value})"
              class="p-mx-1 p-button filter-button"
              label="{{ option.label | translate }}"
              (click)="pushSelected({ key: item.key, value: option.value, multiple: item?.multiple })"
            ><i *ngIf="option.icon" class="pi {{option.icon}} p-mx-2"></i>
            </button>
            <button
              icon="pi pi-filter-slash"
              class="p-mx-1 p-button-rounded p-button-outlined"
              pButton
              (click)="clearSelected(item.key)"
            ></button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'select'">
          <div class="p-d-flex p-ai-center p-jc-center">
            <p-multiSelect
              (onChange)="pushSelected({ key: item.key, value: $event?.value, multiple: true, type: filterTypes.select })"
              [filter]="true"
              [ngModel]="getSelectedValue(item.key)"
              [options]="item.options"
              placeholder="---">
              <ng-template let-value pTemplate="selectedItems">
                <ng-container *ngIf="value else noselected">
                  {{ value?.length }} {{ 'DEFAULTS.items_selected' | translate }}
                </ng-container>
                <ng-template #noselected>
                  {{ 'DEFAULTS.select_option' | translate }}
                </ng-template>
              </ng-template>
              <ng-template let-option pTemplate="item">
                <div> &nbsp;{{option.label | translate}}&nbsp;</div>
              </ng-template>
            </p-multiSelect>
          </div>
        </ng-container>
      </ng-container>
    </fieldset>
  </ng-container>
  <fieldset class="p-mx-1 filter-block reset">
    <div>{{ 'DEFAULTS.reset' | translate }}</div>
    <button
      icon="pi pi-filter-slash"
      class="p-mx-1 p-button-rounded p-button-outlined reset"
      pButton
      (click)="clearAll()"
    ></button>
  </fieldset>
</div>
