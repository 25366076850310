<div *mobxAutorun class="p-d-flex p-flex-column">
  <app-sub-nav [id]="accountId" [path]="'accounts'" class="p-mb-2 p-as-end"></app-sub-nav>
  <div class="p-d-flex p-jc-between p-ai-center p-mb-2">
    <app-sub-heading
      [item]="accountsStore.account"
      [accountId]="accountId"
      [path]="'gateway'">
    </app-sub-heading>
  </div>
  <div class="card">
    <p-tabView>
      <p-tabPanel header="{{ 'PAGES.accounts.groups.main' | translate }}">
        <ng-container *ngIf="!error else errorTemplate">
          <ng-container *ngIf="loaded else loading">
            <div *ngFor="let col of cols" class="p-d-flex p-flex-column p-p-0">
              <div class="p-d-flex p-p-0 p-col-12 p-grid-row-margin">
                <div class="p-col-6 p-grid-header-style">
                  <strong>{{ 'PAGES.gateway.fields.' + col.name | translate }}</strong>
                </div>
                <div class="p-col-6 p-grid-row-style">
                  <ng-container *ngIf="!col['component'] else components">
                    {{ item[col.name] ? item[col.name] : '' }}
                  </ng-container>
                  <ng-template #components>
                    <ng-container [ngSwitch]="col['component']">
                      <ng-container *ngSwitchCase="'date'">
                        {{ item[col.name] | date:'medium' }}
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #errorTemplate>
          <div class="card">
            <div class="p-d-flex p-jc-center">
              {{ 'DEFAULTS.no_data_found' | translate }}
            </div>
          </div>
        </ng-template>
        <ng-template #loading>
          {{ 'DEFAULTS.loading' | translate }}
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

