/**
 * @Service Auth Service for managing authentication and authorization
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

import { first, map } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { NotificationService } from "@services/notification.service";
import { LocalStorageService } from "@app/core/services/local-storage.service";
import { GuiStore } from "@stores/gui.store";

import { environment } from "@environments/environment";
import { config, NAVIGATION } from "@app/const";

import { IUserInterface, Roles } from "@app/core/interfaces/user.interface";
import { formatDate } from "@angular/common";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  endpoint = environment.apiUrl;

  constructor(
    private http: HttpClient,
    public router: Router,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private translationService: TranslateService,
    private guiStore: GuiStore
  ) {
  }

  /**
   * @description Real auth method for testing purposes
   * @param username
   * @param password
   * @return void: if authenticated - set localStorage object with user's credentials
   */
  signIn(username: string, password: string): any {
    return this.http
      .post<any>(`${this.endpoint}${NAVIGATION.login.api}`, { username, password })
      .pipe(
        map((user) => {
          if (user) {
            const parsedUserFromJwt = JSON.parse(atob(user.access_token.split(".")[1]));
            user.language = user.language ?? config.languages.defaultLanguage;
            user.issuedOn = formatDate(new Date(parsedUserFromJwt.iat * 1000), "YYYY-MM-dd HH:mm:ss", "en_US");
            user.expiredIn = formatDate(new Date(parsedUserFromJwt.exp * 1000), "YYYY-MM-dd HH:mm:ss", "en_US");
            user.username = parsedUserFromJwt.username;
            if (user.role === Roles.Experementor) {
              user.account_id = config.experimentor_id;
            }
            if (user.role === Roles.Admin) {
              user.allowNotifications = false;
            }
            this.localStorageService.setItem(config.localStorageObject, user);
            this.guiStore.setLanguage(user.language);
          }
          return user;
        })
      );
  }

  /**
   * @description Getting user's credentials
   * @return localStorage object with user's credentials
   */
  get getUser(): IUserInterface {
    return this.localStorageService.getItem(config.localStorageObject);
  }

  /**
   * @description Getting user's role
   * @return void
   */
  get getRole(): Roles {
    const parsedUserFromJwt = config.roleCheckJwt
      ? JSON.parse(atob(this.getUser?.access_token.split(".")[1]))
      : this.getUser;
    return this.isLoggedIn ? parsedUserFromJwt.role : "";
  }

  /**
   * @description Check that user's role is pilot (experimenter)
   * @return boolean
   */
  get isPilot(): boolean {
    return this.isLoggedIn && this.getRole === Roles.Experementor;
  }

  /**
   * @description Checking users role
   * @return boolean
   */
  hasRole(role: Roles): boolean {
    return this.isLoggedIn && this.getRole === role;
  }

  /**
   * @description Checking that user is logged in
   * @return boolean: if user's token is exists in the localStorage object
   */
  get isLoggedIn(): boolean {
    if (!!this.getUser?.access_token) {
      const parsedUserFromJwt = JSON.parse(atob(this.getUser?.access_token.split(".")[1]));
      return new Date().getTime() <= parsedUserFromJwt.exp * 1000;
    }
    return !!this.getUser?.access_token;
  }

  /**
   * @description Making logout from the project
   * @return void: localStorage clean up && navigate to login page
   */
  doLogout(): void {
    if (this.isLoggedIn) {
      this.logoutRequest().catch(e => {
        console.error("[Error][logoutRequest]", e?.message);
      }).finally(async () => {
        this.localStorageService.removeItem(config.localStorageObject);
        await this.router.navigate([NAVIGATION.login.path]);
      });
    }
  }

  /**
   * @description Making logout request for logging events
   * @return Promise<void> result of logout event log
   */
  logoutRequest(): Promise<void> {
    return this.http.get<any>(`${this.endpoint}${NAVIGATION.logout.api}`).pipe(first()).toPromise();
  }
}
