import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAccount } from '@pages/accounts/accounts/accounts.interface';
import { IAccountPerson } from '@pages/accounts/persons/persons.interface';
import { IAccountContact } from '@pages/accounts/contacts/contacts.interface';
import { IAccountSensor } from '@pages/accounts/sensors/sensors.interface';
import { Roles } from '@app/core/interfaces/user.interface';

@Component({
  selector: 'app-sub-options-buttons',
  templateUrl: './sub-options-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubOptionsButtonsComponent {
  @Input() item: IAccount | IAccountPerson | IAccountContact | IAccountSensor;
  @Input() label: string;
  @Input() route: string;
  @Input() roles: Roles[];
  @Input() includedRoles: Roles[];
  Roles = Roles;

  constructor() {}
}
