/**
 * @Service Service for operating with modal's windows
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { Message } from 'primeng/api';
import { NotificationService } from '@services/notification.service';
import { delay } from '@app/core/utils/async.utility';
import { TranslateService } from '@ngx-translate/core';
import { GuiStore } from '@stores/gui.store';
import { Observable, Subject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

// Default modal settings if no entry parameters specified
const defaultModal = {
    position: '',
    message: 'Message',
    dynamic: '',
    header: 'Header',
    icon: 'pi pi-info-circle',
    accept: {
        notification: {
            severity: 'info',
            summary: 'Confirmed',
            detail: 'Record deleted'
        },
        callback: () => {}
    },
    reject: {
        reject: {
            notification: {
                severity: 'error',
                summary: 'Rejected',
                detail: 'You have rejected'
            },
            callback: () => {}
        },
        cancel: {
            notification: {
                severity: 'warn',
                summary: 'Cancelled',
                detail: 'You have cancelled'
            },
            callback: () => {}
        }
    }
};

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    messages: Message[] = [];
    position: string;
    navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private modalService: ConfirmationService,
        private notification: NotificationService,
        private translationService: TranslateService,
        private guiStore: GuiStore
    ) {}

    /**
     * @description Display modal window with specified modalOptions
     * @param: modalOptions: options for getting specific look of the modal window
     * @return: void: show modal
     */
    showModal(modalOptions): void {
        const {
            accept,
            icon,
            reject,
            type,
            header,
            message,
            position,
            acceptVisible,
            rejectVisible,
            acceptLabel,
            rejectLabel,
            styleClass,
            defaultFocus,
            acceptButtonStyleClass,
            rejectButtonStyleClass
        } = modalOptions;
        const translation = this.translationService.instant(`MODAL.${type}`);
        this.position = position ?? '';
        this.guiStore.setModalOptions(styleClass ? { styleClass } : null);
        this.modalService.confirm({
            defaultFocus: defaultFocus ?? 'accept',
            header: header || defaultModal.header,
            message: message || defaultModal.message,
            icon: icon || defaultModal.icon,
            acceptLabel: acceptLabel || translation.buttons.yes,
            rejectLabel: rejectLabel || translation.buttons.no,
            acceptVisible: acceptVisible ?? true,
            rejectVisible: rejectVisible ?? true,
            acceptButtonStyleClass: acceptButtonStyleClass ?? '',
            rejectButtonStyleClass: rejectButtonStyleClass ?? '',
            accept: () => {
                accept.callback();
                delay(100).then(() =>
                    this.notification.addSingle({ severity: 'info', ...translation.accept.notification })
                );
            },
            reject: (t) => {
                if (type && reject) {
                    switch (t) {
                        case ConfirmEventType.REJECT:
                            this.notification.addSingle({
                                severity: 'warn',
                                ...translation.reject.reject.notification
                            });
                            break;
                        case ConfirmEventType.CANCEL:
                            this.notification.addSingle({
                                severity: 'warn',
                                ...translation.reject.cancel.notification
                            });
                            break;
                    }
                }
            },
            key: 'positionDialog'
        });
    }

    /**
     * @description Display modal window when user trying to leave a page without saving data
     * @param: form: options for getting specific look of the modal window
     * @return: boolean | Observable<boolean> | Promise<boolean>
     */
    exitModal(form: UntypedFormGroup): boolean | Observable<boolean> | Promise<boolean> {
        if (!form.pristine) {
            const modalOptions = {
                type: 'exit',
                defaultFocus: 'reject',
                acceptButtonStyleClass: 'p-button-danger',
                icon: 'pi pi-info-circle big red',
                header: this.translationService.instant(`MODAL.exit.header`),
                message: `<div class="p-error p-my-2">${this.translationService.instant(`MODAL.exit.message`)}</div>`,
                accept: {
                    callback: async () => {
                        this.navigateAwaySelection$.next(true);
                    }
                }
            };
            this.showModal(modalOptions);
            return this.navigateAwaySelection$;
        }
        return true;
    }
}
