/**
 * @Component Footer layout component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import {Component, OnInit} from '@angular/core';
// @ts-ignore
import packageJson from '@root/package.json';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public version: any;

    constructor() {
    }

    ngOnInit(): void {
        this.version = packageJson.version;
    }

}
