import { CypressExtension } from '@modules/Formly/extensions/components/cypress.extention';
import { applyEmailValidation } from '@modules/Formly/extensions/components/email.extension';
import { FormlyFieldConfig } from '@ngx-formly/core';

export const formlyExtensions = [
    {
        name: 'data-cy-extension',
        extension: CypressExtension
    },
    {
        name: 'email',
        extension: { prePopulate: applyEmailValidation }
    },
    {
        name: 'animation',
        extension: { onPopulate: animationExtension }
    }
];

function animationExtension(field: FormlyFieldConfig): void {
    if (field.wrappers && field.wrappers.includes('animation')) {
        return;
    }

    field.wrappers = ['animation', ...(field.wrappers || [])];
}
