/**
 * @Component Custom button component using prime-ng button
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-formly-field-button',
    template: `
    <div class="p-py-2 p-px-3">
      <button type="button" [label]="to.text" pButton [type]="to.type" (click)="onClick($event)"></button>
    </div>
  `,
})
export class FormlyFieldButtonComponent extends FieldType {
    onClick($event): void {
        if (this.to.onClick) {
            this.to.onClick($event);
        }
    }
}
