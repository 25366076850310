/**
 * @Module Pages shared modules
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@modules/shared.module';

import { SubNavComponent } from '@pages/../shared/components/sub-actions/sub-nav/sub-nav.component';
import { SubActionsButtonsComponent } from '@pages/../shared/components/sub-actions/sub-actions-buttons/sub-actions-buttons.component';
import { SubSearchComponent } from '@pages/../shared/components/sub-actions/sub-search/sub-search.component';
import { SubNewDeleteComponent } from '@pages/../shared/components/sub-actions/sub-new-delete/sub-new-delete.component';
import { SubSaveButtonComponent } from '@app/shared/components/sub-actions/sub-save-button/sub-save-button.component';
import { SubHeadingComponent } from '@pages/../shared/components/sub-actions/sub-heading/sub-heading.component';
import { SubFilterComponent } from '@pages/../shared/components/sub-actions/sub-filter/sub-filter.component';
import { IsActiveComponent } from '@app/shared/components/is-active/is-active.component';
import { SubFormActionsButtonsComponent } from '@app/shared/components/sub-actions/sub-form-actions-buttons/sub-form-actions-buttons.component';
import { SubColumnFilterDropDownComponent } from '@app/shared/components/sub-column-filter-dropdown/sub-column-filter-dropdown.component';
import { SubOptionsButtonsComponent } from '@app/shared/components/sub-actions/sub-options-buttons/sub-options-buttons.component';
import { ListViewComponent } from '@app/shared/components/list-view/list-view.component';
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';

import { PersonsStore } from '@pages/accounts/persons/persons.store';
import { PersonsService } from '@pages/accounts/persons/persons.service';
import { RoomsStore } from '@pages/accounts/rooms/rooms.store';
import { RoomsService } from '@pages/accounts/rooms/rooms.service';
import { RulesStore } from '@pages/accounts/rules/rules.store';
import { RulesService } from '@pages/accounts/rules/rules.service';
import { SensorsStore } from '@pages/accounts/sensors/sensors.store';
import { SensorsService } from '@pages/accounts/sensors/sensors.service';

@NgModule({
  declarations: [
    SubNavComponent,
    SubActionsButtonsComponent,
    SubSearchComponent,
    SubNewDeleteComponent,
    SubSaveButtonComponent,
    SubHeadingComponent,
    SubFilterComponent,
    IsActiveComponent,
    SubFormActionsButtonsComponent,
    SubColumnFilterDropDownComponent,
    SubOptionsButtonsComponent,
    ListViewComponent,
    SpinnerComponent
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    SubNavComponent,
    SubActionsButtonsComponent,
    SubSearchComponent,
    SubNewDeleteComponent,
    SubSaveButtonComponent,
    SubHeadingComponent,
    SubFilterComponent,
    IsActiveComponent,
    SubFormActionsButtonsComponent,
    SubColumnFilterDropDownComponent,
    SubOptionsButtonsComponent,
    ListViewComponent,
    SpinnerComponent
  ],
  providers: [
    PersonsStore,
    PersonsService,
    RoomsStore,
    RoomsService,
    RulesStore,
    RulesService,
    SensorsStore,
    SensorsService
  ]
})
export class PagesSharedModule {}
