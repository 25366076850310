/**
 * @Component Sensors component for displaying list of sensors
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

// Stores
import { GuiStore } from '@stores/gui.store';
import { AccountsStore } from '@pages/accounts/accounts/accounts.store';
import { SensorsStore } from '@pages/accounts/sensors/sensors.store';
import { RoomsStore } from '@pages/accounts/rooms/rooms.store';

// Services
import { ModalService } from '@services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { delay } from '@app/core/utils/async.utility';

// Helpers and interfaces
import { IAccountItemColumns } from '@pages/accounts/accounts/accounts.interface';
import { IAccountSensor } from '@pages/accounts/sensors/sensors.interface';
import { Roles } from '@app/core/interfaces/user.interface';
import { formatDate } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html'
})
export class SensorsComponent implements OnInit {
  accountId: string;
  items: IAccountSensor[];
  item: IAccountSensor;
  selectedItems: IAccountSensor[];
  cols: IAccountItemColumns[];
  loading: boolean;
  totalRecords: number;
  Roles = Roles;
  statusOptions = [
    { label: 'active', value: true },
    { label: 'inactive', value: false }
  ];

  deviceTypes = [
    { label: 'Load', value: 'load' },
    { label: 'Lavatory', value: 'lavatory' },
    { label: 'Tof', value: 'tof' },
    { label: 'Panic Button', value: 'panic_button' },
    { label: 'Proximity', value: 'proximity' },
    { label: 'Open Close', value: 'open_close' }
  ];

  roomTypes;

  constructor(
    public title: Title,
    public guiStore: GuiStore,
    public translationService: TranslateService,
    public accountsStore: AccountsStore,
    private sensorsStore: SensorsStore,
    private roomsStore: RoomsStore,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {}

  mapFields(data): void {
    this.items = data
      .map((el) => {
        el.room_id = this.roomsStore.rooms.find((it) => it.id === el.room_id)?.room_tag;
        el.last_seen_at =
          el.last_seen_at.search('1970') > -1
            ? ''
            : formatDate(new Date(el.last_seen_at), 'YYYY-MM-dd HH:mm:ss', 'en_US');
        return el;
      })
      .sort((a, b) => (a.id < b.id ? 1 : -1));
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.paramMap.get('id');
    this.roomsStore.fetchRooms(this.accountId).then((rooms) => {
      this.roomTypes = rooms.map((el) => ({
          label: el.room_tag,
          value: el.id
        }));
    });
    this.loading = true;
    this.sensorsStore
      .fetchSensors(this.accountId)
      .then((data) => {
        this.totalRecords = data.length;
        this.mapFields(data);
        this.loading = false;
      })
      .catch((_) => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });

    this.cols = [
      { name: 'id' },
      { name: 'tag' },
      { name: 'type', component: 'type', filter: true, custom: true },
      { name: 'version' },
      { name: 'room_id', component: 'room_id' },
      { name: 'last_seen_at', component: 'last_seen_at' },
      { name: 'enabled', component: 'enabled', styles: { 'text-align': 'center' } }
    ];

    delay(100).then(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.sensors'));
    });

    this.translationService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.sensors'));
    });
  }

  alertType(type: string): string {
    switch (type) {
      case 'gateway':
        return 'pi-wifi';
      case 'emergency':
        return 'pi-exclamation-triangle';
      case 'security':
        return 'pi-key';
    }
  }

  deleteSelectedItems(): void {
    const modal = {
      type: 'delete_item',
      header: this.translationService.instant(`MODAL.delete_item.header`),
      message: this.translationService.instant(`MODAL.delete_item.message`, {
        item: this.translationService.instant(`MODAL.selected_items`)
      }),
      accept: {
        callback: async () => {
          this.items = this.items.filter((val) => !this.selectedItems.includes(val));
          await this.sensorsStore.deleteSensors(
            this.accountId,
            this.selectedItems.map((el) => el.id)
          );
          this.totalRecords = this.sensorsStore.sensorsLength;
          this.selectedItems = null;
        }
      }
    };
    this.modalService.showModal(modal);
  }

  async deleteItem(item: IAccountSensor): Promise<void> {
    const modal = {
      type: 'delete_item',
      header: this.translationService.instant(`MODAL.delete_item.header`),
      message: this.translationService.instant(`MODAL.delete_item.message`, {
        item: item.id
      }),
      accept: {
        callback: async () => {
          await this.sensorsStore.deleteSensor(this.accountId, item.id);
          this.totalRecords = this.sensorsStore.sensorsLength;
          this.items = this.items.filter((val) => val.id !== item.id);
          this.item = null;
        }
      }
    };
    this.modalService.showModal(modal);
  }
}
