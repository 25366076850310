/**
 * @Component Notification filter component.
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Table } from 'primeng/table/table';

import { NotificationsStore } from '@pages/notifications/notifications.store';
import { RulesStore } from '@pages/accounts/rules/rules.store';

import { LocalStorageService } from '@app/core/services/local-storage.service';

import { alertTypes } from '@pages/accounts/sensors/alert-types';
import { IOptions } from '@pages/accounts/accounts/accounts.interface';
import {
  IFilterItems,
  IFilterSelected,
  IFilterTypes,
  IOptionsFilter
} from '@pages/notifications/notifications.interface';
import { config } from '@root/src/app/const';

interface ISelectedOption {
  key: string;
  value: string;
}

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterListComponent implements OnInit {
  @Input() options: { [key: string]: any };
  @Input() dataTable: Table;
  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();
  selected: ISelectedOption[] = [];
  items: IFilterItems[];
  filterTypes = IFilterTypes;
  stateOptions: IOptionsFilter[] = [
    { label: 'PAGES.notifications.state.open', value: 'open', color: 'green', background: 'green' },
    { label: 'PAGES.notifications.state.closed', value: 'closed', color: 'grey', background: 'grey' },
    { label: 'PAGES.notifications.state.inprogress', value: 'inprogress', color: '#bb9500', background: '#bb9500' }
  ];
  notificationTypesOptions: IOptionsFilter[] = [];
  ruleTypesOptions: IOptions[] = [];
  algoTypesOptions: IOptions[] = [];
  filtered: any = {};

  constructor(
    public cdr: ChangeDetectorRef,
    private notificationStore: NotificationsStore,
    private rulesStore: RulesStore,
    private storage: LocalStorageService
  ) {}

  populateFilters(clear = false): void {
    const itemsFromStorage = this.storage.getItemValue(config.pages.notifications.storageKeyOptions, 'filter');
    this.items?.forEach((el) => {
      this.filtered[el.key] = [];
      if (el?.default && !clear) {
        this.filtered[el.key] = typeof el.default === 'object' ? [...el.default] : [el.default];
      }
      if (itemsFromStorage && itemsFromStorage[el.key] && !clear) {
        this.filtered[el.key] = [...this.filtered[el.key], ...itemsFromStorage[el.key]];
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.notificationStore.fetchNotificationsTypes();
    await this.rulesStore.fetchRuleTypesAll();

    this.notificationTypesOptions = this.notificationStore.currentNotificationTypes.map((el) => ({
      value: el.id,
      label: 'PAGES.notifications.type.' + el.type_key,
      icon: alertTypes[el.type_key].icon,
      background: alertTypes[el.type_key].color
    }));

    this.ruleTypesOptions = this.rulesStore.ruleTypes.map((el) => ({
      value: el.id,
      label: 'PAGES.rules.rule_keys.' + el.type_key
    }));

    this.algoTypesOptions = this.rulesStore.algoTypes.map((el) => ({
      value: el.id,
      label: 'PAGES.rules.algo_keys.' + el.type_key
    }));

    this.items = [
      {
        key: 'type',
        type: 'buttons',
        options: this.notificationTypesOptions,
        multiple: true
      },
      {
        key: 'state',
        type: 'buttons',
        options: this.stateOptions,
        multiple: true,
        default: 'open'
      },
      {
        key: 'rule_type',
        type: 'select',
        options: this.ruleTypesOptions,
        multiple: true
      },
      {
        key: 'algo_type',
        type: 'select',
        options: this.algoTypesOptions,
        multiple: true
      }
    ];

    this.populateFilters();
    this.cdr.detectChanges();
  }

  isSelectedContains({ key, value }: ISelectedOption): boolean {
    return this.filtered[key].findIndex((el) => el === value) > -1;
  }

  pushSelected(selected: IFilterSelected): void {
    if (selected.type === IFilterTypes.select) {
      this.filtered[selected.key] = selected.value;
    } else {
      if (this.filtered[selected.key].includes(selected.value)) {
        this.filtered[selected.key] = this.filtered[selected.key].filter((el) => el !== selected.value);
      } else {
        this.filtered[selected.key] = [...this.filtered[selected.key], selected.value];
      }
    }
    this.filterChange.emit(this.filtered);
  }

  clearSelected(key: string): void {
    this.filtered[key] = [];
    this.filterChange.emit(this.filtered);
  }

  getSelectedValue(key: string): any {
    return this.filtered[key];
  }

  clearAll(): void {
    this.populateFilters(true);
    this.filterChange.emit(this.filtered);
  }
}
