import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-formly-field-input-set',
    template: `
        <ng-container [ngSwitch]="field.type">
            <formly-field
                *ngSwitchCase="'input'"
                [model]="field.model"
                [form]="form"
                [field]="field"
                [options]="options"
            >
            </formly-field>
            <formly-field
                *ngSwitchCase="'datetimepicker'"
                [model]="field.model"
                [form]="form"
                [field]="field"
                [options]="options"
            >
            </formly-field>
        </ng-container>
    `
})
export class FormlyFieldInputSetComponent extends FieldType implements OnInit {
    ngOnInit(): void {
        this.field.wrappers = [];
    }
}
