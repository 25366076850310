/**
 * @Store Sensors mobx store service for state management.
 * Interacts with sensors api service and saves state to observable variables.
 * @Project: TrendLines
 * @Version Mobx: 6.1.X
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { makeAutoObservable, toJS } from 'mobx';

import { AccountsStore } from '@pages/accounts/accounts/accounts.store';

import { TranslateService } from '@ngx-translate/core';
import { SensorsService } from '@pages/accounts/sensors/sensors.service';

import { IAccountSensor } from '@pages/accounts/sensors/sensors.interface';
import { IOptions, ISensorsOptions } from '@pages/accounts/accounts/accounts.interface';
import { ObjectUtils } from '@app/core/utils/objects.utility';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class SensorsStore {
  // ********** Observables ************* //

  $sensors: IAccountSensor[] = [];

  $sensorsLength: number;

  constructor(
    public translate: TranslateService,
    public accountsStore: AccountsStore,
    private sensorsService: SensorsService
  ) {
    makeAutoObservable(this);
  }

  // ********** Setters ************* //

  setSensors(res: IAccountSensor[]): void {
    this.$sensors = res;
  }

  // ********** Actions ************* //

  async fetchSensors(accountId: string): Promise<IAccountSensor[]> {
    try {
      const res = await this.sensorsService.getSensors(accountId);
      if (res) {
        this.setSensors(res.body);
        if (!this.accountsStore.account || '' + this.accountsStore.account?.id !== accountId) {
          await this.accountsStore.fetchAccount(accountId);
        }
        return res.body;
      }
    } catch (e) {
      console.log('Error fetching sensors');
    }
  }

  async fetchSensor(accountId: string, id: string): Promise<IAccountSensor> {
    try {
      const res = await this.sensorsService.getSensor(accountId, id);
      if (res) {
        if (!this.accountsStore.account || '' + this.accountsStore.account?.id !== accountId) {
          await this.accountsStore.fetchAccount(accountId);
        }
        return res;
      }
    } catch (e) {
      console.log('Error fetching sensors');
    }
  }

  async addSensor(accountId: string, body: IAccountSensor): Promise<HttpResponse<any>> {
    try {
      return await this.sensorsService.addSensor(accountId, body);
    } catch (e) {
      console.log('Error adding sensors');
    }
  }

  async editSensor(accountId: string, body: IAccountSensor): Promise<HttpResponse<any>> {
    try {
      const res = await this.sensorsService.editSensor(accountId, body);
      if (res) {
        await this.fetchSensors(accountId);
        return res;
      }
    } catch (e) {
      console.log('Error updating sensor');
    }
  }

  async deleteSensor(accountId: string, id: string): Promise<any> {
    try {
      const res = await this.sensorsService.deleteSensor(accountId, id);
      if (res) {
        await this.fetchSensors(accountId);
      }
    } catch (e) {
      console.log('Error delete sensor');
    }
  }

  async deleteSensors(accountId: string, ids: string[]): Promise<void> {
    try {
      for (const id of ids) {
        await this.sensorsService.deleteSensor(accountId, id);
      }
      await this.fetchSensors(accountId);
    } catch (e) {
      console.log('Error delete sensors');
    }
  }

  get sensors(): IAccountSensor[] {
    return toJS(this.$sensors);
  }

  get sensorsOptions(): ISensorsOptions[] {
    return this.sensors
      .filter((el) => Boolean(el.enabled))
      .map((el) => ({
        label: `${el.id} - ${el.tag} (${el.room_id || '-'})`,
        value: el.id,
        room_id: el?.room_id?.toString(),
        type: el?.type
      }));
  }

  get sensorsForList(): IOptions[] {
    return this.sensors.map((el) => ({
      label: el.tag,
      value: el.id
    }));
  }

  get sensorsIdMap(): any {
    return ObjectUtils.toHash(this.sensors, 'id', 'tag');
  }

  get sensorsLength(): number {
    return toJS(this.$sensorsLength);
  }
}
