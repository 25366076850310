<ng-container *appUserRole="[ Roles.Admin, includedRoles ]">
    <button
        *ngIf="allowEdit === undefined || allowEdit"
        [routerLink]="['./edit/' + item.id]"
        class="p-button-sm p-button-rounded p-button-success p-mr-1 p-ml-1"
        icon="pi pi-pencil"
        pButton
        pRipple>
    </button>
</ng-container>
<ng-container *appUserRole="[ Roles.Admin ]">
    <button
        *ngIf="allowDelete === undefined || allowDelete"
        (click)="deleteItem(item)"
        class="p-button-sm p-button-rounded p-button-warning p-mr-1 p-ml-1"
        icon="pi pi-trash"
        pButton
        pRipple>
    </button>
</ng-container>
<ng-container *appUserRole="[ Roles.Admin, Roles.CallCenterUser ]">
    <button
        *ngIf="allowView === undefined ||  allowView"
        [routerLink]="['./' + item.id]"
        class="p-button-sm p-button-rounded p-button-info p-mr-1 p-ml-1"
        icon="pi pi-search"
        pButton
        pRipple>
    </button>
</ng-container>
