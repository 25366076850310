/**
 * @Modules NGX-formly main module for Encapsulating all needed setting for
 * form's management library, used in the components
 * @depends: from prime-ng.module
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyFieldTabsComponent } from '@app/shared/modules/Formly/types/components/tabs.type.component';
import { TabViewModule } from 'primeng/tabview';
import { FormlyFieldButtonComponent } from '@app/shared/modules/Formly/types/components/button.type.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { InputMaskModule } from 'primeng/inputmask';
import { NgxMaskModule } from 'ngx-mask';
import { ColorPickerModule } from 'primeng/colorpicker';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { formlyValidationConfig } from '@modules/Formly/validators';
import { formlyTypes } from '@modules/Formly/types';
import { formlyWrappers } from '@modules/Formly/wrappers';
import { formlyExtensions } from '@modules/Formly/extensions';
import { registerTranslateExtension } from '@modules/Formly/extensions/components/translate.extention';

import { FormlyFieldSwitchComponent } from '@app/shared/modules/Formly/types/components/switch.type.component';
import { FormlyFieldRepeatingComponent } from '@modules/Formly/types/components/repeating.type.component';
import { FormlyFieldDropdownComponent } from '@modules/Formly/types/components/dropdown.type.component';
import { FormlyFieldMultiSelectComponent } from '@modules/Formly/types/components/multiselect.type.component';
import { FormlyFieldMessageComponent } from '@modules/Formly/types/components/message.type.component';
import { FormlyFieldInputComponent } from '@modules/Formly/types/components/input.type.component';
import { FormlyTimePickerComponent } from '@modules/Formly/types/components/timepicker.type.component';
import { AnimationWrapperComponent } from '@modules/Formly/wrappers/components/animation-wrapper.component';
import { FormlyFieldArrayComponent } from '@modules/Formly/types/components/array.type.component';
import { FormlyFieldLabelComponent } from '@modules/Formly/types/components/label.type.component';
import { FormlyDatePickerComponent } from '@modules/Formly/types/components/datepicker.type.component';
import { FormlyRadioGroupComponent } from '@modules/Formly/types/components/radio.type.component';
import { FormlyFieldInputSetComponent } from '@modules/Formly/types/components/inputset.type.component';
import { FormlyFieldTypesMultipleComponent } from '@modules/Formly/types/components/types.type.component';
import { FormlyFieldTooltipComponent } from '@modules/Formly/types/components/rule-tooltip.type.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SafeHtmlPipe } from '@app/core/pipes/safe-html.pipe';
import { FormlyFieldDoorComponent } from '@modules/Formly/types/components/door.component';
import { FormlyFieldColorComponent } from '@modules/Formly/types/components/color.type.component';
import { FormlyFieldFileComponent } from '@modules/Formly/types/components/file.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { FormlyFieldMultiSelectTooltipComponent } from '@modules/Formly/types/components/multiselect-tooltip.type.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { FormlyFieldInputPasswordComponent } from '@modules/Formly/types/components/password.type.component';
import { PasswordModule } from 'primeng/password';

@NgModule({
  declarations: [
    FormlyFieldTabsComponent,
    FormlyFieldButtonComponent,
    FormlyFieldSwitchComponent,
    FormlyFieldRepeatingComponent,
    FormlyFieldDropdownComponent,
    FormlyFieldMultiSelectComponent,
    FormlyFieldMultiSelectTooltipComponent,
    FormlyFieldMessageComponent,
    FormlyFieldInputComponent,
    FormlyFieldInputPasswordComponent,
    FormlyTimePickerComponent,
    FormlyFieldArrayComponent,
    FormlyFieldLabelComponent,
    AnimationWrapperComponent,
    FormlyDatePickerComponent,
    FormlyRadioGroupComponent,
    FormlyFieldInputSetComponent,
    FormlyFieldTypesMultipleComponent,
    FormlyFieldTooltipComponent,
    FormlyFieldDoorComponent,
    FormlyFieldColorComponent,
    FormlyFieldFileComponent,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    InputMaskModule,
    ConfirmPopupModule,
    OverlayPanelModule,
    CalendarModule,
    ButtonModule,
    DropdownModule,
    RadioButtonModule,
    ChipsModule,
    ColorPickerModule,
    InputSwitchModule,
    InputTextareaModule,
    PasswordModule,
    MultiSelectModule,
    FormlySelectModule,
    TranslateModule,
    FileUploadModule,
    ImageModule,
    NgxMaskModule.forRoot(),
    FormlyModule.forChild({
      types: [...formlyTypes],
      wrappers: [...formlyWrappers],
      extensions: [...formlyExtensions]
    })
  ],
  exports: [
    FormlyModule,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    InputMaskModule,
    PasswordModule,
    InputSwitchModule,
    RadioButtonModule,
    InputTextareaModule,
    FormlySelectModule,
    MultiSelectModule,
    ChipsModule,
    NgxMaskModule,
    CalendarModule,
    ColorPickerModule,
    FileUploadModule,
    ImageModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: formlyValidationConfig,
      deps: [TranslateService]
    },
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: registerTranslateExtension,
      deps: [TranslateService]
    }
  ]
})
export class NgxFormlyModule {}
