/**
 * @Service Update Rule Service with needed methods and functions for data transformations
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { PagesSharedModule } from '@pages/pages-shared.module';

import { RulesStore } from '@pages/accounts/rules/rules.store';

import { TranslateService } from '@ngx-translate/core';

import { UpdateRuleComponent } from '@pages/accounts/rules/update-rule/update-rule.component';
import { IOptions } from '@pages/accounts/accounts/accounts.interface';
import { IRuleTypeMetaData } from '@pages/accounts/rules/rules.interface';

@UntilDestroy()
@Injectable()
export class DtoUpdateService {
  constructor(private translate: TranslateService, private rulesStore: RulesStore) {}

  transformBeforeSave(item): any {
    const newItem = Object.assign({}, item);
    if (item['fix_threshold.low_value_unit'] === 'sd') {
      newItem['fix_threshold.low_value'] = Math.abs(item['fix_threshold.low_value']);
    }
    if (item['fix_threshold.high_value_unit'] === 'sd') {
      newItem['fix_threshold.high_value'] = Math.abs(item['fix_threshold.high_value']);
    }
    if (item['fix_threshold.base_value_unit'] === 'sd') {
      newItem['fix_threshold.base_value'] = Math.abs(item['fix_threshold.base_value']);
    }
    if (item['fix_threshold.base_value_unit'] === 'time' || item['fix_threshold.base_value_unit'] === 'percent') {
      newItem['fix_threshold.low_value'] = [];
      newItem['fix_threshold.low_value_unit'] = [];
      newItem['fix_threshold.high_value'] = [];
      newItem['fix_threshold.high_value_unit'] = [];
    }
    newItem.monitored_persons_ids = item?.monitored_persons_ids === '' ? [] : [item?.monitored_persons_ids];
    newItem.algorithm_type_id = this.rulesStore.algoIdByKey[item.algorithm_type_id]?.id;
    newItem.rule_type_id = this.rulesStore.ruleIdByKey[item.rule_type_id]?.id;

    newItem.fix_threshold.base_value =
      newItem.fix_threshold.base_value_unit !== 'time'
        ? Number(newItem.fix_threshold.base_value)
        : newItem.fix_threshold.base_value;
    newItem.fix_threshold.high_value =
      newItem.fix_threshold.base_value_unit !== 'time'
        ? Number(newItem.fix_threshold.high_value)
        : newItem.fix_threshold.high_value;
    newItem.fix_threshold.low_value =
      newItem.fix_threshold.base_value_unit !== 'time'
        ? Number(newItem.fix_threshold.low_value)
        : newItem.fix_threshold.low_value;

    delete newItem.room_id;
    delete newItem.computed_threshold;
    delete newItem.notification_type;
    delete newItem.algorithm_type;
    delete newItem.rule_type;
    delete newItem.created_at;
    delete newItem.updated_at;
    delete newItem.frequency;

    return newItem;
  }

  getMappedItem(item, data): void {
    item.algorithm_type_id = data.algorithm_type.type_key;
    item.rule_type_id = data.rule_type.type_key;
    item.notification_type_id = data.notification_type.id;
    item.computed_threshold.threshold.base_value = +data?.computed_threshold?.threshold?.base_value.toFixed(2);
    item.time_start = item.time_start.substr(0, 5);
    item.time_end = item.time_end.substr(0, 5);
    item.monitored_persons_ids = item.monitored_persons_ids[0];
  }

  ruleTypeOptions() {
    return this.rulesStore.ruleTypes.map((el) => ({
      label: el.type_name,
      value: el.type_key
    }));
  }

  algorithmTypeOptions(form, item): Observable<any> {
    return form.get('rule_type_id').valueChanges.pipe(
      startWith((item?.rule_type_id as string) || ''),
      map((typeKey) => {
        const defaultOption = [{ label: this.translate.instant('DEFAULTS.select_option'), value: '' }];
        const algoTypeByRuleId = this.rulesStore.mapping.filter((val) => val.ruleType.type_key === typeKey);
        return typeKey && algoTypeByRuleId?.length > 0
          ? [
              ...defaultOption,
              ...this.rulesStore.mapping
                .filter((val) => val.ruleType.type_key === typeKey)
                ?.map((el) => ({ label: el.algorithmType.type_name, value: el.algorithmType.type_key }))
            ]
          : defaultOption;
      })
    );
  }

  limitValidation(ctx: UpdateRuleComponent): void {
    const lowControl = ctx.form.get('fix_threshold.low_value');
    const highControl = ctx.form.get('fix_threshold.high_value');
    const lowUnitControl = ctx.form.get('fix_threshold.low_value_unit');
    const highUnitControl = ctx.form.get('fix_threshold.high_value_unit');

    const useComputed = ctx.item?.use_computed_threshold;
    const baseUnit = ctx.item.fix_threshold.base_value_unit;
    const low = +ctx.item?.fix_threshold.low_value;
    const lowUnit = ctx.item?.fix_threshold.low_value_unit;
    const high = +ctx.item?.fix_threshold.high_value;
    const highUnit = ctx.item?.fix_threshold.high_value_unit;
    let base;
    if (useComputed) {
      base = ctx.item?.computed_threshold?.threshold?.base_value || 0;
    } else {
      base = ctx.item?.fix_threshold?.base_value || 0;
    }

    if (baseUnit === 'time' || baseUnit === 'percent') {
      return;
    }
    if (lowUnit === highUnit) {
      if (high <= base) {
        if (lowUnit === 'percent' || lowUnit === 'sd' || highUnit === 'sd') {
          lowControl.markAsTouched();
          lowUnitControl.markAsUntouched();
          highControl.markAsTouched();
          highUnitControl.markAsTouched();
          return;
        }
        highControl.setErrors({ highLessBase: true });
        highControl.markAsTouched();
      }
      if (low >= base) {
        if (lowUnit === 'percent' || lowUnit === 'sd' || highUnit === 'sd') {
          lowControl.markAsTouched();
          lowUnitControl.markAsUntouched();
          highControl.markAsTouched();
          highUnitControl.markAsTouched();
          return;
        }
        lowControl.setErrors({ lowMoreBase: true });
        lowControl.markAsTouched();
      }
      if (high <= low) {
        if (lowUnit === 'sd' || highUnit === 'sd') {
          lowControl.markAsTouched();
          lowUnitControl.markAsUntouched();
          highControl.markAsTouched();
          highUnitControl.markAsTouched();
          return;
        }
        highControl.setErrors({ highLessLow: true });
        highControl.markAsTouched();
      }

      lowUnitControl.reset(lowUnitControl.value);
      highUnitControl.reset(highUnitControl.value);
    } else {
      lowUnitControl.setErrors({ unitsLowDiffError: true });
      highUnitControl.setErrors({ unitsHighDiffError: true });
    }
    if (low === null || low === undefined || low.toString() === '') {
      lowControl.setErrors({ required: true });
    }

    if (high === null || high === undefined || high.toString() === '') {
      highControl.setErrors({ required: true });
    }
    lowControl.markAsTouched();
    lowUnitControl.markAsUntouched();
    highControl.markAsTouched();
    highUnitControl.markAsTouched();
  }

  dependantOptions(field, item, form): void {
    const algoTypeControl = form.get('algorithm_type_id');
    const metadataOpts = {
      notify_policy: 'notify_policy_opts',
      'fix_threshold.base_value_unit': 'base_thr_units_opts',
      'fix_threshold.low_value_unit': 'lower_thr_units_opts',
      'fix_threshold.high_value_unit': 'upper_thr_units_opts',
      'computed_threshold.threshold.base_value_unit': 'base_thr_units_opts',
      'computed_threshold.threshold.low_value_unit': 'lower_thr_units_opts',
      'computed_threshold.threshold.high_value_unit': 'upper_thr_units_opts'
    };

    const setUnitValues = (aid: string): void => {
      switch (field.key) {
        case 'fix_threshold.base_value_unit':
          field.formControl.setValue(algoType(aid)[0]?.value ?? '');
          break;
        case 'fix_threshold.low_value_unit':
          // field.formControl.setValue(form.get('fix_threshold.base_value_unit')?.value);
          break;
        case 'fix_threshold.high_value_unit':
          // field.formControl.setValue(form.get('fix_threshold.base_value_unit')?.value);
          break;
        case 'computed_threshold.threshold.base_value_unit':
          field.formControl.setValue(form.get('fix_threshold.base_value_unit')?.value);
          break;
        default:
          break;
      }
    };
    const algoType = (aid: string): IOptions[] =>
      this.rulesStore.algoTypes
        .find((el) => el.type_key === aid)
        ?.type_metadata[metadataOpts[field.key]]?.map((it) => {
          const translation =
            field.key !== 'notify_policy'
              ? this.translate.instant('PAGES.rules.base_units.' + it)
              : this.translate.instant('PAGES.rules.notify_policy.' + it);
          return {
            label: translation,
            value: it
          };
        }) || [];
    field.templateOptions.options = algoTypeControl.valueChanges.pipe(
      untilDestroyed(this),
      startWith(item.algorithm_type_id as number),
      tap(setUnitValues),
      map((aid: string) => {
        if (algoType(aid).length > 0) {
          const currentFieldValue = field.formControl.value;
          !Boolean(currentFieldValue) && field.formControl.setValue(algoType(aid)[0].value);
          return [...algoType(aid)];
        }
        return [
          {
            label: this.translate.instant('DEFAULTS.select_option'),
            value: ''
          }
        ];
      })
    );
  }

  algoType(aid): string {
    return this.rulesStore.algoTypes.find((el) => el.type_key === aid)?.type_name || '';
  }

  algoTypeMetaData(aid): IRuleTypeMetaData {
    return this.rulesStore.algoTypes.find((el) => el.type_key === aid)?.type_metadata || {};
  }

  getMappingItemKey(key: string, type: 'rule' | 'algo'): any {
    return {
      rule: this.rulesStore.ruleTypes?.find((el) => el.type_key === key) || {},
      algo: this.rulesStore.algoTypes?.find((el) => el.type_key === key) || {}
    }[type];
  }

  generateTooltip(item: any): any {
    const translateAlgo = (key) => this.translate.instant(`PAGES.rules.algo_keys.${key}`);
    const ruleType = this.getMappingItemKey(item?.rule_type_id, 'rule');
    const algoType = this.getMappingItemKey(item?.algorithm_type_id, 'algo');
    const algoKey = algoType?.type_key ? translateAlgo(algoType?.type_key) : '';
    const sensorsTypeFilter = algoType?.type_metadata?.sensor_type_filter?.toString();
    const notifyPolicy = item?.notify_policy
      ? this.translate.instant(`PAGES.rules.notify_policy.${item?.notify_policy}`)
      : '';
    const notificationTypeKey = this.rulesStore.notificationTypes.find(
      (el) => el.id === item?.notification_type_id
    )?.type_key;
    const notificationType = notificationTypeKey
      ? this.translate.instant(`PAGES.notifications.type.${notificationTypeKey}`)
      : '';
    const baseLineUnit = item?.fix_threshold?.base_value_unit
      ? this.translate.instant(`PAGES.rules.base_units_short.${item?.fix_threshold?.base_value_unit}`)
      : '';

    const ruleKeyString = `${ruleType?.type_key}:${algoType?.type_key}`;

    return this.translate.instant(`PAGES.rules.tooltip.${algoType?.type_key ? ruleKeyString : 'default'}`, {
      algoKey,
      baseLineUnit,
      sensorsTypeFilter,
      notificationType,
      notifyPolicy
    });
  }
}
