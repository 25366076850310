export interface IReport {
  date?: string; // YYYY-MM-DD
  room_id?: number; // room_id
  person_id?: number; // person_id
  sensor_id?: number;
  times?: IReportTime[];
  groups?: IReportGroupTime[];
}

export interface IReportGroupTime {
  start: string;
  end: string;
}

export interface IReportTime {
  start?: string;
  end?: string;
  sensor_id?: number;
  sensor_name?: string;
  direction_pxls?: string;
  annotation?: string;
}

export interface IReportRequest {
  max_records?: string;
  fusion_time_start?: string;
  fusion_time_end?: string;
  fusion_date?: string;
  persons_ids?: string;
  account_ids?: string;
  sensor_ids?: string;
  legacy?: "0" | "1";
  debug?: "0" | "1";
}

export interface IOptionsReports {
  label: string;
  value: string;
  id?: string;
}

export interface IReportFilter {
  account?: number;
  person?: number;
  date?: string | Date;
  lang?: string;
}

export interface IRoomFilter {
  rooms?: string[];
}

export interface INotificationsReport {
  notification_type_key: string;
  timesData: INotificationTimesData[] | [];
}

export interface INotificationTimesData {
  id: string;
  type: string;
  rule_type_id: number;
  rule_type_key: string;
  notification_type: string;
  notification_type_key?: string;
  date_time: string;
  account_id: number;
  account_name: string;
  resident_id: number;
  sensor_id: string;
  time_start: string;
  resident_name: string;
  status: string;
  description: string;
  actual_value?: string;
  actual_value_unit?: string;
}

// noinspection HtmlDeprecatedAttribute
export const activitySvg = `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px"
viewBox="0 0 24 24" width="24px" fill="#000000"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><g><path
d="M5,15h14V9H5V15z M7,11h10v2H7V11z"/><rect height="3" width="2" x="11" y="2"/><rect height="2"
transform="matrix(0.7046 -0.7096 0.7096 0.7046 1.1814 15.2381)" width="2.54" x="17.62" y="5.2"/>
<rect height="3" width="2" x="11" y="19"/><polygon points="17.29,17.71 19.08,19.51 20.5,18.09 18.7,16.3"/>
<rect height="2.53" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.8904 5.4222)" width="1.99" x="4.1" y="4.93"/>
<rect height="2" transform="matrix(0.7096 -0.7046 0.7046 0.7096 -11.1263 8.7897)" width="2.54" x="3.83" y="16.89"/>
</g></g></svg>`;

export interface IReportChartSize {
  width: number;
  height: number;
}

export interface IReportChartMargins {
  top: number;
  right: number;
  bottom;
  left: number;
}

export interface IReportChartConfig {
  defaultColor?: string;
  barWidth?: number;
  barHeight: number;
  barStrokeWidth: number;
  barStrokeColor: string;
  minBarWidth: number;
  hours: number;
  delay: number;
  duration: number;
  iconHeight: number;
  outerMargins?: number;
  verticalGridOpacity?: number;
  zoomStep: number;
  scaleMin: number;
  scaleMax: number;
  modalWidth: number;
  tickPadding: number;
  scrollWidth: number;
  modalShiftPosition: number;
  yAxisTickSizeInner: number;
  yAxisFontSize: string;
  xAxisTextHeight: number;
  xAxisTextFormat: string;
  xAxisFontSize: string;
  barCombinedOpacity?: number;
  clipRightPadding: number;
  clipLeftPadding: number;
}

export interface IReportStorageOptions {
  showGroups?: boolean;
  showColors?: boolean;
}

export interface ILoadingReports {
  activity: boolean;
  notifications: boolean;
}
