/**
 * @Module Global app routing module
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NonAuthGuard } from '@app/core/guards/nonAuth.guard';
import { AuthGuard } from '@app/core/guards/auth.guard';

import { LoginPageComponent } from '@auth/login-page/login-page.component';
import { LayoutContainerComponent } from '@layout/layout-container/layout-container.component';
import { Page404Component } from '@layout/404/404-page.component';
import { NAVIGATION } from '@app/const';

const routes: Routes = [
    {
        path: '',
        redirectTo: NAVIGATION.login.path,
        pathMatch: 'full'
    },
    {
        path: NAVIGATION.login.url,
        canActivate: [NonAuthGuard],
        component: LayoutContainerComponent,
        children: [{ path: '', component: LoginPageComponent }]
    },
    {
        path: NAVIGATION.pages.url,
        component: LayoutContainerComponent,
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        children: [{ path: '', loadChildren: () => import('@pages/pages.module').then((m) => m.PagesModule) }]
    },
    {
        path: '**',
        component: LayoutContainerComponent,
        children: [{ path: '', component: Page404Component }]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
