/**
 * @Component app entry point including prime-ng configuration, setting default language, router-outlet etc.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { GuiStore } from '@stores/gui.store';
import { AuthService } from '@app/core/services/auth.service';
import { config } from '@app/const';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'TrendLines';

  constructor(private primengConfig: PrimeNGConfig, private guiStore: GuiStore, private authService: AuthService) {}

  ngOnInit(): void {
    this.guiStore.setLanguage(
      this.authService.isLoggedIn ? this.authService.getUser.language : config.languages.defaultLanguage
    );
    this.primengConfig.ripple = true;
  }
}
