/**
 * @Component Account's component. Main entry point for managing and displaying accounts
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

// Stores
import { GuiStore } from '@stores/gui.store';
import { AccountsStore } from '@pages/accounts/accounts/accounts.store';
import { PersonsStore } from '@pages/accounts/persons/persons.store';
import { ContactsStore } from '@pages/accounts/contacts/contacts.store';

// Services
import { ModalService } from '@services/modal.service';
import { ConfirmationService } from 'primeng/api';
import { NotificationService } from '@services/notification.service';

// Helpers and interfaces
import { delay } from '@app/core/utils/async.utility';
import { IAccount, IAccountItemColumns } from '@pages/accounts/accounts/accounts.interface';
import { Roles } from '@app/core/interfaces/user.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html'
})
export class AccountsComponent implements OnInit {
  public items: IAccount[];
  public item: IAccount;
  public selectedItems: IAccount[];
  public cols: IAccountItemColumns[];
  public loading: boolean;
  public totalRecords: number;
  public numberResidentsOptions = [
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 }
  ];
  public statusOptions = [
    { label: 'active', value: true },
    { label: 'inactive', value: false }
  ];
  public showMultipleOptions = false;
  public roles = Roles;

  constructor(
    public title: Title,
    public guiStore: GuiStore,
    public translationService: TranslateService,
    public accountsStore: AccountsStore,
    private personsStore: PersonsStore,
    private contactsStore: ContactsStore,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private modalService: ModalService
  ) {}

  mapFields(data): void {
    this.items = data
      .map((el) => {
        el.owner_name = el.owner_first_name + ' ' + el.owner_last_name;
        el.service_provider_id = el?.monitored_persons.length > 0 ? el.monitored_persons[0].service_provider_id : null;
        el.address = el.owner_address_street + ', ' + el.owner_address_city + ', ' + el.owner_address_country;
        el.number_of_residents = el.monitored_persons?.length || 0;
        return el;
      })
      .sort((a, b) => (a.id < b.id ? 1 : -1));
  }

  async isAllowedToDelete(item: IAccount): Promise<boolean> {
    const isPersonsAttached = await this.personsStore.fetchPersons(item.id, false);
    const isContactsAttached = await this.contactsStore.fetchContacts(item.id, false);
    return isPersonsAttached?.length === 0 && isContactsAttached?.length === 0;
  }

  ngOnInit(): void {
    this.loading = true;
    this.accountsStore
      .fetchAccounts()
      .then((data) => {
        this.totalRecords = data.length;
        this.mapFields(data);
        this.loading = false;
      })
      .catch((_) => {
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });

    this.cols = [
      { name: 'id', styles: { width: '5rem', 'text-align': 'center' } },
      { name: 'service_provider_id' },
      { name: 'owner_name' },
      { name: 'owner_home_phone' },
      { name: 'owner_cell_phone' },
      { name: 'address' },
      {
        name: 'number_of_residents',
        styles: { 'text-align': 'center' },
        filter: true
      },
      {
        name: 'is_active',
        styles: { 'text-align': 'center' },
        component: 'is_active',
        filter: true,
        type: 'boolean'
      },
      { name: 'notes', component: 'notes' }
    ];

    delay(100).then(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.accounts'));
    });

    this.translationService.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.title.setTitle(this.translationService.instant('MENU.accounts.accounts'));
    });
  }

  deleteSelectedItems(): void {
    const modal = {
      type: 'delete_item',
      header: this.translationService.instant(`MODAL.delete_item.header`),
      message: this.translationService.instant(`MODAL.delete_item.message`, {
        item: this.translationService.instant(`MODAL.selected_items`)
      }),
      accept: {
        callback: async () => {
          this.items = this.items.filter((val) => !this.selectedItems.includes(val));
          await this.accountsStore.deleteAccounts(this.selectedItems.map((el) => el.id));
          this.totalRecords = this.accountsStore.accountsLength;
          this.selectedItems = null;
        }
      }
    };
    this.modalService.showModal(modal);
  }

  async deleteItem(item: IAccount): Promise<void> {
    let modal;
    if (await this.isAllowedToDelete(item)) {
      modal = {
        type: 'delete_item',
        header: this.translationService.instant(`MODAL.delete_item.header`),
        message: this.translationService.instant(`MODAL.delete_item.message`, {
          item: item.owner_first_name + ' ' + item.owner_last_name
        }),
        accept: {
          callback: async () => {
            await this.accountsStore.deleteAccount(item.id);
            this.totalRecords = this.accountsStore.accountsLength;
            this.items = this.items.filter((val) => val.id !== item.id);
            this.item = null;
          }
        }
      };
    } else {
      modal = {
        type: 'delete_item',
        header: this.translationService.instant(`MODAL.delete_item.header`),
        message:
          '<div class="p-error">' +
          this.translationService.instant(`MODAL.delete_item.cant_delete_account`, {
            item: '"' + item.owner_first_name + ' ' + item.owner_last_name + '"'
          }) +
          '</div>',
        acceptVisible: false
      };
    }
    this.modalService.showModal(modal);
  }
}
