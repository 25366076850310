/**
 * @Modules Global layout module including according components
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '@layout/layout-container/header/header.component';
import { FooterComponent } from '@layout/layout-container/footer/footer.component';
import { LanguageSelectorComponent } from '@layout/layout-container/language-selector/language-selector.component';
import { MenuComponent } from '@layout/layout-container/menu/menu.component';
import { LayoutContainerComponent } from '@layout/layout-container/layout-container.component';
import { SharedModule } from '@modules/shared.module';
import { LogoComponent } from '@layout/layout-container/logo/logo.component';
import { AuthMenuComponent } from '@layout/layout-container/auth-menu/auth-menu.component';
import { AlertComponent } from '@layout/layout-container/alert/alert.component';
import { AlertModalComponent } from './alert/alert-modal/alert-modal.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        MenuComponent,
        AuthMenuComponent,
        LanguageSelectorComponent,
        LayoutContainerComponent,
        LogoComponent,
        AlertComponent,
        AlertModalComponent
    ],
    imports: [CommonModule, SharedModule]
})
export class LayoutContainerModule {}
