/**
 * @Component Custom dropdown component using prime-ng dropdown
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-formly-field-dropdown',
  template: `
    <div class="p-d-flex p-flex-column">
      <p-dropdown
        [id]="field.key.toString()"
        [placeholder]="to.placeholder || ''"
        [options]="to.options | formlySelectOptions: field | async"
        [formControl]="fControl"
        [formlyAttributes]="field"
        [editable]="to.editable"
        [optionLabel]="to.optionLabel"
        [showClear]="to.showClear"
        [filter]="to.filter"
        [optionDisabled]="to.optionDisabled"
        filterBy="label"
        (onChange)="to.change && to.change(field, $event)"
      >
      </p-dropdown>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFieldDropdownComponent extends FieldType {
  defaultOptions = {
    templateOptions: { options: [] }
  };

  public get fControl() {
    return this.formControl as UntypedFormControl;
  }
}
