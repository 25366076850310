/**
 * @Service Notification service for global displaying a toast bar at the any position of the screen (bottom by default)
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import {Injectable} from '@angular/core';
import {Message, MessageService} from 'primeng/api';

const defaultMessage: Message = {
    severity: 'info',
    summary: 'Title',
    detail: 'Detail',
    life: 2000,
};

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    message: Message = defaultMessage;

    constructor(private messageService: MessageService) {
    }

    /**
     * @description Display single toast bar
     * @param: message: options for getting specific look of the notification bar
     * @return: void: add notification bar to the que and display it
     */
    addSingle(message: Message): void {
        this.messageService.add(message);
    }

    /**
     * @description Clear all notification bars from the screen
     * @return: void: clear que to stop displaying all notification bars
     */
    clear(): void {
        this.messageService.clear();
    }
}
