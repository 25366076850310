/**
 * @constants default constants of the project
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { ELanguages, IConfig, INavigationInterface } from '@app/const/config.interface';

export const config: IConfig = {
  localStorageObject: 'currentUserData',
  allowNotifications: false,
  allowReports: true,
  roleCheckJwt: true,
  experimentor_id: 64,
  tableDefaults: {
    recordsPerPage: 10,
    order_by: 'created_at'
  },
  uiLoader: {
    bgsColor: '#9D28B0',
    bgsOpacity: 0.9,
    bgsPosition: 'center-center',
    bgsSize: 80,
    bgsType: 'cube-grid',
    blur: 0,
    delay: 0,
    fastFadeOut: true,
    fgsColor: 'red',
    fgsPosition: 'center-center',
    fgsSize: 60,
    fgsType: 'ball-spin',
    gap: 24,
    logoPosition: 'center-center',
    logoSize: 120,
    logoUrl: '',
    masterLoaderId: 'master',
    overlayBorderRadius: '0',
    overlayColor: 'rgba(40,40,40,0.8)',
    pbColor: 'red',
    pbDirection: 'ltr',
    pbThickness: 1,
    hasProgressBar: true,
    text: '',
    textColor: '#FFFFFF',
    textPosition: 'center-center',
    maxTime: -1,
    minTime: 300
  },
  storeSettings: {
    useProxies: 'never',
    enforceActions: 'always',
    computedRequiresReaction: false,
    reactionRequiresObservable: false,
    observableRequiresReaction: false,
    disableErrorBoundaries: false,
    isolateGlobalState: false
  },
  languages: {
    defaultLanguage: ELanguages.en,
    list: [ELanguages.en, ELanguages.he],
    path: 'assets/i18n/'
  },
  pages: {
    notifications: {
      storageKeyOptions: 'notificationSettings',
      storageType: 'local',
      storageKeyTable: 'notificationTable'
    },
    reports: {
      storageKeyOptions: 'reportsOptions',
      showGroupedBars: true,
      showColors: true
    },
    persons: {
      limitActivePersons: 2
    }
  }
};

Object.freeze(config);

export const NAVIGATION: INavigationInterface = {
  login: {
    url: 'login',
    path: '/login',
    api: '/auth/login'
  },
  logout: {
    api: '/auth/logout'
  },
  profile: {
    url: 'profile',
    path: '/profile',
    api: 'profile'
  },
  pages: {
    url: 'pages',
    path: '/pages',
    api: 'pages'
  },
  accounts: {
    url: 'accounts',
    path: './accounts',
    api: 'accounts'
  },
  rooms: {
    url: 'rooms',
    path: './rooms',
    api: 'rooms'
  },
  persons: {
    url: 'persons',
    path: './persons',
    api: 'persons'
  },
  contacts: {
    url: 'contacts',
    path: './contacts',
    api: 'contacts'
  },
  sensors: {
    url: 'sensors',
    path: './sensors',
    api: 'sensors'
  },
  rules: {
    url: 'rules',
    path: './rules',
    api: 'rules'
  },
  notifications: {
    url: 'notifications',
    path: './notifications',
    api: 'notifications'
  },
  reports: {
    url: 'reports',
    path: './reports',
    api: 'reports'
  },
  log: {
    url: 'log',
    path: './log',
    api: 'audit/log'
  },
  gateway: {
    url: 'gateway',
    path: './gateway',
    api: 'gateway'
  }
};
