<div class="p-d-flex p-ai-center layout-menubar">
    <button
        [ngClass]="{'p-button-help':this.urlSegment === nav.accounts.url || this.routePath.includes(nav.accounts.url)}"
        [routerLinkActiveOptions]="{exact: false}"
        [routerLinkActive]="'p-button-help'"
        [routerLink]="[nav.pages.path,nav.accounts.url]"
        class="p-button-secondary"
        label="{{ 'MENU.accounts.accounts' | translate }}"
        pButton
    >
    </button>
    <button
        [ngClass]="{'p-button-help':this.urlSegment === nav.notifications.url|| this.routePath.includes(nav.notifications.url) }"
        [routerLinkActiveOptions]="{exact: false}"
        [routerLinkActive]="'p-button-help'"
        [routerLink]="[nav.pages.path,nav.notifications.url]"
        class="p-button-secondary"
        label="{{ 'MENU.notifications.notifications' | translate }}"
        pButton
    >
    </button>
    <button
        [ngClass]="{'p-button-help':this.urlSegment === nav.reports.url|| this.routePath.includes(nav.reports.url) }"
        [routerLinkActiveOptions]="{exact: false}"
        [routerLinkActive]="'p-button-help'"
        [routerLink]="[nav.pages.path,nav.reports.url]"
        class="p-button-secondary"
        label="{{ 'MENU.reports.reports' | translate }}"
        pButton
    >
    </button>
    <ng-container *appUserRole="[ Roles.Admin ]">
        <button
            [ngClass]="{'p-button-help':this.urlSegment === nav.log.url|| this.routePath.includes(nav.log.url) }"
            [routerLinkActiveOptions]="{exact: false}"
            [routerLinkActive]="'p-button-help'"
            [routerLink]="[nav.pages.path,nav.log.url]"
            class="p-button-secondary"
            label="{{ 'MENU.log.log' | translate }}"
            pButton
        >
        </button>
    </ng-container>
</div>
