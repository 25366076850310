<div class="p-d-flex p-flex-column">
  <app-sub-nav [id]="accountId" [path]="'accounts'" class="p-mb-2 p-as-end"></app-sub-nav>
  <div class="p-d-flex p-jc-between p-ai-center p-mb-2">
    <app-sub-heading
      [item]="accountsStore.account"
      [accountId]="accountId"
      [path]="'rooms'">
    </app-sub-heading>
    <div class="page-actions">
      <app-sub-search (filterHandler)="dt.filterGlobal($event, 'contains')" [page]="'rooms'"></app-sub-search>
      <app-sub-new-delete (deleteHandler)="deleteSelectedItems()" [page]="'rooms'"
                          [selectedItems]="selectedItems"></app-sub-new-delete>
    </div>
  </div>
  <div>
    <div class="card">
      <p-table #dt
               [(selection)]="selectedItems"
               [columns]="cols"
               [globalFilterFields]="[
                          'room_tag',
                          'room_size.length',
                          'room_size.width',
                          'connected_to'
                     ]"
               [loading]="loading"
               [paginator]="true"
               [resizableColumns]="true"
               [rowHover]="true"
               [rowsPerPageOptions]="[10,25,50]"
               [rows]="10"
               [showCurrentPageReport]="false"
               [totalRecords]="totalRecords"
               [value]="items"
               dataKey="id"
               scrollHeight="100%"
               tableStyleClass="p-datatable p-datatable-sm p-datatable-striped p-datatable-customers p-datatable-gridlines">
        <ng-template let-columns pTemplate="header">
          <tr>
            <th style="width: 3rem" *appUserRole="[ Roles.Admin ]">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns" [ngStyle]="col.styles"
                pResizableColumn
                pSortableColumn="{{col.name}}">
              <div class="p-d-flex p-ai-center p-p-1">
                <p-sortIcon field="{{col.name}}"></p-sortIcon>
                {{ 'PAGES.rooms.fields.' + col.name | translate}}
              </div>
            </th>
            <th style="width: 7.2rem">
              <p-button
                (click)="dt.clear()"
                icon="pi pi-filter-slash">
              </p-button>
            </th>
          </tr>
        </ng-template>
        <ng-template let-columns="columns" let-item pTemplate="body">
          <tr class="items-row">
            <td style="width: 3rem" *appUserRole="[ Roles.Admin ]">
              <p-tableCheckbox [value]="item"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns" [ngStyle]="col.styles" [routerLink]="['./' + item.id]"
                class="td-row">
              <ng-container *ngIf="!col['component'] else components">
                {{item[col.name]}}
              </ng-container>
              <ng-template #components>
                <div [ngSwitch]="col['component']">
                  <div *ngSwitchCase="'room_size_length'">
                    {{ item.room_size?.length || '---'}}
                  </div>
                  <div *ngSwitchCase="'room_tag'" class="p-d-flex p-jc-lg-between">
                    {{ item.room_tag }}
                    <ng-container *ngIf="item?.color">
                      <div
                        [style.background]="item.color"
                        [style.width]="'15px'"
                        [style.height]="'15px'"
                        [style.borderRadius]="'50%'"
                        [style.margin]="'0 0.5rem'"
                      ></div>
                    </ng-container>
                  </div>
                  <div *ngSwitchCase="'room_size_width'">
                    {{ item.room_size?.width || '---'}}
                  </div>
                  <div *ngSwitchCase="'connected_to'">
                    <ng-container *ngFor="let room of item['connected_to']; let last = last; let first = first">
                      {{ room }}<span [hidden]="last || !room">, </span>
                    </ng-container>
                  </div>
                </div>
              </ng-template>
            </td>
            <td>
              <app-sub-actions-buttons
                (deleteHandler)="deleteItem(item)"
                [allowView]="false"
                [allowDelete]="!item.is_outdoor"
                [item]="item">
              </app-sub-actions-buttons>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td *appUserRole="[ Roles.Admin ]"></td>
            <td [attr.colspan]="cols?.length + 1">
              <div class="p-d-flex p-jc-center">{{ 'DEFAULTS.no_data_found' | translate }}</div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
