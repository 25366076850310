/**
 * @Store Account's mobx store service for state management.
 * Interacts with account's api service and saves state to observable variables.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { toJS, makeAutoObservable } from 'mobx';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { AccountsService } from '@pages/accounts/accounts/accounts.service';
import { DataService } from '@services/data.service';
import { AuthService } from '@app/core/services/auth.service';

import { IAccount, IOptions } from '@pages/accounts/accounts/accounts.interface';
import { IAccountPerson } from '@pages/accounts/persons/persons.interface';
import { Roles } from '@app/core/interfaces/user.interface';
import { config } from '@app/const';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class AccountsStore {
  // ********** Observables ************* //
  $account: IAccount = null;
  $accounts: IAccount[] = [];

  $countriesArray = [];

  $accountsLength: number;

  constructor(
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    private accountsService: AccountsService,
    private dataService: DataService,
    private authService: AuthService
  ) {
    makeAutoObservable(this);
  }

  // ********** Setters ************* //
  setCountries(res): void {
    this.$countriesArray = res;
  }

  setAccounts(res: IAccount[]): void {
    this.$accounts = res;
  }

  setAccount(res: IAccount): void {
    this.$account = res;
  }

  // ********** Actions ************* //

  fetchCountries(): Observable<any> {
    return this.countries?.length === 0
      ? this.dataService.getCountries().pipe(
          tap((el) => {
            this.setCountries(el);
          })
        )
      : of(this.countries);
  }

  fetchCities(country: string): Observable<any> {
    const foundCities = this.countries.find((el) => el.value === country)?.cities;
    return of(foundCities?.map((el) => ({ label: el, value: el })) ?? []);
  }

  async fetchAccounts(offset = null): Promise<IAccount[]> {
    try {
      // Is Experimenter check
      const pilot = this.authService?.getRole === Roles.Experementor;
      const account = pilot ? this.authService?.getUser?.account_id ?? config.experimentor_id : null;
      const res = await this.accountsService.getAccounts(offset);
      if (res) {
        const result = account ? res.body.filter((el) => +el.id === account) : res.body;
        this.setAccounts(result);
        return result;
      }
    } catch (e) {
      console.log('Error fetching accounts');
    }
  }

  async fetchAccount(id: string): Promise<IAccount> {
    try {
      const res = await this.accountsService.getAccount(id);
      if (res) {
        this.setAccount(res);
        return res;
      }
    } catch (e) {
      console.log('Error fetching accounts');
    }
  }

  async addAccount(body: IAccount): Promise<HttpResponse<any>> {
    try {
      const res = await this.accountsService.addAccount(body);
      if (res) {
        await this.fetchAccounts();
        return res;
      }
    } catch (e) {
      console.log('Error adding accounts');
    }
  }

  async addAccountImage(id: string, formData: FormData): Promise<HttpResponse<any>> {
    try {
      const res = await this.accountsService.addAccountImage(id, formData);
      if (res) {
        return res;
      }
    } catch (e) {
      console.log('Error adding account image');
    }
  }

  async deleteAccountImage(id: string): Promise<HttpResponse<any>> {
    try {
      const res = await this.accountsService.deleteAccountImage(id);
      if (res) {
        return res;
      }
    } catch (e) {
      console.log('Error delete account image');
    }
  }

  async editAccount(body: IAccount): Promise<HttpResponse<any>> {
    try {
      const res = await this.accountsService.editAccount(body);
      if (res) {
        await this.fetchAccounts();
        return res;
      }
    } catch (e) {
      console.log('Error updating accounts');
    }
  }

  async deleteAccount(id: string): Promise<any> {
    try {
      const res = await this.accountsService.deleteAccount(id);
      if (res) {
        await this.fetchAccounts();
      }
    } catch (e) {
      console.log('Error delete accounts');
    }
  }

  async deleteAccounts(ids: string[]): Promise<void> {
    try {
      for (const id of ids) {
        await this.accountsService.deleteAccount(id);
      }
      await this.fetchAccounts();
    } catch (e) {
      console.log('Error delete accounts');
    }
  }

  get accounts(): IAccount[] {
    return toJS(this.$accounts);
  }

  get account(): IAccount {
    return toJS(this.$account);
  }

  get accountPersons(): IAccountPerson[] {
    return toJS(this.$account.monitored_persons);
  }

  get accountPersonsOptions(): IOptions[] {
    return this.account?.monitored_persons.map((el) => ({
      label: el.first_name + ' ' + el.last_name,
      value: el.id
    }));
  }

  get accountActivePersonsOptions(): IOptions[] {
    return (
      this.account?.monitored_persons
        .filter((it) => it.is_active)
        .map((el) => ({
          label: el.first_name + ' ' + el.last_name,
          value: +el.id
        })) ?? []
    );
  }

  get countries(): any[] {
    return toJS(this.$countriesArray);
  }

  get accountsLength(): number {
    return toJS(this.$accountsLength);
  }
}
