/**
 * @Component Language Selector layout component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {GuiStore} from '@stores/gui.store';

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectorComponent {
    constructor(public guiStore: GuiStore) {
    }
}
