/**
 * @Service Zoom service
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum IZoomType {
  IN = 'IN',
  OUT = 'OUT',
  RESET = 'RESET'
}

@Injectable({ providedIn: 'root' })
export class ZoomService {
  public zoomSource$: Subject<IZoomType> = new Subject<IZoomType>();
  public readonly currentZoom: Observable<IZoomType> = this.zoomSource$.asObservable();

  set(type: IZoomType): void {
    this.zoomSource$.next(type);
  }
}
