import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { INotificationNavStructure } from '@pages/notifications/notifications.interface';
import { NAVIGATION } from '@app/const';
import { INavigationInterface } from '@app/const/config.interface';

@Component({
  selector: 'app-sub-nav',
  templateUrl: './sub-nav.component.html',
  styleUrls: ['./sub-nav.component.scss']
})
export class SubNavComponent implements OnInit {
  @Input() id: string;
  @Input() path: string;
  public urlSegment: string;
  public navStructure: INotificationNavStructure[];
  public nav: INavigationInterface = NAVIGATION;

  constructor(private route: ActivatedRoute) {}

  attachNav(): void {
    this.navStructure = [
      {
        segment: ':id',
        icon: this.urlSegment === ':id' ? '' : 'pi pi-angle-double-left',
        exact: true,
        icon_active: 'p-button-help',
        label: 'PAGES.accounts.account',
        class: 'p-mx-1 p-button-outlined p-button-secondary',
        routerLink: [this.nav.pages.path, this.path, this.id]
      },
      {
        segment: 'rooms',
        icon: 'pi pi-th-large',
        icon_active: 'p-button-help',
        label: 'MENU.accounts.rooms',
        routerLink: [this.nav.pages.path, this.path, this.id, this.nav.rooms.url]
      },
      {
        segment: 'persons',
        icon: 'pi pi-users',
        icon_active: 'p-button-help',
        label: 'MENU.accounts.persons',
        routerLink: [this.nav.pages.path, this.path, this.id, this.nav.persons.url]
      },
      {
        segment: 'contacts',
        icon: 'pi pi-briefcase',
        icon_active: 'p-button-help',
        label: 'MENU.accounts.contacts',
        routerLink: [this.nav.pages.path, this.path, this.id, this.nav.contacts.url]
      },
      {
        segment: 'sensors',
        icon: 'pi pi-wifi',
        icon_active: 'p-button-help',
        label: 'MENU.accounts.sensors',
        routerLink: [this.nav.pages.path, this.path, this.id, this.nav.sensors.url]
      },
      {
        segment: 'rules',
        icon: 'pi pi-sliders-v',
        icon_active: 'p-button-help',
        label: 'MENU.accounts.rules',
        routerLink: [this.nav.pages.path, this.path, this.id, this.nav.rules.url]
      },
      {
        segment: 'reports',
        icon: 'pi pi-chart-bar',
        icon_active: 'p-button-help',
        label: 'MENU.reports.reports',
        routerLink: [this.nav.pages.path, this.nav.reports.url, this.id]
      },
      {
        segment: 'notifications',
        icon: 'pi pi-bell',
        icon_active: 'p-button-help',
        label: 'MENU.accounts.notifications',
        routerLink: [this.nav.pages.path, this.nav.notifications.url, 'account', this.id]
      },
      {
        segment: 'gateway',
        icon: 'pi pi-cloud',
        icon_active: 'p-button-help',
        label: 'MENU.accounts.gateway',
        routerLink: [this.nav.pages.path, this.path, this.id, this.nav.gateway.url]
      }
    ];
  }

  ngOnInit(): void {
    this.attachNav();
    this.urlSegment = this.route.snapshot.parent.routeConfig.path;
  }
}
