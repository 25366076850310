import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Roles } from '@app/core/interfaces/user.interface';

@Component({
  selector: 'app-sub-save-button',
  templateUrl: './sub-save-button.component.html'
})
export class SubSaveButtonComponent {
  @Input() isView: boolean;
  @Input() isDisabled: boolean;
  @Input() isLoading: boolean;
  @Input() label: string;
  @Input() icon: string | undefined;
  @Output() saveItem: EventEmitter<any> = new EventEmitter<any>();
  Roles = Roles;
}
