/**
 * @Service Gateways's service.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PagesSharedModule } from '@pages/pages-shared.module';

import { NAVIGATION } from '@app/const';
import { environment } from '@environments/environment';
import { IAccountGatewayResponse } from '@pages/accounts/gateway/gateway.interface';
import { lastValueFrom, map } from 'rxjs';

@Injectable()
export class GatewayService {
  apiURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  api = (id: string) => `${this.apiURL}/${NAVIGATION.accounts.api}/${id}/${NAVIGATION.gateway.api}`;

  getGateway(accountId: string, gid: string): Promise<IAccountGatewayResponse> {
    return lastValueFrom(
      this.http
        .get<IAccountGatewayResponse>(`${this.api(accountId)}/${gid}`, {
          observe: 'response'
        })
        .pipe(map((res: HttpResponse<IAccountGatewayResponse>) => res.body))
    );
  }
}
