/**
 * @Component Notification's component.
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { formatDate } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

// Stores
import { GuiStore } from '@stores/gui.store';
import { AccountsStore } from '@pages/accounts/accounts/accounts.store';
import { RulesStore } from '@pages/accounts/rules/rules.store';

// Services
import { ModalService } from '@services/modal.service';
import { ConfirmationService, TableState } from 'primeng/api';
import { NotificationsStore } from '@pages/notifications/notifications.store';
import { EventService } from '@services/event.service';
import { UtilityService } from '@services/utility.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';

// Helpers  and notifications
import { alertTypes } from '@pages/accounts/sensors/alert-types';
import { delay } from '@app/core/utils/async.utility';
import {
  INotificationItem,
  INotificationItemColumns,
  INotificationMeta,
  INotificationQueryFilter,
  INotificationQueryRequest,
  INotificationStorageSettings,
  IOptionsFilter,
  IOrderByOptions
} from '@pages/notifications/notifications.interface';
import { Roles } from '@app/core/interfaces/user.interface';
import { Table } from 'primeng/table/table';
import { IConfig } from '@app/const/config.interface';
import { config } from '@app/const';

@UntilDestroy()
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit {
  @ViewChild('dt') dataTable: Table;

  @Input() get selectedColumns(): INotificationItemColumns[] {
    return this.selectedColumnsValues;
  }

  configOptions: IConfig = config;
  items: INotificationItem[];
  item: INotificationItem;
  selectedItems: INotificationItem[];
  cols: INotificationItemColumns[];
  loading = true;
  Roles = Roles;
  paginationSettings: INotificationMeta = {
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 1
  };

  notificationQuery: INotificationQueryRequest = {
    query: {
      page: 1,
      limit: config.tableDefaults.recordsPerPage,
      order_by: config.tableDefaults.order_by,
      sort_dir: 'DESC'
    },
    filter: {
      state: ['open']
    }
  };

  statusOptions: IOptionsFilter[] = [
    { label: 'Open', value: 'open', color: 'green', background: '#bbf8a79e' },
    { label: 'Closed', value: 'closed', color: 'grey' },
    { label: 'In Porgress', value: 'inprogress', color: '#bb9500', background: '#f5ea92' }
  ];

  alertOptions: IOptionsFilter[] = Object.keys(alertTypes).map((el) => ({
    value: el,
    label: el,
    color: alertTypes[el].color,
    icon: alertTypes[el].icon
  }));

  alertTypes: any;
  accountId: string;

  selectedColumnsValues: INotificationItemColumns[];
  minCols: string[] = ['type', 'rule_type', 'state'];
  hiddenCols: string[] = [];

  filterActionsMap = {
    state: 'state',
    type: 'notification_type_ids',
    rule_type: 'rule_type_ids',
    algo_type: 'algo_type_ids',
    account: 'account_type_ids'
  };
  selectedRowsPerPage = config.tableDefaults.recordsPerPage;

  globalFilterFields: string[] = [
    'id',
    'sensor_id',
    'service_provider_id',
    'rule_type',
    'algo_type',
    'type',
    'account_name',
    'person_name',
    'time_range',
    'state',
    'created_at'
  ];

  constructor(
    public title: Title,
    public guiStore: GuiStore,
    public translate: TranslateService,
    public accountsStore: AccountsStore,
    private rulesStore: RulesStore,
    private notificationsStore: NotificationsStore,
    private confirmationService: ConfirmationService,
    private modalService: ModalService,
    private event: EventService,
    private route: ActivatedRoute,
    public utilityService: UtilityService,
    private cdr: ChangeDetectorRef,
    private storage: LocalStorageService,
    private utility: UtilityService
  ) {}

  mapFields(data): void {
    this.items = data.map((el) => {
      const formattedDate = formatDate(new Date(el.created_at), `YYYY-MM-dd HH:mm:ss`, 'en_US');
      const timeStart = el.rule?.time_start.substr(0, 5) || '00:00';
      const timeEnd = el.rule?.time_end.substr(0, 5) || '24:00';
      return {
        id: el.id,
        created_at: formattedDate,
        date_time_date: formattedDate.split(' ')[1],
        date_time_year: formattedDate.split(' ')[0],
        account_name: el.account?.owner_first_name + ' ' + el?.account.owner_last_name,
        time_range: timeStart + ' - ' + (timeEnd < timeStart ? timeEnd + '(+1)' : timeEnd),
        service_provider_id: el?.person.service_provider_id || '---',
        person_name: el?.person.first_name + ' ' + el?.person.last_name,
        rule_type: el.rule ? this.translate.instant(`PAGES.rules.rule_keys.${el.rule?.rule_type?.type_key}`) : '---',
        rule_id: el.rule?.id ? el.rule?.id : '---',
        algo_type: el.algorithm_type
          ? this.translate.instant(`PAGES.rules.algo_keys.${el.algorithm_type.type_key}`)
          : '---',
        type: el?.notification_type.type_key,
        sensor_id: el?.rule?.sensor_ids,
        extra_values: {
          base_value: el.rule?.fix_threshold?.base_value,
          actual_value: this.utility.notificationActualValueParsed(el),
          base_value_unit: el.rule?.fix_threshold?.base_value_unit,
          high_value: el.rule?.fix_threshold?.high_value,
          high_value_unit: el.rule?.fix_threshold?.high_value_unit,
          low_value: el.rule?.fix_threshold?.low_value,
          low_value_unit: el.rule?.fix_threshold?.low_value_unit
        },
        state: el?.state
      };
    });
  }

  async getAccountById(id: string): Promise<void> {
    if (id) {
      try {
        await this.accountsStore.fetchAccount(id);
      } catch (e) {
        console.log(['Error FetchAccount'], e);
      }
    }
  }

  displayColumns(): void {
    this.cols = [
      { field: 'created_at', name: 'created_at', component: 'created_at', sortable: true },
      { field: 'account_name', name: 'account_name', sortable: true },
      { field: 'service_provider_id', name: 'service_provider_id' },
      { field: 'person_name', name: 'person_name', sortable: true },
      { field: 'rule_id', name: 'rule_id', sortable: false },
      { field: 'rule_type', name: 'rule_type', sortable: true },
      { field: 'algo_type', name: 'algo_type', sortable: true },
      {
        field: 'type',
        name: 'type',
        component: 'type',
        icon: (type) => this.utilityService.alertType(type),
        sortable: true
      },
      { field: 'sensor_id', name: 'sensor_id' },
      { field: 'time_range', name: 'time_range' },
      { field: 'extra_values', name: 'extra_values', component: 'extra_values' },
      { field: 'state', name: 'state', component: 'state' }
    ];

    this.selectedColumnsValues = this.cols.filter((col) => this.hiddenColsCallback(col));
  }

  getPaginatorSettings(): void {
    const storage: INotificationStorageSettings = this.storage.getItem(config.pages.notifications.storageKeyOptions);
    if (storage && storage?.pagination?.rows) {
      this.notificationQuery.query.limit = storage?.pagination.rows;
    }
    if (storage && storage?.filter) {
      this.notificationQuery.filter = storage?.filter;
    }
  }

  async ngOnInit(): Promise<void> {
    this.getPaginatorSettings();
    this.accountId = this.route.snapshot.paramMap.get('id');
    await this.getAccountById(this.accountId);
    this.alertTypes = alertTypes;
    if (this.accountId) {
      this.notificationQuery.filter.account_ids = [+this.accountId];
    }
    this.displayColumns();
    this.event.currentDetectionChange.pipe(untilDestroyed(this)).subscribe(async (_) => {
      await this.getNotifications(this.notificationQuery);
      this.cdr.detectChanges();
    });
    await this.getNotifications(this.notificationQuery);
    this.cdr.detectChanges();

    delay(100).then(() => {
      this.title.setTitle(this.translate.instant('MENU.notifications.notifications'));
    });

    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.title.setTitle(this.translate.instant('MENU.notifications.notifications'));
    });
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  set selectedColumns(val: INotificationItemColumns[]) {
    if (val.length < 4) {
      return;
    }
    this.selectedColumnsValues = this.cols.filter((col) => val.includes(col));
  }

  hiddenColsCallback(el): boolean {
    return !this.hiddenCols.find((it) => it === el.name);
  }

  async onFilterGlobal(value): Promise<void> {
    this.dataTable.filterGlobal(value, 'contains');
  }

  filterMapBeforeSend(filter: INotificationQueryFilter): INotificationQueryFilter {
    const mappedFilter = {};
    for (const item in filter) {
      if (filter.hasOwnProperty(item)) {
        const newKey = this.filterActionsMap[item];
        mappedFilter[newKey] = filter[item];
      }
    }
    return mappedFilter;
  }

  onChange(event): void {
    // console.log(event);
  }

  async onPage(event): Promise<void> {
    this.storage.insertItem({ pagination: { rows: event.rows } }, config.pages.notifications.storageKeyOptions);
    this.notificationQuery.query.limit = event.rows;
    this.notificationQuery.query.page = 1 + event.first / event.rows;
    await this.getNotifications(this.notificationQuery);
    this.cdr.detectChanges();
  }

  async onSort(event: any): Promise<void> {
    let orderBy;
    switch (event.field) {
      case 'type':
        orderBy = IOrderByOptions.NOTIFICATION_TYPE;
        break;
      default:
        orderBy = event.field;
        break;
    }
    const sortDir = event.order === 1 ? 'ASC' : 'DESC';
    const isSameRequest =
      orderBy === this.notificationQuery?.query?.order_by && sortDir === this.notificationQuery?.query?.sort_dir;

    if (!this.dataTable?.customSort || isSameRequest) {
      return;
    }

    this.notificationQuery = {
      ...this.notificationQuery,
      query: {
        ...this.notificationQuery.query,
        order_by: orderBy,
        sort_dir: sortDir
      }
    };
    this.storage.insertItem(
      {
        sorting: {
          order_by: orderBy,
          sort_dir: sortDir
        }
      },
      config.pages.notifications.storageKeyOptions
    );
    try {
      await this.getNotifications(this.notificationQuery);
    } catch (e) {
      console.log('[R][getNotifications]', e);
    }
    this.cdr.detectChanges();
  }

  async getNotifications(queryParams: INotificationQueryRequest): Promise<void> {
    try {
      const mappedQuery: INotificationQueryRequest = {
        query: queryParams.query,
        filter: this.filterMapBeforeSend(queryParams.filter)
      };

      if (this.accountId) {
        mappedQuery.filter.account_ids = [+this.accountId];
      }

      // console.log('getNotifications', mappedQuery);
      const notifications = await this.notificationsStore.fetchNotifications(mappedQuery);
      this.paginationSettings = {
        ...notifications.meta
      };
      this.mapFields([...notifications?.items]);
    } catch (e) {
      console.log('[R][ErrorName]');
    } finally {
      this.loading = false;
    }
  }

  async clearFilterState(): Promise<void> {
    this.storage.removeItem(config.pages.notifications.storageKeyTable);
    this.items = this.items.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
    this.notificationQuery = {
      query: {
        page: 1,
        limit: config.tableDefaults.recordsPerPage,
        order_by: config.tableDefaults.order_by,
        sort_dir: 'DESC'
      },
      filter: {
        ...this.notificationQuery.filter
      }
    };
    this.storage.insertItem(
      { pagination: { rows: config.tableDefaults.recordsPerPage } },
      config.pages.notifications.storageKeyOptions
    );
    try {
      await this.getNotifications(this.notificationQuery);
    } catch (e) {
      console.log('[R][getNotifications after clearState]', e);
    }
    this.cdr.detectChanges();
  }

  getStatusBg(status: string): string {
    return this.statusOptions.find((el) => el.value === status)?.background;
  }

  async filterTable(event: any): Promise<void> {
    this.storage.insertItem({ filter: event }, config.pages.notifications.storageKeyOptions);
    this.notificationQuery.filter = event;
    await this.getNotifications(this.notificationQuery);
    this.cdr.detectChanges();
  }

  onStateSave(event: TableState): void {
    this.storage.insertItem({ columnWidths: event.columnWidths }, config.pages.notifications.storageKeyTable);
  }
}
