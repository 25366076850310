/**
 * @Component Update notification component
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

// Stores
import { GuiStore } from '@stores/gui.store';
import { AccountsStore } from '@pages/accounts/accounts/accounts.store';
import { NotificationsStore } from '@pages/notifications/notifications.store';

// Services
import { AuthService } from '@app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@services/notification.service';
import { ModalService } from '@services/modal.service';
import { AccountsSharedService } from '@pages/accounts/accounts-shared.service';

// Helpers and interfaces
import { NAVIGATION } from '@app/const';
import { delay } from '@app/core/utils/async.utility';
import { INotificationEditRequest } from '@pages/notifications/notifications.interface';

@Component({
  selector: 'app-update-notification',
  templateUrl: './update-notification.component.html',
  styles: [
    `
      ::ng-deep formly-field {
        display: block !important;
      }
    `
  ]
})
export class UpdateNotificationComponent implements OnInit {
  isLoading = false;
  item: INotificationEditRequest | any;
  rooms: FormlyFieldConfig[] = [];
  form = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  isEdit: boolean;
  accountId: string;
  notificationId: string;

  constructor(
    public guiStore: GuiStore,
    public translate: TranslateService,
    private authService: AuthService,
    public route: ActivatedRoute,
    private router: Router,
    private notificationsStore: NotificationsStore,
    private notificationService: NotificationService,
    public accountsStore: AccountsStore,
    private location: Location,
    private modalService: ModalService,
    private accountsShared: AccountsSharedService
  ) {
    this.model.lang = guiStore.currentLanguage;
  }

  async saveItem(): Promise<void> {
    try {
      const item: INotificationEditRequest = {
        id: this.item.id,
        message: this.item.message,
        state: this.item.state
      };
      if (this.item.close_reason !== '') {
        item.close_reason = { id: +this.item.close_reason };
      }
      await this.notificationsStore.editNotification(item);
      this.notificationService.addSingle({
        severity: 'success',
        summary: this.translate.instant('MODAL.attention'),
        detail: this.translate.instant('MODAL.notification.saved')
      });
    } catch (e) {
      console.log(e);
    }
    delay(2000).then(async () => {
      if (!this.accountId) {
        await this.router.navigate([NAVIGATION.pages.url, NAVIGATION.notifications.url]);
      } else {
        this.location.back();
      }
    });
    this.accountsShared.resetForm(this.form);
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.modalService.exitModal(this.form);
  }

  mapFields(data): void {
    this.item = {
      id: data?.id,
      state: data?.state,
      message: data.message || '',
      close_reason: data?.close_reason?.id?.toString() || ''
    };
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.paramMap.get('aid');
    this.notificationId = this.route.snapshot.paramMap.get('id');
    this.isLoading = true;
    this.notificationsStore
      .fetchNotification(this.notificationId)
      .then(async (data) => {
        await this.notificationsStore.fetchNotificationsTypes();
        this.mapFields(data);
        this.isLoading = false;
        this.formFields();
      })
      .catch((e) => {
        console.log('[Error][fetchNotification]', e);
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  formFields(): void {
    this.fields = [
      {
        type: 'tabs',
        fieldGroup: [
          {
            templateOptions: {
              label: 'PAGES.accounts.groups.main'
            },
            fieldGroupClassName: 'p-d-flex p-flex-column',
            fieldGroup: [
              {
                fieldGroupClassName: 'p-d-flex dropdown',
                fieldGroup: [
                  {
                    className: 'p-field p-col',
                    type: 'dropdown',
                    key: 'state',
                    templateOptions: {
                      required: true,
                      options: [
                        { label: 'PAGES.notifications.state.open', value: 'open' },
                        { label: 'PAGES.notifications.state.inprogress', value: 'inprogress' },
                        { label: 'PAGES.notifications.state.closed', value: 'closed' }
                      ],
                      label: 'PAGES.notifications.fields.state'
                    }
                  },
                  {
                    className: 'p-field p-col',
                    type: 'dropdown',
                    key: 'close_reason',
                    templateOptions: {
                      placeholder: 'DEFAULTS.select_option',
                      required: true,
                      options: [
                        { label: 'DEFAULTS.select_option', value: '' },
                        ...this.notificationsStore.closeReasonsOptions.map((el) => ({
                          ...el,
                          label: 'PAGES.notifications.fields.reasons.' + el.value
                        }))
                      ],
                      label: 'PAGES.notifications.fields.reason'
                    },
                    expressionProperties: {
                      'templateOptions.disabled': (model) => model.state !== 'closed'
                    }
                  },
                  {
                    className: 'p-field p-col-8',
                    type: 'textarea',
                    key: 'message',
                    templateOptions: {
                      minLength: 5,
                      maxLength: 512,
                      label: 'PAGES.notifications.fields.notes'
                    },
                    expressionProperties: {
                      'templateOptions.required': 'model.close_reason==="9"'
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ];
  }
}
