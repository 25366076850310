/**
 * @Component Rooms component for creating new room and update selected one
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";

import { GuiStore } from "@stores/gui.store";
import { AccountsStore } from "@pages/accounts/accounts/accounts.store";
import { RoomsStore } from "@pages/accounts/rooms/rooms.store";

import { NotificationService } from "@services/notification.service";
import { ModalService } from "@services/modal.service";
import { AccountsSharedService } from "@pages/accounts/accounts-shared.service";

import { NAVIGATION } from "@app/const";
import { delay } from "@app/core/utils/async.utility";
import { IAccountRoom } from "@pages/accounts/rooms/rooms.interface";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { IOptions } from "@pages/accounts/accounts/accounts.interface";

@Component({
  selector: "app-update-room",
  templateUrl: "./update-room.component.html"
})
export class UpdateRoomComponent implements OnInit {
  isLoading = false;
  item: IAccountRoom | any;
  accountId: string;
  pId: string;
  isEdit;
  isView;

  connectedToOptions: IOptions[] = [];
  rooms: FormlyFieldConfig[] = [];
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];

  constructor(
    public guiStore: GuiStore,
    public translate: TranslateService,
    public route: ActivatedRoute,
    private router: Router,
    public accountsStore: AccountsStore,
    public roomsStore: RoomsStore,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private accountsShared: AccountsSharedService
  ) {
    this.model.lang = guiStore.currentLanguage;
  }

  async saveItem(): Promise<void> {
    this.item.extra = JSON.stringify({ color: this.item.color });
    if (this.route.snapshot?.data?.isEdit) {
      try {
        const result = await this.roomsStore.editRoom(this.accountId, this.item);
        if (result?.ok) {
          this.notificationService.addSingle({
            severity: "success",
            summary: this.translate.instant("MODAL.attention"),
            detail: this.translate.instant("MODAL.notification.saved")
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const result = await this.roomsStore.addRoom(this.accountId, this.item);
        if (result?.ok) {
          this.notificationService.addSingle({
            severity: "success",
            summary: this.translate.instant("MODAL.attention"),
            detail: this.translate.instant("MODAL.notification.added")
          });
        }
        delay(2000).then(
          async () =>
            await this.router.navigate([
              NAVIGATION.pages.url,
              NAVIGATION.accounts.url,
              this.accountId,
              NAVIGATION.rooms.url
            ])
        );
      } catch (e) {
        console.log(e);
      }
    }
    this.accountsShared.resetForm(this.form);
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.modalService.exitModal(this.form);
  }

  ngOnInit(): void {
    this.isView = this.route.snapshot?.data?.isView;
    this.isEdit = this.route.snapshot?.data?.isEdit;
    this.accountId = this.route.snapshot.paramMap.get("id");
    this.pId = this.route.snapshot.paramMap.get("pid");
    if (this.isEdit || this.isView) {
      this.isLoading = true;
      this.roomsStore
        .fetchRoom(this.accountId, this.pId)
        .then(async (data) => {
          this.item = data;
          this.item.color = JSON.parse(this.item.extra)?.color;
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.item = {};
    }
    this.roomsStore.fetchRooms(this.accountId).then((rooms) => {
      this.connectedToOptions = rooms
        .filter((el) => +el.id !== +this.item?.id)
        .map((el) => ({
          label: el.room_tag,
          value: el.id
        }));
      this.formFields();
    });
  }

  formFields(): void {
    this.fields = [
      {
        hooks: {
          onInit: (_) => {
            if (this.isView) {
              this.form.disable();
              this.cdr.detectChanges();
            }
          }
        },
        type: "tabs",
        fieldGroup: [
          {
            expressionProperties: {
              "templateOptions.label": this.translate.stream("PAGES.rooms.groups.main")
            },
            fieldGroupClassName: "p-d-flex",
            fieldGroup: [
              {
                className: "p-field p-col",
                type: "input",
                key: "room_tag",
                templateOptions: {
                  minLength: 3,
                  required: true,
                  label: "PAGES.rooms.fields.room_tag"
                }
              },
              {
                className: "p-field p-col",
                key: "connected_to",
                type: "multiselect",
                templateOptions: {
                  placeholder: "PAGES.rooms.fields.connected_to.placeholder",
                  label: "PAGES.rooms.fields.connected_to",
                  options: this.connectedToOptions
                }
              },
              {
                className: "p-field p-col",
                type: "input",
                key: "room_size.length",
                templateOptions: {
                  type: "number",
                  min: 1,
                  max: 100,
                  label: "PAGES.rooms.fields.room_size.length"
                }
              },
              {
                className: "p-field p-col",
                type: "input",
                key: "room_size.width",
                templateOptions: {
                  type: "number",
                  min: 1,
                  max: 100,
                  label: "PAGES.rooms.fields.room_size.width"
                }
              },
              {
                className: "p-field p-col",
                type: "color",
                key: "color",
                defaultValue: "#000000",
                templateOptions: {
                  label: "PAGES.rooms.fields.color"
                },
                expressionProperties: {
                  "templateOptions.disabled": () => this.isView
                }
              }
            ]
          }
        ]
      }
    ];
  }
}
