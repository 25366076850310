/**
 * @Component Header layout component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Component } from '@angular/core';
import { GuiStore } from '@stores/gui.store';
import { AuthService } from '@app/core/services/auth.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    constructor(public guiStore: GuiStore, public authService: AuthService) {}
}
