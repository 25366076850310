/**
 * @Component Update Rule component for creating new rule and update selected one
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";

import { GuiStore } from "@stores/gui.store";
import { AccountsStore } from "@pages/accounts/accounts/accounts.store";
import { RulesStore } from "@pages/accounts/rules/rules.store";
import { SensorsStore } from "@pages/accounts/sensors/sensors.store";

import { TranslateService } from "@ngx-translate/core";
import { NotificationService } from "@services/notification.service";
import { AccountsSharedService } from "@pages/accounts/accounts-shared.service";
import { ModalService } from "@services/modal.service";

import { delay } from "@app/core/utils/async.utility";
import { NAVIGATION } from "@app/const";
import { IAccountRule } from "@pages/accounts/rules/rules.interface";
import { RoomsStore } from "@pages/accounts/rooms/rooms.store";
import { DtoUpdateService } from "@pages/accounts/rules/update-rule/update-rule.service";
import { UpdateRuleFormService } from "@pages/accounts/rules/update-rule/update-rule-form.service";

@UntilDestroy()
@Component({
  selector: "app-update-rule",
  templateUrl: "./update-rule.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateRuleComponent implements OnInit {
  isLoading = false;
  item: IAccountRule | any;
  accountId: string;
  pId: string;

  rooms: FormlyFieldConfig[] = [];
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = { formState: { submitted: false } };
  fields: FormlyFieldConfig[];
  isEdit: boolean;
  isView: boolean;
  excludedAlgoTypesFromFixLimits = ["algo_t_out_of_bed_time", "algo_t_sensor_battery_status", "algo_t_panic"]; // algorithm ids that should lead to disabling limits

  constructor(
    public guiStore: GuiStore,
    public translate: TranslateService,
    public route: ActivatedRoute,
    public accountsStore: AccountsStore,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private rulesStore: RulesStore,
    public readonly sensorsStore: SensorsStore,
    private roomsStore: RoomsStore,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private accountsShared: AccountsSharedService,
    private dto: DtoUpdateService,
    private formService: UpdateRuleFormService
  ) {
    this.model.lang = guiStore.currentLanguage;
  }

  async saveItem(): Promise<void> {
    const updatedItem = this.dto.transformBeforeSave(this.item);
    if (this.route.snapshot?.data?.isEdit) {
      try {
        const result = await this.rulesStore.editRule(this.accountId, updatedItem);
        if (result?.ok) {
          this.notificationService.addSingle({
            severity: "success",
            summary: this.translate.instant("MODAL.attention"),
            detail: this.translate.instant("MODAL.notification.saved")
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const result = await this.rulesStore.addRule(this.accountId, updatedItem);
        if (result?.ok) {
          this.notificationService.addSingle({
            severity: "success",
            summary: this.translate.instant("MODAL.attention"),
            detail: this.translate.instant("MODAL.notification.added")
          });
        }
        delay(2000).then(
          async () =>
            await this.router.navigate([NAVIGATION.pages.url, NAVIGATION.accounts.url, this.accountId, "rules"])
        );
      } catch (e) {
        console.log(e);
      }
    }
    this.accountsShared.resetForm(this.form);
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.modalService.exitModal(this.form);
  }

  onModelChange(): void {
    this.dto.limitValidation(this);
  }

  async ngOnInit(): Promise<void> {
    this.accountId = this.route.snapshot.paramMap.get("id");
    this.pId = this.route.snapshot.paramMap.get("pid");
    this.isEdit = this.route.snapshot?.data?.isEdit;
    this.isView = this.route.snapshot?.data?.isView;
    await this.rulesStore.fetchNotificationTypes();
    await this.rulesStore.fetchRuleTypesAll();

    if (this.isEdit || this.isView) {
      this.isLoading = true;
      this.rulesStore
        .fetchRule(this.accountId, this.pId)
        .then(async (data) => {
          await this.sensorsStore.fetchSensors(this.accountId);
          await this.roomsStore.fetchRooms(this.accountId);
          this.item = data;
          // Remember that monitored_persons_ids should be array later
          this.dto.getMappedItem(this.item, data);
          this.isLoading = false;
          this.formService.formFields(this);
          this.markFormGroupTouched(this.form);
          this.cdr.detectChanges();
          this.dto.limitValidation(this);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.item = {};
      this.accountsStore.fetchAccount(this.accountId).then(async (_) => {
        await this.sensorsStore.fetchSensors(this.accountId);
        await this.roomsStore.fetchRooms(this.accountId);
        this.cdr.detectChanges();
        this.formService.formFields(this);
        this.markFormGroupTouched(this.form);
      });
    }
    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe((_) => {
      this.formService.formFields(this);
    });
  }

  public markFormGroupTouched(formGroup: UntypedFormGroup): void {
    (Object as any).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
