/**
 * @Store Notification's mobx store service for state management.
 * @Project: TrendLines
 * @Version Mobx: 6.1.X
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { toJS, makeAutoObservable } from 'mobx';

import {
  INotificationEditRequest,
  INotificationMeta,
  INotificationQueryRequest,
  INotificationItem,
  INotificationsResponse,
  INotificationType,
  INotificationTypesRes
} from '@pages/notifications/notifications.interface';
import { NotificationsService } from '@pages/notifications/notifications.service';
import { IOptions } from '@pages/accounts/accounts/accounts.interface';
import { config } from '@app/const';

const defaultNotificationsQuery: INotificationQueryRequest = {
  query: {
    page: 1,
    limit: config.tableDefaults.recordsPerPage,
    order_by: config.tableDefaults.order_by,
    sort_dir: 'DESC'
  },
  filter: {
    state: ['open']
  }
};

const defaultPaginationSettings: INotificationMeta = {
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 1
};

const defaultCloseReasons = [
  {
    id: 1,
    type_key: 'refer_to_doctor_call',
    type_name: 'Refer to Doctor Call',
    type_metadata: {}
  },
  {
    id: 2,
    type_key: 'scheduled_doctor_visit',
    type_name: 'Scheduled Doctor Visit',
    type_metadata: {}
  },
  {
    id: 3,
    type_key: 'ambulance',
    type_name: 'Ambulance',
    type_metadata: {}
  },
  {
    id: 4,
    type_key: 'intensive_care_ambulance',
    type_name: 'Intensive Care Ambulance',
    type_metadata: {}
  },
  {
    id: 5,
    type_key: 'called_customer',
    type_name: 'Called Customer',
    type_metadata: {}
  },
  {
    id: 6,
    type_key: 'er_recommendation',
    type_name: 'ER Recommendation',
    type_metadata: {}
  },
  {
    id: 7,
    type_key: 'doctor_recommendation',
    type_name: 'Doctor Recommendation',
    type_metadata: {}
  },
  {
    id: 8,
    type_key: 'no_action',
    type_name: 'No Action',
    type_metadata: {}
  },
  {
    id: 9,
    type_key: 'false_alarm',
    type_name: 'False Alarm',
    type_metadata: {}
  },
  {
    id: 10,
    type_key: 'other',
    type_name: 'Other',
    type_metadata: {}
  }
];

@Injectable({ providedIn: 'root' })
export class NotificationsStore {
  // ********** Observables ************* //
  $notification: INotificationItem = null;
  $notifications: INotificationsResponse = null;
  $notificationTypes: INotificationTypesRes = null;
  $notificationsQuery: INotificationQueryRequest = defaultNotificationsQuery;
  $notificationsSettings: INotificationMeta = defaultPaginationSettings;
  $notificationsLength: number;

  constructor(private notificationsService: NotificationsService) {
    makeAutoObservable(this);
  }

  setNotificationsQuery(query: INotificationQueryRequest): void {
    this.$notificationsQuery = query;
  }

  setNotificationsSettings(settings: INotificationMeta): void {
    this.$notificationsSettings = settings;
  }

  setNotifications(res: INotificationsResponse): void {
    this.$notifications = res;
  }

  setNotification(res: INotificationItem): void {
    this.$notification = res;
  }

  setNotificationTypes(types: INotificationTypesRes): void {
    this.$notificationTypes = types;
  }

  // ********** Actions ************* //

  async fetchNotificationsTypes(): Promise<INotificationTypesRes> {
    try {
      const res = await this.notificationsService.getNotificationTypes();
      if (res) {
        this.setNotificationTypes(res);
        return res;
      }
    } catch (e) {
      console.log('Error fetching alerts');
    }
  }

  async fetchNotifications(query: INotificationQueryRequest): Promise<INotificationsResponse> {
    try {
      const res = await this.notificationsService.getNotificationsCondition(query);
      if (res) {
        this.setNotifications(res.body);
        return res.body;
      }
    } catch (e) {
      console.log('Error fetching notifications');
    }
  }

  async fetchNotification(id: string): Promise<INotificationItem> {
    try {
      const res = await this.notificationsService.getNotification(id);
      if (res) {
        this.setNotification(res.body);
        return res.body;
      }
    } catch (e) {
      console.log('Error fetching notification');
    }
  }

  async editNotification(body: INotificationEditRequest): Promise<void> {
    try {
      await this.notificationsService.editNotification(body);
    } catch (e) {
      console.log('Error updating notification');
    }
  }

  get notifications(): INotificationsResponse {
    return toJS(this.$notifications);
  }

  get notificationsItems(): INotificationItem[] {
    return toJS(this.$notifications.items);
  }

  get notification(): INotificationItem {
    return toJS(this.$notification);
  }

  get notificationsLength(): number {
    return toJS(this.$notificationsLength);
  }

  get closeReasons(): INotificationType[] {
    return this.$notificationTypes?.close_reasons?.length === 0
      ? defaultCloseReasons
      : toJS(this.$notificationTypes.close_reasons);
  }

  get currentNotificationTypes(): INotificationType[] {
    return toJS(this.$notificationTypes?.notification_types ?? []);
  }

  get currentNotificationTypesAll(): INotificationTypesRes {
    return toJS(this.$notificationTypes);
  }

  get closeReasonsOptions(): IOptions[] {
    return this.closeReasons.map((el) => ({ label: el.type_name, value: '' + el.id }));
  }

  get query(): INotificationQueryRequest {
    return toJS(this.$notificationsQuery);
  }
}
