interface IRanges {
  annotation?: string;
  confidence?: number;
  end: Date;
  sensor_id: number;
  start: Date;
}

export class ObjectUtils {
  public static unique = (value, index, self): boolean => self.indexOf(value) === index;

  public static isObject = (val) => {
    if (val === null) {
      return false;
    }
    return typeof val === 'function' || typeof val === 'object';
  };

  public static toHash = (array, keyName, valueName) =>
    array.reduce((dictionary, next) => {
      dictionary[next[keyName]] = next[valueName];
      return dictionary;
    }, {});

  public static groupBy = (arr: any[], type: string) =>
    arr.reduce((r, a) => {
      r[a[type]] = r[a[type]] || [];
      r[a[type]].push(a);
      return r;
    }, Object.create(null));

  public static mergeTimes(ranges: IRanges[]) {
    // first, sort the ranges
    ranges.sort((a, b) => a.start.getTime() - b.start.getTime());

    // take two ranges, and merges them together
    const mergeFn = (a, b) => ({ start: Math.min(a.start.getTime(), b.start.getTime()), end: Math.max(a.end.getTime(), b.end.getTime()) });

    // check if two ranges overlap
    const overlapFn = (a, b) => b.start.getTime() <= a.end.getTime();

    // make current the first item of the array (start the array from 1 to not check the first item against itself)
    let current: any = ranges[0];
    const result = [];
    for (let i = 1; i < ranges.length; i++) {
      if (overlapFn(current, ranges[i])) {
        // if the current range overlapping with this range
        current = mergeFn(current, ranges[i]);
      } // merge them into the current range
      else {
        // if not
        result.push(current); // add the current accumulated range as result
        current = ranges[i]; // start accumulating another one from this range
      }
    }
    result.push(current); // add the last result

    return result;
  }
}
