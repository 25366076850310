import {FormlyExtension, FormlyFieldConfig} from '@ngx-formly/core';

export const CypressExtension: FormlyExtension = {
    prePopulate(field: FormlyFieldConfig): void {
        field.templateOptions = {
            ...(field.templateOptions || {}),
            attributes: {
                'data-cy': `${field.key}`
            }
        };
    }
};
