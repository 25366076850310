/**
 * @module Rules' module for encapsulating rules components
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@modules/shared.module';
import { PagesSharedModule } from '@pages/pages-shared.module';

import { UpdateRuleComponent } from '@pages/accounts/rules/update-rule/update-rule.component';
import { RulesComponent } from '@pages/accounts/rules/rules.component';
import { ViewRuleComponent } from './view-rule/view-rule.component';
import { UpdateExpressionPropertiesService } from '@pages/accounts/rules/update-rule/update-expression.service';
import { DtoUpdateService } from '@pages/accounts/rules/update-rule/update-rule.service';
import { UpdateRuleFormService } from '@pages/accounts/rules/update-rule/update-rule-form.service';
import { UpdateHooksService } from '@pages/accounts/rules/update-rule/update-rule-hooks.service';

@NgModule({
  declarations: [RulesComponent, UpdateRuleComponent, ViewRuleComponent],
  imports: [CommonModule, SharedModule, PagesSharedModule],
  providers: [UpdateExpressionPropertiesService, DtoUpdateService, UpdateRuleFormService, UpdateHooksService]
})
export class RulesModule {}
