/**
 * @Service Rules service for CRUD actions with API server.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { environment } from '@environments/environment';
import { NAVIGATION } from '@app/const';
import { IAccountRule, IRulesTypesRes } from '@pages/accounts/rules/rules.interface';
import { lastValueFrom, map } from 'rxjs';

@Injectable()
export class RulesService {
  apiURL = environment.apiUrl;
  nav = {
    rules: (accountId) => `${this.apiURL}/${NAVIGATION.accounts.api}/${accountId}/${NAVIGATION.rules.api}`,
    ruleTypes: `${this.apiURL}/rule-types/mapping`,
    notificationTypes: `${this.apiURL}/rules/notification_types`
  };

  constructor(private http: HttpClient) {}

  getRuleTypes(): Promise<IRulesTypesRes> {
    return lastValueFrom(this.http.get<any>(this.nav.ruleTypes));
  }

  getRules(accountId: string): Promise<HttpResponse<IAccountRule[]>> {
    return lastValueFrom(
      this.http
        .get<IAccountRule[]>(this.nav.rules(accountId), {
          observe: 'response'
        })
        .pipe(map((res: HttpResponse<IAccountRule[]>) => res))
    );
  }

  getRule(accountId: string, id: string): Promise<IAccountRule> {
    return lastValueFrom(
      this.http
        .get<IAccountRule>(`${this.nav.rules(accountId)}/${id}`, {
          observe: 'response'
        })
        .pipe(map((res: HttpResponse<IAccountRule>) => res.body))
    );
  }

  addRule(accountId: string, body: IAccountRule): Promise<any> {
    return lastValueFrom(
      this.http.post<IAccountRule>(this.nav.rules(accountId), body, {
        observe: 'response'
      })
    );
  }

  editRule(accountId: string, body: IAccountRule): Promise<any> {
    return lastValueFrom(
      this.http.put<IAccountRule>(`${this.nav.rules(accountId)}/${body.id}`, body, {
        observe: 'response'
      })
    );
  }

  deleteRule(accountId: string, id: string): Promise<any> {
    return lastValueFrom(
      this.http.delete<any>(`${this.nav.rules(accountId)}/${id}`, {
        observe: 'response'
      })
    );
  }
}
