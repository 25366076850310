/**
 * @Component Account's component for creating new account and updating selected one.
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { startWith, switchMap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { GuiStore } from '@stores/gui.store';
import { AccountsStore } from '@pages/accounts/accounts/accounts.store';
import { SensorsStore } from '@pages/accounts/sensors/sensors.store';

import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@services/modal.service';
import { NotificationService } from '@services/notification.service';
import { AccountsSharedService } from '@pages/accounts/accounts-shared.service';

import { IAccount } from '@pages/accounts/accounts/accounts.interface';
import { IAccountSensor } from '@pages/accounts/sensors/sensors.interface';

import { timeZoneSorted } from '@app/const/timezones';
import { NAVIGATION } from '@app/const';
import { delay } from '@app/core/utils/async.utility';
import { Roles } from '@app/core/interfaces/user.interface';
import { AuthService } from '@app/core/services/auth.service';

@Component({
  selector: 'app-update-account',
  templateUrl: './update-account.component.html'
})
export class UpdateAccountComponent implements OnInit {
  isLoading = false;
  item: IAccount | any;
  rooms: FormlyFieldConfig[] = [];
  form = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  sensorsAttachedRooms: any[] = [];
  isEdit: boolean;
  isView: boolean;
  role = Roles;

  constructor(
    public guiStore: GuiStore,
    public translationService: TranslateService,
    public route: ActivatedRoute,
    private router: Router,
    private accountsStore: AccountsStore,
    private authServise: AuthService,
    private sensorsStore: SensorsStore,
    private notificationService: NotificationService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private accountsShared: AccountsSharedService
  ) {
    this.model.lang = guiStore.currentLanguage;
  }

  async saveItem(): Promise<void> {
    if (this.isEdit) {
      try {
        if (!this.item.owner_pet) {
          this.form.get('owner_pet_weight').reset('');
          delete this.item.owner_pet_weight;
          this.item.pets = [];
        } else {
          this.item.pets = [{ weight_kg: this.item.owner_pet_weight }];
        }
        delete this.item.owner_pet;
        const result = await this.accountsStore.editAccount(this.item);
        if (result?.ok) {
          this.notificationService.addSingle({
            severity: 'success',
            summary: this.translationService.instant('MODAL.attention'),
            detail: this.translationService.instant('MODAL.notification.saved')
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        if (!this.item.owner_pet) {
          this.form.get('owner_pet_weight').reset('');
          this.item.pets = [];
        } else {
          this.item.pets = [{ weight_kg: this.item.owner_pet_weight }];
        }
        delete this.item.owner_pet_weight;
        delete this.item.owner_pet;
        this.item.is_active = this.item.is_active ?? true;
        // this.item.apartment_image_b64 = "";
        const result = await this.accountsStore.addAccount(this.item);
        if (result?.ok) {
          this.notificationService.addSingle({
            severity: 'success',
            summary: this.translationService.instant('MODAL.attention'),
            detail: this.translationService.instant('MODAL.notification.added')
          });
        }
        delay(2000).then(async () => await this.router.navigate([NAVIGATION.pages.url, NAVIGATION.accounts.url]));
      } catch (e) {
        console.log(e);
      }
    }
    this.accountsShared.resetForm(this.form);
  }

  async uploadHanlder(event: any) {
    if (event?.files?.length) {
      const formData = new FormData();
      formData.append('file', event.files[0]);
      try {
        const result = await this.accountsStore.addAccountImage(this.item.id, formData);
        if (result) {
          this.item.apartment_image_url = result.body.apartment_image_url;
          this.notificationService.addSingle({
            severity: 'success',
            summary: this.translationService.instant('MODAL.attention'),
            detail: this.translationService.instant('MODAL.notification.image_uploaded')
          });
          return result.body.apartment_image_url;
        }
      } catch (e) {
        console.log('[Error][addAccountImage]', e);
        throwError(e);
      }
    }
  }

  async deleteHanlder() {
    try {
      const result = await this.accountsStore.deleteAccountImage(this.item.id);
      if (result) {
        return result.status;
      }
      return null;
    } catch (e) {
      console.log('[Error][deleteAccountImage]', e);
    }
  }

  ngOnInit(): void {
    this.isView = this.route.snapshot?.data?.isView;
    this.isEdit = this.route.snapshot?.data?.isEdit;
    if (this.isEdit || this.isView) {
      this.isLoading = true;
      this.accountsStore
        .fetchAccount(this.route.snapshot.paramMap.get('id'))
        .then((data) => {
          this.item = data;
          this.item.owner_pet_weight = data.pets?.length > 0 ? data.pets[0].weight_kg : null;
          this.item.owner_pet = data.pets?.length > 0;
          this.isLoading = false;
          this.sensorsStore.fetchSensors(this.item.id).then((rooms: IAccountSensor[]) => {
            this.sensorsAttachedRooms = rooms;
            this.formFields();
            this.cdr.detectChanges();
          });
          this.formFields();
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.item = {};
      this.formFields();
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.modalService.exitModal(this.form);
  }

  formFields(): void {
    this.fields = [
      {
        hooks: {
          onInit: (_) => {
            if (this.isView || this.authServise.isPilot) {
              this.form.disable();
            }

            if (this.isEdit) {
              this.form.get('is_active').enable();
            }
          }
        },
        type: 'tabs',
        fieldGroup: [
          {
            templateOptions: {
              label: 'PAGES.accounts.groups.main'
            },
            fieldGroupClassName: 'p-d-flex p-flex-column',
            fieldGroup: [
              {
                fieldGroupClassName: 'p-d-flex',
                fieldGroup: [
                  {
                    className: 'p-field p-col',
                    type: 'input',
                    key: 'owner_first_name',
                    templateOptions: {
                      minLength: 2,
                      maxLength: 42,
                      required: true,
                      label: 'PAGES.accounts.fields.owner_first_name'
                    }
                  },
                  {
                    className: 'p-field p-col',
                    type: 'input',
                    key: 'owner_last_name',
                    templateOptions: {
                      minLength: 2,
                      maxLength: 42,
                      required: true,
                      label: 'PAGES.accounts.fields.owner_last_name'
                    }
                  },
                  {
                    className: 'p-field p-col-3',
                    type: 'input',
                    key: 'gateway_id',
                    templateOptions: {
                      minLength: 3,
                      maxLength: 18,
                      required: true,
                      label: 'PAGES.accounts.fields.gateway_id'
                    }
                  },
                  {
                    className: 'p-field p-col-1',
                    type: 'switch',
                    key: 'is_active',
                    defaultValue: true,
                    templateOptions: {
                      label: 'PAGES.accounts.fields.is_active'
                    }
                  }
                ]
              },
              {
                fieldGroupClassName: 'p-d-flex',
                fieldGroup: [
                  {
                    className: 'p-field p-col',
                    type: 'mask',
                    key: 'owner_cell_phone',
                    templateOptions: {
                      placeholder: '+XXX-XX-XXX-XXXX',
                      maskString: '+000-00-000-0099',
                      pattern: '^[+0-9]{10,13}$',
                      required: true,
                      label: 'PAGES.accounts.fields.owner_cell_phone'
                    }
                  },
                  {
                    className: 'p-field p-col',
                    type: 'mask',
                    key: 'owner_home_phone',
                    templateOptions: {
                      placeholder: '+XXX-XX-XXX-XXXX',
                      maskString: '+000-00-000-0099',
                      pattern: '^[+0-9]{10,13}$',
                      label: 'PAGES.accounts.fields.owner_home_phone',
                      required: true
                    }
                  },
                  {
                    className: 'p-field p-col',
                    type: 'input',
                    key: 'owner_account_email',
                    templateOptions: {
                      type: 'email',
                      pattern: '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}',
                      label: 'PAGES.accounts.fields.owner_account_email',
                      required: true
                    }
                  }
                ]
              },
              {
                fieldGroupClassName: 'p-d-flex',
                fieldGroup: [
                  {
                    className: 'p-field p-col',
                    type: 'dropdown',
                    key: 'owner_address_country',
                    defaultValue: 'Israel',
                    templateOptions: {
                      required: true,
                      filter: true,
                      options: this.accountsStore.fetchCountries(),
                      label: 'PAGES.accounts.fields.owner_address_country'
                    }
                  },
                  {
                    className: 'p-field p-col',
                    type: 'dropdown',
                    key: 'owner_address_city',
                    defaultValue: 'Tel Aviv',
                    templateOptions: {
                      required: true,
                      filter: true,
                      options: [],
                      label: 'PAGES.accounts.fields.owner_address_city'
                    },
                    hooks: {
                      onInit: (field: FormlyFieldConfig) => {
                        field.templateOptions.options = this.form.get('owner_address_country').valueChanges.pipe(
                          startWith(this.item?.owner_address_country || 'Israel'),
                          switchMap((country) => country !== null && this.accountsStore.fetchCities(country))
                        );
                      }
                    }
                  },
                  {
                    className: 'p-field p-col',
                    type: 'dropdown',
                    defaultValue: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    key: 'timezone_utc_offset',
                    templateOptions: {
                      required: true,
                      filter: true,
                      label: 'PAGES.accounts.fields.timezone_utc_offset',
                      options: timeZoneSorted
                    }
                  }
                ]
              },
              {
                fieldGroupClassName: 'p-d-flex',
                fieldGroup: [
                  {
                    className: 'p-col-8 p-p-0',
                    fieldGroupClassName: 'p-d-flex p-flex-column',
                    fieldGroup: [
                      {
                        fieldGroupClassName: 'p-d-flex',
                        fieldGroup: [
                          {
                            className: 'p-field p-col',
                            type: 'input',
                            key: 'owner_address_zip',
                            templateOptions: {
                              minLength: 3,
                              maxLength: 8,
                              required: true,
                              label: 'PAGES.accounts.fields.owner_address_zip'
                            }
                          },
                          {
                            className: 'p-field p-col',
                            type: 'input',
                            key: 'owner_address_street',
                            templateOptions: {
                              minLength: 2,
                              maxLength: 256,
                              required: true,
                              label: 'PAGES.accounts.fields.owner_address_street'
                            }
                          }
                        ]
                      },
                      {
                        fieldGroupClassName: 'p-d-flex',
                        fieldGroup: [
                          {
                            className: 'p-field p-col-1 p-mt-1 checkbox',
                            key: 'owner_pet',
                            type: 'checkbox',
                            templateOptions: {
                              label: 'PAGES.accounts.fields.owner_pet'
                            }
                          },
                          {
                            className: 'p-field p-col-5',
                            key: 'owner_pet_weight',
                            type: 'input',
                            templateOptions: {
                              type: 'number',
                              min: 1,
                              max: 80,
                              step: 0.1,
                              maxLength: 2,
                              minLength: 1,
                              label: 'PAGES.accounts.fields.owner_pet_weight'
                            },
                            expressionProperties: {
                              'templateOptions.required': 'model.owner_pet'
                            }
                          },
                          {
                            className: 'p-field p-col-6',
                            type: 'textarea',
                            key: 'notes',
                            templateOptions: {
                              label: 'PAGES.accounts.fields.notes'
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    className: 'p-col-4',
                    fieldGroupClassName: 'p-d-flex',
                    fieldGroup: [
                      {
                        className: 'p-field',
                        type: 'file',
                        key: 'apartment_image_url',
                        templateOptions: {
                          uploadHandler: (event) => this.uploadHanlder(event),
                          deleteHandler: () => this.deleteHanlder(),
                          required: false,
                          auto: true,
                          uploadText: 'PAGES.accounts.upload',
                          reuploadText: 'PAGES.accounts.reupload',
                          label: 'PAGES.accounts.fields.apartment_image_s3_key'
                        },
                        expressionProperties: {
                          'templateOptions.disabled': () => this.isView || this.authServise.isPilot
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ];
  }

  async deleteItem(): Promise<void> {
    const modalOptions = {
      type: 'delete_item',
      defaultFocus: 'reject',
      acceptButtonStyleClass: 'p-button-danger',
      icon: 'pi pi-info-circle big red',
      header: this.translationService.instant(`MODAL.delete_item.header`),
      message: `<div class="p-error p-my-2">${this.translationService.instant(
        `MODAL.delete_item.account_delete_warning`,
        { item: this.item.id }
      )}</div>`,
      accept: {
        callback: async () => {
          await this.accountsStore.deleteAccount(this.item.id);
          this.item = null;
          await this.router.navigate(['../../']);
        }
      }
    };
    this.modalService.showModal(modalOptions);
  }
}
