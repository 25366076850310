/**
 * @Service Sensors service for generation form fields for different sensors' types
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Injectable } from '@angular/core';
import { IFieldsProps } from '@pages/accounts/sensors/sensors.interface';
import { UpdateSensorComponent } from '@pages/accounts/sensors/update-sensor/update-sensor.component';

@Injectable()
export class SensorTypesService {
  connectedToOptions = [];
  connectedInOptions = [];

  getFields(ctx: UpdateSensorComponent): IFieldsProps {
    return {
      sofa: [
        { name: 'win_size', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 1200 },
        {
          group: 'row',
          items: [
            { name: 'movement_range_min', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 0 },
            { name: 'movement_range_max', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 1 }
          ]
        },
        {
          tab: 'main',
          group: 'row',
          name: 'baseline_weigths',
          items: [
            {
              name: 'label',
              type: 'label'
            },
            {
              name: 's1',
              type: 'input',
              templateOptionsType: 'number',
              min: -1,
              defaultValue: 20
            },
            {
              name: 's2',
              type: 'input',
              templateOptionsType: 'number',
              min: -1,
              defaultValue: 20
            },
            {
              name: 's3',
              type: 'input',
              templateOptionsType: 'number',
              min: -1,
              defaultValue: 20
            },
            {
              name: 's4',
              type: 'input',
              templateOptionsType: 'number',
              min: -1,
              defaultValue: 20
            }
          ]
        },
        { name: 'annotations_perc_thr', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 0.07 },
        { name: 'mlvl', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 10 },
        { name: 'bypass_time_diff_ms', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 300000 },
        {
          group: 'row',
          items: [
            { name: 'ss_lim_min', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 30 },
            { name: 'ss_lim_max', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 120 }
          ]
        },
        { name: 'weight_factor', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 1.00, tab:'main' },
        { name: 'load_thr', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 40, tab: 'main' },
        {
          name: 'bed_length',
          type: 'input',
          templateOptionsType: 'number',
          min: 0,
          defaultValue: 140,
          tab: 'main'
        },
        { name: 'bed_width', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 200, tab: 'main' },
        {
          group: 'row',
          tab: 'main',
          name: 'sensors_order',
          items: [
            {
              name: 'label',
              type: 'label'
            },
            {
              name: 's1',
              type: 'dropdown',
              defaultValue: 1,
              options: [
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 }
              ]
            },
            {
              name: 's2',
              type: 'dropdown',
              defaultValue: 2,
              options: [
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 }
              ],
              settings: {
                expressionProperties: {
                  'templateOptions.options': (m, state, f) => {
                    const order = m.settings[m.type]?.sensors_order;
                    const options = [
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 }
                    ].filter((el) => el.value !== order?.s1);
                    if (!options.find((el) => el.value === order?.s2)) {
                      f.formControl.setValue(options[0].value);
                    }
                    return options;
                  }
                }
              }
            },
            {
              name: 's3',
              type: 'dropdown',
              defaultValue: 3,
              options: [
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 }
              ],
              settings: {
                expressionProperties: {
                  'templateOptions.options': (m, state, f) => {
                    const order = m.settings[m.type]?.sensors_order;
                    const options = [
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 }
                    ].filter((el) => el?.value !== order?.s1 && el?.value !== order?.s2);
                    if (!options.find((el) => el.value === order?.s3)) {
                      f.formControl.setValue(options[0].value);
                    }
                    return options;
                  }
                }
              }
            },
            {
              name: 's4',
              type: 'dropdown',
              defaultValue: 4,
              options: [
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 }
              ],
              settings: {
                expressionProperties: {
                  'templateOptions.options': (m, state, f) => {
                    const order = m.settings[m.type]?.sensors_order;
                    const options = [
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 }
                    ].filter((el) => el.value !== order?.s1 && el.value !== order?.s2 && el.value !== order?.s3);
                    if (!options.find((el) => el.value === order?.s4)) {
                      f.formControl.setValue(options[0].value);
                    }
                    return options;
                  }
                }
              }
            },
            {
              type: 'image',
              settings: {
                src: '/assets/images/bed.png',
                height: '130px'
              }
            }
          ]
        },
        {
          group: 'row',
          items: [
            { name: 'db_en', type: 'switch', defaultValue: true },
            { name: 'db_depth', type: 'input', templateOptionsType: 'number', defaultValue: 5 }
          ]
        }
      ],
      bed: [
        { name: 'win_size', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 1200 },
        {
          group: 'row',
          items: [
            { name: 'movement_range_min', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 0 },
            { name: 'movement_range_max', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 1 }
          ]
        },
        {
          tab: 'main',
          group: 'row',
          name: 'baseline_weigths',
          items: [
            {
              name: 'label',
              type: 'label'
            },
            {
              name: 's1',
              type: 'input',
              templateOptionsType: 'number',
              min: -1,
              defaultValue: 20
            },
            {
              name: 's2',
              type: 'input',
              templateOptionsType: 'number',
              min: -1,
              defaultValue: 20
            },
            {
              name: 's3',
              type: 'input',
              templateOptionsType: 'number',
              min: -1,
              defaultValue: 20
            },
            {
              name: 's4',
              type: 'input',
              templateOptionsType: 'number',
              min: -1,
              defaultValue: 20
            }
          ]
        },
        { name: 'annotations_perc_thr', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 0.05 },
        { name: 'mlvl', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 0.5 },
        { name: 'bypass_time_diff_ms', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 300000 },
        {
          group: 'row',
          items: [
            { name: 'ss_lim_min', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 30 },
            { name: 'ss_lim_max', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 120 }
          ]
        },
        { name: 'weight_factor', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 1.00, tab:'main' },
        { name: 'load_thr', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 40, tab: 'main' },
        {
          name: 'bed_length',
          type: 'input',
          templateOptionsType: 'number',
          min: 0,
          defaultValue: 140,
          tab: 'main'
        },
        { name: 'bed_width', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 200, tab: 'main' },
        {
          group: 'row',
          tab: 'main',
          name: 'sensors_order',
          items: [
            {
              name: 'label',
              type: 'label'
            },
            {
              name: 's1',
              type: 'dropdown',
              defaultValue: 1,
              options: [
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 }
              ]
            },
            {
              name: 's2',
              type: 'dropdown',
              defaultValue: 2,
              options: [
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 }
              ],
              settings: {
                expressionProperties: {
                  'templateOptions.options': (m, state, f) => {
                    const order = m.settings[m.type]?.sensors_order;
                    const options = [
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 }
                    ].filter((el) => el.value !== order?.s1);
                    if (!options.find((el) => el.value === order?.s2)) {
                      f.formControl.setValue(options[0].value);
                    }
                    return options;
                  }
                }
              }
            },
            {
              name: 's3',
              type: 'dropdown',
              defaultValue: 3,
              options: [
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 }
              ],
              settings: {
                expressionProperties: {
                  'templateOptions.options': (m, state, f) => {
                    const order = m.settings[m.type]?.sensors_order;
                    const options = [
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 }
                    ].filter((el) => el?.value !== order?.s1 && el?.value !== order?.s2);
                    if (!options.find((el) => el.value === order?.s3)) {
                      f.formControl.setValue(options[0].value);
                    }
                    return options;
                  }
                }
              }
            },
            {
              name: 's4',
              type: 'dropdown',
              defaultValue: 4,
              options: [
                { label: 1, value: 1 },
                { label: 2, value: 2 },
                { label: 3, value: 3 },
                { label: 4, value: 4 }
              ],
              settings: {
                expressionProperties: {
                  'templateOptions.options': (m, state, f) => {
                    const order = m.settings[m.type]?.sensors_order;
                    const options = [
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 }
                    ].filter((el) => el.value !== order?.s1 && el.value !== order?.s2 && el.value !== order?.s3);
                    if (!options.find((el) => el.value === order?.s4)) {
                      f.formControl.setValue(options[0].value);
                    }
                    return options;
                  }
                }
              }
            },
            {
              type: 'image',
              settings: {
                src: '/assets/images/bed.png',
                height: '130px'
              }
            }
          ]
        },
        {
          group: 'row',
          items: [
            { name: 'db_en', type: 'switch', defaultValue: true },
            { name: 'db_depth', type: 'input', templateOptionsType: 'number', defaultValue: 5 }
          ]
        }
      ],
      tof: [
        { name: 'smoothing_windows', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 3 },
        { name: 'mean_smooth_window_len', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 11 },
        { name: 'mean_smooth_polyorder', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 3 },
        { name: 'mean_peaks_distance', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 5 },
        { name: 'mean_peaks_min_height', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 600 },
        { name: 'mean_diff_peaks_distance', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 5 },
        { name: 'mean_diff_peaks_min_height', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 50 },
        { name: 'temporal_history_len', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 5 },
        {
          group: 'row',
          items: [
            {
              name: 'annotations_perc_thr_min',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 0.05
            },
            {
              name: 'annotations_perc_thr_max',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 0.1
            }
          ]
        },
        { name: 'mean_diff_margin', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 2 },
        { name: 'person_contrast_factor', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 0.9 },
        {
          name: 'position_height_mm',
          type: 'input',
          templateOptionsType: 'number',
          min: 0,
          defaultValue: 2200,
          tab: 'main'
        },
        {
          group: 'row',
          name: 'fov',
          items: [
            {
              type: 'label'
            },
            {
              name: 'x',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 0
            },
            {
              name: 'y',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 0
            },
            {
              name: 'w',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 8
            },
            {
              name: 'h',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 8
            }
          ]
        },
        { name: 'frame_rate', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 15 },
        { name: 'minimum_test_height', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 1200 },
        {
          group: 'row',
          items: [
            {
              name: 'camera_horizontal_size_px',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 8
            },
            {
              name: 'camera_vertical_size_px',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 8
            }
          ]
        },
        {
          group: 'row',
          items: [
            {
              name: 'data_horizontal_size_px',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 8
            },
            {
              name: 'data_vertical_size_px',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 8
            }
          ]
        },
        {
          group: 'row',
          items: [
            {
              name: 'horizontal_camera_view_angle',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 30
            },
            {
              name: 'vertical_camera_view_angle',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 30
            }
          ]
        },
        {
          group: 'row',
          tab: 'main',
          items: [
            {
              name: 'active',
              type: 'switch',
              defaultValue: true
            }
          ]
        },
        {
          tab: 'main',
          type: 'message',
          className: 'p-d-flex p-jc-center rooms-label',
          templateOptions: {
            label: 'PAGES.sensors.fields.settings.tof.door_map'
          }
        },
        {
          group: 'row',
          tab: 'main',
          groupClassName: 'p-d-flex p-jc-center rooms-row',
          items: [
            {
              name: 'room_id',
              type: 'dropdown',
              templateOptions: {
                filter: true,
                translateOptions: false,
                placeholder: 'PAGES.sensors.fields.room.connected_to.placeholder',
                label: 'PAGES.sensors.fields.room_id',
                options: [
                  {
                    label: '---',
                    value: 'none'
                  }
                ]
              },
              settings: {
                expressionProperties: {
                  'templateOptions.options': () => this.connectedInOptions
                }
              }
            },
            {
              name: 'room_out_id',
              type: 'dropdown',
              templateOptions: {
                filter: true,
                required: true,
                translateOptions: false,
                placeholder: 'PAGES.sensors.fields.room.connected_to.placeholder',
                label: 'PAGES.sensors.fields.room_out_id',
                options: [
                  {
                    label: '---',
                    value: 'none'
                  }
                ]
              },
              settings: {
                expressionProperties: {
                  'templateOptions.options': () => this.connectedToOptions
                }
              }
            }
          ]
        },
        {
          group: 'row',
          groupClassName: 'p-col',
          tab: 'main',
          items: [
            {
              name: 'door_map',
              type: 'door',
              templateOptions: {
                labelsOut: ctx?.translate.stream('PAGES.sensors.fields.settings.tof.door_out'),
                labelsIn: ctx?.translate.stream('PAGES.sensors.fields.settings.tof.door_in'),
                label: 'PAGES.sensors.fields.settings.tof.door_map'
              },
              settings: {
                expressionProperties: {
                  'templateOptions.doorHidden': (model) => !model.settings.tof.active,
                  'templateOptions.disabled': () => ctx?.isEdit === undefined,
                  'templateOptions.options': () => this.connectedToOptions
                }
              }
            }
          ]
        },
        { name: 'shoulders_height_conf_boost', type: 'input', templateOptionsType: 'number', defaultValue: 1.1 },
        { name: 'max_height', type: 'input', templateOptionsType: 'number', defaultValue: 2000 },
        { name: 'min_distance', type: 'input', templateOptionsType: 'number', defaultValue: 1 },
        { name: 'min_door_overlap', type: 'input', templateOptionsType: 'number', defaultValue: 0.4 },
        { name: 'max_speed', type: 'input', templateOptionsType: 'number', defaultValue: 15 },
        { name: 'min_fidelity_thr', type: 'input', templateOptionsType: 'number', defaultValue: 0.4 },
        {
          group: 'row',
          items: [
            { name: 'angle_correction_tilt_x', type: 'input', templateOptionsType: 'number', defaultValue: 0 },
            { name: 'angle_correction_tilt_y', type: 'input', templateOptionsType: 'number', defaultValue: 0 }
          ]
        },
        {
          group: 'row',
          items: [
            { name: 'roi_margin_x', type: 'input', templateOptionsType: 'number', defaultValue: 0 },
            { name: 'roi_margin_y', type: 'input', templateOptionsType: 'number', defaultValue: 0 }
          ]
        }
      ],
      lavatory: [
        { name: 'window_size', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 2 },
        { name: 'load_bin', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 1 },
        { name: 'distance_bin', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 5 },
        {
          group: 'row',
          items: [
            { name: 'sample_rate_load', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 20000 },
            { name: 'sample_rate_tof', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 10 }
          ]
        },
        { name: 'stability_min_thr', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 0.3 },
        { name: 'speed_min_thr', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 0.5 },
        {
          group: 'row',
          items: [
            { name: 'hist_load_idx_start', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 0 },
            { name: 'hist_load_idx_end', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 100 }
          ]
        },
        {
          group: 'row',
          items: [
            {
              name: 'hist_distance_idx_start',
              type: 'input',
              templateOptionsType: 'number',
              min: 0,
              defaultValue: 100
            },
            { name: 'hist_distance_idx_end', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 120 }
          ]
        },
        { name: 'total_time_idx', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 120 },
        { name: 'sit_sec_min_thr', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 120 },
        { name: 'stand_sec_min_thr', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 30 },
        { name: 'sit_kg_min_thr', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 20 },
        { name: 'sit_weight_kg', type: 'input', templateOptionsType: 'number', min: 0, required: true },
        { name: 'weight_factor', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 1.00 },
        { name: 'annotations_perc_thr', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 0.05 },
        { name: 'bin0_scale_factor', type: 'input', templateOptionsType: 'number', min: 0, defaultValue: 0.8 }
      ],
      panic_button: [],
      proximity: [],
      open_close: [],
      temperature: []
    };
  }

  getSensorType = (ctx: UpdateSensorComponent, type: string, options = null, tab = 'parameters'): FormlyFieldConfig[] =>
    this.getFields(ctx)[type]?.filter((field) => (!field.tab ? tab === 'parameters' : tab === field.tab))
      .map((el) => {
        // exception for fields where we need pass server requested options from parent component
        if (type === 'tof' && options?.options.length > 0 && el.name === 'room_in_out') {
          el.options = [{ label: 'DEFAULTS.select_option', value: '' }, ...options.options];
        }

        if (type === 'open_close' && options?.options.length > 0 && el.name === 'room_in_out') {
          el.options = [{ label: 'DEFAULTS.select_option', value: '' }, ...options.options];
        }

        const fieldGroup = {
          className: tab === 'main' ? 'p-field p-col-12' : 'p-field p-col-6',
          fieldGroupClassName: 'p-grid p-ai-center ' + el.groupClassName,
          fieldGroup: el?.items?.map((it) => {
            switch (it?.type) {
              case 'label':
                return {
                  className: 'p-field ' + el.group === 'row' ? 'p-col-12' : 'p-col',
                  type: it.type,
                  templateOptions: {
                    ...el.templateOptions,
                    label: el?.templateOptions?.label || `PAGES.sensors.fields.settings.${type}.${el.name}.${it.type}`
                  }
                };
              case 'image':
                return {
                  className: 'p-field ' + el.group === 'row' ? 'p-col-12' : 'p-col',
                  template: `<div class="sensors-image">
                                            <img src="${it.settings.src}" alt="" />
                                           </div>`
                };
              default:
                const groupClass = el.group === 'row' ? (el.groupClassName ? el.groupClassName : 'p-col') : 'p-col-12';
                return {
                  className: 'p-field ' + groupClass,
                  type: it.type,
                  defaultValue: it?.defaultValue,
                  key: el.name
                    ? 'settings.' + type + '.' + el.name + '.' + it.name
                    : 'settings.' + type + '.' + it.name,
                  templateOptions: {
                    ...it.templateOptions,
                    min: it.min,
                    type: it.templateOptionsType ? it.templateOptionsType : '',
                    label: el.name
                      ? it.templateOptions?.label || `PAGES.sensors.fields.settings.${type}.${el.name}.${it.name}`
                      : it.templateOptions?.label || `PAGES.sensors.fields.settings.${type}.${it.name}`,
                    options: it.options ?? null,
                    required: it?.templateOptions?.required ?? true,
                    optionDisabled: it.settings?.optionDisabled
                  },
                  hooks: it.settings?.hooks,
                  expressionProperties: it.settings?.expressionProperties
                };
            }
          })
        };
        const result = {
          className: tab === 'main' ? 'p-field p-col-12 ' + el?.className : 'p-field p-col-6 ' + el.className,
          type: el.type,
          defaultValue: el.defaultValue,
          key: el.name ? 'settings.' + type + '.' + el.name : null,
          templateOptions: {
            ...el.templateOptions,
            optionDisabled: el.optionDisabled,
            min: el.min,
            type: el.templateOptionsType ? el.templateOptionsType : '',
            label:
              el.name
                ? el.templateOptions?.label || `PAGES.sensors.fields.settings.${type}.${el.name}`
                : el.templateOptions?.label || null,
            options: el.options ?? null,
            required: el?.required || true
          },
          expressionProperties: el.settings?.expressionProperties,
          hooks: el.settings?.hooks
        };
        return !el?.items ? result : fieldGroup;
      });
}
