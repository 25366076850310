/**
 * @Component Custom repeating component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-array-value',
  template: `
    <h4>{{ to.label }}</h4>
    <div class="p-grid">
      <ng-container *ngFor="let fieldItem of field.fieldGroup">
        <formly-field class="p-field p-col-3" [field]="fieldItem"></formly-field>
      </ng-container>
    </div>
  `
})
export class FormlyFieldArrayComponent extends FieldArrayType implements OnInit {
  ngOnInit(): void {
    this.field.fieldGroup.forEach((el, i) => {
      el.fieldGroup[0].templateOptions.label = el.fieldGroup[0].templateOptions.labels[i];
      el.fieldGroup[0].defaultValue = el.fieldGroup[0].templateOptions.defaultValues[i];
    });
  }
}
