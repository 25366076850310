<div *ngIf="item else loading">
  <div class="p-d-flex p-jc-between p-ai-center p-mb-2">
    <div class="p-d-flex p-ai-center" *ngIf="editView !== true">
      <button (click)="location.back()"
              class="p-button-rounded"
              icon="pi pi-arrow-left"
              pButton
              pRipple
              style="min-width:30px"
              type="button">
      </button>
      <h1 class="p-mx-2">
        {{ 'PAGES.notifications.details' | translate }} ID: {{ item.id }}
      </h1>
    </div>
    <app-sub-nav
      *ngIf="accountId!=='' && accountId!==undefined && editView !== true"
      [id]="accountId"
      [path]="'accounts'"
      class="p-mb-2 p-as-end">
    </app-sub-nav>
  </div>
  <p-tabView>
    <p-tabPanel header="{{ 'PAGES.notifications.groups.detailed' | translate }}">
      <div *ngFor="let col of cols" class="p-d-flex p-flex-column p-p-0">
        <div class="p-d-flex p-p-0 p-col-12 p-grid-row-margin">
          <div class="p-col-2 p-grid-header-style">
            <strong>{{ 'PAGES.notifications.fields.' + col.name | translate}}</strong>
          </div>
          <div class="p-col-10 p-grid-row-style">
            <ng-container *ngIf="!col['component'] else components">
              {{item[col.name]}}
            </ng-container>
            <ng-template #components>
              <ng-container [ngSwitch]="col['component']">
                <ng-container *ngSwitchCase="'created_at'">
                  {{ item[col.name] | date:'medium' }}
                </ng-container>
                <ng-container *ngSwitchCase="'algo_type'">
                  {{ 'PAGES.rules.algo_keys.' + item[col.name] | translate }}
                </ng-container>
                <ng-container *ngSwitchCase="'rule_id'">
                  <a [ngStyle]="{'font-weight': 'bold'}"
                     [routerLink]="['/pages', 'accounts', item['account_id'], 'rules', item[col.name]]">{{item[col.name]}}</a>
                </ng-container>
                <ng-container *ngSwitchCase="'sensor_id'">
                  <ng-container *ngFor="let id of item[col.name]; let last = last">
                    <a [ngStyle]="{'font-weight': 'bold'}"
                       [routerLink]="['/pages', 'accounts', item['account_id'], 'sensors', id]">{{ id }}{{!last ? ', ':''}}</a>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'triggered_sensor'">
                  <ng-container *ngFor="let id of item[col.name]; let last = last">
                    <a [ngStyle]="{'font-weight': 'bold'}"
                       [routerLink]="['/pages', 'accounts', item['account_id'], 'sensors', id]">{{ id }}{{!last ? ', ':''}}</a>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'close_reason'">
                  <ng-container *ngIf="item[col.name]">
                    {{ 'PAGES.notifications.fields.reasons.' + item[col.name] | translate }}
                  </ng-container>
                  <ng-container *ngIf="!item[col.name]">---</ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'notes'">
                  <ng-container *ngIf="item[col.name]">
                    {{ item[col.name] }}
                  </ng-container>
                  <ng-container *ngIf="!item[col.name]">---</ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'translation'">
                  {{ 'PAGES.notifications.' + col.name + '.' + item[col.name] | translate }}
                </ng-container>
                <ng-container *ngSwitchCase="'time_range'">
                  {{ item['time_start'] }} - {{ item['time_end'] }}
                  <span *ngIf="item['time_start'] >= item['time_end']">(+1)</span>
                </ng-container>
                <ng-container *ngSwitchCase="'extra_values'">
                  <ng-container *ngIf="item?.extra_values?.base_value">
                    {{ 'PAGES.notifications.fields.extra_values.base_value' | translate }}
                    : {{ item?.extra_values?.base_value }}
                    {{ 'PAGES.rules.base_units_short.' + item?.extra_values?.base_value_unit | translate }}
                    <br>
                  </ng-container>
                  <ng-container *ngIf="item?.extra_values?.actual_value">
                    {{ 'PAGES.notifications.fields.extra_values.actual_value' | translate }}
                    : {{ item?.extra_values?.actual_value }}
                    {{ 'PAGES.rules.base_units_short.' + item?.extra_values?.base_value_unit | translate }}
                    <br>
                  </ng-container>
                  <ng-container>
                    {{ 'PAGES.notifications.fields.extra_values.limits' | translate }}
                    : {{ item?.extra_values?.low_value }} ... {{ item?.extra_values?.high_value }}
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
  <app-sub-options-buttons
    *ngIf="editView !== true"
    [item]="item"
    label="{{ 'DEFAULTS.edit' | translate }}"
  ></app-sub-options-buttons>
</div>
<ng-template #loading></ng-template>


