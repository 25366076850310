/**
 * @Component main entry layout component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Component, OnInit } from '@angular/core';
import { GuiStore } from '@stores/gui.store';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ELanguages } from '@app/const/config.interface';

@UntilDestroy()
@Component({
  selector: 'app-layout-container',
  templateUrl: './layout-container.component.html',
  styleUrls: ['./layout-container.component.scss']
})
export class LayoutContainerComponent implements OnInit {
  ELanguages = ELanguages;

  constructor(public guiStore: GuiStore, private translationService: TranslateService, private config: PrimeNGConfig) {}

  ngOnInit(): void {
    this.translationService.onLangChange.pipe(untilDestroyed(this)).subscribe((l) => {
      this.translate(l.lang);
    });
  }

  translate(lang: string): void {
    this.translationService.use(lang);
    this.translationService
      .get('primeng')
      .pipe(untilDestroyed(this))
      .subscribe((res) => this.config.setTranslation(res));
  }
}
