/**
 * @Service Sensors service for CRUD actions with API server.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { PagesSharedModule } from '@pages/pages-shared.module';

import { NAVIGATION } from '@app/const';
import { environment } from '@environments/environment';

import { IAccountSensor } from '@pages/accounts/sensors/sensors.interface';
import { lastValueFrom, map } from 'rxjs';

@Injectable()
export class SensorsService {
  apiURL = environment.apiUrl;

  constructor(private http: HttpClient) {}

  api = (id) => `${this.apiURL}/${NAVIGATION.accounts.api}/${id}/${NAVIGATION.sensors.api}`;

  getSensors(accountId: string): Promise<HttpResponse<IAccountSensor[]>> {
    return lastValueFrom(
      this.http
        .get<IAccountSensor[]>(this.api(accountId), {
          observe: 'response'
        })
        .pipe(map((res: HttpResponse<IAccountSensor[]>) => res))
    );
  }

  getSensor(accountId: string, id: string): Promise<IAccountSensor> {
    return lastValueFrom(
      this.http
        .get<IAccountSensor>(`${this.api(accountId)}/${id}`, {
          observe: 'response'
        })
        .pipe(map((res: HttpResponse<IAccountSensor>) => res.body))
    );
  }

  addSensor(accountId: string, body: IAccountSensor): Promise<any> {
    return lastValueFrom(
      this.http.post<IAccountSensor>(this.api(accountId), body, {
        observe: 'response'
      })
    );
  }

  editSensor(accountId: string, body: IAccountSensor): Promise<any> {
    return lastValueFrom(
      this.http.put<IAccountSensor>(`${this.api(accountId)}/${body.id}`, body, {
        observe: 'response'
      })
    );
  }

  deleteSensor(accountId: string, id: string): Promise<any> {
    return lastValueFrom(
      this.http.delete<any>(`${this.api(accountId)}/${id}`, {
        observe: 'response'
      })
    );
  }
}
