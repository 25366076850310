/**
 * @Component Notifications view component for selected id
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */
import { Component, Input, OnInit } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { NotificationsStore } from '@pages/notifications/notifications.store';

import { INotificationItemColumns, INotificationView } from '@pages/notifications/notifications.interface';
import { TranslateService } from '@ngx-translate/core';
import { UtilityService } from '@services/utility.service';

@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html'
})
export class ViewNotificationComponent implements OnInit {
  @Input() editView: boolean;
  id: string;
  item: INotificationView | any;
  cols: INotificationItemColumns[];
  isLoading = false;
  accountId: string;

  constructor(
    private route: ActivatedRoute,
    private utility: UtilityService,
    private notificationsStore: NotificationsStore,
    public location: Location,
    private translate: TranslateService
  ) {}

  mapFields(data): void {
    const formattedDate = formatDate(new Date(data.created_at), `YYYY-MM-dd HH:mm:ss`, 'en_US');
    const timeStart = data.rule?.time_start.substr(0, 5) || '00:00';
    const timeEnd = data.rule?.time_end.substr(0, 5) || '24:00';
    // noinspection UnnecessaryLocalVariableJS
    const res = {
      id: data.id,
      created_at: formattedDate,
      date_time_date: formattedDate.split(' ')[1],
      date_time_year: formattedDate.split(' ')[0],
      account_id: data.account.id,
      account_name: data.account?.owner_first_name + ' ' + data?.account.owner_last_name,
      time_start: timeStart,
      time_end: timeEnd,
      time_range: timeStart + ' - ' + (timeEnd < timeStart ? timeEnd + '(+1)' : timeEnd),
      service_provider_id: data?.person.service_provider_id || '---',
      resident_name: data?.person.first_name + ' ' + data?.person.last_name,
      rule_type: data.rule ? this.translate.instant(`PAGES.rules.rule_keys.${data.rule?.rule_type.type_key}`) : '---',
      rule_id: data.rule ? data.rule.id : '',
      algo_type: data.algorithm_type ? data.algorithm_type.type_key : '',
      type: data?.notification_type.type_key,
      sensor_id: data?.rule?.sensor_ids,
      triggered_sensor: this.utility.notificationTriggeredSensor(data),
      extra_values: {
        base_value: data.rule?.fix_threshold?.base_value || '',
        actual_value: this.utility.notificationActualValueParsed(data),
        base_value_unit: data.rule?.fix_threshold?.base_value_unit,
        high_value: data.rule?.fix_threshold?.high_value,
        high_value_unit: data.rule?.fix_threshold?.high_value_unit,
        low_value: data.rule?.fix_threshold?.low_value,
        low_value_unit: data.rule?.fix_threshold?.low_value_unit
      },
      state: data?.state,
      close_reason: data?.close_reason?.id || '',
      notes: data?.message || ''
    };

    this.item = res;
  }

  async ngOnInit(): Promise<void> {
    if (!this.notificationsStore.currentNotificationTypesAll) {
      try {
        await this.notificationsStore.fetchNotificationsTypes();
      } catch (e) {
        console.log('[R][fetchNotificationsTypes]', e);
      }
    }

    this.id = this.route.snapshot.paramMap.get('id');
    this.accountId = this.route.snapshot.paramMap.get('aid');
    this.isLoading = true;
    this.notificationsStore
      .fetchNotification(this.id)
      .then((data) => {
        this.mapFields(data);
        this.showColumns();
        this.isLoading = false;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  showColumns(): void {
    this.cols = [
      { name: 'id' },
      { name: 'service_provider_id' },
      { name: 'type', component: 'translation' },
      { name: 'rule_type' },
      { name: 'rule_id', component: 'rule_id' },
      { name: 'algo_type', component: 'algo_type' },
      { name: 'reason' },
      { name: 'created_at' },
      { name: 'account_id' },
      { name: 'account_name' },
      { name: 'resident_name' },
      { name: 'sensor_id', component: 'sensor_id' },
      { field: 'triggered_sensor', name: 'triggered_sensor', component: 'triggered_sensor' },
      { name: 'close_reason', component: 'close_reason' },
      { name: 'notes', component: 'notes' },
      { name: 'state', component: 'translation' },
      { name: 'time_range', component: 'time_range' },
      { name: 'extra_values', component: 'extra_values' }
    ].filter((el) => this.item[el.name] !== undefined && this.item[el.name] !== null);
  }
}
