<!--@ts-ignore-->
<div *mobxAutorun="{ detach: false }">
  <div class="p-d-flex p-jc-between p-ai-center">
    <app-sub-heading-reports
      [account]="reportsStore.account"
      [person]="reportsStore.person"
    ></app-sub-heading-reports>
    <app-sub-nav
      *ngIf="accountIdRoute"
      [id]="accountId.toString()"
      [path]="'accounts'"
      class="p-mb-2 p-as-end">
    </app-sub-nav>
  </div>
  <div class="p-card p-pt-4 p-pb-2 p-mt-3">
    <div class="p-d-flex p-flex-sm-column  p-flex-md-row p-p-2 p-ai-center">
      <div class="p-col p-px-4">
        <app-sub-details-reports
          [account]="reportsStore.account"
          [person]="reportsStore.person"
        ></app-sub-details-reports>
      </div>
      <div class="p-col">
        <formly-form
          (modelChange)="onModelChange($event)"
          [fields]="fields"
          [form]="form"
          [model]="reportsStore.currentSelectedModel"
        ></formly-form>
      </div>
    </div>
    <div class="p-px-4 p-d-flex p-jc-end p-pt-2 p-mb-0">{{ 'PAGES.reports.selected_day' | translate }}
      : {{ reportsStore.currentSelectedModel?.date | date:'YYYY/MM/dd'}}</div>
    <p-divider></p-divider>
    <div class="p-m-3 p-p-3">
      <h4 class="p-d-flex p-jc-center">{{ 'PAGES.reports.room_activity' | translate }}</h4>
      <div *ngIf="reportsStore.currentLoading.activity else chart">
        <ng-container [ngTemplateOutlet]="loading"></ng-container>
      </div>
      <ng-template #chart>
        <div *ngIf="reportsStore?.report?.length > 0 else noData" class="p-grid">
          <div class="p-grid p-col-12 p-ai-end">
            <div class="p-col p-d-flex p-jc-between p-mx-2">
              <formly-form
                (modelChange)="onRoomFilterChange($event)"
                [fields]="roomFields"
                [form]="roomFilterform"
                [options]="optionsRoomFilter"
                [model]="reportsStore.currentSelectedRoomModel"
              ></formly-form>
            </div>
            <div class="p-col">
              <div class="p-d-flex p-jc-end p-flex-nowrap" style="min-width: 10rem;">
                <button (click)="zoomIn()" pButton pRipple
                        pTooltip="{{ 'PAGES.reports.zoom_in' | translate }}"
                        tooltipPosition="top"
                        type="button"
                        icon="pi pi-search-plus"
                        class="p-button-rounded p-button-secondary p-button-outlined p-mx-1">
                </button>
                <button (click)="zoomOut()" pButton pRipple
                        pTooltip="{{ 'PAGES.reports.zoom_out' | translate }}"
                        tooltipPosition="top"
                        type="button"
                        icon="pi pi-search-minus"
                        class="p-button-rounded p-button-secondary p-button-outlined p-mx-1">
                </button>
                <button (click)="zoomReset()" pButton pRipple
                        pTooltip="{{ 'PAGES.reports.zoom_reset' | translate }}"
                        tooltipPosition="top"
                        type="button" icon="pi pi-ban"
                        class="p-button-rounded p-button-secondary p-button-outlined p-mx-1">
                </button>
                <button (click)="toggleGroups()" pButton pRipple
                        [ngClass]="{'p-button-help': showGroups}"
                        pTooltip="{{ 'PAGES.reports.show_groups' | translate }}"
                        tooltipPosition="top"
                        type="button"
                        icon="pi pi-th-large"
                        class="p-button-rounded p-button-secondary p-button-outlined p-mx-1">
                </button>
                <button (click)="toggleColors()" pButton pRipple
                        [ngClass]="{'p-button-help': showColors}"
                        pTooltip="{{ 'PAGES.reports.show_colors' | translate }}"
                        tooltipPosition="top"
                        type="button"
                        icon="pi pi-palette"
                        class="p-button-rounded p-button-secondary p-button-outlined p-mx-1">
                </button>
              </div>
            </div>
          </div>
          <div>
            <app-sub-chart-report [data]="reportsStore.fileredReport"
                                  [rooms]="roomsStore.rooms"
                                  [showGroups]="showGroups"
                                  [showColors]="showColors"
            ></app-sub-chart-report>
          </div>
        </div>
      </ng-template>

      <h4 class="p-d-flex p-jc-center">{{ 'PAGES.reports.notification_activity' | translate }}</h4>

      <div *ngIf="reportsStore.currentLoading.notifications else notificationChart">
        <ng-container [ngTemplateOutlet]="loading"></ng-container>
      </div>
      <ng-template #notificationChart>
        <div *ngIf="reportsStore?.notificationsReport?.length > 0 else noData" class="p-grid">
          <app-sub-notifications-chart-report
            [data]="reportsStore.notificationsReport"
          ></app-sub-notifications-chart-report>
        </div>
      </ng-template>
      <ng-template #noData>
        <div class="p-d-flex p-jc-center">{{ 'DEFAULTS.no_data_found' | translate }}</div>
      </ng-template>
      <ng-template #loading>
        <div class="p-d-flex p-jc-center">
          <app-spinner-component></app-spinner-component>
        </div>
      </ng-template>
    </div>
  </div>
</div>

