/**
 * @Component Custom input component using prime-ng inputText
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-formly-field-input',
  template: `
    <div class="p-d-flex p-flex-column">
      <input
        value="{{ this.model[field.key.toString()] | mask: to.maskString }}"
        id="{{ field.key }}"
        pInputText
        [mask]="to.maskString || ''"
        [type]="to.type || 'text'"
        [formControl]="fControl"
        [formlyAttributes]="field"
      />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFieldInputComponent extends FieldType {
  public get fControl() {
    return this.formControl as UntypedFormControl;
  }
}
