/**
 * @Module Notification's module.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@modules/shared.module';
import { PagesSharedModule } from '@pages/pages-shared.module';
import { NotificationsRoutingModule } from '@pages/notifications/notifications-routing.module';

import { AccountsStore } from '@pages/accounts/accounts/accounts.store';

import { AccountsService } from '@pages/accounts/accounts/accounts.service';

import { NotificationsComponent } from '@pages/notifications/notifications.component';
import { UpdateNotificationComponent } from '@pages/notifications/update-notification/update-notification.component';
import { ViewNotificationComponent } from '@pages/notifications/view-notification/view-notification.component';
import { FilterListComponent } from './filter-list/filter-list.component';

@NgModule({
  declarations: [NotificationsComponent, UpdateNotificationComponent, ViewNotificationComponent, FilterListComponent],
  imports: [CommonModule, NotificationsRoutingModule, SharedModule, PagesSharedModule],
  providers: [AccountsStore, AccountsService]
})
export class NotificationsModule {}
