import { TranslateService } from '@ngx-translate/core';
import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { map } from 'rxjs/operators';

export class TranslateExtension {
  constructor(private translate: TranslateService) {}

  prePopulate(field: FormlyFieldConfig): void {
    const to: FormlyTemplateOptions = field.templateOptions || {};

    if (field.templateOptions.text) {
      field.expressionProperties = {
        ...(field.expressionProperties || {}),
        'templateOptions.text': this.translate.stream(field.templateOptions.text)
      };
    }

    if (!to.label) {
      return;
    }

    field.expressionProperties = {
      ...(field.expressionProperties || {}),
      'templateOptions.label': this.translate.stream(field.templateOptions.label)
    };
    if (field.templateOptions.placeholder) {
      field.expressionProperties = {
        ...(field.expressionProperties || {}),
        'templateOptions.placeholder': this.translate.stream(field.templateOptions.placeholder)
      };
    }
    if (field.templateOptions.type === 'email') {
      field.validators.email = {
        ...(field.validators.email || {}),
        message: this.translate.stream('FORM.validation.email')
      };
    }
    if (field.templateOptions?.uploadText) {
      field.expressionProperties = {
        ...(field.expressionProperties || {}),
        'templateOptions.uploadText': this.translate.stream(field.templateOptions.uploadText)
      };
    }
    if (field.templateOptions?.reuploadText) {
      field.expressionProperties = {
        ...(field.expressionProperties || {}),
        'templateOptions.reuploadText': this.translate.stream(field.templateOptions.reuploadText)
      };
    }
    if (Array.isArray(field.templateOptions?.options)) {
      if (to?.translateOptions === false) {
        return;
      }
      const options = field.templateOptions.options;
      if (options.length) {
        field.templateOptions.options = this.translate
          .stream(options?.map((o) => o?.label))
          .pipe(map((labels) => options?.map((o) => ({ ...o, label: labels[o?.label] }))));
      }
    }
  }
}

export function registerTranslateExtension(
  translate: TranslateService
): { extensions: { extension: TranslateExtension; name: string }[] } {
  return {
    extensions: [
      {
        name: 'translate-extension',
        extension: new TranslateExtension(translate)
      }
    ]
  };
}
