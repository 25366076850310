/**
 * @Service HTTP interceptor service for operating with http request and errors
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { NotificationService } from "@services/notification.service";
import { AuthService } from "@app/core/services/auth.service";
import { LoadingIndicatorService } from "@services/loading-indicator.service";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private excludedApis = ["gateway"];

  constructor(
    private loadingIndicator: LoadingIndicatorService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
  }

  /**
   * @description Utility method for checking that part of an api string is existed in the excluded api url list
   * @param url
   * @return boolean
   */
  private checkExcludedApi = (url: string): boolean => this.excludedApis.some(el => url.indexOf(el) > -1);

  /**
   * @description Intercepting реез request and checking for errors
   * If errors occurs making some actions and displaying notifications
   * @param request
   * @param next
   * @return request next to the middleware que
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingIndicator.addTask(request.url);

    const isExcludedApis = this.checkExcludedApi(request.url) || false;

    request = request.clone({
      headers: request.headers.set("Accept", "application/json")
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loadingIndicator.removeTask(request.url);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const data = {
          status: "Error: " + error.status,
          reason: error?.error?.message ? error?.error.message : error?.statusText ? error.statusText : ""
        };
        if (error.status === 401) {
          this.authService.doLogout();
        }
        if (!isExcludedApis && error.status === 500) {
          this.notificationService.addSingle({
            severity: "error",
            summary: data.status.toString(),
            detail: data.reason
          });
        }
        this.loadingIndicator.removeTask(request.url);
        return throwError(() => new Error(error.message));
      })
    );
  }
}
