import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: 'app-formly-field-primeng-datepicker',
  template: `
    <p-calendar
      [disabled]="to.disabled"
      [id]="field.key.toString()"
      [defaultDate]="to?.defaultDate"
      [dateFormat]="to?.dateFormat"
      [hourFormat]="to.hourFormat"
      [showTime]="to.showTime"
      [showSeconds]="to.showSeconds"
      [showIcon]="to.showIcon"
      [showButtonBar]="to.showButtonBar"
      [showOtherMonths]="to.showOtherMonths"
      [selectOtherMonths]="to.selectOtherMonths"
      [selectionMode]="to.selectionMode || 'single'"
      [numberOfMonths]="to.numberOfMonths"
      [inline]="to.inline"
      [readonlyInput]="to.readonlyInput"
      [touchUI]="to.touchUI"
      [monthNavigator]="to.monthNavigator"
      [yearNavigator]="to.yearNavigator"
      [yearRange]="to.yearRange"
      [placeholder]="to.placeholder"
      [formControl]="fControl"
      [formlyAttributes]="field"
    >
    </p-calendar>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyDatePickerComponent extends FieldType {
  public get fControl() {
    return this.formControl as UntypedFormControl;
  }
}
