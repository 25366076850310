/**
 * @Service Notification's service for CRUD actions with API server.
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */

import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { NAVIGATION } from "@app/const";
import { environment } from "@environments/environment";
import {
  INotificationEditRequest,
  INotificationItem,
  INotificationQueryRequest,
  INotificationsResponse,
  INotificationTypesRes
} from "@pages/notifications/notifications.interface";
import { lastValueFrom, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class NotificationsService {
  apiURL = environment.apiUrl;
  api = `${this.apiURL}/${NAVIGATION.notifications.api}`;

  constructor(private http: HttpClient) {
  }

  // *********** Notifications ************ //

  getNotificationTypes(): Promise<INotificationTypesRes> {
    return lastValueFrom(this.http
      .get<INotificationTypesRes>(`${this.api}/types`, {
        observe: "response"
      })
      .pipe(map((res: HttpResponse<INotificationTypesRes>) => res.body)));
  }

  getNotificationsCondition(condition: INotificationQueryRequest): Promise<HttpResponse<INotificationsResponse>> {
    let paginationQuery = "";

    for (const item in condition.query) {
      if (condition.query.hasOwnProperty(item)) {
        paginationQuery = paginationQuery + `&${item}=${condition.query[item]}`;
      }
    }

    paginationQuery = "?" + paginationQuery.substr(1);
    return lastValueFrom(this.http
      .post<INotificationsResponse>(
        `${this.api}/${paginationQuery}`,
        {
          ...condition.filter
        },
        {
          observe: "response"
        }
      )
      .pipe(map((res: HttpResponse<INotificationsResponse>) => res)));
  }

  getNotification(id: string): Promise<HttpResponse<INotificationItem>> {
    return lastValueFrom(this.http
      .get<INotificationItem>(`${this.api}/${id}`, {
        observe: "response"
      })
      .pipe(map((res: HttpResponse<INotificationItem>) => res)));
  }

  editNotification(body: INotificationEditRequest): Promise<HttpResponse<INotificationItem>> {
    return lastValueFrom(this.http
      .put<INotificationItem>(`${this.api}/${body.id}`, body, {
        observe: "response"
      })
      .pipe(map((res: HttpResponse<INotificationItem>) => res)));
  }

  deleteNotification(id: string): Promise<any> {
    return lastValueFrom(this.http
      .delete<any>(`${this.api}/${id}`, {
        observe: "response"
      }));
  }
}
