<div><strong>{{ 'PAGES.reports.person' | translate }}</strong>
    : {{ person?.first_name || '---' }} {{ person?.last_name || '' }} ({{person?.id}})
</div>
<div>
    <strong>{{ 'PAGES.persons.fields.passport_id' | translate }}</strong>: {{ person?.passport_id || '---'}}
</div>
<div>
    <strong>{{ 'PAGES.persons.fields.home_phone' | translate }}</strong>: {{ person?.home_phone || '---'}},
    <strong>{{ 'PAGES.persons.fields.cell_phone' | translate }}</strong>: {{ person?.cell_phone || '---'}}
</div>
<div><strong>{{ 'PAGES.accounts.fields.address' | translate }}</strong>:
    {{ account?.owner_address_street || '---' }},
    {{ account?.owner_address_city || '---' }},
    {{ account?.owner_address_country || '---' }}
</div>
