/**
 * @Component Custom color picker component
 * @Project: TrendLines
 * @Author: EMG-SOFT, www.emg-soft.com
 */
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-color',
  template: `
    <p-colorPicker [disabled]="to.disabled" [(ngModel)]="model[field.key.toString()]"></p-colorPicker>
  `
})
export class FormlyFieldColorComponent extends FieldType {}
