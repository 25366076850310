/**
 * @Component Sensors component for creating new sensor and update selected one
 * @Project: TrendLines
 * @Author: EMG-SOFT
 */

import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

// Stores
import { GuiStore } from "@stores/gui.store";
import { AccountsStore } from "@pages/accounts/accounts/accounts.store";
import { SensorsStore } from "@pages/accounts/sensors/sensors.store";
import { RoomsStore } from "@pages/accounts/rooms/rooms.store";

// Services
import { TranslateService } from "@ngx-translate/core";
import { NotificationService } from "@services/notification.service";
import { SensorTypesService } from "@pages/accounts/sensors/sensor-types.service";
import { AccountsSharedService } from "@pages/accounts/accounts-shared.service";
import { ModalService } from "@services/modal.service";

// Helpers and interfaces
import { delay } from "@app/core/utils/async.utility";
import { NAVIGATION } from "@app/const";
import { IAccountSensor } from "@pages/accounts/sensors/sensors.interface";

@UntilDestroy()
@Component({
  selector: "app-update-sensor",
  templateUrl: "./update-sensor.component.html"
})
export class UpdateSensorComponent implements OnInit {
  isLoading = false;
  item: IAccountSensor | any;
  accountId: string;
  pId: string;
  emptyParameters = [];
  rooms: FormlyFieldConfig[] = [];
  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  connectedToOptions: any[] = [];
  connectedInOptions: any[] = [];
  isEdit: boolean;
  isView: boolean;

  constructor(
    public guiStore: GuiStore,
    public translate: TranslateService,
    public route: ActivatedRoute,
    private router: Router,
    public accountsStore: AccountsStore,
    public roomsStore: RoomsStore,
    private sensorsStore: SensorsStore,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private sensorTypes: SensorTypesService,
    private modalService: ModalService,
    private accountsShared: AccountsSharedService
  ) {
    this.model.lang = guiStore.currentLanguage;
  }

  async saveItem(): Promise<void> {
    const settings: any = {};
    if (["sofa", "bed"].includes(this.item.type)) {
      settings.load = this.item.settings[this.item.type];
    } else {
      settings[this.item.type] = this.item.settings[this.item.type];
      if (this.item.type === "tof") {
        this.item.room_id = this.item.settings.tof.room_id;
        this.item.room_out_id = this.item.settings.tof.room_out_id;
        this.item.settings.tof.doorway_ui_arrows = this.item.settings.tof.door_map.doorway_ui_arrows;
        this.item.settings.tof.doorway_ui_door = this.item.settings.tof.door_map.doorway_ui_door;
        if (!this.item.settings.tof.active) {
          this.item.settings.tof.doorway_ui_door = 0;
        }
        const { active, room_id, room_out_id, door_fov_axis, door_map, ...actualSettings } = this.item.settings.tof;
        settings.tof = actualSettings;
      }
    }

    const item = { ...this.item, settings };

    if (this.isEdit) {
      try {
        const result = await this.sensorsStore.editSensor(this.accountId, item);
        if (result?.ok) {
          this.notificationService.addSingle({
            severity: "success",
            summary: this.translate.instant("MODAL.attention"),
            detail: this.translate.instant("MODAL.notification.saved")
          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const result = await this.sensorsStore.addSensor(this.accountId, item);
        if (result?.ok) {
          this.notificationService.addSingle({
            severity: "success",
            summary: this.translate.instant("MODAL.attention"),
            detail: this.translate.instant("MODAL.notification.added")
          });
        }
        delay(2000).then(
          async () =>
            await this.router.navigate([
              NAVIGATION.pages.url,
              NAVIGATION.accounts.url,
              this.accountId,
              NAVIGATION.sensors.url
            ])
        );
      } catch (e) {
        console.log(e);
      }
    }

    this.accountsShared.resetForm(this.form);
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.modalService.exitModal(this.form);
  }

  mapFields(data: IAccountSensor): void {
    this.item = data;
    if (["sofa", "bed"].includes(this.item.type)) {
      this.item = {
        ...data,
        settings: {
          [data.type]: data.settings.load
        }
      };
    }
    if (this.item.type === "tof") {
      this.item.settings.tof.room_id = this.item.room_id;
      this.item.settings.tof.room_out_id = this.item.room_out_id;
      this.item.settings.tof.door_map = {
        doorway_ui_arrows: this.item.settings.tof.doorway_ui_arrows || 1,
        doorway_ui_door: this.item.settings.tof.doorway_ui_door
      };
      this.item.settings.tof.active = this.item.settings.tof.door_map.doorway_ui_door !== 0;
    }
  }

  ngOnInit(): void {
    this.accountId = this.route.snapshot.paramMap.get("id");
    this.pId = this.route.snapshot.paramMap.get("pid");
    this.isView = this.route.snapshot?.data?.isView;
    this.isEdit = this.route.snapshot?.data?.isEdit;
    this.emptyParameters = Object.entries(this.sensorTypes.getFields(this))
      .filter(([, value]) => value?.length === 0)
      .map(([key]) => key);
    if (this.isEdit || this.isView) {
      this.isLoading = true;
      this.sensorsStore
        .fetchSensor(this.accountId, this.pId)
        .then((data) => {
          this.mapFields(data);
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.item = {};
    }
    this.roomsStore.fetchRooms(this.accountId).then((room) => {
      this.connectedToOptions = room.map((item) => ({
        label: item.room_tag,
        value: item.id
      }));
      this.connectedInOptions = room
        ?.filter((el) => el.room_type != "room_t_outdoors")
        .map((item) => ({
          label: item.room_tag,
          value: item.id
        }));
      this.sensorTypes.connectedToOptions = [
        {
          value: "",
          label: this.translate.instant("DEFAULTS.select_option")
        },
        ...this.connectedToOptions
      ];
      this.sensorTypes.connectedInOptions = [
        {
          value: "",
          label: this.translate.instant("DEFAULTS.select_option")
        },
        ...this.connectedInOptions
      ];
      this.formFields();
      this.cdr.detectChanges();
    });

    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe((_) => {
      this.formFields();
      this.cdr.detectChanges();
    });
  }

  sensorFieldGroups(type: "main" | "parameters" = "main"): FormlyFieldConfig[] {
    return [
      {
        fieldGroupClassName: "p-grid p-col column-flow",
        fieldGroup: this.sensorTypes.getSensorType(this, "sofa", null, type),
        hideExpression: (model) => model.type !== "sofa"
      },
      {
        fieldGroupClassName: "p-grid p-col column-flow",
        fieldGroup: this.sensorTypes.getSensorType(this, "bed", null, type),
        hideExpression: (model) => model.type !== "bed"
      },
      {
        fieldGroupClassName: "p-grid p-col column-flow",
        fieldGroup: this.sensorTypes.getSensorType(
          this,
          "tof",
          {
            options: this.connectedToOptions
          },
          type
        ),
        hideExpression: (model) => model.type !== "tof"
      },
      {
        fieldGroupClassName: "p-grid p-col fullwidth column-flow",
        fieldGroup: this.sensorTypes.getSensorType(this, "lavatory", null, type),
        hideExpression: (model) => model.type !== "lavatory"
      },
      {
        fieldGroupClassName: "p-grid p-col fullwidth column-flow",
        fieldGroup: this.sensorTypes.getSensorType(this, "panic_button", null, type),
        hideExpression: (model) => model.type !== "panic_button"
      },
      {
        fieldGroupClassName: "p-grid p-col fullwidth column-flow",
        fieldGroup: this.sensorTypes.getSensorType(this, "proximity", null, type),
        hideExpression: (model) => model.type !== "proximity"
      },
      {
        fieldGroupClassName: "p-grid p-col column-flow",
        fieldGroup: this.sensorTypes.getSensorType(this, "open_close", null, type),
        hideExpression: (model) => model.type !== "open_close"
      },
      {
        fieldGroupClassName: "p-grid p-col fullwidth column-flow",
        fieldGroup: this.sensorTypes.getSensorType(this, "temperature", null, type),
        hideExpression: (model) => model.type !== "temperature"
      }
    ];
  }

  formFields(): void {
    this.fields = [
      {
        hooks: {
          onInit: (_) => {
            if (this.isView) {
              this.form.disable();
              this.cdr.detectChanges();
            }
          }
        },
        type: "tabs",
        fieldGroup: [
          {
            templateOptions: {
              label: "PAGES.sensors.groups.main"
            },
            fieldGroupClassName: "p-d-flex",
            fieldGroup: [
              {
                className: "fullwidth",
                fieldGroupClassName: "p-field",
                fieldGroup: [
                  {
                    fieldGroupClassName: "p-grid",
                    fieldGroup: [
                      {
                        className: "p-field p-col",
                        type: "input",
                        key: "tag",
                        templateOptions: {
                          minLength: 2,
                          maxLength: 24,
                          required: true,
                          label: "PAGES.sensors.fields.tag"
                        }
                      },
                      {
                        className: "p-field p-col",
                        type: "input",
                        key: "topic_prefix",
                        templateOptions: {
                          minLength: 2,
                          maxLength: 32,
                          required: true,
                          pattern: "[0-9A-Za-z_-]+$",
                          label: "PAGES.sensors.fields.topic_prefix"
                        }
                      },
                      {
                        className: "p-field p-col",
                        type: "input",
                        key: "version",
                        templateOptions: {
                          minLength: 1,
                          maxLength: 7,
                          label: "PAGES.sensors.fields.version"
                        }
                      },
                      {
                        className: "p-field p-col",
                        type: "select",
                        key: "type",
                        defaultValue: "sofa",
                        templateOptions: {
                          disabled: this.isEdit,
                          required: true,
                          label: "PAGES.sensors.fields.type",
                          options: [
                            { label: "PAGES.sensors.types.select", value: "" },
                            { label: "PAGES.sensors.types.sofa", value: "sofa" },
                            { label: "PAGES.sensors.types.bed", value: "bed" },
                            { label: "PAGES.sensors.types.tof", value: "tof" },
                            { label: "PAGES.sensors.types.toilet", value: "lavatory" },
                            {
                              label: "PAGES.sensors.types.panic_button",
                              value: "panic_button"
                            },
                            { label: "PAGES.sensors.types.occupancy", value: "proximity" },
                            { label: "PAGES.sensors.types.magnet", value: "open_close" },
                            {
                              label: "PAGES.sensors.types.temperature",
                              value: "temperature"
                            }
                          ]
                        }
                      },
                      {
                        className: "p-field p-col",
                        type: "switch",
                        key: "enabled",
                        defaultValue: true,
                        templateOptions: {
                          label: "PAGES.sensors.fields.enabled"
                        }
                      }
                    ]
                  },
                  {
                    fieldGroupClassName: "p-d-flex p-field p-row",
                    fieldGroup: [
                      {
                        fieldGroupClassName: "p-d-flex",
                        fieldGroup: [
                          {
                            className: "p-field p-col",
                            key: "room_id",
                            type: "dropdown",
                            templateOptions: {
                              filter: true,
                              translateOptions: false,
                              placeholder: "PAGES.sensors.fields.room.connected_to.placeholder",
                              label: "PAGES.sensors.fields.room_id",
                              options: [
                                {
                                  label: "---",
                                  value: null
                                },
                                ...this.connectedInOptions
                              ].filter(el=> !this.isEdit ? el.value !== null : true)
                            },
                            hideExpression: (model) => model.type === "tof"
                          },
                          {
                            className: "p-field p-col",
                            key: "room_out_id",
                            type: "dropdown",
                            templateOptions: {
                              filter: true,
                              translateOptions: false,
                              placeholder: "PAGES.sensors.fields.room.connected_to.placeholder",
                              label: "PAGES.sensors.fields.room_out_id",
                              options: [
                                {
                                  label: "---",
                                  value: "none"
                                },
                                ...this.connectedToOptions
                              ]
                            },
                            hideExpression: (model) => model.type !== "open_close"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    className: "formly-group",
                    fieldGroupClassName: "p-d-flex p-field p-row formly-group",
                    fieldGroup: this.sensorFieldGroups("main")
                  }
                ]
              }
            ]
          },
          {
            templateOptions: {
              label: "PAGES.sensors.groups.parameters",
              hidden: false
            },
            expressionProperties: {
              "templateOptions.hidden": (model) => this.emptyParameters.includes(model?.type)
            },
            fieldGroupClassName: "p-d-flex p-flex-column p-p-2",
            fieldGroup: this.sensorFieldGroups("parameters")
          }
        ]
      }
    ];
  }
}
