import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesSharedModule } from '@pages/pages-shared.module';
import { SharedModule } from '@modules/shared.module';

import { SensorTypesService } from '@pages/accounts/sensors/sensor-types.service';

import { SensorsComponent } from '@pages/accounts/sensors/sensors.component';
import { UpdateSensorComponent } from './update-sensor/update-sensor.component';

@NgModule({
    declarations: [SensorsComponent, UpdateSensorComponent],
    imports: [CommonModule, PagesSharedModule, SharedModule],
    providers: [SensorTypesService]
})
export class SensorsModule {}
